import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import CompletedOrderPopup from 'components/completed-order-popup/CompletedOrderPopup';
import CartItems from 'pages/cart/CartItems';
import CartPaymentOptions from 'pages/cart/CartPaymentOptions';
import CartDeliveryOptions from 'pages/cart/CartDeliveryOptions';
import SectionCard from 'components/cards/section-card/SectionCard';
import { Button, ButtonState, ButtonType } from 'components/button/Button';
import { DeliveryPayer, HandoverType } from 'types/ICustomer';
import useForm from 'hooks/useForm';
import { validateCustomerFields } from 'utils/validators/customerValidator';
import useDropdown from 'hooks/useDropdown';
import { useNotification } from 'components/notification/Notification';
import { redirectTo, ROUTES } from 'routes/routes';
import { useGetShoppingCartQuery } from 'store/api/cartApi';
import { useCalculateDeliveryQuery } from 'store/api/deliveryApi';
import { useCreateOrderMutation } from 'store/api/orderApi';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import { useCreateCompanyInvoiceMutation, useCreatePersonalInvoiceMutation } from 'store/api/invoiceApi';
import { InvoiceOptions } from './CartInvoice';
import './Cart.scss';
import PageSeoSection from '../../components/page-seo-section/PageSeoSection';

interface ICart extends IClassName {
}

interface OrderValues {
  deliveryAddressOrOffice: HandoverType
  firstName: string
  lastName: string
  phoneNumber: string
  postCode: string
  office?: {
    id: string
  }
  complex?: {
    id: string
  }
  street?: {
    id: string
  }
  blockNo?: string
  entranceNo?: string
  floorNo?: string
  apartmentNo?: string
  addressNote?: string
}

export enum DeliveryOptionsFields {
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber'
}

const initialDeliveryOptionsValues = {
  [DeliveryOptionsFields.FirstName]: '',
  [DeliveryOptionsFields.MiddleName]: '',
  [DeliveryOptionsFields.LastName]: '',
  [DeliveryOptionsFields.PhoneNumber]: ''
};

export enum DeliveryFields {
  DeliveryOption = 'deliveryOption',
  DeliveryAddressOrOffice = 'deliveryAddressOrOffice'
}

const initialDeliveryFields = {
  [DeliveryFields.DeliveryOption]: DeliveryPayer.Buyer,
  [DeliveryFields.DeliveryAddressOrOffice]: HandoverType.Address
};

const hasErrors = (obj: any) => {
  return Object.values(obj).some(value => typeof value === 'string' && value.trim() !== '');
};

const Cart = ({ className }: ICart) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Cart.path });
  const { data: shoppingCart, refetch } = useGetShoppingCartQuery();
  const [createOrder, { isLoading: isProcessingOrder }] = useCreateOrderMutation();
  const [createCompanyInvoice] = useCreateCompanyInvoiceMutation();
  const [createPersonInvoice] = useCreatePersonalInvoiceMutation();

  const [showCompletedOrderPopup, setShowCompletedOrderPopup] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [invoiceData, setInvoiceData] = useState<any>(null);

  const initialFormState = {
    ...initialDeliveryFields,
    ...initialDeliveryOptionsValues
  };

  const {
    selectedValues: selectedCity,
    handleSelectionChange: handleCityChange
  } = useDropdown([], true);

  const {
    selectedValues: selectedComplex,
    handleSelectionChange: handleComplexChange
  } = useDropdown([], true);

  const {
    selectedValues: selectedStreet,
    handleSelectionChange: handleStreetChange
  } = useDropdown([], true);

  const {
    selectedValues: selectedOffice,
    handleSelectionChange: handleOfficeChange
  } = useDropdown([], true);

  const onSubmit = async (values: any) => {
    try {
      const createOrderData = +values.deliveryAddressOrOffice === HandoverType.Office
        ? { ...values.office?.address, pickupOfficeId: values.office?.id }
        : {
            siteId: selectedCity.id,
            siteName: selectedCity.name,
            siteType: selectedCity.type,
            postCode: values.postCode,
            streetType: values.street?.type,
            streetName: values.street?.name,
            streetNo: selectedStreet?.streetNo,
            complexType: values.complex?.type || null,
            complexName: values.complex?.name,
            blockNo: values.blockNo,
            entranceNo: values.entranceNo,
            floorNo: values.floorNo,
            apartmentNo: values.apartmentNo,
            addressNote: values.addressNote,
            dropOffOfficeId: selectedOffice.id
          };

      const order: any = await createOrder({
        ...values,
        deliveryType: +values.deliveryAddressOrOffice,
        deliveryAddress: {
          ...createOrderData
        }
      });

      if (order.error) {
        showNotification(t('shoppingCart.error'));
        return;
      }

      if (order.data) {
        if (+invoiceData?.type === InvoiceOptions.Company) {
          await createCompanyInvoice({
            ...invoiceData,
            orderId: order.data
          });
        }

        if (+invoiceData?.type === InvoiceOptions.Person) {
          await createPersonInvoice({
            ...invoiceData,
            orderId: order.data
          });
        }

        setShowCompletedOrderPopup(true);
      }
    } catch (e) {
      console.error(e);
      showNotification(t('shoppingCart.error'));
    }
  };

  const {
    values,
    setValues,
    handleChange,
    errors
  } = useForm(initialFormState, onSubmit, validateCustomerFields, t('profile.personalDataChangeSuccess'), false);

  const { data: deliveryPrice, isLoading } = useCalculateDeliveryQuery({
    sellerId: shoppingCart?.sellerId,
    totalWeight,
    deliveryTypeValue: +values.deliveryAddressOrOffice
  }, {
    skip: !shoppingCart?.sellerId ||
    !values?.deliveryAddressOrOffice ||
    +values.deliveryAddressOrOffice === HandoverType.Office
      ? !selectedOffice?.id
      : !selectedCity?.id
  });

  const handleInvoiceData = (data: any) => {
    setInvoiceData(data);
  };

  useEffect(() => {
    void refetch();
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      firstName: shoppingCart?.firstName ?? '',
      middleName: shoppingCart?.middleName ?? '',
      lastName: shoppingCart?.lastName ?? '',
      phoneNumber: shoppingCart?.phoneNumber ?? ''
    });
  }, [shoppingCart]);

  const handleCitySelectionChange = (selectedCityValue: any) => {
    handleCityChange(selectedCityValue);
  };

  const createOrderHandler = async () => {
    await onSubmit(values);
  };

  const handleItemChanged = async () => {
    await refetch();
  };

  useEffect(() => {
    if (shoppingCart?.items.length) {
      const newTotalPrice = shoppingCart.items.map((item) => item.productPrice.amount * item.quantity);
      setTotalPrice(newTotalPrice.reduce((acc, curr) => acc + curr, 0));

      const newTotalWeight = shoppingCart.items.map((item) => item.productWeight * item.quantity);
      setTotalWeight(newTotalWeight.reduce((acc, curr) => acc + curr, 0));
    }
  }, [shoppingCart?.items]);

  const disableOrderButton = (values: OrderValues, invoiceData: any): boolean => {
    if (!values) {
      console.error('No values provided');
      return false;
    }

    const isOffice = +values.deliveryAddressOrOffice === HandoverType.Office;
    const baseCheck = !values.firstName || !values.lastName || !values.phoneNumber || !values.postCode;

    let addressCheck: boolean;
    if (isOffice) {
      addressCheck = !values.office?.id;
    } else {
      const complexOrStreetProvided = values.complex?.id ?? values.street?.id;
      addressCheck = !complexOrStreetProvided || !values.blockNo || !values.floorNo || !values.apartmentNo;
    }

    let invoiceCheck = false;
    if (invoiceData?.type) {
      if (invoiceData.type === InvoiceOptions.Company) {
        invoiceCheck = !invoiceData.companyName || !invoiceData.unifiedIdentificationCode || !invoiceData.address || !invoiceData.city || !invoiceData.country || !invoiceData.vatNumber || !invoiceData.accountablePerson || hasErrors(invoiceData.errors);
      } else if (invoiceData.type === InvoiceOptions.Person) {
        invoiceCheck = !invoiceData.fullName || !invoiceData.unifiedIdentificationCode || !invoiceData.address || !invoiceData.city || !invoiceData.country || hasErrors(invoiceData.errors);
      }
    }

    return baseCheck || addressCheck || invoiceCheck || isLoading || isProcessingOrder;
  };

  return (
    <section className={concatClassNames(className, 'cart')}>
      {shoppingCart === undefined || shoppingCart?.items.length === 0
        ? (
            <>
              <PageSeoSection pageSeo={pageSeoData}/>
              <div className="cart-empty">
                <h2 className="cart-empty-title">
                  {t('shoppingCart.emptyCart')}
                </h2>
              </div>
            </>)
        : (
            <>
              <PageSeoSection pageSeo={pageSeoData}/>
              <div className="cart-content justify-center align-start">
                <div className="flex flex-column cart-delivery-options" style={{ gap: 14 }}>
                <CartDeliveryOptions
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    handleCitySelectionChange={handleCitySelectionChange}
                    handleComplexChange={handleComplexChange}
                    handleStreetChange={handleStreetChange}
                    handleOfficeChange={handleOfficeChange}
                    selectedCity={selectedCity}
                    selectedOffice={selectedOffice}
                    selectedComplex={selectedComplex}
                    selectedStreet={selectedStreet}
                />
                <CartPaymentOptions
                    onInvoiceDataChange={handleInvoiceData}
                />
              </div>
              <div className="flex flex-column" style={{ gap: 14 }}>
                <CartItems
                    shoppingCart={shoppingCart?.items}
                    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                    handleItemChanged={handleItemChanged}
                />
                <SectionCard className="cart-delivery">
                  <div className="flex space-between cart-total-row">
                    <span>{t('shoppingCart.products')} ({shoppingCart?.items.length})</span>
                    <span>{totalPrice.toFixed(2)} лв.</span>
                  </div>
                  <div className="flex space-between cart-total-row">
                    <span>{t('shoppingCart.delivery')}</span>
                    <span>{deliveryPrice?.toFixed(2) ?? 0} лв.</span>
                  </div>
                  <div className="separator"/>
                  <div className="flex space-between cart-total-price">
                    <span>{t('shoppingCart.total')}</span>
                    <span>{(totalPrice + (deliveryPrice ?? 0)).toFixed(2)} лв.</span>
                  </div>
                  <Button
                      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                      onClick={createOrderHandler}
                      type={ButtonType.primary}
                      text={t('shoppingCart.order')}
                      className="cart-order-button"
                      state={disableOrderButton(values as any, invoiceData) ? ButtonState.disabled : ButtonState.enabled}
                  />
                </SectionCard>
              </div>
            </div>
          </>
          )}
      {showCompletedOrderPopup && (
          <CompletedOrderPopup
              open={showCompletedOrderPopup}
              onClose={() => {
                setShowCompletedOrderPopup(false);
                redirectTo(ROUTES.Home.path);
              }}
          />
      )}
    </section>
  );
};

export default Cart;
