import i18n from 'i18next';
import {
  createValidator,
  type FieldValidators,
  maxLength,
  maxNumber,
  minLength,
  minNumber,
  validateFields
} from 'utils/validators/validators';
import { type FormErrors } from 'hooks/useForm';
import { type IProductInventoryItem, type ProductLabel } from 'types/IProduct';

interface IFormValues {
  nameEnglish: string
  nameBulgarian: string
  isGift: boolean
  price: number
  weight: number
  label: ProductLabel
  descriptionEnglish: string
  descriptionBulgarian: string
  gendersIds: number[]
  categoryId: string | number
  subCategoryId: string | number
  nestedSubCategoryId: string | number
  inventoryItems: IProductInventoryItem[]
}

const MIN_NAME_LENGTH: number = 2;
const MAX_NAME_LENGTH: number = 120;
const MIN_DESCRIPTION_LENGTH: number = 10;
const MAX_DESCRIPTION_LENGTH: number = 2400;
const MIN_PRICE_VALUE: number = 1;
const MAX_PRICE_VALUE: number = Number.MAX_SAFE_INTEGER;
const MIN_WEIGHT_VALUE: number = 0.1;
const MAX_WEIGHT_VALUE: number = 10;
const MIN_QUANTITY_VALUE: number = 1;
const MAX_QUANTITY_VALUE: number = Number.MAX_SAFE_INTEGER;

const validateProductName = createValidator(
  minLength(MIN_NAME_LENGTH),
  maxLength(MAX_NAME_LENGTH)
);

const validateDescription = createValidator(
  minLength(MIN_DESCRIPTION_LENGTH),
  maxLength(MAX_DESCRIPTION_LENGTH)
);

const validatePrice = createValidator(
  minNumber(MIN_PRICE_VALUE),
  maxNumber(MAX_PRICE_VALUE)
);

const validateWeight = createValidator(
  minNumber(MIN_WEIGHT_VALUE),
  maxNumber(MAX_WEIGHT_VALUE)
);

const validateQuantity = createValidator(
  minNumber(MIN_QUANTITY_VALUE),
  maxNumber(MAX_QUANTITY_VALUE)
);

const validateCategoryId = (id: number, isRequired?: boolean): string | null => {
  if (!id) {
    return i18n.t('validations.atLeastOneCategoryIsRequired');
  }

  return null;
};

const validateGenderIds = (gendersIds: number[]): string | null => {
  if (gendersIds.length === 0) {
    return i18n.t('validations.atLeastOneGenderIsRequired');
  }

  return null;
};

const validateInventoryItems = (inventoryItems: IProductInventoryItem[], categoryId: string | number) => {
  const noSizeRequired = [3, 4, 5, 7, 8, 9, 10, 11, 12];

  return inventoryItems.map(item => {
    const errors: any = {};

    // Check if sizeId is required based on the categoryId
    if (!noSizeRequired.includes(+categoryId) && !item.sizeId) {
      errors.sizeId = i18n.t('validations.sizeIsRequired');
    }

    // Validate quantity
    if (!item.quantity) {
      errors.quantity = i18n.t('validations.quantityIsRequired');
    } else {
      const quantityError = validateQuantity(item.quantity);
      if (quantityError) {
        errors.quantity = quantityError;
      }
    }

    return errors;
  });
};

const fieldValidators: FieldValidators = {
  nameEnglish: validateProductName,
  nameBulgarian: validateProductName,
  descriptionEnglish: validateDescription,
  descriptionBulgarian: validateDescription,
  price: validatePrice,
  weight: validateWeight,
  categoryId: validateCategoryId,
  gendersIds: validateGenderIds
};

const validateProductFields = (values: IFormValues): FormErrors => {
  // Validate all fields except inventory items
  const errors: any = validateFields(values, {
    ...fieldValidators,
    inventoryItems: () => null,
    price: () => values.isGift ? null : validatePrice(values.price)
  });

  const inventoryItemErrors = validateInventoryItems(values.inventoryItems, values.categoryId);
  const hasInventoryErrors = inventoryItemErrors.some(itemErrors => Object.keys(itemErrors).length > 0);

  if (hasInventoryErrors) {
    errors.inventoryItems = inventoryItemErrors;
  }

  return errors;
};

export {
  validateProductFields
};
