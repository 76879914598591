import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
import './Slider.scss';

interface ISlider {
  slides: React.ReactNode[]
  slidesToShow: number
  className?: string
  minSlidesToShow?: number
  pagination?: boolean
  spaceBetween?: number
  autoplay?: boolean
}

const MIN_SLIDES_TO_SHOW = 1;

const Slider = ({ slides, slidesToShow, className, minSlidesToShow, pagination, spaceBetween, autoplay }: ISlider) => {
  const shouldHaveSliderEffects = slides && slides?.length > (minSlidesToShow || MIN_SLIDES_TO_SHOW);
  const slidesToShowPerView = slides.length < slidesToShow ? slides.length : slidesToShow;
  const hasAutoplay = autoplay ?? true;

  return (
    <Swiper
      className={className}
      draggable={shouldHaveSliderEffects}
      grabCursor={shouldHaveSliderEffects}
      centeredSlidesBounds={true}
      centerInsufficientSlides={true}
      spaceBetween={spaceBetween ?? 14}
      slidesPerView={slidesToShowPerView}
      slidesPerGroup={slidesToShowPerView}
      autoplay={
        !hasAutoplay
          ? undefined
          : {
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false
            }
      }
      pagination={{
        enabled: pagination ?? false,
        type: 'bullets',
        dynamicBullets: true,
        clickable: true
      }}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {slides?.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
