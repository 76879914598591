import i18n from 'i18next';
import { createValidator, maxLength, minLength, validateFields } from 'utils/validators/validators';
import { HandoverType } from 'types/ICustomer';

const MIN_SITE_TYPE_LENGTH = 2;
const MAX_SITE_TYPE_LENGTH = 20;
const MIN_SITE_NAME_LENGTH = 2;
const MAX_SITE_NAME_LENGTH = 50;
const MIN_POST_CODE_LENGTH = 2;
const MAX_POST_CODE_LENGTH = 10;
const MIN_STREET_TYPE_LENGTH = 2;
const MAX_STREET_TYPE_LENGTH = 15;
const MIN_STREET_NAME_LENGTH = 2;
const MAX_STREET_NAME_LENGTH = 50;
const MIN_STREET_NO_LENGTH = 1;
const MAX_STREET_NO_LENGTH = 10;
const MIN_COMPLEX_TYPE_LENGTH = 2;
const MAX_COMPLEX_TYPE_LENGTH = 15;
const MIN_COMPLEX_NAME_LENGTH = 2;
const MAX_COMPLEX_NAME_LENGTH = 50;
const MIN_BLOCK_NO_LENGTH = 1;
const MAX_BLOCK_NO_LENGTH = 32;
const MIN_ENTRANCE_NO_LENGTH = 1;
const MAX_ENTRANCE_NO_LENGTH = 10;
const MIN_FLOOR_NO_LENGTH = 1;
const MAX_FLOOR_NO_LENGTH = 10;
const MIN_APARTMENT_NO_LENGTH = 1;
const MAX_APARTMENT_NO_LENGTH = 10;
const MIN_ADDRESS_NOTE_LENGTH = 1;
const MAX_ADDRESS_NOTE_LENGTH = 200;

const validateSiteType = createValidator(
  minLength(MIN_SITE_TYPE_LENGTH),
  maxLength(MAX_SITE_TYPE_LENGTH)
);

const validateSiteName = createValidator(
  minLength(MIN_SITE_NAME_LENGTH),
  maxLength(MAX_SITE_NAME_LENGTH)
);
const validatePostCode = createValidator(
  minLength(MIN_POST_CODE_LENGTH),
  maxLength(MAX_POST_CODE_LENGTH)
);

const validateStreetType = createValidator(
  minLength(MIN_STREET_TYPE_LENGTH),
  maxLength(MAX_STREET_TYPE_LENGTH)
);

const validateStreetName = createValidator(
  minLength(MIN_STREET_NAME_LENGTH),
  maxLength(MAX_STREET_NAME_LENGTH)
);

const validateStreetNo = createValidator(
  minLength(MIN_STREET_NO_LENGTH),
  maxLength(MAX_STREET_NO_LENGTH)
);

const validateComplexType = createValidator(
  minLength(MIN_COMPLEX_TYPE_LENGTH),
  maxLength(MAX_COMPLEX_TYPE_LENGTH)
);

const validateComplexName = createValidator(
  minLength(MIN_COMPLEX_NAME_LENGTH),
  maxLength(MAX_COMPLEX_NAME_LENGTH)
);

const validateBlockNo = createValidator(
  minLength(MIN_BLOCK_NO_LENGTH),
  maxLength(MAX_BLOCK_NO_LENGTH)
);

const validateEntranceNo = createValidator(
  minLength(MIN_ENTRANCE_NO_LENGTH),
  maxLength(MAX_ENTRANCE_NO_LENGTH)
);

const validateFloorNo = createValidator(
  minLength(MIN_FLOOR_NO_LENGTH),
  maxLength(MAX_FLOOR_NO_LENGTH)
);

const validateApartmentNo = createValidator(
  minLength(MIN_APARTMENT_NO_LENGTH),
  maxLength(MAX_APARTMENT_NO_LENGTH)
);

const validateAddressNote = createValidator(
  minLength(MIN_ADDRESS_NOTE_LENGTH),
  maxLength(MAX_ADDRESS_NOTE_LENGTH)
);

const fieldValidators = {
  siteType: validateSiteType,
  siteName: validateSiteName,
  postCode: validatePostCode,
  streetType: validateStreetType,
  streetName: validateStreetName,
  streetNo: validateStreetNo,
  complexType: validateComplexType,
  complexName: validateComplexName,
  blockNo: validateBlockNo,
  entranceNo: validateEntranceNo,
  floorNo: validateFloorNo,
  apartmentNo: validateApartmentNo,
  addressNote: validateAddressNote
};

const validateCity = (city: string) => {
  if (city) {
    return i18n.t('validations.validateCity');
  }

  return null;
};

const validateOffice = (office: string) => {
  if (!office) {
    return i18n.t('validations.validateOffice');
  }

  return null;
};

const validateComplex = (complex: string) => {
  if (!complex) {
    return i18n.t('validations.validateComplex');
  }

  return null;
};

const validateStreet = (street: string) => {
  if (!street) {
    return i18n.t('validations.validateStreet');
  }

  return null;
};

const validateProfileOptions = (values: any) => {
  let validators = {};

  if (+values.deliveryAddressOrOffice === HandoverType.Office) {
    validators = {
      ...validators,
      city: () => validateCity(values.city?.name),
      office: validateOffice
    };
  }

  if (+values.deliveryAddressOrOffice === HandoverType.Address) {
    validators = {
      ...validators,
      city: () => validateCity(values.city?.name),
      ...fieldValidators
    };

    if (values.complex) {
      validators = {
        ...validators,
        complex: validateComplex
      };
    }

    if (values.street) {
      validators = {
        ...validators,
        street: validateStreet
      };
    }
  }

  return validateFields(values, validators);
};

export {
  validateProfileOptions
};
