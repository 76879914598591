const toLowerCase = (str: string) => str.toLowerCase();

const truncateTextByWords = (text: string, maxWords: number) => {
  const words = text.split(' ');
  if (words.length <= maxWords) {
    return text;
  }
  const truncatedWords = words.slice(0, maxWords);
  return truncatedWords.join(' ') + '...';
};

const generateGuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
  });
};

const urlize = (name: string) =>
  name
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

const extractTextFromHtml = (html: string) => {
  // Create a temporary DOM element
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  const textOnly = tempDiv.textContent || tempDiv.innerText;
  return textOnly
    .replace(/<br>/g, '\n')
    .replace(/<br \/>/g, '\n')
    .trim();
};

export {
  toLowerCase,
  truncateTextByWords,
  generateGuid,
  urlize,
  extractTextFromHtml
};
