import { findItemByValue, findItemsByValues } from 'utils/trees';
import { type IMetaTag } from './IPage';
import { head, intersection, isEmpty, isNil } from 'lodash';

interface ICategory {
  id?: number
  descriptionBg?: string
  descriptionEn?: string
  heading1Bg?: string
  heading1En?: string
  nameEn?: string
  nameBg?: string
  canonicalUrl?: string
  parentId?: number
  subcategories?: ICategory[]
  metaTags?: IMetaTag[]
}

const findParentCategoryByIds = (categories: ICategory[], categoryIds: number[]): ICategory | null => {
  // Find all categories by their IDs
  const selectedCategories = findCategoriesByIds(categories, categoryIds);

  const selectedCategoriesWithoutParents = selectedCategories
    .filter(parent => !selectedCategories.some(child => child.parentId === parent.id));

  // Get parent IDs of the selected categories
  const parentIds = selectedCategoriesWithoutParents.map(cat => cat.parentId);

  // Find the common parent ID
  const commonParentId = head(intersection(...parentIds.map(pid => [pid])));

  // Return the parent category
  return findCategoryById(categories, commonParentId);
};

const findCategoryByCanonicalUrl = (categories?: ICategory[], canonicalUrl?: string): ICategory | null =>
  findItemByValue(
    categories,
    canonicalUrl,
    (category: ICategory, canonicalUrl: string) => category.canonicalUrl?.toLowerCase() === canonicalUrl?.toLowerCase(),
    'subcategories'
  );

const findCategoryById = (categories?: ICategory[], id?: number): ICategory | null =>
  findItemByValue(
    categories,
    id,
    (category: ICategory, id: number) => category.id === id,
    'subcategories'
  );

const findCategoriesByIds = (categories: ICategory[], ids: number[]): ICategory[] =>
  findItemsByValues(
    categories,
    ids,
    (category: ICategory, id: number) => id === category.id,
    'subcategories'
  );

const findCategoryByName = (categoriesApi?: ICategory[], name?: string): ICategory | null =>
  findItemByValue(
    categoriesApi,
    name,
    (category: ICategory, name: string) => category.nameEn?.toLowerCase() === name?.toLowerCase(),
    'subcategories'
  );

const findTopParentById = (categories?: ICategory[], id?: number) => {
  if (isEmpty(categories) || isNil(id)) {
    return null;
  }
  let current = findCategoryById(categories, id);
  while (true) {
    const parent = findParentCategoryByIds(categories as ICategory[], [current?.id] as number[]);
    if (isNil(parent) || isEmpty(parent)) {
      return current;
    }
    current = parent;
  }
};

export type {
  ICategory
};

export {
  findCategoryByCanonicalUrl,
  findCategoryById,
  findCategoryByName,
  findParentCategoryByIds,
  findTopParentById
};
