import { useEffect, useState } from 'react';

const BREAKPOINTS = {
  smallMobile: 400,
  mobile: 600,
  smallTablet: 768,
  tablet: 900,
  laptop: 1024,
  desktop: 1280,
  largeDesktop: 1536
};

const WINDOW_WIDTH_EVENT_NAME = 'resize';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener(WINDOW_WIDTH_EVENT_NAME, handleResize);

    return () => {
      window.removeEventListener(WINDOW_WIDTH_EVENT_NAME, handleResize);
    };
  }, []);

  const isSmallMobile = windowWidth < BREAKPOINTS.smallMobile && windowWidth < BREAKPOINTS.mobile;
  const isMobile = windowWidth < BREAKPOINTS.mobile;
  const isTablet = windowWidth >= BREAKPOINTS.mobile && windowWidth < BREAKPOINTS.laptop;
  const isSmallTablet = windowWidth >= BREAKPOINTS.mobile && windowWidth < BREAKPOINTS.smallTablet;
  const isLaptop = windowWidth >= BREAKPOINTS.tablet && windowWidth < BREAKPOINTS.desktop;
  const isDesktop = windowWidth >= BREAKPOINTS.laptop && windowWidth < BREAKPOINTS.largeDesktop;
  const isLargeDesktop = windowWidth >= BREAKPOINTS.largeDesktop;

  return {
    isSmallMobile,
    isMobile,
    isSmallTablet,
    isTablet,
    isLaptop,
    isDesktop,
    isLargeDesktop,
    windowWidth
  };
};

export default useWindowWidth;
