import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { Language } from 'i18n';
import { type IProductMineResponse } from 'types/IProduct';
import { getCurrencySymbol } from 'utils/currency';
import { redirectTo, ROUTES } from 'routes/routes';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PromoteIcon } from 'assets/icons/promote.svg';
import { ReactComponent as CarouselIcon } from 'assets/icons/carousel.svg';
import { ReactComponent as AddBadgeIcon } from 'assets/icons/add-badge.svg';
import useWindowWidth from 'hooks/useWindowWidth';
import PaidAdsPopup from 'components/paid-ads/PaidAdsPopup';
import { useApplyBadgeMutation, useArchiveProductMutation, useShowOnHomePageMutation } from 'store/api/productApi';
import { useNotification } from 'components/notification/Notification';
import AddToCarouselConfirmationPopup
  from 'components/common/add-to-carousel-confirmation-popup/AddToCarouselConfirmationPopup';
import ChooseBadgePopup from 'components/common/choose-badge-popup/ChooseBadgePopup';
import concatClassNames from 'utils/classNames';
import BadgeActivationPopup from 'components/common/badge-activation-popup/BadgeActivationPopup';
import DeleteConfirmationPopup from 'components/delete-confirmation-popup/DeleteConfirmationPopup';
import { useCustomerDetails } from 'pages/profile/Profile';
import CloudinaryImage from 'components/common/cloudinary-img/CloudinaryImage';
import './ProfileProductCard.scss';

interface IProfileProductCard {
  product: IProductMineResponse
  cardType?: CardType
}

export enum CardType {
  Active = 'active',
  Complete = 'complete',
}

const ProfileProductCard = ({
  product,
  cardType
}: IProfileProductCard) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isMobile } = useWindowWidth();
  const { showNotification } = useNotification();
  const { customerDetails } = useCustomerDetails();
  const currentLanguage = i18n.language;

  const [showOnHomePage] = useShowOnHomePageMutation();
  const [applyBadge] = useApplyBadgeMutation();
  const [archiveProduct] = useArchiveProductMutation();

  const [showCarouselPopup, setShowCarouselPopup] = useState(false);
  const [showPaidAdsPopup, setShowPaidAdsPopup] = useState(false);
  const [showBadgePopup, setShowBadgePopup] = useState(false);
  const [showConfirmationBadgePopup, setShowConfirmationBadgePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [preselectedPaidAdId, setPreselectedPaidAdId] = useState<number | undefined>();
  const [selectedBadge, setSelectedBadge] = useState<any>(null);
  const [hasActiveBadge, setHasActiveBadge] = useState<number | null>(null);
  const [hasActiveCarousel, setHasActiveCarousel] = useState<number | null>(null);

  const canAddBadge = customerDetails?.purchasedBadges.length > 0;
  const canApplyBadgesToAllProducts = customerDetails?.canApplyBadgesToAllProducts;
  const canPromote = canApplyBadgesToAllProducts || customerDetails?.canPresentToHomePage;

  const isBadgeActive = hasActiveBadge === product.id || product.badge.value > 1;
  const isCarouselActive = hasActiveCarousel === product.id || product.showOnHomePage;

  const onDeleteClick = () => {
    setShowDeletePopup(true);
  };

  const onEditClick = () => {
    redirectTo(`/profile/edit/${product.id}`);
  };

  const onPromoteClick = () => {
    setShowPaidAdsPopup(true);
  };

  const onAddBadgeClick = () => {
    if (customerDetails?.purchasedBadges.length === 0) {
      setShowPaidAdsPopup(true);
    } else {
      if (isBadgeActive) {
        setSelectedBadge({ id: product.badge.value });
      }

      setShowBadgePopup(true);
    }
  };

  const onCarouselClick = () => {
    if (customerDetails?.canPresentToHomePage) {
      setShowCarouselPopup(true);
    } else {
      setPreselectedPaidAdId(1);
      setShowPaidAdsPopup(true);
    }
  };

  const showOnHomePageClick = async () => {
    try {
      const response: any = await showOnHomePage(product.id);

      if (response.error) {
        showNotification(response.error.data[0]);
        setShowCarouselPopup(false);
      } else {
        setHasActiveCarousel(product.id);
        showNotification(t('profile.products.productSuccessfullyAddedToCarousel'));
        setShowCarouselPopup(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleApplyBadge = async () => {
    try {
      const response: any = await applyBadge({
        id: product.id,
        badge: selectedBadge.id
      });

      if (response.error) {
        showNotification(response.error.data[0]);
      } else {
        setHasActiveBadge(product.id);
        showNotification(t('profile.products.badgeAddedSuccessfully'));
        setShowBadgePopup(false);
        setShowConfirmationBadgePopup(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = async () => {
    try {
      const response: any = await archiveProduct({
        id: product.id
      });

      if (response.error) {
        showNotification(t('errors.anErrorOccurred'));
      } else {
        showNotification(t('profile.products.productSuccessfullyDeleted'));
      }

      setShowDeletePopup(false);
      navigate(ROUTES.Home.path);
    } catch (e) {
      console.error(e);
    }
  };

  const publishedOnDate = (
    <span className="profile-product-card-content-published-on">
      {t('profile.products.publishedOn')}: {new Date(product.createdOn).toLocaleDateString()}
    </span>
  );

  const shownOnHomePageText = product.showOnHomePage
    ? t('profile.products.shownInCarousel')
    : t('profile.products.showInCarousel');

  return (
    <div className="flex space-between profile-product-card">
      <div className="flex justify-center align-center" style={{ gap: 14 }}>
        <div className="profile-product-card-image">
          <CloudinaryImage imagePublicId={product.mainImageSource}/>
        </div>
        <div className="flex flex-column profile-product-card-content">
          {!isMobile && publishedOnDate}
          <div className="profile-product-card-content-name">
            {currentLanguage === Language.BG ? product.name.bulgarian : product.name.english}
          </div>
          <div className="profile-product-card-content-price">
            {product.price.amount.toFixed(2)} {getCurrencySymbol(product.price.currency)}
          </div>
        </div>
      </div>
      {cardType === CardType.Active && (
        <div className="flex justify-center align-center profile-product-card-content-actions">
          {!canPromote && (
            <IconButton className="flex flex-column profile-product-card-content-actions-button"
                        onClick={onPromoteClick}>
              <PromoteIcon/>
              <span className="profile-product-card-content-actions-button-text">
                {t('profile.products.promote')}
              </span>
            </IconButton>
          )}
          {canPromote && (
            <IconButton
              disabled={product.showOnHomePage}
              className={concatClassNames('flex flex-column profile-product-card-content-actions-button', isCarouselActive ? 'active' : '')}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onClick={onCarouselClick}
            >
              <CarouselIcon/>
              <span className="profile-product-card-content-actions-button-text">
                {shownOnHomePageText}
              </span>
            </IconButton>
          )}
          {canAddBadge && (
            canApplyBadgesToAllProducts && isBadgeActive
              ? (
                <IconButton
                  className={concatClassNames('flex flex-column profile-product-card-content-actions-button', isBadgeActive ? 'active' : '')}
                  onClick={onAddBadgeClick}
                >
                  <AddBadgeIcon/>
                  <span className="profile-product-card-content-actions-button-text">
                    {t('profile.products.changeBadge')}
                  </span>
                </IconButton>
                )
              : (
              <IconButton
                disabled={product.badge.value > 1}
                className={concatClassNames('flex flex-column profile-product-card-content-actions-button', isBadgeActive ? 'active' : '')}
                onClick={onAddBadgeClick}
              >
                <AddBadgeIcon/>
                <span className="profile-product-card-content-actions-button-text">
                  {t('profile.products.addBadge')}
                </span>
              </IconButton>
                )
          )}
          <IconButton className="flex flex-column profile-product-card-content-actions-button" onClick={onEditClick}>
            <EditIcon/>
            <span className="profile-product-card-content-actions-button-text">
              {t('profile.products.edit')}
            </span>
          </IconButton>
          <IconButton className="flex flex-column profile-product-card-content-actions-button" onClick={onDeleteClick}>
            <CloseIcon/>
            <span className="profile-product-card-content-actions-button-text">
              {t('profile.products.delete')}
            </span>
          </IconButton>
        </div>
      )}
      {showPaidAdsPopup && (
        <PaidAdsPopup
          onClose={() => {
            setShowPaidAdsPopup(false);
          }}
          preselectedPaidAdId={preselectedPaidAdId}
          open={showPaidAdsPopup}/>
      )}
      {showBadgePopup && (
        <ChooseBadgePopup
          onAccept={() => {
            setShowConfirmationBadgePopup(true);
            setShowBadgePopup(false);
          }}
          open={showBadgePopup}
          selectedBadge={selectedBadge}
          onBadgeClick={(badge: any) => {
            setSelectedBadge(badge);
          }}
          onClose={() => {
            setShowBadgePopup(false);
          }}
        />
      )}
      {showCarouselPopup && (
        <AddToCarouselConfirmationPopup
          open={showCarouselPopup}
          onClose={() => {
            setShowCarouselPopup(false);
          }}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onAccept={showOnHomePageClick}
        />
      )}
      {showConfirmationBadgePopup && (
        <BadgeActivationPopup
          open={showConfirmationBadgePopup}
          onClose={() => {
            setShowConfirmationBadgePopup(false);
          }}
          title={isBadgeActive ? t('paidAds.badgeActivationText') : t('paidAds.0')}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onAccept={handleApplyBadge}
        />
      )}
      {showDeletePopup && (
        <DeleteConfirmationPopup
          open={showDeletePopup}
          onClose={() => {
            setShowDeletePopup(false);
          }}
          text={t('productActions.areYouSureYouWantToDeleteProduct')}
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onAccept={handleDelete}
        />
      )}
    </div>
  );
};

export default ProfileProductCard;
