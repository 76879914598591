import React from 'react';
import Filter from 'components/filter-menu/Filter';

interface ISpecialFilterProps {
  specialFilters: any[]
  selectedSpecialFilters: any
  setSelectedSpecialFilters: any
}

const SpecialFilter = ({ specialFilters, selectedSpecialFilters, setSelectedSpecialFilters }: ISpecialFilterProps) => (
  <Filter
    items={specialFilters}
    selectedItems={selectedSpecialFilters}
    setSelectedItems={setSelectedSpecialFilters}
    className="generic-filters"
  />
);

export default SpecialFilter;
