import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { storeToken, USER_TOKEN } from 'utils/token';

const useAuthToken = () => {
  // gets the token after the returnUrl redirect that is set from the server
  const initialToken = Cookies.get(USER_TOKEN);

  useEffect(() => {
    if (initialToken) {
      // remove the server cookie
      Cookies.remove(USER_TOKEN);

      // set a new cookie with the same name on the client
      storeToken(initialToken);
    }
  }, [initialToken]);
};

export default useAuthToken;
