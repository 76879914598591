import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryMutation,
  useGetAllCategoriesQuery
} from '../../../store/api/categoiesApi';
import { findCategoryByCanonicalUrl, type ICategory } from '../../../types/ICategory';
import { Button, ButtonColors, ButtonState, ButtonType } from '../../button/Button';
import Dialog from '@mui/material/Dialog';
import FormControl, { FormControlType } from '../../forms/FormControl';
import TextEditor from '../../editor/TextEditor';
import './AdminCategoriesTab.scss';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { urlize } from 'utils/text';
import { useTranslation } from 'react-i18next';
import { type IMetaTag } from 'types/IPage';
import { getLocalizedValue } from 'utils/localization';
import { useI18n } from 'hooks/usei18n';
import { useGetProductsQuery } from 'store/api/productApi';

interface IDeleteConfirmationModalProps {
  isOpen: boolean
  dialogData?: ICategory
  closeModal: () => void
  refetch: () => Promise<any>
}

interface IConfirmationModalProps extends IDeleteConfirmationModalProps {
  title: string
  isEdit: boolean
}

const initialValidationErrors = {
  descriptionEn: '',
  descriptionBg: '',
  nameEn: '',
  nameBg: '',
  canonicalUrl: '',
  metaName: '',
  metaContent: '',
  metaContentEn: '',
  headingBg: '',
  headingEn: ''
};

const DeleteConfirmationModal = (props: IDeleteConfirmationModalProps) => {
  const { isOpen, dialogData, closeModal, refetch } = props;
  const { language } = useI18n();
  const { t } = useTranslation();

  const [deleteCategory, { isLoading, isError }] = useDeleteCategoryMutation();

  const {
    id,
    nameEn,
    nameBg
  } = dialogData || {};

  const getName = ({ nameBg, nameEn }: ICategory) => getLocalizedValue(language, nameBg, nameEn);

  const { data: productsData } = useGetProductsQuery({
    categories: [Number(id)]
  });

  const products = useMemo(
    () => productsData?.models ?? [],
    [productsData]
  );

  const productsCount = useMemo(() => products.length, [products]);

  const onYes = async () => {
    if (isNil(id)) {
      return;
    }

    await deleteCategory({ id });
    await refetch();
    closeModal();
  };

  const title = useMemo(() => {
    console.log(productsCount);
    return productsCount === 0
      ? t('administration.categories.deleteCategoryConfirm', { categoryName: getName({ nameBg, nameEn }) })
      : t('administration.categories.deleteCategoryWithProductsConfirmWithProducts', { categoryName: getName({ nameBg, nameEn }), productsCount });
  },
  [productsCount]);

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      {isError && <div className="error-in-modal">{t('errors.anErrorOccurred')}</div>}
      <DialogActions>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button state={isLoading ? ButtonState.disabled : ButtonState.enabled} onClick={onYes}>{t('common.yes')}</Button>
        <Button state={isLoading ? ButtonState.disabled : ButtonState.enabled} onClick={closeModal}>{t('common.no')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationModal = (props: IConfirmationModalProps) => {
  const { isOpen, title, dialogData, isEdit, closeModal, refetch } = props;
  const { t } = useTranslation();

  const { data: categories } = useGetAllCategoriesQuery();

  const {
    id,
    parentId,
    descriptionEn,
    descriptionBg,
    heading1Bg,
    heading1En,
    nameEn,
    nameBg,
    canonicalUrl,
    metaTags
  } = dialogData || {};

  const [descriptionEnValue, setDescriptionEnValue] = useState<string>(descriptionEn ?? '');
  const [descriptionBgValue, setDescriptionBgValue] = useState<string>(descriptionBg ?? '');
  const [nameEnValue, setNameEnValue] = useState<string>(nameEn ?? '');
  const [nameBgValue, setNameBgValue] = useState<string>(nameBg ?? '');
  const [canonicalUrlValue, setCanonicalUrlValue] = useState<string>(canonicalUrl || '');
  const [heading1BgValue, setHeading1BgValue] = useState<string>('');
  const [heading1EnValue, setHeading1EnValue] = useState<string>('');
  const [metaTagsValue, setMetaTagsValue] = useState(metaTags || []);

  const [inputValidationErrors, setInputValidationErrors] = useState(initialValidationErrors);

  const [
    editCategory,
    {
      isLoading: editCategoryIsLoading,
      isError: editCategoryIsError
    }] = useEditCategoryMutation();
  const [
    addNewCategory,
    {
      isLoading: addNewCategoryIsLoading,
      isError: addNewCategoryIsError
    }] = useAddCategoryMutation();

  useEffect(() => {
    if (isEdit) {
      setDescriptionEnValue(descriptionEn || '');
      setDescriptionBgValue(descriptionBg || '');
      setNameEnValue(nameEn || '');
      setNameBgValue(nameBg || '');
      setCanonicalUrlValue(canonicalUrl || '');
      setHeading1BgValue(heading1Bg || '');
      setHeading1EnValue(heading1En || '');
      setMetaTagsValue(metaTags || []);
    } else {
      setDescriptionBgValue('');
      setDescriptionEnValue('');
      setNameEnValue('');
      setNameBgValue('');
      setCanonicalUrlValue('');
      setHeading1BgValue('');
      setHeading1EnValue('');
      setMetaTagsValue([]);
    }
  }, [descriptionBg, descriptionBg, nameEn, nameBg, heading1Bg, heading1En, canonicalUrl, metaTags, isEdit]);
  const onYes = async () => {
    const newCategoryItem: ICategory = {
      descriptionEn: descriptionEnValue || undefined,
      descriptionBg: descriptionBgValue || undefined,
      nameEn: nameEnValue || undefined,
      nameBg: nameBgValue || undefined,
      canonicalUrl: canonicalUrlValue || undefined,
      heading1Bg: heading1BgValue || undefined,
      heading1En: heading1EnValue || undefined,
      parentId,
      metaTags: metaTagsValue || []
    };
    if (isEdit) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await editCategory({ categoryId: id!, category: newCategoryItem });
    } else {
      await addNewCategory(newCategoryItem);
      setDescriptionBgValue('');
      setDescriptionEnValue('');
      setNameBgValue('');
      setNameEnValue('');
      setCanonicalUrlValue('');
      setMetaTagsValue([]);
    }

    await refetch();
    closeModal();
  };

  const onValueChange = (key: string, value: string, setValueFunc: (value: React.SetStateAction<string | undefined>) => void) => {
    if (key === 'nameEn' || key === 'nameBg') {
      const inputErrors = { ...inputValidationErrors, [key]: value && value.length < 2 ? 'Minimum length must be 2 characters!' : value && value.length > 120 ? 'Maximum length must be 120 characters!' : '' };
      setInputValidationErrors(inputErrors);

      if (key === 'nameEn' && !isEdit && (isNil(canonicalUrl) || isEmpty(canonicalUrl))) {
        const automaticCanonicalUrl = urlize(value);
        onValueChange('canonicalUrl', automaticCanonicalUrl, () => { setCanonicalUrlValue(automaticCanonicalUrl); });
      }
    } else if (key === 'canonicalUrl') {
      const cat = findCategoryByCanonicalUrl(categories, value);
      const inputErrors = { ...inputValidationErrors, [key]: isNil(cat) ? '' : 'Canonical URL already exists!' };
      setInputValidationErrors(inputErrors);
    }

    setValueFunc(value);
  };

  const handleAddMetaTag = () => {
    const newMetaTags = [...metaTagsValue, { name: '', content: '', contentEn: '' }];
    setMetaTagsValue(newMetaTags);
  };

  const handleRemoveMetaTag = (index: number) => {
    const newMetaTags = metaTagsValue.filter((_, i) => i !== index);
    setMetaTagsValue(newMetaTags);
  };

  const handleMetaTagChange = (index: number, field: string, value: string) => {
    const newMetaTags = cloneDeep(metaTagsValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    newMetaTags[index][field] = value;
    setMetaTagsValue(newMetaTags);
  };

  const renderAddCategoryFields = () => {
    return (
      <div className="category-fields">
        <FormControl
          name="name"
          value={nameEnValue}
          labelText="Name EN"
          shouldUpdateValue
          error={inputValidationErrors.nameEn}
          type={FormControlType.text}
          onChange={(value) => {
            onValueChange('nameEn', value, () => { setNameEnValue(value); });
          }}
        />
        <FormControl
          name="name-bg"
          value={nameBgValue}
          labelText="Name BG"
          shouldUpdateValue
          error={inputValidationErrors.nameBg}
          type={FormControlType.text}
          onChange={(value) => {
            onValueChange('nameBg', value, () => { setNameBgValue(value); });
          }}
        />
        <FormControl
          name="canonical-url"
          value={canonicalUrlValue}
          labelText="Canonical URL"
          shouldUpdateValue
          error={inputValidationErrors.canonicalUrl}
          type={FormControlType.text}
          onChange={(value) => {
            onValueChange('canonicalUrl', value, () => { setCanonicalUrlValue(value); });
          }}
        />
        <FormControl
          name="heading-bg"
          value={heading1BgValue}
          labelText="Heading BG"
          shouldUpdateValue
          error={inputValidationErrors.headingBg}
          type={FormControlType.text}
          onChange={(value) => {
            onValueChange('headingBg', value, () => { setHeading1BgValue(value); });
          }}
        />
        <FormControl
          name="name-bg"
          value={heading1EnValue}
          labelText="Heading EN"
          shouldUpdateValue
          error={inputValidationErrors.headingEn}
          type={FormControlType.text}
          onChange={(value) => {
            onValueChange('headingEn', value, () => { setHeading1EnValue(value); });
          }}
        />
        <TextEditor
          name="description-en"
          label="Description EN"
          value={descriptionEnValue}
          onChange={(value) => {
            onValueChange('descriptionEn', value, () => { setDescriptionEnValue(value); });
          }}
        />
        <TextEditor
          name="description-bg"
          label="Description BG"
          value={descriptionBgValue}
          onChange={(value) => {
            onValueChange('descriptionBg', value, () => { setDescriptionBgValue(value); });
          }}
        />
        {metaTagsValue?.map((metaTag, index) => (
          <div key={index} className="meta-tag-group">
            <FormControl
              name={`metaTags[${index}].name`}
              value={metaTag.name}
              labelText={`${t('administration.blog.create.metaTagName')} ${index + 1}`}
              shouldUpdateValue
              error={inputValidationErrors.metaName}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'name', value);
              }}
            />
            <FormControl
              name={`metaTags[${index}].content`}
              value={metaTag.content}
              labelText={`${t('administration.blog.create.metaTagContent')} ${index + 1}`}
              shouldUpdateValue
              error={inputValidationErrors.metaContent}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'content', value);
              }}
            />
            <FormControl
              name={`metaTags[${index}].contentEn`}
              value={metaTag.contentEn}
              labelText={`${t('administration.blog.create.metaTagContentEn')} ${index + 1}`}
              shouldUpdateValue
              error={inputValidationErrors.metaContentEn}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'contentEn', value);
              }}
            />
            {index !== 0 && (
              <IconButton className="add-btn" onClick={() => {
                handleRemoveMetaTag(index);
              }}>
                <MinusIcon />
              </IconButton>
            )}
          </div>
        ))}
        <Tooltip title="Add Meta Tag">
          <IconButton onClick={handleAddMetaTag} className="remove-btn">
            <PlusIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      {editCategoryIsError && <div className="error-in-modal">{t('errors.anErrorOccurred')}</div>}
      {addNewCategoryIsError && <div className="error-in-modal">{t('errors.anErrorOccurred')}</div>}
      <DialogContent>
        {addNewCategoryIsLoading || editCategoryIsLoading ? <div>{t('common.loading')}</div> : renderAddCategoryFields()}
      </DialogContent>
      <DialogActions>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <Button onClick={onYes}>{t('common.yes')}</Button>
        <Button onClick={closeModal}>{t('common.no')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const AdminCategoriesTab = () => {
  const { language } = useI18n();
  const { t } = useTranslation();

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    error: categoriesError,
    refetch
  } = useGetAllCategoriesQuery();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogData, setDialogData] = useState<ICategory | undefined>();
  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const [removeDialogData, setRemoveDialogData] = useState<ICategory | undefined>();
  const [isEditDialog, setIsEditDialog] = useState<boolean>(false);

  const getName = ({ nameBg, nameEn }: ICategory) =>
    getLocalizedValue(language, nameBg, nameEn);

  const onCategoryEditClick = (e: any,
    id: number | undefined,
    nameEn?: string,
    nameBg?: string | undefined,
    descriptionBg?: string | undefined,
    descriptionEn?: string | undefined,
    heading1Bg?: string | undefined,
    heading1En?: string | undefined,
    canonicalUrl?: string | undefined,
    metaTags?: IMetaTag[] | undefined) => {
    e.stopPropagation();
    const categoryData: ICategory = {
      id,
      descriptionBg,
      descriptionEn,
      nameEn,
      nameBg,
      canonicalUrl,
      metaTags,
      heading1Bg,
      heading1En
    };

    setDialogData(categoryData);
    setDialogTitle(t('administration.categories.editCategoryConfirm', { categoryName: getName({ nameBg, nameEn }) }));
    setIsEditDialog(true);
    setDialogOpen(true);
  };

  const onCategoryAddClick = (e: any, isNew: boolean, id?: number, nameEn?: string, nameBg?: string) => {
    e.stopPropagation();
    const name = getName({ nameBg, nameEn });
    const title = !isNew ? t('administration.categories.addSubcategoryConfirm', { categoryName: name }) : t('administration.categories.addCategory');
    setDialogTitle(title);
    setIsEditDialog(false);
    if (!isNew) {
      const categoryData: ICategory = {
        parentId: id,
        nameEn,
        nameBg
      };
      setDialogData(categoryData);
    }
    setDialogOpen(true);
  };

  const onCategoryRemoveClick = (e: any, id?: number, nameEn?: string, nameBg?: string) => {
    e.stopPropagation();
    setRemoveDialogData({ id, nameEn, nameBg });
    setRemoveDialogOpen(true);
  };

  const renderCategoryWithSubcategories = useCallback((category: ICategory) => {
    const {
      id, nameEn, nameBg, descriptionEn, descriptionBg, subcategories, canonicalUrl, metaTags,
      heading1Bg, heading1En
    } = category;

    return (
      <Accordion key={id} className="accordionWrapper">
        <AccordionSummary className="accordionName" expandIcon={<ExpandMoreIcon />} style={{ height: 30 }}>
          <div style={{ marginRight: 15 }}>{getName({ nameBg, nameEn })}</div>
          <EditIcon className="adminIcon" onClick={(e) => {
            onCategoryEditClick(e, id, nameEn, nameBg, descriptionBg, descriptionEn, heading1Bg, heading1En, canonicalUrl, metaTags);
          }} />
          <Button
            type={ButtonType.primary}
            className="adminButton"
            onClick={(e) => {
              onCategoryEditClick(e, id, nameEn, nameBg, descriptionBg, descriptionEn, heading1Bg, heading1En, canonicalUrl, metaTags);
            }}
          >
            {t('administration.categories.editCategory')}
          </Button>
          <AddCircleIcon className="adminIcon" onClick={(e) => { onCategoryAddClick(e, false, id, nameEn, nameBg); }} />
          <Button
            type={ButtonType.primary}
            className="adminButton"
            onClick={(e) => { onCategoryAddClick(e, false, id, nameEn, nameBg); }}
          >
            {t('administration.categories.addSubcategory')}
          </Button>
          <DeleteIcon className="adminIcon" onClick={(e) => { onCategoryRemoveClick(e, id, nameEn, nameBg); }} />
          <Button
            type={ButtonType.primary}
            className="adminButton"
            onClick={(e) => { onCategoryRemoveClick(e, id, nameEn, nameBg); }}
          >
            {t('administration.categories.deleteCategory')}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          {subcategories && subcategories.length > 0
            ? (
                subcategories.map((subCategory: any) => (
                <div key={subCategory.id} style={{ paddingLeft: '20px' }}>
                  {renderCategoryWithSubcategories(subCategory)}
                </div>
                ))
              )
            : (
              <span>
                {t('administration.categories.noSubcategories')}
              </span>
              )}
        </AccordionDetails>
      </Accordion>
    );
  }, [categoriesData]);

  if (categoriesError) {
    return (<div>Failed loading categories, please try again!</div>);
  }

  return (
    <div className="categoriesWrapper">
      <Button color={ButtonColors.secondary} onClick={(e) => { onCategoryAddClick(e, true); }}>{t('administration.categories.addCategory')}</Button>
      {categoriesIsLoading
        ? <div>{t('common.loading')}</div>
        : categoriesData?.map((category: ICategory) => {
          return renderCategoryWithSubcategories(category);
        })}
      <ConfirmationModal
        isOpen={dialogOpen}
        title={dialogTitle}
        dialogData={dialogData}
        isEdit={isEditDialog}
        closeModal={() => { setDialogOpen(false); }}
        refetch={refetch}
      />
      <DeleteConfirmationModal
        isOpen={removeDialogOpen}
        dialogData={removeDialogData}
        closeModal={() => { setRemoveDialogOpen(false); }}
        refetch={refetch}
      />
    </div>
  );
};

export default AdminCategoriesTab;
