import { type Currency } from 'types/IProduct';

const currencySymbols: Record<string, string> = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  BGN: 'лв.'
};

const getCurrencySymbol = (currency: Currency): string => currencySymbols[currency.name] || 'Unknown';

export {
  getCurrencySymbol
};
