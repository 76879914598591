import { type FC, type SVGProps, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from 'assets/images/header/logo.png';
import LogoEn from 'assets/images/header/logo-en.png';
import { ReactComponent as CartRounded } from 'assets/icons/cart-rounded.svg';
import { ReactComponent as HeartRounded } from 'assets/icons/heart-rounded.svg';
import { ReactComponent as ProfileRounded } from 'assets/icons/profile-rounded.svg';
import { ReactComponent as Cart } from 'assets/icons/cart.svg';
import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as HamburgerMenu } from 'assets/icons/hamburger-menu-mobile.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import useWindowWidth from 'hooks/useWindowWidth';
import concatClassNames from 'utils/classNames';
import MobileMenu from 'components/header/MobileMenu';
import ProfileMenu from 'components/header/ProfileMenu';
import { NAVBAR_ROUTES, ROUTES } from 'routes/routes';
import NavigationLink from 'components/header/NavigationLink';
import { hasAdminAccess, isUserLoggedIn } from 'utils/auth';
import useToggle from 'hooks/useToggle';
import ProfileMenuMobile from 'components/header/ProfileMenuMobile';
import useOutsideClick from 'hooks/useOutsideClick';
import NotificationCount, { NotificationCountColor } from 'components/notification-count/NotificationCount';
import { CART_ITEMS, CART_ITEMS_CHANGED_EVENT } from 'components/product/ProductActions';
import useLocalStorageListener from 'hooks/useLocalStorageListener';
import { Language } from 'i18n';
import './Header.scss';
import { useI18n } from 'hooks/usei18n';
import { FAVOURITE_PRODUCTS, FAVOURITE_PRODUCTS_CHANGED_EVENT } from 'hooks/useFavouriteProducts';

interface RoutesWithIconsType {
  desktop: FC<SVGProps<SVGSVGElement>>
  mobile: FC<SVGProps<SVGSVGElement>>
  path: string
}

const routesWithIcons: Record<string, RoutesWithIconsType> = {
  cart: {
    desktop: CartRounded,
    mobile: Cart,
    path: ROUTES.Cart.path
  },
  heart: {
    desktop: HeartRounded,
    mobile: Heart,
    path: ROUTES.AllProductsByCategory.path.replace(':category1', 'favourites')
  },
  profile: {
    desktop: ProfileRounded,
    mobile: Profile,
    path: ROUTES.Profile.path
  }
};

interface IHeaderProps {
  isNavigationMenuOpen: boolean
  toggleNavigationMenu: () => void
  isProfileMenuOpen: boolean
  toggleProfileMenu: () => void
}

const Header = ({ isNavigationMenuOpen, toggleNavigationMenu, isProfileMenuOpen, toggleProfileMenu }: IHeaderProps) => {
  const { language, getLanguageUrl } = useI18n();

  const isLoggedIn = isUserLoggedIn();
  const isAdmin = hasAdminAccess();

  const { isMobile } = useWindowWidth();
  const [isDesktopProfileMenuOpen, toggleDesktopProfileMenu] = useToggle(false);

  const mobileMenuRef = useRef(null);
  const profileMenuRef = useRef(null);

  const cartItems: number = useLocalStorageListener(CART_ITEMS, CART_ITEMS_CHANGED_EVENT);
  const updatedProducts: number[] = useLocalStorageListener(FAVOURITE_PRODUCTS, FAVOURITE_PRODUCTS_CHANGED_EVENT);

  useOutsideClick(mobileMenuRef, () => {
    if (isNavigationMenuOpen) {
      toggleNavigationMenu();
    }
  });

  useOutsideClick(profileMenuRef, () => {
    if (isProfileMenuOpen) {
      toggleProfileMenu();
    }
  });

  return (
    <header className="flex space-between align-center header">
      {!isMobile && (
        <nav className="flex header-nav">
          {NAVBAR_ROUTES.map((route) => (
            <NavigationLink
              key={route.name}
              route={route}
              className="header-nav-link"
            />
          ))}
          {isAdmin && (
            <NavigationLink
              route={ROUTES.Administration}
              className="header-nav-link"
            />
          )}
        </nav>
      )}
      <div className="flex justify-center align-center header-logo-wrapper">
        {isMobile && (
          <div
            className={concatClassNames('menu-icon', isNavigationMenuOpen ? 'open' : 'closed')}
            onClick={toggleNavigationMenu}
          >
            {isNavigationMenuOpen
              ? <CloseIcon className="close-icon"/>
              : <HamburgerMenu className="hamburger-menu"/>}
          </div>
        )}
        <NavLink to={getLanguageUrl(ROUTES.Home.path)}>
          <img src={language === Language.BG ? Logo : LogoEn} alt="logo" className="header-logo"/>
        </NavLink>
      </div>
      <div className="flex header-user-items">
        {Object.values(routesWithIcons).map((icon) => {
          const IconComponent = isMobile ? icon.mobile : icon.desktop;

          const hoverHandler = !isMobile ? toggleDesktopProfileMenu : null;
          const clickHandler = isMobile ? toggleProfileMenu : null;

          if (icon.path === ROUTES.Profile.path) {
            return (
              <NavigationLink
                key={icon.path}
                className="header-icon-link profile-link"
                route={icon}
                onMouseEnter={hoverHandler}
                onMouseLeave={hoverHandler}
                onClick={clickHandler}
                disableNavigation={isLoggedIn}
              >
                <IconComponent/>
                {isLoggedIn && !isMobile && isDesktopProfileMenuOpen && (
                  <div className="profile-menu-wrapper">
                    <ProfileMenu/>
                  </div>
                )}
                {isLoggedIn && isMobile && isProfileMenuOpen && (
                  <div className="header-mobile-menu-container">
                    <div ref={profileMenuRef}>
                      <ProfileMenuMobile/>
                    </div>
                  </div>)}
              </NavigationLink>
            );
          }

          return (
            <NavigationLink key={icon.path} route={icon} className="header-icon-link">
              <>
                {
                  isLoggedIn && icon.path === ROUTES.AllProductsByCategory.path.replace(':category1', 'favourites') && updatedProducts && (
                    <NotificationCount
                      count={updatedProducts.length}
                      color={NotificationCountColor.red}
                    />
                  )
                }
                {
                  isLoggedIn && icon.path === ROUTES.Cart.path && cartItems > 0 && (
                    <NotificationCount
                      count={cartItems}
                      color={NotificationCountColor.red}
                    />
                  )
                }
                <IconComponent/>
              </>
            </NavigationLink>
          );
        })}
      </div>
      {isMobile && isNavigationMenuOpen && (
        <div className="header-mobile-menu-container">
          <div ref={mobileMenuRef}>
            <MobileMenu className="header-mobile-menu" toggleMenu={toggleNavigationMenu}/>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
