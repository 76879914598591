import { type ComponentType } from 'react';
import Home from 'pages/home/Home';
import Login from 'pages/login/Login';
import Register from 'pages/register/Register';
import BlogPost from 'pages/blog-post/BlogPost';
import Administration from 'pages/administration/Administration';
import Blog from 'pages/blog/Blog';
import PageNotFound from 'pages/page-not-found/PageNotFound';
import CreateNewProduct from 'pages/create-new-product/CreateNewProduct';
import Profile from 'pages/profile/Profile';
import ProfileSettings from 'pages/profile-settings/ProfileSettings';
import ProfileDeliveryAndPayment from 'pages/profile-delivery-and-payment/ProfileDeliveryAndPayment';
import Product from 'pages/product/Product';
import Cart from 'pages/cart/Cart';
import AllProducts from 'pages/all-products/AllProducts';
import ProfilePayments from 'pages/profile-payments/ProfilePayments';
import ProfileProducts from 'pages/profile-products/ProfileProducts';
import EditProduct from 'pages/edit-product/EditProduct';

export enum Auth {
  None,
  Protected,
  Anonymous,
  Admin
}

interface RouteType {
  path: string
  exact: boolean
  component: ComponentType<any>
  auth: Auth
  name?: string
  children?: RouteType[]
}

enum RouteNames {
  Home = 'Home',
  Login = 'Login',
  Register = 'Register',
  AllProducts = 'AllProducts',
  AllProductsByUserId = 'AllProductsByUserId',
  AllProductsByCategory = 'AllProductsByCategory',
  AllProductsByTwoCategories = 'AllProductsByTwoCategories',
  CreateNewProduct = 'CreateNewProduct',
  EditProduct = 'EditProduct',
  Blog = 'Blog',
  BlogPostByCanonicalUrl = 'BlogPostByCanonicalUrl',
  SizesChart = 'SizesChart',
  Returns = 'Returns',
  GeneralTerms = 'GeneralTerms',
  PrivatePolicy = 'PrivatePolicy',
  CookiesPolicy = 'CookiesPolicy',
  Cart = 'Cart',
  Profile = 'Profile',
  ProfileSettings = 'ProfileSettings',
  DeliveryAndPayment = 'DeliveryAndPayment',
  Products = 'Products',
  Payments = 'Payments',
  Administration = 'Administration',
  PageNotFound = 'PageNotFound',
  Product = 'Product',
  ResetPassword = 'ResetPassword',
  ConfirmEmail = 'ConfirmEmail',
  AddReview = 'AddReview'
}

const ROUTES: Record<RouteNames, RouteType> = {
  [RouteNames.Home]: {
    path: '/',
    exact: true,
    component: Home,
    auth: Auth.None
  },
  [RouteNames.Login]: {
    path: '/login',
    exact: true,
    component: Login,
    auth: Auth.Anonymous
  },
  [RouteNames.Register]: {
    path: '/register',
    exact: true,
    component: Register,
    auth: Auth.Anonymous
  },
  [RouteNames.AllProducts]: {
    path: '/all-products',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByUserId]: {
    path: '/all-products/user/:userId',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByCategory]: {
    path: '/all-products/:category1',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByTwoCategories]: {
    path: '/all-products/:category1/:category2',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByTwoCategories + '3']: {
    path: '/all-products/:category1/:category3/:category2',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByTwoCategories + '4']: {
    path: '/all-products/:category1/:category3/:category4/:category2',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByTwoCategories + '5']: {
    path: '/all-products/:category1/:category3/:category4/:category5/:category2',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.AllProductsByTwoCategories + '6']: {
    path: '/all-products/:category1/:category3/:category4/:category5/:category6/:category2',
    exact: true,
    component: AllProducts,
    name: 'buy',
    auth: Auth.None
  },
  [RouteNames.Product]: {
    path: '/products/:idAndName',
    exact: true,
    component: Product,
    auth: Auth.None
  },
  [RouteNames.CreateNewProduct]: {
    path: '/profile/create-new-product',
    exact: true,
    component: CreateNewProduct,
    name: 'sell',
    auth: Auth.Protected
  },
  [RouteNames.EditProduct]: {
    path: '/profile/edit/:id',
    exact: true,
    component: EditProduct,
    name: 'Редактирай',
    auth: Auth.Protected
  },
  [RouteNames.Blog]: {
    path: '/blog',
    exact: true,
    component: Blog,
    name: 'blog',
    auth: Auth.None
  },
  [RouteNames.BlogPostByCanonicalUrl]: {
    path: '/blog/:canonicalUrl',
    exact: true,
    component: BlogPost,
    auth: Auth.None
  },
  [RouteNames.SizesChart]: {
    path: '/sizes-chart',
    exact: true,
    component: Home,
    name: 'tableWithChartSizes',
    auth: Auth.None
  },
  [RouteNames.Returns]: {
    path: '/returns',
    exact: true,
    component: Home,
    name: 'returnPolicy',
    auth: Auth.None
  },
  [RouteNames.GeneralTerms]: {
    path: '/general-terms',
    exact: true,
    component: Home,
    name: 'termsAndConditions',
    auth: Auth.None
  },
  [RouteNames.PrivatePolicy]: {
    path: '/private-policy',
    exact: true,
    component: Home,
    name: 'privacyPolicy',
    auth: Auth.None
  },
  [RouteNames.CookiesPolicy]: {
    path: '/cookies-policy',
    exact: true,
    component: Home,
    name: 'cookiePolicy',
    auth: Auth.None
  },
  [RouteNames.Cart]: {
    path: '/cart',
    exact: true,
    component: Cart,
    auth: Auth.Protected
  },
  [RouteNames.Profile]: {
    path: '/profile',
    exact: true,
    component: Profile,
    auth: Auth.Protected
  },
  [RouteNames.ProfileSettings]: {
    path: '/profile/settings',
    exact: true,
    component: ProfileSettings,
    name: 'profileSettings',
    auth: Auth.Protected
  },
  [RouteNames.DeliveryAndPayment]: {
    path: '/profile/delivery-and-payment',
    exact: true,
    component: ProfileDeliveryAndPayment,
    name: 'deliveryAndBankDetails',
    auth: Auth.Protected
  },
  [RouteNames.Products]: {
    path: '/profile/products',
    exact: true,
    component: ProfileProducts,
    name: 'ads',
    auth: Auth.Protected
  },
  [RouteNames.Payments]: {
    path: '/profile/payments',
    exact: true,
    component: ProfilePayments,
    name: 'payments',
    auth: Auth.Protected
  },
  [RouteNames.Administration]: {
    path: '/administration',
    exact: true,
    name: 'admin',
    component: Administration,
    auth: Auth.Admin
  },
  [RouteNames.ResetPassword]: {
    path: '/reset-password',
    exact: true,
    component: Home,
    auth: Auth.None
  },
  [RouteNames.ConfirmEmail]: {
    path: '/confirm-email',
    exact: true,
    component: Home,
    auth: Auth.None
  },
  [RouteNames.AddReview]: {
    path: '/add-review',
    exact: true,
    component: Home,
    auth: Auth.None
  },
  [RouteNames.PageNotFound]: {
    path: '*',
    exact: false,
    component: PageNotFound,
    name: 'Page Not Found',
    auth: Auth.None
  }
};

const generateRoutesFromNames = (names: RouteNames[]): RouteType[] => {
  return Object.entries(ROUTES)
    .filter(([name]) => names.includes(name as RouteNames))
    .map(([, route]) => route);
};

const routes: RouteType[] = Object.values(ROUTES).filter(route => route.component);

const NAVBAR_ROUTES = generateRoutesFromNames([RouteNames.AllProducts, RouteNames.CreateNewProduct, RouteNames.Blog]);

const FOOTER_ROUTES = generateRoutesFromNames([RouteNames.Blog, RouteNames.SizesChart, RouteNames.Returns]);

const PROFILE_ROUTES = generateRoutesFromNames([RouteNames.ProfileSettings, RouteNames.DeliveryAndPayment, RouteNames.Products, RouteNames.Payments]);

const redirectTo = (route: string) => {
  window.location.replace(route);
};

export default routes;

export {
  type RouteType,
  ROUTES,
  NAVBAR_ROUTES,
  FOOTER_ROUTES,
  PROFILE_ROUTES,
  redirectTo
};
