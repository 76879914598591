import { type IPagination } from 'types/IPagination';
import { type IAddress } from 'types/IDelivery';

interface ICustomerBankDetails {
  iban: string
  swift: string
}

interface ICustomerFullName {
  firstName: string
  middleName: string
  lastName: string
}

interface phoneNumber {
  number: string
}

interface ISellingOptionsDeliveryPayer {
  value: number
  name: string
}

interface ISellingOptionsHandoverType {
  value: number
  name: string
}

interface ICustomer {
  id: number
  username: string
  bankDetails: ICustomerBankDetails
  email: string
  fullName: ICustomerFullName
  phoneNumber: phoneNumber
}

interface ICustomerApiResponse extends IPagination {
  models: ICustomer[]
  totalCount: number
}

interface ICustomerPersonalDataApiRequest {
  firstName: string
  middleName: string
  lastName: string
  phoneNumber: string
}

interface ICustomerPersonalDataApiResponse {
  email: string
  fullName: ICustomerFullName
  phoneNumber: phoneNumber
}

enum DeliveryPayer {
  Buyer = 1,
  Seller = 2
}

enum HandoverType {
  Office = 1,
  Address = 2
}

interface IHandoverAddress {
  siteId: number
  siteType: string
  siteName: string
  postCode: string
  streetType: string
  streetName: string
  streetNo: string
  complexType: string
  complexName: string
  blockNo: string
  entranceNo: string
  floorNo: string
  apartmentNo: string
  addressNote: string
  officeId: number
}

interface ICustomerDetailsApiResponse {
  email: string
  displayNumber: string
  username: string
  fullName: ICustomerFullName
  phoneNumber: phoneNumber
  bankDetails: ICustomerBankDetails
  sellingOptionsDeliveryPayer: ISellingOptionsDeliveryPayer
  sellingOptionsHandoverType: ISellingOptionsHandoverType
  sellingOptionsHandoverAddress: IAddress
  hasPermissionToSell: boolean
  purchasedBadges: []
  canPresentToHomePage: boolean
  canApplyBadgesToAllProducts: boolean
}

interface ICustomerUpdateSellingOptionsApiRequest {
  deliveryPayer: DeliveryPayer
  handoverType: HandoverType
  handoverAddress: IHandoverAddress
}

export type {
  ICustomer,
  ICustomerBankDetails,
  ICustomerApiResponse,
  ICustomerPersonalDataApiRequest,
  ICustomerPersonalDataApiResponse,
  ICustomerDetailsApiResponse,
  ICustomerUpdateSellingOptionsApiRequest
};

export {
  DeliveryPayer,
  HandoverType
};
