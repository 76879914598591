import { configureStore, type Middleware } from '@reduxjs/toolkit';
import { userApi } from 'store/api/userApi';
import { blogApi } from 'store/api/blogApi';
import { orderApi } from 'store/api/orderApi';
import { customerApi } from 'store/api/customerApi';
import { publisherApi } from 'store/api/publisherApi';
import { productApi } from 'store/api/productApi';
import { paymentApi } from 'store/api/paymentApi';
import { deliveryApi } from 'store/api/deliveryApi';
import { bannerApi } from 'store/api/bannerApi';
import { cartApi } from 'store/api/cartApi';
import { reviewApi } from 'store/api/reviewApi';
import { invoiceApi } from 'store/api/invoiceApi';
import { paidAdsApi } from 'store/api/paidAdsApi';
import { pagesApi } from './api/pagesApi';
import { categoriesApi } from './api/categoiesApi';

const middleware: Middleware[] = [
  userApi.middleware,
  blogApi.middleware,
  publisherApi.middleware,
  orderApi.middleware,
  customerApi.middleware,
  categoriesApi.middleware,
  productApi.middleware,
  paymentApi.middleware,
  bannerApi.middleware,
  publisherApi.middleware,
  deliveryApi.middleware,
  cartApi.middleware,
  reviewApi.middleware,
  invoiceApi.middleware,
  paidAdsApi.middleware,
  pagesApi.middleware
];

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [publisherApi.reducerPath]: publisherApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [publisherApi.reducerPath]: publisherApi.reducer,
    [deliveryApi.reducerPath]: deliveryApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [paidAdsApi.reducerPath]: paidAdsApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware)
});

export type RootState = ReturnType<typeof store.getState>;
