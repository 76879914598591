import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, type Order } from 'utils/constants';
import type { IOrderApiRequest, IOrderApiResponse, IOrderProfitApiResponse } from 'types/IOrder';

const ORDERS_URL = 'orders';

export enum SortBy {
  Id = 'id',
  Date = 'date'
}

export const orderApi = createApi({
  reducerPath: 'order',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllOrders: builder.query<IOrderApiResponse, {
      id?: number
      page?: number
      pageSize?: number
      buyer?: string
      sortBy?: SortBy
      order?: Order
      from?: string
      to?: string
      deliveryType?: number
      handoverType?: number
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        buyer,
        sortBy,
        order,
        from,
        to,
        deliveryType,
        handoverType
      }) => {
        let queryString = `${ORDERS_URL}?pageSize=${pageSize}&page=${page}&order=${order}`;

        if (buyer) {
          queryString += `&buyer=${buyer}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (from) {
          queryString += `&from=${from}`;
        }

        if (to) {
          queryString += `&to=${to}`;
        }

        if (deliveryType) {
          queryString += `&deliveryType=${deliveryType}`;
        }

        if (handoverType) {
          queryString += `&handoverType=${handoverType}`;
        }

        return queryString;
      }
    }),
    reportExcel: builder.mutation<any, {
      sortBy?: SortBy
      order?: Order
      from?: string
      to?: string
      deliveryType?: number
      handoverType?: number
    }>({
      queryFn: async ({
        sortBy,
        order,
        from,
        to,
        deliveryType,
        handoverType
      }, api, extraOptions, baseQuery) => {
        let queryString = `${ORDERS_URL}/Export?order=${order}`;

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (from) {
          queryString += `&from=${from}`;
        }

        if (to) {
          queryString += `&to=${to}`;
        }

        if (deliveryType) {
          queryString += `&deliveryType=${deliveryType}`;
        }

        if (handoverType) {
          queryString += `&handoverType=${handoverType}`;
        }

        const result = await baseQuery({
          url: queryString,
          responseHandler: async response => await response.blob()
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL;
        hiddenElement.href = url.createObjectURL((result as any).data);
        hiddenElement.target = '_blank';
        hiddenElement.download = `orders_report${from ? `_from_${from.split('T')[0]}` : ''}${to ? `_to_${to.split('T')[0]}` : ''}.xlsx`;
        hiddenElement.click();

        return { data: null };
      }
    }),
    createOrder: builder.mutation<number, IOrderApiRequest>({
      query: (body) => ({
        url: ORDERS_URL,
        method: 'POST',
        body
      })
    }),
    getMyOrders: builder.query<IOrderApiResponse, {
      id?: number
      from?: string
      to?: string
      deliveryType?: number
      handoverType?: number
      sortBy?: SortBy
      order?: Order
      page?: number
      pageSize?: number
    }>({
      query: ({
        id,
        from,
        to,
        deliveryType,
        handoverType,
        sortBy,
        order,
        page,
        pageSize
      }) => {
        let queryString = `${ORDERS_URL}/Mine?`;

        if (id) queryString += `&id=${id}`;
        if (from) queryString += `&from=${from}`;
        if (to) queryString += `&to=${to}`;
        if (deliveryType) queryString += `&deliveryType=${deliveryType}`;
        if (handoverType) queryString += `&handoverType=${handoverType}`;
        if (sortBy) queryString += `&sortBy=${sortBy}`;
        if (order) queryString += `&order=${order}`;
        if (page) queryString += `&page=${page}`;
        if (pageSize) queryString += `&pageSize=${pageSize}`;

        return queryString;
      }
    }),
    getOrdersProfit: builder.query<IOrderProfitApiResponse, {
      page?: number
      pageSize?: number
    }>({
      query: ({
        page,
        pageSize
      }) => {
        let queryString = `${ORDERS_URL}/Profit?`;

        if (page) queryString += `&page=${page}`;
        if (pageSize) queryString += `&pageSize=${pageSize}`;

        return queryString;
      }
    }),
    deleteOrderItem: builder.mutation<any, {
      orderItemId: number
    }>({
      query: ({ orderItemId }) => ({
        url: `${ORDERS_URL}/${orderItemId}/deleteOrderItem`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetAllOrdersQuery,
  useReportExcelMutation,
  useCreateOrderMutation,
  useGetMyOrdersQuery,
  useGetOrdersProfitQuery,
  useDeleteOrderItemMutation
} = orderApi;
