import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import './HorizontalTextSeparator.scss';

interface IHorizontalTextSeparatorProps extends IOptionalClassName {
  word: string
}

const HorizontalTextSeparator = ({ word, className }: IHorizontalTextSeparatorProps) => (
  <article className={concatClassNames('flex horizontal-text-separator', className)}>
    <hr/>
    <span className="horizontal-text-separator-text">
      {word}
    </span>
    <hr/>
  </article>
);

export default HorizontalTextSeparator;
