import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '@mui/material';
import {
  useGetProductsGendersQuery,
  useGetProductsSizesQuery
} from 'store/api/productApi';
import CategoryFilter from 'components/filter-menu/CategoryFilter';
import concatClassNames from 'utils/classNames';
import SizesFilter from 'components/filter-menu/SizesFilter';
import GenderFilter from 'components/filter-menu/GenderFilter';
import SpecialFilter from 'components/filter-menu/SpecialFilter';
import { localizeSize } from 'pages/all-products/filter-utils';
import { type IGender } from 'components/common/dropdowns/GenderDropdown';
import './FilterMenu.scss';
import { useGetAllCategoriesQuery } from '../../store/api/categoiesApi';
import { useI18n } from 'hooks/usei18n';
import { getLocalizedValue } from 'utils/localization';
import ProductsService from 'services/ProductsService';
import { type ICategory } from 'types/ICategory';
import { useNavigate } from 'react-router-dom';
import { preventDefault } from 'utils/htmlElementHandlers';
import { intersection, isEmpty } from 'lodash';

interface IFilterMenuProps {
  categoryFilters: number[]
  sizeFilters: number[]
  setSizeFilters: any
  genderFilters: number[]
  setGenderFilters: any
  specialFilters: any
  selectedSpecialFilters: any
  setSelectedSpecialFilters: any
}

const FilterMenu = ({
  categoryFilters,
  sizeFilters,
  setSizeFilters,
  genderFilters,
  setGenderFilters,
  specialFilters,
  setSelectedSpecialFilters,
  selectedSpecialFilters
}: IFilterMenuProps) => {
  const { t } = useTranslation();
  const { data: categories, isLoading, error } = useGetAllCategoriesQuery();
  const { language, getLanguageUrl } = useI18n();

  const { data: genders } = useGetProductsGendersQuery({});
  const { data: sizes } = useGetProductsSizesQuery(categoryFilters, { skip: !categoryFilters });

  const localizedGenders = genders?.map((gender: IGender) => ({
    ...gender,
    name: t(`genders.${gender.name.toLowerCase()}`)
  }));

  const localizedSizes = sizes?.map((size: any) => ({
    ...size,
    name: Number(size.name) ? size.name : t(localizeSize(size.name))
  }));

  const buildCategoryUrl = useCallback(
    (category: ICategory) => {
      const categoryUrl = new ProductsService(categories as ICategory[])
        .constructUrl({ categories: [category] });
      const pageUrl = `/all-products/${categoryUrl}`;
      return getLanguageUrl(pageUrl);
    },
    [categories, getLanguageUrl]
  );

  const navigate = useNavigate();

  const handleCategoryClick = useCallback(
    (category: ICategory) => {
      const { id } = category;
      const url = categoryFilters.includes(Number(id))
        ? '/all-products'
        : buildCategoryUrl(category);

      navigate(url);
    },
    [buildCategoryUrl, categoryFilters, navigate]
  );

  const isCategoryOrChildrenSelected = useCallback(
    (category: ICategory) => {
      const { id, subcategories } = category;
      const categoryAndSubcategories = [
        id,
        ...subcategories?.map(({ id }: ICategory) => id) || [],
        ...subcategories?.flatMap(({ subcategories }: ICategory) => subcategories)
          .filter(ssc => !isEmpty(ssc))
          .map(ssc => ssc as ICategory)
          ?.map(({ id }: ICategory) => id) || []
      ];

      return !isEmpty(intersection(categoryAndSubcategories, categoryFilters));
    },
    [categoryFilters]
  );

  const renderSubcategories = useCallback(
    (category: ICategory) => {
      const { subcategories } = category;
      if (!isCategoryOrChildrenSelected(category)) {
        return null;
      }

      return subcategories?.map((subcat: any) => (
        <CategoryFilter
          key={subcat.id}
          category={subcat}
          filters={categoryFilters}
          level={1}
        />
      ));
    },
    [categoryFilters, isCategoryOrChildrenSelected]
  );

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }
  if (error) {
    return <div>{t('common.errorLoadingCategories')}</div>;
  }

  return (
    <div className="flex flex-column" style={{ gap: 8 }}>
      <SpecialFilter
        specialFilters={specialFilters}
        selectedSpecialFilters={selectedSpecialFilters}
        setSelectedSpecialFilters={setSelectedSpecialFilters}
      />
      {genders?.length && (
        <GenderFilter
          genders={localizedGenders}
          selectedGenders={genderFilters}
          setSelectedGenders={setGenderFilters}
        />
      )}
      <FormGroup className="filter-menu">
        {categories?.map((category: ICategory) => (
          <div key={category.id}>
            <a
              onClick={(ev: any) => preventDefault(ev, () => { handleCategoryClick(category); })}
              href={buildCategoryUrl(category)}
              className={concatClassNames('filter-menu-name', isCategoryOrChildrenSelected(category) ? 'selected' : '')}
            >
              {getLocalizedValue(language, category.nameBg, category.nameEn)}
            </a>
            {renderSubcategories(category)}
          </div>
        ))}
      </FormGroup>
      {!isEmpty(categoryFilters) && !isEmpty(sizes) && (
        <SizesFilter
          sizes={localizedSizes}
          selectedSizes={sizeFilters}
          setSelectedSizes={setSizeFilters}
        />
      )}
    </div>
  );
};

export default FilterMenu;
