import { useTranslation } from 'react-i18next';
import Popup, { type IPopup } from 'components/popup/Popup';

interface ICompletedOrderPopup extends IPopup {
}

const ReturnPolicyPopup = ({ open, onClose }: ICompletedOrderPopup) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      title={t('footer.returnPolicy')}
      text={t('footer.returnPolicyText')}
      onClose={onClose}
    />
  );
};

export default ReturnPolicyPopup;
