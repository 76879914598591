import { Language } from 'i18n';
import { isNil } from 'lodash';

const getLocalizedValue = (language: string, valueBg?: any, valueEn?: any) => {
  if (isNil(valueBg) || isNil(valueEn)) {
    return valueBg ?? valueEn ?? null;
  }

  return language === Language.BG
    ? valueBg
    : valueEn;
};

export {
  getLocalizedValue
};
