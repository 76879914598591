import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import FormControl, { FormControlType, FormControlVariant } from 'components/forms/FormControl';
import Label, { LabelColor } from 'components/label/Label';
import PhotoUploader from 'components/photo-uploader/PhotoUploader';
import RadioButtons from 'components/radio-buttons/RadioButtons';
import GenderDropdown from 'components/common/dropdowns/GenderDropdown';
import useDropdown from 'hooks/useDropdown';
import CategoryDropdown from 'components/common/dropdowns/CategoryDropdown';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/photo-plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { type IProductInventoryItem, ProductLabel } from 'types/IProduct';
import useForm from 'hooks/useForm';
import { validateProductFields } from 'utils/validators/productValidator';
import { useGetAllCategoriesQuery } from 'store/api/categoiesApi';
import { useEditProductMutation, useGetProductQuery } from 'store/api/productApi';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/tooltip/Tooltip';
import SizesDropdown from 'components/common/dropdowns/SizesDropdown';
import { ButtonState } from 'components/button/Button';
import Profile from 'pages/profile/Profile';
import concatClassNames from 'utils/classNames';
import { urlize } from 'utils/text';
import { PRODUCT_IMAGES_COUNT } from 'utils/constants';
import { redirectTo } from 'routes/routes';
import { type IClassName } from 'types/common/Props';
import './EditProduct.scss';

interface IEditProduct extends IClassName {
}

enum EditProductFormFields {
  NameEnglish = 'nameEnglish',
  NameBulgarian = 'nameBulgarian',
  DescriptionEnglish = 'descriptionEnglish',
  DescriptionBulgarian = 'descriptionBulgarian',
  IsGift = 'isGift',
  Price = 'price',
  Weight = 'weight',
  Label = 'label',
  GendersIds = 'gendersIds',
  CategoryId = 'categoryId',
  SubCategoryId = 'subCategoryId',
  NestedSubCategoryId = 'nestedSubCategoryId',
  InventoryItems = 'inventoryItems',
  Quantity = 'quantity',
  SizeId = 'sizeId'
}

const initialInventoryItem = {
  [EditProductFormFields.Quantity]: 0,
  [EditProductFormFields.SizeId]: 0
};

const EditProduct = ({ className }: IEditProduct) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: product } = useGetProductQuery(Number(id));

  const [initialFormValues] = useState<any>({
    [EditProductFormFields.NameEnglish]: '',
    [EditProductFormFields.NameBulgarian]: '',
    [EditProductFormFields.IsGift]: false,
    [EditProductFormFields.Price]: 0,
    [EditProductFormFields.Weight]: 0.1,
    [EditProductFormFields.DescriptionEnglish]: '',
    [EditProductFormFields.DescriptionBulgarian]: '',
    [EditProductFormFields.Label]: ProductLabel.Used,
    [EditProductFormFields.GendersIds]: [],
    [EditProductFormFields.CategoryId]: '',
    [EditProductFormFields.SubCategoryId]: '',
    [EditProductFormFields.NestedSubCategoryId]: '',
    [EditProductFormFields.InventoryItems]: [initialInventoryItem]
  });

  const { data: categories } = useGetAllCategoriesQuery();
  const [editProduct, { isLoading: isSubmitting }] = useEditProductMutation();

  const [images, setImages] = useState<any>([]);
  const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);
  const [inventoryItems, setInventoryItems] = useState(product?.inventoryItems ?? [initialInventoryItem]);
  const [selectedCategory, setSelectedCategory] = useState(product?.categories[0] ?? null);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(null);
  const [nestedSubCategories, setNestedSubCategories] = useState<any>([]);
  const [selectedNestedSubCategory, setSelectedNestedSubCategory] = useState<number | null>(null);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);

  useEffect(() => {
    if (id && product) {
      setValues({
        [EditProductFormFields.NameEnglish]: product?.name.english ?? '',
        [EditProductFormFields.NameBulgarian]: product?.name.bulgarian ?? '',
        [EditProductFormFields.IsGift]: product?.isGift ?? false,
        [EditProductFormFields.Price]: product?.price?.amount ?? 0,
        [EditProductFormFields.Weight]: product?.weight ?? 0.1,
        [EditProductFormFields.DescriptionEnglish]: product?.description.english ?? '',
        [EditProductFormFields.DescriptionBulgarian]: product?.description.bulgarian ?? '',
        [EditProductFormFields.Label]: product?.label?.value ?? ProductLabel.Used,
        [EditProductFormFields.GendersIds]: product?.genders?.map((gender: any) => gender.id) ?? [],
        [EditProductFormFields.CategoryId]: product?.categories[0]?.id ?? '',
        [EditProductFormFields.SubCategoryId]: product?.categories[1]?.id ?? '',
        [EditProductFormFields.NestedSubCategoryId]: product?.categories[2]?.id ?? '',
        [EditProductFormFields.InventoryItems]: product?.inventoryItems ?? [initialInventoryItem]
      });

      setSelectedCategory(product?.categories[0]?.id ?? null);
      setSelectedSubCategory(product?.categories[1]?.id ?? null);
      setSelectedNestedSubCategory(product?.categories[2]?.id ?? null);
      setSelectedGenders(product?.genders?.map((gender: any) => gender.id) ?? []);
      setInventoryItems(product?.inventoryItems);
      setImages(product?.images ?? []);
    }
  }, [id, product]);

  const handleTextareaFocus = () => {
    setIsTextAreaFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsTextAreaFocused(false);
  };

  const handleNameFieldFocus = () => {
    setIsNameFieldFocused(true);
  };

  const handleNameFieldBlur = () => {
    setIsNameFieldFocused(false);
  };

  const {
    selectedValues: selectedGenders,
    setSelectedValues: setSelectedGenders,
    handleSelectionChange: handleGenderChange
  } = useDropdown([]);

  const {
    setSelectedValues: setSelectedSizes
  } = useDropdown([], true);

  const handleInventoryItemChange = (index: number, field: any, value: any) => {
    const itemToUpdate = { ...inventoryItems[index] };

    itemToUpdate[field] = value;

    const newInventoryItems = [
      ...inventoryItems.slice(0, index),
      itemToUpdate,
      ...inventoryItems.slice(index + 1)
    ];

    setInventoryItems(newInventoryItems);
    handleChange(EditProductFormFields.InventoryItems, newInventoryItems);
  };

  const addInventoryItem = () => {
    setInventoryItems([...inventoryItems, { ...initialInventoryItem }]);
  };

  const removeInventoryItem = (index: number) => {
    if (inventoryItems.length === 1) {
      return;
    }

    const newInventoryItems = [...inventoryItems];
    newInventoryItems.splice(index, 1);
    setInventoryItems(newInventoryItems);

    handleChange(EditProductFormFields.InventoryItems, newInventoryItems);
  };

  const options = [
    { value: false, label: t('createNewProduct.sale') },
    { value: true, label: t('createNewProduct.gift') }
  ];

  const productOptions = [
    { value: ProductLabel.New, label: <Label text={t('createNewProduct.new')}/> },
    { value: ProductLabel.Used, label: <Label text={t('createNewProduct.used')} color={LabelColor.Secondary}/> }
  ];

  const handleFileUpload = (file: File) => {
    const newImages = [...images];
    newImages.push(file);
    setImages(newImages);
  };

  const getImageSources = (src: string) => {
    setImagesToRemove([...imagesToRemove, src]);
  };

  const onSubmit = async (values: any) => {
    const { categoryId, subCategoryId, nestedSubCategoryId, ...newValues } = values;

    const categories = [categoryId, subCategoryId, nestedSubCategoryId]
      .filter((category) => category !== null)
      .map((category) => Number(category));

    const data = {
      ...newValues,
      id: Number(id),
      price: Number(values.price),
      file: images,
      filesToDelete: imagesToRemove,
      gendersIds: selectedGenders.map((id: string) => Number(id)),
      categoriesIds: categories,
      inventoryItems: inventoryItems.map((item: IProductInventoryItem) => ({
        quantity: item.quantity,
        sizeId: item.sizeId === 0 ? null : Number(item.sizeId)
      }))
    };

    const response: any = await editProduct(data);

    if (response.error) {
      throw new Error(t('errors.anErrorOccurred'));
    }

    const productName = urlize(newValues.nameEnglish);

    if (response.data) {
      redirectTo(`/products/${productName}-${response.data}`);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    errors
  } = useForm(initialFormValues, onSubmit, validateProductFields, t('createNewProduct.successfullyEditedProduct'), false);

  useEffect(() => {
    if (selectedCategory) {
      setSubCategories(categories?.find((category: any) => category.id === +selectedCategory)?.subcategories ?? []);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      setNestedSubCategories(subCategories?.find((category: any) => category.id === +selectedSubCategory)?.subcategories ?? []);
    }
  }, [subCategories, selectedSubCategory]);

  const uploadedImageCount = product?.images?.length;
  const remainingUploads = PRODUCT_IMAGES_COUNT - uploadedImageCount;

  return (
    <Profile className={concatClassNames('edit-product', className)}>
      <Form
        encType={'multipart/form-data'}
        onSubmit={handleSubmit}
        className="edit-product-form"
        submitText={t('createNewProduct.publish')}
        submitButtonState={isSubmitting ? ButtonState.disabled : ButtonState.enabled}
        submitButtonClassName="full-width"
      >
        <div className="flex flex-column edit-product-form-row details-row">
          <div className="edit-product-form-section form">
            <FormControl
                name={EditProductFormFields.NameBulgarian}
                labelText={t('createNewProduct.name')}
                variant={FormControlVariant.secondary}
                value={values.nameBulgarian.toString()}
                error={errors.nameBulgarian}
                onChange={(value) => {
                  handleChange(EditProductFormFields.NameBulgarian, value.trim());
                }}
                shouldUpdateValue
                placeholder={!isNameFieldFocused ? t('createNewProduct.namePlaceholder') : ''}
                onFocus={handleNameFieldFocus}
                onBlur={handleNameFieldBlur}
            />
            <FormControl
              name={EditProductFormFields.NameEnglish}
              labelText={`${t('createNewProduct.name')} EN`}
              variant={FormControlVariant.secondary}
              value={values.nameEnglish.toString()}
              error={errors.nameEnglish}
              onChange={(value) => {
                handleChange(EditProductFormFields.NameEnglish, value.trim());
              }}
              shouldUpdateValue
              placeholder={!isNameFieldFocused ? t('createNewProduct.namePlaceholder') : ''}
              onFocus={handleNameFieldFocus}
              onBlur={handleNameFieldBlur}
            />
            <FormControl
              name={EditProductFormFields.DescriptionBulgarian}
              type={FormControlType.textarea}
              shouldUpdateValue
              value={values.descriptionBulgarian.toString()}
              error={errors.descriptionBulgarian}
              labelText={t('createNewProduct.description')}
              placeholder={!isTextAreaFocused ? t('createNewProduct.descriptionPlaceholder') : ''}
              variant={FormControlVariant.secondary}
              onChange={(value) => {
                handleChange(EditProductFormFields.DescriptionBulgarian, value);
              }}
              onFocus={handleTextareaFocus}
              onBlur={handleTextareaBlur}
            />
            <FormControl
                name={EditProductFormFields.DescriptionEnglish}
                type={FormControlType.textarea}
                shouldUpdateValue
                value={values.descriptionEnglish.toString()}
                error={errors.descriptionEnglish}
                labelText={`${t('createNewProduct.description')} EN`}
                placeholder={!isTextAreaFocused ? t('createNewProduct.descriptionPlaceholder') : ''}
                variant={FormControlVariant.secondary}
                onChange={(value) => {
                  handleChange(EditProductFormFields.DescriptionEnglish, value);
                }}
                onFocus={handleTextareaFocus}
                onBlur={handleTextareaBlur}
            />
            {
              inventoryItems.map((item: any, index: number) => (
                <div className="edit-product-form-section-row" key={index}>
                  <div className="flex" style={{ gap: 25 }}>
                    <Tooltip title={t('createNewProduct.addSize')}>
                      <IconButton onClick={addInventoryItem} className="edit-product-form-section-row-btn">
                        <PlusIcon/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('createNewProduct.removeSize')}>
                      <IconButton
                        onClick={() => {
                          removeInventoryItem(index);
                        }}
                        className="edit-product-form-section-row-btn"
                      >
                        <MinusIcon/>
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="flex full-width edit-product-form-section-row-inputs">
                    <FormControl
                      name={EditProductFormFields.Quantity}
                      onChange={(value) => {
                        handleInventoryItemChange(index, EditProductFormFields.Quantity, value);
                      }}
                      shouldUpdateValue
                      value={item.quantity.toString()}
                      type={FormControlType.number}
                      error={errors.inventoryItems?.[index]?.quantity}
                      labelText={t('createNewProduct.quantity')}
                    />
                    <SizesDropdown
                      categoryId={selectedCategory}
                      selectedSize={item.sizeId}
                      setSelectedSize={setSelectedSizes}
                      handleSizeChange={(e: any) => {
                        setSelectedSizes(e);
                        handleInventoryItemChange(index, EditProductFormFields.SizeId, e);
                      }}
                      disabled={selectedCategory === null}
                      error={errors.inventoryItems?.[index]?.sizeId}
                      className="edit-product-form-section-row-sizes"
                    />
                    {index === 0 && (
                      <FormControl
                        name={EditProductFormFields.Weight}
                        onChange={(value) => {
                          handleChange(EditProductFormFields.Weight, value);
                        }}
                        shouldUpdateValue
                        value={values.weight.toString()}
                        type={FormControlType.number}
                        error={errors.weight}
                        labelText={t('createNewProduct.weight')}
                      />
                    )}
                  </div>
                </div>
              ))
            }
            <div>
              <span className="edit-product-form-section-title">
                {t('createNewProduct.productCondition')}
              </span>
              <div className="flex product-status">
                <RadioButtons
                  options={productOptions}
                  value={values[EditProductFormFields.Label]}
                  onValueChange={(value) => {
                    handleChange(EditProductFormFields.Label, value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="edit-product-form-section upload-images">
            <span className="edit-product-form-section-title">
              {t('createNewProduct.uploadImages')}
            </span>
            <div className="flex upload-images-row">
              <PhotoUploader
                id="photo-1"
                onUpload={handleFileUpload}
                icon={<PlusIcon/>}
                initialImage={product?.mainImageSource}
                onRemove={() => {
                  getImageSources(product?.mainImageSource);
                }}
              />
              {product?.images?.filter((image: string) => image !== product?.mainImageSource).map((image: any, index: number) => (
                <PhotoUploader
                  key={index}
                  id={`photo-${index + 2}`}
                  onUpload={handleFileUpload}
                  initialImage={image}
                  icon={<CameraIcon/>}
                  onRemove={() => {
                    getImageSources(image);
                  }}
                />
              ))}
              {Array.from({ length: remainingUploads }, (_, i) => (
                <PhotoUploader
                  key={`new-${i}`}
                  id={`photo-new-${i}`}
                  onUpload={handleFileUpload}
                  icon={<CameraIcon/>}
                />
              ))}
            </div>
          </div>
          <div className="edit-product-form-section price">
            <span className="edit-product-form-section-title">
              {t('createNewProduct.price')}
            </span>
            <div className="price-checkbox-options">
              <RadioButtons
                options={options}
                value={values[EditProductFormFields.IsGift]}
                onValueChange={(value) => {
                  handleChange(EditProductFormFields.IsGift, value);
                }}
              />
            </div>
            <FormControl
              name={EditProductFormFields.Price}
              value={values.isGift ? '0' : values.price.toString()}
              error={errors.price}
              onChange={(value) => {
                handleChange(EditProductFormFields.Price, value);
              }}
              readOnly={values.isGift.toString() === 'true'}
              shouldUpdateValue
              variant={FormControlVariant.secondary}
              type={FormControlType.number}
              placeholder="лв."
              containerClassName="price-input"
            />
          </div>
        </div>
        <div className="flex flex-column edit-product-form-row categories-row">
          <div className="edit-product-form-section genders">
            <span className="edit-product-form-section-title">
              {t('createNewProduct.gender')}
            </span>
            <GenderDropdown
              error={errors.gendersIds}
              selectedGenders={selectedGenders}
              setSelectedGenders={setSelectedGenders}
              handleGenderChange={(value) => {
                setSelectedGenders(value);
                handleChange(EditProductFormFields.GendersIds, value);
                handleGenderChange(value);
              }}
            />
          </div>
          <div className="edit-product-form-section category">
            <span className="edit-product-form-section-title">
              {t('createNewProduct.category')}
            </span>
            <CategoryDropdown
              label={t('createNewProduct.chooseCategory')}
              categories={categories ?? []}
              selectedCategory={selectedCategory}
              setSelectedCategory={(value: any) => {
                setSelectedCategory(value);
                handleChange(EditProductFormFields.CategoryId, value);
              }}
              error={errors.categoryId?.toString()}
            />
            {
              selectedCategory && subCategories.length > 0 && (
                <CategoryDropdown
                  label={t('createNewProduct.chooseSubcategory')}
                  categories={subCategories}
                  selectedCategory={selectedSubCategory}
                  setSelectedCategory={(value: any) => {
                    setSelectedSubCategory(value);
                    handleChange(EditProductFormFields.SubCategoryId, value);
                  }}
                  error={errors.subCategoryId?.toString()}
                />
              )
            }
            {
              selectedSubCategory && nestedSubCategories.length > 0 && (
                <CategoryDropdown
                  label={t('createNewProduct.chooseSubcategory')}
                  categories={nestedSubCategories}
                  selectedCategory={selectedNestedSubCategory}
                  setSelectedCategory={(value: any) => {
                    setSelectedNestedSubCategory(value);
                    handleChange(EditProductFormFields.NestedSubCategoryId, value);
                  }}
                  error={errors.nestedSubCategoryId?.toString()}
                />
              )
            }
          </div>
        </div>
      </Form>
    </Profile>
  );
};

export default EditProduct;
