import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { type CreateCompanyRequest, type CreatePersonalRequest } from 'types/IInvoice';

const INVOICE_URL = 'invoices';

export const invoiceApi = createApi({
  reducerPath: 'invoice',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    createCompanyInvoice: builder.mutation<void, CreateCompanyRequest>({
      query: (companyData) => ({
        url: `${INVOICE_URL}/createCompany`,
        method: 'POST',
        body: companyData
      })
    }),
    createPersonalInvoice: builder.mutation<void, CreatePersonalRequest>({
      query: (personalData) => ({
        url: `${INVOICE_URL}/createPersonal`,
        method: 'POST',
        body: personalData
      })
    })
  })
});

export const {
  useCreateCompanyInvoiceMutation,
  useCreatePersonalInvoiceMutation
} = invoiceApi;
