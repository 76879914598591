import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
import useWindowWidth from 'hooks/useWindowWidth';
import { ROUTES } from 'routes/routes';
import { Language } from 'i18n';
import LanguageSwitcher from 'components/language-switcher/LanguageSwitcher';
import ReturnPolicyPopup from 'components/return-policy-popup/ReturnPolicyPopup';
import './Footer.scss';
import { useI18n } from 'hooks/usei18n';
import { getLocalizedValue } from 'utils/localization';

export const SOCIAL_MEDIA = {
  instagram: 'https://www.instagram.com/2kiddo.bg?igsh=MXkwbXAxcjhsOXU0Ng==',
  facebook: 'https://www.facebook.com/share/6Wg1R3zBGoMRLzZu/?mibextid=LQQJ4d'
};

const year = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation();
  const { getLanguageUrl, language } = useI18n();
  const { isMobile } = useWindowWidth();
  const [showReturnPolicyPopup, setShowReturnPolicyPopup] = useState(false);

  const sizeChartsFilePath = language === Language.BG
    ? require('assets/files/size-charts/2KIDDO_Size_Charts.pdf')
    : require('assets/files/size-charts/2KIDDO_Size_Charts_EN.pdf');

  const termsAndConditionsFilePath = language === Language.BG
    ? require('assets/files/general-conditions/Общи условия 2_КИДДО.pdf')
    : require('assets/files/general-conditions/General Conditions 2_KIDDO.pdf');

  const cookiesPolicyFilePath = language === Language.BG
    ? require('assets/files/cookies-policy/ПОЛИТИКА_ЗА_ИЗПОЛЗВАНЕ_НА_БИСКВИТКИ.pdf')
    : require('assets/files/cookies-policy/Cookies_policy_2KIDDO.pdf');

  const privacyPolicyFilePath = language === Language.BG
    ? require('assets/files/privacy-policy/ПОЛИТИКА_ЗА_ПОВЕРИТЕЛНОСТВЪВ_ВРЪЗКА_С_ОБРАБОТВАНЕТО_НА_ЛИЧНИ_ДАННИ.pdf')
    : require('assets/files/privacy-policy/Privacy_Policy_2KIDDO.pdf');

  const renderCategories = (listName: string) => {
    const { t } = useTranslation();

    return (
      <ul className="flex flex-column footer-list">
        <li className="fs-18 footer-list-category">{listName}</li>
        <li className="footer-list-item">
          <Link to={getLanguageUrl(ROUTES.AllProducts.path + '/boys')}>{t('footer.boys')}</Link>
        </li>
        <li className="footer-list-item">
          <Link to={getLanguageUrl(ROUTES.AllProducts.path + '/girls')}>{t('footer.girls')}</Link>
        </li>
        <li className="footer-list-item">
          <Link to={getLanguageUrl(ROUTES.AllProducts.path + '/twins')}>{t('footer.twins')}</Link>
        </li>
      </ul>
    );
  };

  return (
    <footer className="flex flex-column footer">
      {isMobile && <LanguageSwitcher />}
      <section className="flex space-between footer-row">
        <article className="flex footer-content">
          {renderCategories(t('footer.categories'))}
          <ul className="flex flex-column footer-list">
            <li className="fs-18 footer-list-category">{t('footer.useful')}</li>
            <li className="footer-list-item">
              <Link to={getLanguageUrl(ROUTES.Blog.path)}>{t('footer.blog')}</Link>
            </li>
            <li className="footer-list-item">
              <a target="_blank"
                rel="noreferrer"
                href={sizeChartsFilePath}
              >
                {t('footer.tableWithChartSizes')}
              </a>
            </li>
            <li className="footer-list-item">
              <a href="#" onClick={(e) => {
                e.preventDefault();
                setShowReturnPolicyPopup(true);
              }}>
                {t('footer.returnPolicy')}
              </a>
            </li>
          </ul>
        </article>
        <article className="flex footer-contacts">
          <div className="flex flex-column footer-contacts-row">
            <a href="mailto:2kiddo.bg@gmail.com" className="fs-16 semi-bold">2kiddo.bg@gmail.com</a>
          </div>
          <div className="flex footer-contacts-social-media">
            <a href={SOCIAL_MEDIA.instagram} target="_blank" rel="noopener noreferrer">
              <Instagram className="footer-contacts-social-media-icon" />
            </a>
            <a href={SOCIAL_MEDIA.facebook} target="_blank" rel="noopener noreferrer">
              <Facebook className="footer-contacts-social-media-icon" />
            </a>
          </div>
          {!isMobile && <LanguageSwitcher />}
        </article>
      </section>
      <section className="flex space-between fs-12">
        <article className="flex align-center footer-copyright-wrapper">
          <p className="footer-copyright">{year} 2Kiddo. {t('footer.allRightsReserved')}.</p>
          <ul className="flex footer-links">
            <li className="text-underline">
              <a target="_blank"
                rel="noreferrer"
                download={
                  getLocalizedValue(language, 'Общи условия 2_КИДДО.pdf', 'General Conditions 2_KIDDO.pdf')
                }
                href={termsAndConditionsFilePath}
              >
                {t('footer.termsAndConditions')}
              </a>
            </li>
            <li className="text-underline">
              <a target="_blank"
                rel="noreferrer"
                download={
                  getLocalizedValue(language, 'ПОЛИТИКА_ЗА_ПОВЕРИТЕЛНОСТВЪВ_ВРЪЗКА_С_ОБРАБОТВАНЕТО_НА_ЛИЧНИ_ДАННИ.pdf', 'Privacy_Policy_2KIDDO.pdf')
                }
                href={privacyPolicyFilePath}
              >
                {t('footer.privacyPolicy')}
              </a>
            </li>
            <li className="text-underline">
              <a target="_blank"
                rel="noreferrer"
                download={
                  getLocalizedValue(language, 'ПОЛИТИКА_ЗА_ИЗПОЛЗВАНЕ_НА_БИСКВИТКИ.pdf', 'Cookies_policy_2KIDDO.pdf')
                }
                href={cookiesPolicyFilePath}
              >
                {t('footer.cookiePolicy')}
              </a>
            </li>
          </ul>
        </article>
      </section>
      {showReturnPolicyPopup && (
        <ReturnPolicyPopup
          open={showReturnPolicyPopup}
          onClose={() => {
            setShowReturnPolicyPopup(false);
          }}
        />
      )}
    <p> wabada </p>
    </footer>
  );
};

export default Footer;
