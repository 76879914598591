import Tabs from 'components/tabs/Tabs';
import ItemsForSaleTab from 'components/administration/products/ItemsForSaleTab';
import { useTranslation } from 'react-i18next';

enum AdminProductsTabs {
  ItemsForSale = 'Items For Sale',
  AllProducts = 'All Products'
}

const AdminProductsTab = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: AdminProductsTabs.ItemsForSale,
      content: <ItemsForSaleTab/>,
      name: t('administration.products.itemsForSale')
    }
  ];

  return <Tabs className="user-administration-tabs" tabsData={tabs} ariaLabel="product tabs"/>;
};

export default AdminProductsTab;
