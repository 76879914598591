import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetComplexesQuery } from 'store/api/deliveryApi';
import debounce from 'utils/debounce';
import { DEFAULT_DEBOUNCE_TIME } from 'utils/constants';

interface IComplexDropdownProps {
  siteId: number
  selectedComplex: any
  handleComplexChange: any
  disabled?: boolean
  error?: string
}

const ComplexDropdown = ({ siteId, selectedComplex, handleComplexChange, disabled, error }: IComplexDropdownProps) => {
  const { t, i18n } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const { data: complexes, isLoading, isError } = useGetComplexesQuery({
    siteId,
    name: inputValue,
    language: i18n.language
  }, { skip: !siteId });

  useEffect(() => {
    if (selectedComplex?.name) {
      setInputValue(selectedComplex.name);
    }
  }, [selectedComplex]);

  const debouncedInputChange = useCallback(debounce((newInputValue: string) => {
    setInputValue(newInputValue);
  }, DEFAULT_DEBOUNCE_TIME), []);

  const handleInputChange = (event: any, newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  if (isLoading) return <div>{t('common.loading')}</div>;
  if (isError) return <div>{t('common.errorLoadingComplexes')}</div>;

  const complexOptions = complexes?.map((complex) => ({
    ...complex,
    label: `${complex.type} ${complex.name}`
  })) ?? [];

  const handleChange = (event: any, newValue: any) => {
    handleComplexChange(newValue);
  };

  return (
    <Autocomplete
      className="dropdown"
      disabled={disabled}
      options={complexOptions}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
          <li {...props} key={option.id}>{option.label}</li>
      )}
      value={complexOptions.find(complex => complex.name === selectedComplex?.name) ?? null}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
          <TextField {...params as any} label={t('common.chooseComplex')} error={!!error} helperText={error}/>
      )}
      noOptionsText={inputValue.length > 0 ? t('common.noComplexesFound') : ''}
    />
  );
};

export default ComplexDropdown;
