import React, { useCallback } from 'react';
import Breadcrumbs, { type BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import isNil from 'lodash/isNil';
import concatClassNames from 'utils/classNames';

interface IBreadcrumbProps<TValue> extends BreadcrumbsProps {
  items?: TValue[] | null
  itemFunc: (value: TValue) => React.ReactElement
  keyFunc?: (value: TValue) => string
}

const Breadcrumb = <TValue, >({
  items = null,
  itemFunc,
  className = '',
  ...props
}: IBreadcrumbProps<TValue>) => {
  const breadcrumbClassName = concatClassNames(className);

  const renderItems = useCallback(
    () => {
      if (isNil(items)) {
        return null;
      }

      return items.map((value, index) => (
        <div key={index}>
          {itemFunc(value)}
        </div>
      ));
    },
    [itemFunc, items]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Breadcrumbs className={breadcrumbClassName} {...props}>
      {renderItems()}
    </Breadcrumbs>
  );
};

export default Breadcrumb;

export type {
  IBreadcrumbProps
};
