import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import useOutsideClick from 'hooks/useOutsideClick';
import './Popup.scss';

export interface IPopup extends IOptionalClassName {
  open: boolean
  onClose: () => void
  title?: string
  text?: string | React.ReactNode
  children?: React.ReactNode
  shouldShowCloseIcon?: boolean
}

const Popup = ({
  open,
  onClose,
  title,
  text,
  children,
  className,
  shouldShowCloseIcon = true,
  ...otherProps
}: IPopup) => {
  const popupRef = useRef(null);

  useOutsideClick(popupRef, onClose);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={concatClassNames('popup', className)}
      {...otherProps}
    >
      <div className="flex align-center space-between full-width popup-header">
        {shouldShowCloseIcon && (
          <CloseIcon onClick={onClose} className="popup-close-icon"/>
        )}
        {title && (
          <div className="text-center popup-title">
            {title}
          </div>
        )}
      </div>
      <section className="popup-scrollable-content">
        {text && (
          <div className="popup-content-text">
            {text}
          </div>
        )}
        {children && (
          <article className="flex flex-column popup-content-children">
            {children}
          </article>
        )}
      </section>
    </Dialog>
  );
};

export default Popup;
