import React, { createContext, type ReactElement, useContext } from 'react';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import ProfileSidebar from 'components/profile/profile-sidebar/ProfileSidebar';
import useWindowWidth from 'hooks/useWindowWidth';
import { useGetCustomerDetailsQuery } from 'store/api/customerApi';
import { type IPage } from '../../types/IPage';
import PageSeoSection from '../../components/page-seo-section/PageSeoSection';
import './Profile.scss';

interface IProfile extends IClassName {
  pageSeo?: IPage
  containerClassName?: string
  children: ReactElement | ReactElement[]
}

const CustomerDetailsContext = createContext<any>({});

export const useCustomerDetails = () => useContext(CustomerDetailsContext);

const Profile = ({ className, containerClassName, pageSeo, children }: IProfile) => {
  const { data: customerDetails, refetch } = useGetCustomerDetailsQuery();
  const { isMobile, isSmallTablet } = useWindowWidth();

  const shouldRenderSidebar = !isMobile && !isSmallTablet;

  return (
    <CustomerDetailsContext.Provider value={{ customerDetails, refetch }}>
      <section className={concatClassNames(className, 'flex profile')}>
        {shouldRenderSidebar && <ProfileSidebar/>}
        <div className={containerClassName}>
          {pageSeo && <PageSeoSection pageSeo={pageSeo} className="page-seo"/>}
          <div className="profile-content">
            {children}
          </div>
        </div>
      </section>
    </CustomerDetailsContext.Provider>
  );
};

export default Profile;
