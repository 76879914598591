import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import useForm from 'hooks/useForm';
import { validateEditArticleFields } from 'utils/validators/admin/blogValidator';
import TextEditor from 'components/editor/TextEditor';
import { useEditArticleMutation, useGetArticleByIdQuery } from 'store/api/blogApi';
import PhotoUploader from 'components/photo-uploader/PhotoUploader';
import { ThumbnailSize } from 'components/common/cloudinary-img/CloudinaryImage';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PlusIcon } from 'assets/icons/photo-plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { cloneDeep } from 'lodash';

enum BlogEditFields {
  File = 'file',
  CanonicalUrl = 'canonicalUrl',
  TitleEn = 'titleEn',
  TitleBg = 'titleBg',
  Heading1En = 'heading1En',
  Heading1Bg = 'heading1Bg',
  Heading2En = 'heading2En',
  Heading2Bg = 'heading2Bg',
  DescriptionEn = 'descriptionEn',
  DescriptionBg = 'descriptionBg',
  IsIndexed = 'isIndexed',
  MetaTags = 'metaTags',
}

interface IAdminBlogEdit {
  articleId: number
  onClosePopup?: () => void
}

const AdminBlogEdit = ({ articleId, onClosePopup }: IAdminBlogEdit) => {
  const { t } = useTranslation();
  const { data: article } = useGetArticleByIdQuery({ id: articleId });
  const [editArticle] = useEditArticleMutation();
  const [image, setImage] = useState<File | null>(null);

  const handleFileUpload = (file: File) => {
    setImage(file);
  };

  const onSubmit = async (values: any) => {
    const hasImageChanged = image && (image.name !== article?.images[0]);
    const editedArticle = {
      isIndexed: values.isIndexed,
      canonicalUrl: values.canonicalUrl,
      titleEn: values.titleEn,
      titleBg: values.titleBg,
      heading1En: values.heading1En,
      heading1Bg: values.heading1Bg,
      heading2En: values.heading2En,
      heading2Bg: values.heading2Bg,
      descriptionEn: values.descriptionEn,
      descriptionBg: values.descriptionBg,
      file: hasImageChanged ? image : null,
      metaTags: values.metaTags
    };

    try {
      await editArticle({ id: articleId, editedArticle }).unwrap();
      onClosePopup?.();
    } catch (err) {
      console.error('Failed to update the article:', err);
    }
  };

  const initialValues = {
    [BlogEditFields.File]: article?.images[0] ?? [],
    [BlogEditFields.IsIndexed]: article?.isIndexed ?? false,
    [BlogEditFields.CanonicalUrl]: article?.canonicalUrl ?? '',
    [BlogEditFields.TitleEn]: article?.titleEnglishContent ?? '',
    [BlogEditFields.TitleBg]: article?.titleBulgarianContent ?? '',
    [BlogEditFields.Heading1En]: article?.heading1EnglishContent ?? '',
    [BlogEditFields.Heading1Bg]: article?.heading1BulgarianContent ?? '',
    [BlogEditFields.Heading2En]: article?.heading2EnglishContent ?? '',
    [BlogEditFields.Heading2Bg]: article?.heading2BulgarianContent ?? '',
    [BlogEditFields.DescriptionEn]: article?.descriptionEnglishContent ?? '',
    [BlogEditFields.DescriptionBg]: article?.descriptionBulgarianContent ?? '',
    [BlogEditFields.MetaTags]: article?.metaTags ?? [{ name: '', content: '', contentEn: '' }]
  };

  const { values, setValues, handleChange, handleSubmit, errors } = useForm(
    initialValues,
    onSubmit,
    validateEditArticleFields
  );

  useEffect(() => {
    if (article) {
      setValues({
        [BlogEditFields.IsIndexed]: article.isIndexed,
        [BlogEditFields.CanonicalUrl]: article.canonicalUrl ?? '',
        [BlogEditFields.TitleEn]: article.titleEnglishContent ?? '',
        [BlogEditFields.TitleBg]: article.titleBulgarianContent ?? '',
        [BlogEditFields.Heading1En]: article.heading1EnglishContent ?? '',
        [BlogEditFields.Heading1Bg]: article.heading1BulgarianContent ?? '',
        [BlogEditFields.Heading2En]: article.heading2EnglishContent ?? '',
        [BlogEditFields.Heading2Bg]: article.heading2BulgarianContent ?? '',
        [BlogEditFields.DescriptionEn]: article.descriptionEnglishContent ?? '',
        [BlogEditFields.DescriptionBg]: article.descriptionBulgarianContent ?? '',
        [BlogEditFields.MetaTags]: article.metaTags,
        [BlogEditFields.File]: article.images[0] ?? [image]
      });
    }
  }, [article, setValues]);

  const handleAddMetaTag = () => {
    const newMetaTags = [...values.metaTags, { name: '', content: '', contentEn: '' }];
    handleChange(BlogEditFields.MetaTags, newMetaTags);
  };

  const handleRemoveMetaTag = (index: number) => {
    const newMetaTags = values.metaTags.filter((_, i) => i !== index);
    handleChange(BlogEditFields.MetaTags, newMetaTags);
  };

  const handleMetaTagChange = (index: number, field: string, value: string) => {
    const newMetaTags = cloneDeep(values.metaTags);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    newMetaTags[index][field] = value;
    handleChange(BlogEditFields.MetaTags, newMetaTags);
  };

  return (
    <div className="admin-blog-edit">
      <Form
        encType={'multipart/form-data'}
        onSubmit={handleSubmit}
        className="flex flex-column admin-blog-edit-form"
        submitText={t('editArticle.edit')}
      >
        {article?.images && (
            <div className="admin-blog-edit-image-wrapper">
              <PhotoUploader
                  id="article-image"
                  onUpload={handleFileUpload}
                  initialImage={article.images[0]}
                  icon={<CameraIcon/>}
                  thumbnailSize={ThumbnailSize.Large}
                  onRemove={() => {
                  }}
              />
            </div>
        )}
        <FormControl
            name={BlogEditFields.IsIndexed}
            labelText="Is Indexed"
            checked={values.isIndexed}
            error={errors.isIndexed}
            shouldUpdateValue
            type={FormControlType.checkbox}
            containerClassName="flex align-center indexed-checkbox"
            onChange={(value) => {
              handleChange(BlogEditFields.IsIndexed, value);
            }}
        />
        <FormControl
            name={BlogEditFields.CanonicalUrl}
            labelText="Canonical URL"
            value={values.canonicalUrl}
            error={errors.canonicalUrl}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.CanonicalUrl, value);
            }}
        />
        <FormControl
            name={BlogEditFields.TitleEn}
            labelText="Title En"
            value={values.titleEn}
            error={errors.titleEn}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.TitleEn, value);
            }}
        />
        <FormControl
            name={BlogEditFields.TitleBg}
            labelText="Title Bg"
            value={values.titleBg}
            error={errors.titleBg}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.TitleBg, value);
            }}
        />
        <FormControl
            name={BlogEditFields.Heading1En}
            labelText="Heading 1 En"
            value={values.heading1En}
            error={errors.heading1En}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.Heading1En, value);
            }}
        />
        <FormControl
            name={BlogEditFields.Heading1Bg}
            labelText="Heading 1 BG"
            value={values.heading1Bg}
            error={errors.heading1Bg}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.Heading1Bg, value);
            }}
        />
        <FormControl
            name={BlogEditFields.Heading2En}
            labelText="Heading 2 En"
            value={values.heading2En}
            error={errors.heading2En}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.Heading2En, value);
            }}
        />
        <FormControl
            name={BlogEditFields.Heading2Bg}
            labelText="Heading 2 BG"
            value={values.heading2Bg}
            error={errors.heading2Bg}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogEditFields.Heading2Bg, value);
            }}
        />
        <TextEditor
            name={BlogEditFields.DescriptionEn}
            label="Description En"
            value={values.descriptionEn}
            error={errors.descriptionEn}
            onChange={(newContent) => {
              handleChange(BlogEditFields.DescriptionEn, newContent);
            }}
        />
        <TextEditor
            name={BlogEditFields.DescriptionBg}
            label="Description Bg"
            value={values.descriptionBg}
            error={errors.descriptionBg}
            onChange={(newContent) => {
              handleChange(BlogEditFields.DescriptionBg, newContent);
            }}
        />
        {values.metaTags.map((metaTag, index) => (
          <div key={index} className="meta-tag-group">
            <FormControl
              name={`metaTags[${index}].name`}
              value={metaTag.name}
              labelText={`${t('administration.blog.create.metaTagName')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaName}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'name', value);
              }}
            />
            <FormControl
              name={`metaTags[${index}].content`}
              value={metaTag.content}
              labelText={`${t('administration.blog.create.metaTagContent')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaContent}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'content', value);
              }}
            />
            <FormControl
              name={`metaTags[${index}].contentEn`}
              value={metaTag.contentEn}
              labelText={`${t('administration.blog.create.metaTagContentEn')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaContentEn}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'contentEn', value);
              }}
            />
            {index !== 0 && (
              <IconButton onClick={() => {
                handleRemoveMetaTag(index);
              }} className="add-btn">
                <MinusIcon/>
              </IconButton>
            )}
          </div>
        ))}
        <IconButton onClick={handleAddMetaTag} className="remove-btn">
          <PlusIcon/>
        </IconButton>
      </Form>
    </div>
  );
};

export default AdminBlogEdit;
