import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import App from 'App';
import { NotificationProvider } from 'components/notification/Notification';
import './i18n';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-TTNK4BFF'
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </Provider>
  </React.StrictMode>
);
