import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import createHeaders from 'utils/createHeaders';
import { type IBanner } from 'types/IBanner';
import { type Order } from 'utils/constants';

const BANNERS_URL = 'banners';

export enum SortBy {
  Id = 'id',
  Name = 'name',
  IsVisible = 'isVisible'
}

export const bannerApi = createApi({
  reducerPath: 'banner',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllBanners: builder.query<IBanner[], {
      onlyVisible?: boolean
      sortBy?: SortBy
      order?: Order
    }>({
      query: ({
        onlyVisible = false,
        sortBy,
        order
      }) => {
        let queryString = `${BANNERS_URL}?onlyVisible=${onlyVisible}`;

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (order) {
          queryString += `&order=${order}`;
        }

        return queryString;
      }
    }),
    toggleBannerVisibility: builder.mutation<any, number>({
      query: (id) => ({
        url: `${BANNERS_URL}/${id}/ToggleVisibility`,
        method: 'PUT',
        body: {}
      })
    }),
    createBanner: builder.mutation<any, any>({
      query: (newBanner) => {
        const headers = createHeaders();

        const formData = new FormData();
        formData.append('name', newBanner.name);
        formData.append('file', newBanner.file);
        formData.append('isVisible', newBanner.isVisible);

        return {
          url: BANNERS_URL,
          method: 'POST',
          body: formData,
          headers
        };
      }
    }),
    deleteBanner: builder.mutation<{
      success: boolean
    }, number>({
      query: (id) => ({
        url: `${BANNERS_URL}/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetAllBannersQuery,
  useToggleBannerVisibilityMutation,
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useLazyGetAllBannersQuery
} = bannerApi;
