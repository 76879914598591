import { useTranslation } from 'react-i18next';
import Popup, { type IPopup } from 'components/popup/Popup';
import './ConfirmEmailPopup.scss';

interface IConfirmEmailPopup extends IPopup {
}

const ConfirmEmailPopup = ({ open, onClose }: IConfirmEmailPopup) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={t('confirmEmail.title')}
      text={t('confirmEmail.text')}
      className="confirm-email-popup"
    />
  );
};
export default ConfirmEmailPopup;
