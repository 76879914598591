import { useTranslation } from 'react-i18next';
import { getLocalizedValue } from 'utils/localization';

const useLocalization = (valueBg?: string, valueEn?: string): string => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  return getLocalizedValue(language, valueBg, valueEn);
};

export { useLocalization };
