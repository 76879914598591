import { useTranslation } from 'react-i18next';
import ShoppingCartCard from 'components/cards/shopping-cart-card/ShoppingCartCard';
import SectionCard from 'components/cards/section-card/SectionCard';
import { type IShoppingCartItem } from 'types/ICart';

interface ICartItems {
  shoppingCart?: IShoppingCartItem[]
  handleItemChanged: () => void
}

const CartItems = ({ shoppingCart, handleItemChanged }: ICartItems) => {
  const { t } = useTranslation();

  return (
    <SectionCard title={t('shoppingCart.orderTitle')} className="cart-items">
      {shoppingCart?.map((item) => (
        <ShoppingCartCard
          key={item.id}
          cartItem={item}
          className="cart-item"
          handleItemChanged={handleItemChanged}
        />
      ))}
    </SectionCard>
  );
};

export default CartItems;
