import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'components/slider/Slider';
import useWindowWidth from 'hooks/useWindowWidth';
import classNames from 'utils/classNames';
import { type IBanner } from 'types/IBanner';
import CloudinaryImage from 'components/common/cloudinary-img/CloudinaryImage';
import './HomeBanners.scss';

interface IBanners {
  banners: IBanner[]
}

enum LayoutType {
  Single = 'single',
  Double = 'double',
}

const SLIDER_LAYOUT_THRESHOLD = 2;

const HomeBanners = ({ banners }: IBanners) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();

  const layoutMapping = {
    1: LayoutType.Single,
    2: LayoutType.Double
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const layoutType = banners && banners.length <= 4 ? layoutMapping[banners.length] : LayoutType.Multiple;

  const generateBannerComponent = (key: string, imgSrc: string) => (
    <div key={key} className="home-banners-content-image-wrapper">
      <CloudinaryImage imagePublicId={imgSrc} className="home-banners-content-image"/>
    </div>
  );

  const bannerComponents = banners?.map((banner) => generateBannerComponent(banner.id.toString(), banner.imageSource)) || [];

  const groupedBannerComponents = [];

  for (let i = 0; i < bannerComponents.length; i += SLIDER_LAYOUT_THRESHOLD) {
    const group = bannerComponents.slice(i, i + SLIDER_LAYOUT_THRESHOLD);
    groupedBannerComponents.push(group);
  }

  const wrappedBannerComponents = groupedBannerComponents.map((group, index) => (
    <div key={index} className="home-banners-content slider">
      {group}
    </div>
  ));

  const shouldRenderSlider = bannerComponents.length > SLIDER_LAYOUT_THRESHOLD;

  return (
    <section
      className={classNames('flex flex-column justify-center align-center home-banners', isMobile ? '' : 'section-padding')}>
      <h2 className="home-banners-title">{t('home.bannersTitle')}</h2>
      {isMobile || shouldRenderSlider
        ? (
          <div className="home-banners-slider-wrapper pseudo-elements">
            <Slider
              autoplay={isMobile}
              slides={isMobile ? bannerComponents : wrappedBannerComponents}
              slidesToShow={isMobile ? 1 : 3}
            />
          </div>
          )
        : (
          <div className={classNames('home-banners-content pseudo-elements', layoutType)}>
            {bannerComponents}
          </div>
          )}
    </section>
  );
};

export default HomeBanners;
