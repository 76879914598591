import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'components/tabs/Tabs';
import AllBannersTab from 'components/administration/banners/AllBannersTab';
import CreateBannerTab from 'components/administration/banners/CreateBannerTab';

export enum AdminBannersTabs {
  AllBanners = 'All Banners',
  CreateBanner = 'Create Banner'
}

const AdminBannerTabs = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(AdminBannersTabs.CreateBanner);

  const tabs = useMemo(() => [
    {
      label: AdminBannersTabs.CreateBanner,
      content: <CreateBannerTab setCurrentTab={setCurrentTab} />,
      name: t('administration.banners.createBanner.title')
    },
    {
      label: AdminBannersTabs.AllBanners,
      content: <AllBannersTab/>,
      name: t('administration.banners.allBanners.title')
    }
  ], []);

  return (
    <Tabs
      className="user-administration-tabs"
      tabsData={tabs}
      ariaLabel="banner tabs"
      activeTab={currentTab}
    />
  );
};

export default AdminBannerTabs;
