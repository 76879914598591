import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import concatClassNames from 'utils/classNames';
import { Order } from 'utils/constants';
import { SortBy, useGetProductsByUserQuery, useGetProductsQuery } from 'store/api/productApi';
import ProductCard from 'components/cards/product-card/ProductCard';
import Pagination from 'components/pagination/Pagination';
import DesktopProductsFilter from 'pages/all-products/DesktopProductsFilter';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import useWindowWidth from 'hooks/useWindowWidth';
import { GenderIds, ProductLabel } from 'types/IProduct';
import MobileProductsFilter from 'pages/all-products/MobileProductsFilter';
import { REMOVE_FROM_FAVOURITES } from 'hooks/useFavouriteProducts';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import DOMPurify from 'dompurify';
import { ROUTES } from '../../routes/routes';
import PageSeoSection from '../../components/page-seo-section/PageSeoSection';
import './AllProducts.scss';
import { flatten, head, isEmpty, isNil } from 'lodash';
import { useGetAllCategoriesQuery } from 'store/api/categoiesApi';
import { findCategoryById, findParentCategoryByIds, findTopParentById, type ICategory } from 'types/ICategory';
import ProductsService, { Gender } from 'services/ProductsService';
import { getLocalizedValue } from 'utils/localization';
import { type IMetaTag } from 'types/IPage';
import { useI18n } from 'hooks/usei18n';

interface IAllProducts {
  className?: string
}

interface ISelectedFilterOption {
  id: string | number
  name: string
}

type ISelectedFilters = Record<string, Record<string, ISelectedFilterOption>>;

enum SpecialFilters {
  TOP_OFFER = 'TOP_OFFER',
  NEW = 'NEW',
  USED = 'USED',
  GIFT = 'GIFT',
  FROM_HOME_PAGE = 'FROM_HOME_PAGE',
  FAVORITES = 'FAVORITES'
}

const mapGenderIdsToGender = (genderId: GenderIds) => genderId === GenderIds.Boys
  ? Gender.boys
  : genderId === GenderIds.Girls
    ? Gender.girls
    : genderId === GenderIds.Twins
      ? Gender.twins
      : genderId === GenderIds.Unisex
        ? Gender.unisex
        : null;

const mapGenderToGenderId = (gender: Gender) => gender === Gender.boys
  ? GenderIds.Boys
  : gender === Gender.girls
    ? GenderIds.Girls
    : gender === Gender.twins
      ? GenderIds.Twins
      : gender === Gender.unisex
        ? GenderIds.Unisex
        : null;

const AllProducts = ({ className }: IAllProducts) => {
  const { t } = useTranslation();

  const { category1: categoryArg1, category2: categoryArg2, userId } = useParams();
  const { data: categories } = useGetAllCategoriesQuery();

  const { isMobile, isTablet } = useWindowWidth();

  const { getLanguageUrl } = useI18n();

  const PAGE_SIZE = isMobile || isTablet ? 16 : 15;

  const specialFilters = [
    {
      id: SpecialFilters.TOP_OFFER,
      name: t('specialFilters.topOffer')
    },
    {
      id: SpecialFilters.NEW,
      name: t('specialFilters.new')
    },
    {
      id: SpecialFilters.USED,
      name: t('specialFilters.used')
    },
    {
      id: SpecialFilters.GIFT,
      name: t('specialFilters.gift')
    },
    {
      id: SpecialFilters.FROM_HOME_PAGE,
      name: t('specialFilters.fromHomePage')
    },
    {
      id: SpecialFilters.FAVORITES,
      name: t('specialFilters.favourites')
    }
  ];

  const [categoryFilters, setCategoryFilters] = useState<number[]>([]);
  const [sizeFilters, setSizeFilters] = useState<number[]>([]);
  const [genderFilters, setGenderFilters] = useState<number[]>([]);
  const [selectedSpecialFilters, setSelectedSpecialFilters] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openMobileFilters, setOpenMobileFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>({});
  const [expandedCategory, setExpandedCategory] = useState('');
  const [selectedParentCategory, setSelectedParentCategory] = useState<number | null>(null);
  // TODO: no time to fix it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSelectedFilterDescription] = useState('');

  const mapSpecialFiltersToProductLabels = (selectedFilters: any) => {
    const labels = [];
    if (selectedFilters?.includes(SpecialFilters.NEW)) {
      labels.push(ProductLabel.New);
    }
    if (selectedFilters?.includes(SpecialFilters.USED)) {
      labels.push(ProductLabel.Used);
    }
    return labels;
  };

  const productLabels = mapSpecialFiltersToProductLabels(selectedSpecialFilters);

  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.AllProducts.path });

  const { data: allProductsByCategory, refetch } = useGetProductsQuery({
    page: currentPage,
    pageSize: PAGE_SIZE,
    categories: categoryFilters,
    sizes: sizeFilters,
    genders: genderFilters?.includes(GenderIds.Boys) || genderFilters?.includes(GenderIds.Girls)
      ? [...genderFilters, GenderIds.Unisex]
      : genderFilters,
    onlyGifts: selectedSpecialFilters?.includes(SpecialFilters.GIFT),
    onlyWithBadge: selectedSpecialFilters?.includes(SpecialFilters.TOP_OFFER),
    onlyForHomePage: selectedSpecialFilters?.includes(SpecialFilters.FROM_HOME_PAGE),
    labels: productLabels.length > 0 ? productLabels : undefined,
    favourites: selectedSpecialFilters?.includes(SpecialFilters.FAVORITES),
    sortBy: SortBy.Badge,
    order: Order.DESC
  },
  {
    skip: !isNil(userId)
  });

  const { data: allProductsByUser } = useGetProductsByUserQuery({
    page: currentPage,
    pageSize: PAGE_SIZE,
    categories: categoryFilters,
    sizes: sizeFilters,
    genders: genderFilters?.includes(GenderIds.Boys) || genderFilters?.includes(GenderIds.Girls)
      ? [...genderFilters, GenderIds.Unisex]
      : genderFilters,
    onlyGifts: selectedSpecialFilters?.includes(SpecialFilters.GIFT),
    onlyWithBadge: selectedSpecialFilters?.includes(SpecialFilters.TOP_OFFER),
    onlyForHomePage: selectedSpecialFilters?.includes(SpecialFilters.FROM_HOME_PAGE),
    labels: productLabels.length > 0 ? productLabels : undefined,
    favourites: selectedSpecialFilters?.includes(SpecialFilters.FAVORITES),
    sortBy: SortBy.Badge,
    order: Order.DESC,
    userId: userId as string
  },
  {
    skip: isNil(userId)
  });

  const allProducts = useMemo(() => {
    return isNil(userId)
      ? allProductsByCategory
      : allProductsByUser;
  }, [allProductsByCategory, allProductsByUser, userId]);

  useEffect(() => {
    const handleFavouriteProductsChange = () => {
      void refetch();
    };

    window.addEventListener(REMOVE_FROM_FAVOURITES, handleFavouriteProductsChange);

    return () => {
      window.removeEventListener(REMOVE_FROM_FAVOURITES, handleFavouriteProductsChange);
    };
  }, []);

  useEffect(() => {
    const { categories: categoriesFromUrl, genders: gendersFromUrl } = new ProductsService(categories as ICategory[]).decostruct(categoryArg1, categoryArg2);

    if (!isNil(categoriesFromUrl) && !isEmpty(categoriesFromUrl)) {
      const ids = categoriesFromUrl.map(({ id }) => Number(id));
      setCategoryFilters(ids);
      const { id: parentId } = findTopParentById(categories, head(ids)) || {};
      if (!isNil(parentId)) {
        setExpandedCategory(parentId.toString());
        setSelectedParentCategory(parentId);
      }
    }

    if (!isNil(gendersFromUrl) && !isEmpty(gendersFromUrl)) {
      setGenderFilters(gendersFromUrl.map(x => Number(mapGenderToGenderId(x))));
    }

    if (categoryArg1 === 'favourites') {
      setSelectedSpecialFilters([SpecialFilters.FAVORITES]);
    }
  }, [categories, categoryArg1, categoryArg2, setSelectedSpecialFilters]);

  useEffect(() => {
    if (selectedSpecialFilters?.includes(SpecialFilters.FAVORITES)) {
      void refetch();
    }
  }, [selectedSpecialFilters?.includes(SpecialFilters.FAVORITES)]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const buildCanonicalUrl = useCallback(
    () => {
      const selectedGenders = genderFilters?.map(mapGenderIdsToGender) ?? [];
      const selectedCategories = categoryFilters?.map(categoryId => findCategoryById(categories, categoryId));

      const canonicalUrl = new ProductsService(categories as ICategory[]).constructUrl({
        categories: selectedCategories as ICategory[],
        genders: selectedGenders as Gender[]
      });

      return `/all-products/${canonicalUrl}`;
    },
    [genderFilters, categoryFilters, categories]
  );

  const { i18n } = useTranslation();
  const { language } = i18n;

  const selectedCategories = useMemo(
    () => categoryFilters
      .map(categoryId => findCategoryById(categories, categoryId) as ICategory)
      .filter(x => !isNil(x)),
    [categories, categoryFilters]
  );

  const categoryMetaTags = useMemo(
    () => selectedCategories
      .map(({ metaTags: categoryMetaTags }) => categoryMetaTags),
    [categoryFilters, categories]
  );

  const metaTags = useMemo(
    () => {
      if (isNil(pageSeoData)) {
        return [];
      }
      const { metaTags: originalMetaTags } = pageSeoData || { metaTags: [] };

      return isEmpty(selectedCategories)
        ? originalMetaTags
        : flatten(categoryMetaTags) as IMetaTag[];
    },
    [pageSeoData, categoryMetaTags]
  );

  const categoriesDescriptions = useMemo(
    () => selectedCategories
      .filter(x => !isNil(x))
      .map(({ descriptionBg, descriptionEn }) => getLocalizedValue(language, descriptionBg, descriptionEn))
      .map(description => DOMPurify.sanitize(description)),
    [categoryFilters, categories]
  );

  const heading1Content = useMemo(
    () => {
      if (isNil(selectedCategories) || isEmpty(selectedCategories)) {
        const { heading1BulgarianContent, heading1EnglishContent } = pageSeoData ?? {};
        return { heading1BulgarianContent, heading1EnglishContent };
      }

      const {
        nameBg,
        nameEn,
        heading1Bg,
        heading1En
      } = selectedCategories.length === 1
        ? head(selectedCategories) as ICategory
        : findParentCategoryByIds(categories as ICategory[], selectedCategories.map(x => Number(x.id))) as ICategory;

      const heading1BgContent = isNil(heading1Bg)
        ? nameBg
        : heading1Bg;
      const heading1EnContent = isNil(heading1En)
        ? nameEn
        : heading1En;

      return {
        heading1BulgarianContent: heading1BgContent,
        heading1EnglishContent: heading1EnContent
      };
    },
    [selectedCategories, categories, pageSeoData]
  );

  const buildPageSeo = useCallback(
    () => {
      const {
        titleBulgarianContent: originalTitleBulgarianContent,
        titleEnglishContent: originalTitleEnglishContent
      } = pageSeoData ?? { metaTags: [] as IMetaTag[] };

      const titleMetaTag = metaTags.find(x => x.name === 'title');

      const titleBulgarianContent = isNil(titleMetaTag)
        ? originalTitleBulgarianContent
        : titleMetaTag.content;
      const titleEnglishContent = isNil(titleMetaTag)
        ? originalTitleEnglishContent
        : titleMetaTag.contentEn;

      const {
        heading1BulgarianContent,
        heading1EnglishContent
      } = heading1Content;

      return {
        ...pageSeoData,
        titleBulgarianContent,
        titleEnglishContent,
        metaTags,
        heading1BulgarianContent,
        heading1EnglishContent
      };
    },
    [pageSeoData, metaTags]);

  const actualPageSeoData: any = useMemo(
    () => {
      const canonicalUrl = buildCanonicalUrl();
      const pageSeoDataWithCategoriesSeo = buildPageSeo();

      return {
        ...pageSeoDataWithCategoriesSeo,
        canonicalUrl
      };
    },
    [pageSeoData, buildCanonicalUrl, buildPageSeo, heading1Content]
  );

  const navigate = useNavigate();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad) {
      return;
    }

    const url = buildCanonicalUrl();
    const languageUrl = getLanguageUrl(url);

    navigate(languageUrl);
  }, [genderFilters, categoryFilters, categories]);

  const handleCategoriesFiltersChanged = (categoryIds: number[]) => {
    setCategoryFilters(categoryIds);
    setIsFirstLoad(false);
  };

  const handleGenderFiltersChanged = (genderIds: number[]) => {
    setGenderFilters(genderIds);
    setIsFirstLoad(false);
  };

  const handleSizeFiltersChanged = useCallback(
    (value: number[]) => {
      setSizeFilters(value);

      void refetch();
    },
    [setSizeFilters, refetch]
  );

  const renderAllProducts = useCallback(
    () => {
      return (
        <div className="flex flex-column all-products-wrapper">
          {allProducts?.models && allProducts.models.length === 0 && (
            <span className="all-products-no-products">
              {t('allProducts.empty')}
            </span>
          )}
          <article className="all-products-items">
            {allProducts?.models?.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
              />
            ))}
          </article>
          {allProducts?.models && allProducts.models.length > 0 && (
            <Pagination
              count={allProducts?.totalPages ?? 1}
              page={currentPage}
              onChange={handlePageChange}
            />
          )}
          {categoriesDescriptions.map((description, index) => (
            <div key={index} className='category-description' dangerouslySetInnerHTML={{ __html: description }} />
          ))}
        </div>
      );
    },
    [allProducts, currentPage, handlePageChange, categoriesDescriptions]
  );

  const renderDesktopProductsFilter = useCallback(
    () => {
      if (isMobile) {
        return null;
      }

      return (
        <DesktopProductsFilter
          categoryFilters={categoryFilters}
          sizeFilters={sizeFilters}
          genderFilters={genderFilters}
          specialFilters={specialFilters}
          selectedSpecialFilters={selectedSpecialFilters}
          setSizeFilters={handleSizeFiltersChanged}
          setGenderFilters={handleGenderFiltersChanged}
          setSelectedSpecialFilters={setSelectedSpecialFilters}
        />

      );
    },
    [categoryFilters, sizeFilters, genderFilters, specialFilters, selectedSpecialFilters, handleSizeFiltersChanged, handleGenderFiltersChanged, setSelectedSpecialFilters]
  );

  return (
    <section className="all-products-container">
      <PageSeoSection pageSeo={actualPageSeoData} />

      <section className={concatClassNames(className, 'flex align-start all-products')}>
        {renderDesktopProductsFilter()}
        {isMobile && (
          <div className="filter-mobile flex align-center" onClick={() => {
            setOpenMobileFilters(true);
          }}>
            <FilterIcon />
            <span className="filter-mobile-text">
              {t('allProducts.filters')}
            </span>
          </div>
        )}

        {isMobile && openMobileFilters && (
          <MobileProductsFilter
            title={t('allProducts.filters')}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            expandedCategory={expandedCategory}
            selectedParentCategory={selectedParentCategory}
            setExpandedCategory={setExpandedCategory}
            sizeFilters={sizeFilters}
            categoryFilters={categoryFilters}
            setCategoryFilters={handleCategoriesFiltersChanged}
            specialFilters={specialFilters}
            selectedSpecialFilters={selectedSpecialFilters}
            setSelectedSpecialFilters={setSelectedSpecialFilters}
            genderFilters={genderFilters}
            setGenderFilters={handleGenderFiltersChanged}
            setSizeFilters={handleSizeFiltersChanged}
            setSelectedFilterDescription={setSelectedFilterDescription}
            onClose={() => {
              setOpenMobileFilters(false);
            }}
          />
        )}
        {renderAllProducts()}
      </section>
    </section>
  );
};

export default AllProducts;
