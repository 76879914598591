import { createElement, useCallback, useEffect } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes, { type RouteType } from 'routes/routes';
import PageLayout from 'components/layout/PageLayout';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';
import withAuth from 'routes/withAuth';
import { useGetShoppingCartSummaryQuery } from 'store/api/cartApi';
import { dispatchStorageEvent, setStorageItem } from 'utils/storage';
import { CART_ITEMS, CART_ITEMS_CHANGED_EVENT } from 'components/product/ProductActions';
import { isUserLoggedIn } from 'utils/auth';
import { checkForMobileFilter } from 'utils/helpers';
import { FAVOURITE_PRODUCTS, FAVOURITE_PRODUCTS_CHANGED_EVENT } from 'hooks/useFavouriteProducts';
import useSignalR from 'hooks/useSignalR';
import 'styles/index.scss';
import I18nProvider from 'hooks/usei18n';
import { flatten } from 'lodash';
import withLanguageUrlPrefix from 'routes/withLanguageUrlPrefix';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Comfortaa',
      'sans-serif'
    ].join(',')
  }
});

const SIGNALR_URL = `${process.env.REACT_APP_BASE_API_URL}notifications`;

const App = () => {
  const isLoggedIn = isUserLoggedIn();
  isLoggedIn && useSignalR(SIGNALR_URL);

  const { data: shoppingCartSummary } = isLoggedIn ? useGetShoppingCartSummaryQuery() : { data: null };

  useEffect(() => {
    if (!isLoggedIn) {
      setStorageItem(FAVOURITE_PRODUCTS, []);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (shoppingCartSummary !== null) {
      const cartItems = shoppingCartSummary?.cartItemsCount || 0;
      setStorageItem(CART_ITEMS, cartItems);
      dispatchStorageEvent(CART_ITEMS_CHANGED_EVENT, cartItems);

      const favouriteProductIds = shoppingCartSummary?.favouriteProductIds || [];
      setStorageItem(FAVOURITE_PRODUCTS, favouriteProductIds);
      dispatchStorageEvent(FAVOURITE_PRODUCTS_CHANGED_EVENT, favouriteProductIds);
    }
  }, [shoppingCartSummary]);

  useEffect(() => {
    checkForMobileFilter();

    const observer = new MutationObserver(checkForMobileFilter);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const buildRoutes = useCallback(
    (routes: RouteType[]) => {
      return flatten(
        routes.map((route) => {
          const { path, auth, component } = route;
          const enPath = '/en' + path;

          return [
            (<Route
              key={path}
              path={path}
              element={createElement(withLanguageUrlPrefix(withAuth(component, auth)), { className: 'page-layout-content' })} />
            ),
            (
              (<Route
                key={enPath}
                path={enPath}
                element={createElement(withLanguageUrlPrefix(withAuth(component, auth)), { className: 'page-layout-content' })} />
              )
            )
          ];
        }));
    },
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <I18nProvider>
          <ScrollToTop>
            <PageLayout>
              <Routes>
                {buildRoutes(routes)}
              </Routes>
            </PageLayout>
          </ScrollToTop>
        </I18nProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
