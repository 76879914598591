import { useTranslation } from 'react-i18next';
import StyledPopup, { type IStyledPopup } from 'components/common/styled-popup/StyledPopup';
import { useDeleteOrderItemMutation } from 'store/api/orderApi';

interface IReturnProductToActivePopup extends IStyledPopup {
  orderItemId: number
  orderItemName: string
  refetch: () => Promise<any>
}

const ReturnProductToActivePopup = ({ open, onClose, orderItemId, orderItemName, refetch }: IReturnProductToActivePopup) => {
  const { t } = useTranslation();

  const [useDeleteOrderItem] = useDeleteOrderItemMutation();

  const onDelete = async () => {
    try {
      const response: any = await useDeleteOrderItem({ orderItemId });

      if (response.data === null) {
        await refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <StyledPopup
      open={open}
      title={t('profile.payments.returnToActiveConfirmation', { orderItemName })}
      text={t('profile.payments.returnToActiveConfirmationText')}
      onClose={onClose}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onAccept={onDelete}
    />
  );
};

export default ReturnProductToActivePopup;
