import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import concatClassNames from 'utils/classNames';
import { LOCAL_STORAGE_LOCALIZATION_KEY } from '../../i18n';

interface IMobileCategory {
  item: { id: number, nameBg: string, subcategories: any[], nameEn: string }
  onClick: (id: number) => void
  className?: string
  itemClassName?: string
  setHasNestedSubcategories?: any
}

const MobileCategory = ({ item, onClick, className, itemClassName, setHasNestedSubcategories }: IMobileCategory) => {
  const selectedLanguage = localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_KEY) ?? 'bg';
  const handleOnClick = () => {
    onClick(item.id);

    if (item?.subcategories.length > 0) {
      setHasNestedSubcategories?.(true);
    } else {
      setHasNestedSubcategories?.(false);
    }
  };

  return (
    <div onClick={handleOnClick} className={className}>
    <span className={concatClassNames('flex align-center space-between', itemClassName)}>
      {selectedLanguage === 'bg' ? item?.nameBg : item?.nameEn}
      {item?.subcategories?.length > 0 && <ArrowRightIcon/>}
    </span>
    </div>
  );
};

export default MobileCategory;
