import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/dropdown/Dropdown';
import { useGetProductsGendersQuery } from 'store/api/productApi';

export interface IGender {
  id: number
  name: string
}

interface IGenderDropdownProps {
  selectedGenders: number[]
  setSelectedGenders: React.Dispatch<React.SetStateAction<number[]>>
  handleGenderChange: (event: React.ChangeEvent<{ value: unknown }>) => void
  error?: string
}

const GenderDropdown = ({ selectedGenders, handleGenderChange, error }: IGenderDropdownProps) => {
  const { t } = useTranslation();
  const { data: genders, isLoading, isError } = useGetProductsGendersQuery({});

  const localizedGenders = genders?.map((gender: IGender) => ({
    ...gender,
    name: t(`genders.${gender.name.toLowerCase()}`)
  }));

  if (isLoading) return <div>{t('common.loading')}</div>;
  if (isError) return <div>{t('common.errorLoadingGenders')}</div>;

  return (
    <Dropdown
      label={t('common.chooseGender')}
      options={localizedGenders}
      value={selectedGenders}
      onChange={handleGenderChange}
      error={error}
      renderValue={(selected: number[]) => {
        const names = selected.map(id => localizedGenders.find((option: IGender) => +option.id === +id)?.name ?? '');
        return names.join(', ');
      }}
    />
  );
};

export default GenderDropdown;
