import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import { useGetMyOrdersQuery } from 'store/api/orderApi';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils/constants';
import WriteReviewPopup from 'components/review/write-review-popup/WriteReviewPopup';
import { getCurrencySymbol } from 'utils/currency';
import useWindowWidth from 'hooks/useWindowWidth';
import Pagination from 'components/pagination/Pagination';

const ProfileOrders = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [queryParams, setQueryParams] = useState({ page: DEFAULT_PAGE });
  const { data: orders, isLoading } = useGetMyOrdersQuery(queryParams);
  const [reviewPopupOpen, setReviewPopupOpen] = useState(false);
  const [currentSeller, setCurrentSeller] = useState<{ id: number, name: string }>({
    id: 0,
    name: ''
  });

  const handleReviewClick = (sellerId: number, sellerUsername: string) => {
    setCurrentSeller({
      id: sellerId,
      name: sellerUsername
    });
    setReviewPopupOpen(true);
  };

  const dataColumnsDesktop: GridColDef[] = [
    {
      field: 'createdOn',
      headerName: t('profile.payments.date'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.createdOn).toLocaleDateString();
      }
    },
    {
      field: 'id',
      flex: 1,
      headerName: t('profile.payments.numberOfOrder'),
      disableColumnMenu: true
    },
    {
      field: 'sellerUsername',
      headerName: t('profile.payments.user'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column">
          {params.row.sellerUsername}
          <span
            className="profile-orders-table-write-review"
            onClick={() => {
              handleReviewClick(params.row.sellerId, params.row.sellerUsername);
            }}
          >
            {t('profile.payments.writeReview')}
          </span>
        </div>
      )
    },
    {
      field: 'totalPrice',
      headerName: t('profile.payments.paidAmount'),
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.totalPrice.amount.toFixed(2)} ${getCurrencySymbol(params.row.totalPrice.currency)}`;
      }
    },
    {
      field: 'status',
      headerName: t('profile.payments.status'),
      disableColumnMenu: true,
      width: 250,
      valueGetter: (params: GridValueGetterParams) =>
        t(`operationCodes.${params.row.operationCode.toString()}`)
    }
  ];

  const dataColumnsMobile: GridColDef[] = [
    {
      field: 'createdOn',
      headerName: t('profile.payments.date'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column justify-center align-center">
          <span className="profile-orders-table-order-id">№ {params.row.id}</span>
          {new Date(params.row.createdOn).toLocaleDateString()}
        </div>
      )
    },
    {
      field: 'sellerUsername',
      headerName: t('profile.payments.user'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column justify-center align-center">
          {params.row.sellerUsername}
          <span
            className="profile-orders-table-write-review"
            onClick={() => {
              handleReviewClick(params.row.sellerId, params.row.sellerUsername);
            }}
          >
            {t('profile.payments.writeReview')}
          </span>
        </div>
      )
    },
    {
      field: 'totalPrice',
      headerName: t('profile.payments.paidAmount'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.totalPrice.amount.toFixed(2)} ${getCurrencySymbol(params.row.totalPrice.currency)}`;
      }
    },
    {
      field: 'status',
      headerName: t('profile.payments.status'),
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        t(`operationCodes.${params.row.operationCode.toString()}`)
    }
  ];

  if (isLoading) {
    return <div>{t('profile.payments.loadingOrders')}</div>;
  }

  if (orders?.models.length === 0) {
    return <div>{t('profile.payments.noOrders')}</div>;
  }

  return (
    <div>
      <DataGrid
        className="profile-data-grid profile-orders-table"
        rows={orders?.models ?? []}
        columns={isMobile ? dataColumnsMobile : dataColumnsDesktop}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
          }
        }}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[DEFAULT_PAGE_SIZE]}
        disableColumnFilter
        rowCount={orders?.totalCount ?? 0}
        paginationMode='server'
        onPaginationModelChange={(e) => {
          setQueryParams({ page: e.page + 1 });
        }}
      />
      <Pagination
        count={orders?.totalPages ?? 1}
        page={queryParams.page}
        onChange={(value) => {
          setQueryParams({ page: value });
        }}
      />
      {reviewPopupOpen && (
        <WriteReviewPopup
          sellerId={currentSeller.id}
          sellerUsername={currentSeller.name}
          open={reviewPopupOpen}
          onClose={() => {
            setReviewPopupOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ProfileOrders;
