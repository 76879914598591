import { useTranslation } from 'react-i18next';
import { type IPopup } from 'components/popup/Popup';
import StyledPopup from 'components/common/styled-popup/StyledPopup';
import Badges from 'components/common/badges/Badges';

interface IChooseBadgePopup extends IPopup {
  onAccept: () => void
  selectedBadge?: any
  onBadgeClick?: (badge: any) => void
}

const ChooseBadgePopup = ({ open, onClose, selectedBadge, onBadgeClick, onAccept }: IChooseBadgePopup) => {
  const { t } = useTranslation();

  return (
    <StyledPopup
      open={open}
      onClose={onClose}
      shouldShowCloseIcon={false}
      onAccept={onAccept}
      className="choose-badge-popup"
      title={t('paidAds.chooseBadgeText')}
    >
      <Badges
        selectedBadge={selectedBadge}
        onClick={onBadgeClick}
      />
    </StyledPopup>
  );
};

export default ChooseBadgePopup;
