import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils/constants';
import Pagination from 'components/pagination/Pagination';
import { getCurrencySymbol } from 'utils/currency';
import useWindowWidth from 'hooks/useWindowWidth';
import { useGetUserPaymentsQuery } from 'store/api/paymentApi';
import './ProfileProfit.scss';

const ProfileSubscriptions = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [queryParams, setQueryParams] = useState({ page: DEFAULT_PAGE });
  const { data: subscriptions } = useGetUserPaymentsQuery(queryParams);

  const dataColumnsDesktop: GridColDef[] = [
    {
      field: 'paidAdDescription',
      headerName: t('profile.payments.subscriptionType'),
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'paymentDate',
      headerName: t('profile.payments.dateOfPurchase'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.paymentDate).toLocaleDateString();
      }
    },
    {
      field: 'validUntil',
      headerName: t('profile.payments.validUntil'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.validUntil).toLocaleDateString();
      }
    },
    {
      field: 'priceWithVat',
      headerName: t('profile.payments.price'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.priceWithVat.amount.toFixed(2)} ${getCurrencySymbol(params.row.priceWithVat.currency)}`;
      }
    }
  ];

  const dataColumnsMobile: GridColDef[] = [
    {
      field: 'paidAdDescription',
      headerName: t('profile.payments.subscriptionType'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column">
          <span>{new Date(params.row.paymentDate).toLocaleDateString()}</span>
          <span>{params.row.paidAdDescription}</span>
        </div>
      )
    },
    {
      field: 'priceWithVat',
      headerName: t('profile.payments.price'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.priceWithVat.amount.toFixed(2)} ${getCurrencySymbol(params.row.priceWithVat.currency)}`;
      }
    }
  ];

  if (subscriptions?.models.length === 0) {
    return <div>{t('profile.payments.noSubscriptions')}</div>;
  }

  return (
    <div className="profile-profit">
      <DataGrid
        className="profile-data-grid profile-profit-table"
        rows={subscriptions?.models ?? []}
        columns={isMobile ? dataColumnsMobile : dataColumnsDesktop}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
          }
        }}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[DEFAULT_PAGE_SIZE]}
        disableColumnFilter
        rowCount={subscriptions?.totalCount ?? 0}
        paginationMode='server'
        onPaginationModelChange={(e) => {
          setQueryParams({ page: e.page + 1 });
        }}
      />
      <Pagination
        count={subscriptions?.totalPages ?? 1}
        page={queryParams.page}
        onChange={(value) => {
          setQueryParams({ page: value });
        }}
      />
    </div>
  );
};

export default ProfileSubscriptions;
