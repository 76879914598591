import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColors, ButtonType } from 'components/button/Button';
import { getStorageItem, setStorageItem } from 'utils/storage';
import { migrateTokenToCookie } from 'utils/token';
import './CookiePolicy.scss';

export const COOKIE_CONSENT_KEY = 'cookieConsent';

export enum CookieConsentType {
  accepted = 'accepted',
  rejected = 'rejected',
}

const CookiePolicy = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = getStorageItem(COOKIE_CONSENT_KEY);

    if (cookieConsent === null) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = useCallback(() => {
    setStorageItem(COOKIE_CONSENT_KEY, CookieConsentType.accepted);
    setIsVisible(false);
    migrateTokenToCookie();
  }, []);

  const handleReject = useCallback(() => {
    setStorageItem(COOKIE_CONSENT_KEY, CookieConsentType.rejected);
    setIsVisible(false);
  }, []);

  if (!isVisible) return null;

  return (
    <section className="flex space-between align-center cookie-policy" aria-live="polite">
      <p className="cookie-policy-text">
        {t('cookiePolicy.text')}
      </p>
      <div className="flex cookie-policy-actions">
        <Button
          onClick={handleAccept}
          className="cookie-policy-actions-accept"
          type={ButtonType.primary}
          color={ButtonColors.custom}
          text={t('cookiePolicy.accept')}
        />
        <Button
          onClick={handleReject}
          className="cookie-policy-actions-reject"
          type={ButtonType.secondary}
          color={ButtonColors.custom}
          text={t('cookiePolicy.reject')}
        />
      </div>
    </section>
  );
};

export default CookiePolicy;
