import React, { useEffect, useMemo, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import Tooltip, { TooltipPlacement } from 'components/tooltip/Tooltip';
import LinkCard from 'components/cards/LinkCard';
import { type IArticle } from 'types/IArticle';
import { ROUTES } from 'routes/routes';
import Button, { ButtonType } from 'components/button/Button';
import './ArticleCard.scss';
import { useI18n } from 'hooks/usei18n';
import { useLocalization } from 'hooks/useLocalization';
import { getLocalizedValue } from 'utils/localization';
import concatClassNames from 'utils/classNames';

interface IArticleCardProps {
  article: IArticle
  onDelete?: (id: number) => void
  onEdit?: (id: number) => void
  className?: string | null
  onChangeVisibility?: (id: number, isPublic: boolean) => void
}

const ArticleCard = ({
  article,
  onDelete,
  onEdit,
  onChangeVisibility,
  className = null
}: IArticleCardProps) => {
  const { t } = useTranslation();
  const { language } = useI18n();

  const {
    id,
    heading1EnglishContent,
    heading1BulgarianContent,
    descriptionEnglishContent,
    descriptionBulgarianContent,
    isPublic,
    images,
    canonicalUrl
  } = article;

  const link = useMemo(
    () => ROUTES.BlogPostByCanonicalUrl.path.replace(':canonicalUrl', canonicalUrl),
    [canonicalUrl]
  );

  useEffect(
    () => { console.log(link); },
    [link]
  );

  const titleRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const heading = useLocalization(heading1BulgarianContent, heading1EnglishContent);
  const description = useLocalization(descriptionBulgarianContent, descriptionEnglishContent);

  const sanitizedDescription = DOMPurify.sanitize(description);

  const createMarkup = () => {
    return { __html: sanitizedDescription };
  };

  useEffect(() => {
    const checkTitleOverflow = () => {
      if (titleRef.current) {
        const element = titleRef.current;
        const maxHeight = parseInt(window.getComputedStyle(element).lineHeight, 10) * 2;
        setShowTooltip(element.scrollHeight > maxHeight);
      }
    };

    checkTitleOverflow();
    return () => {
      setShowTooltip(false);
    };
  }, []);

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete && onDelete(id);
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit && onEdit(id);
  };

  const handleChangeVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeVisibility && onChangeVisibility(id, isPublic);
  };

  const titleElement = (
    <div ref={titleRef} className="article-card__title truncate-multi-2">
      {getLocalizedValue(language, heading1BulgarianContent, heading1EnglishContent)}
    </div>
  );

  const linkCardClassName = useMemo(
    () => concatClassNames('article-card', className),
    [className]
  );

  return (
    <LinkCard
      title={showTooltip
        ? <Tooltip title={heading} placement={TooltipPlacement.Bottom}>{titleElement}</Tooltip>
        : titleElement}
      imgSrc={images?.[0]}
      isCloudinaryImage
      className={linkCardClassName}
      link={link}
    >
      <div className="actions">
        {onDelete &&
          (<Button
            type={ButtonType.secondary}
            onClick={handleDelete}
            text={t('administration.common.delete')}
            className="article-card-action"
          />
          )}
        {onEdit && (
          <Button
            type={ButtonType.secondary}
            onClick={handleEdit}
            className="article-card-action"
            text={t('administration.common.edit')}
          />
        )}
        {onChangeVisibility && (
          <Button
            type={ButtonType.secondary}
            onClick={handleChangeVisibility}
            text={isPublic ? t('administration.common.hide') : t('administration.common.publish')}
            className="article-card-action"
          />)}
      </div>
      <div className="article-card-content truncate-multi-2" dangerouslySetInnerHTML={createMarkup()} />
    </LinkCard>
  );
};

export default ArticleCard;
