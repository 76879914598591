import { type IPopup } from 'components/popup/Popup';
import StyledPopup from 'components/common/styled-popup/StyledPopup';

interface IBadgeActivationPopup extends IPopup {
  onAccept?: () => void
}

const BadgeActivationPopup = ({ title, open, onClose, onAccept }: IBadgeActivationPopup) => {
  return (
    <StyledPopup
      open={open}
      onClose={onClose}
      shouldShowCloseIcon={false}
      onAccept={onAccept}
      className="badge-activation-popup"
      title={title}
    />
  );
};

export default BadgeActivationPopup;
