import React, { type ChangeEvent, type ReactNode, useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ALLOWED_IMAGE_SIZE, SUPPORTED_IMAGE_TYPES } from 'cloudinary-config';
import CloudinaryImage, { ThumbnailSize } from 'components/common/cloudinary-img/CloudinaryImage';
import { useNotification } from '../notification/Notification';
import { useTranslation } from 'react-i18next';
import './PhotoUploader.scss';

interface IPhotoUploaderProps {
  id: string
  onUpload: (file: File) => void
  onRemove?: () => void
  icon: ReactNode
  initialImage?: string
  thumbnailSize?: ThumbnailSize
}

const PhotoUploader = ({
  id,
  onUpload,
  onRemove,
  icon,
  initialImage,
  thumbnailSize = ThumbnailSize.Small
}: IPhotoUploaderProps) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [isNewUpload, setIsNewUpload] = useState<boolean>(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialImage && !isNewUpload) {
      setImagePreviewUrl(initialImage);
      setIsUploadDisabled(true);
    }
  }, [initialImage, isNewUpload]);

  const handleOnRemove = () => {
    setImagePreviewUrl(null);
    onRemove?.();
    setIsUploadDisabled(false);
  };

  const handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const file = event.target.files[0];

      if (file.size > ALLOWED_IMAGE_SIZE) {
        showNotification(t('validations.fileTooLarge'));
        return;
      }

      const fileUrl = URL.createObjectURL(file);
      setImagePreviewUrl(fileUrl);
      setIsNewUpload(true);
      onUpload(file);
    }
  };

  const handleImageClick = () => {
    if (!isUploadDisabled) {
      fileInputRef.current?.click();
    }
  };

  return (
    <div className="flex justify-center align-center photo-uploader">
      {imagePreviewUrl
        ? (
          <div className="photo-uploader-preview">
            <div className="photo-uploader-image-container" onClick={handleImageClick}>
              {isNewUpload
                ? (
                  <img src={imagePreviewUrl} alt="Uploaded" className="photo-uploader-image"/>
                  )
                : (
                  <CloudinaryImage
                    imagePublicId={initialImage ?? ''}
                    thumbnailSize={thumbnailSize}
                    className="photo-uploader-image"
                  />
                  )}
            </div>
            {onRemove && (
              <IconButton
                className="photo-uploader-preview-remove-icon"
                onClick={handleOnRemove}
                size="small"
              >
                <CloseIcon/>
              </IconButton>
            )}
          </div>
          )
        : (
          <label htmlFor={`icon-button-file-${id}`}>
            <IconButton
              className="photo-uploader-icon"
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              {icon}
            </IconButton>
          </label>
          )}
      <input
        accept={SUPPORTED_IMAGE_TYPES.map(type => `.${type}`).join(',')}
        style={{ display: 'none' }}
        id={`icon-button-file-${id}`}
        type="file"
        onChange={handleUploadClick}
        ref={fileInputRef}
      />
    </div>
  );
};

export default PhotoUploader;
