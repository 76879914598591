import { useTranslation } from 'react-i18next';
import ArticleCard from 'components/cards/article-card/ArticleCard';
import { type ButtonColors, LinkButton, LinkButtonType } from 'components/button/Button';
import { ROUTES } from 'routes/routes';
import { useGetAllArticlesQuery } from 'store/api/blogApi';
import './HomeBlog.scss';
import { useMemo } from 'react';

interface IHomeBlogProps {
  buttonColor?: ButtonColors
}

const HomeBlog = ({ buttonColor }: IHomeBlogProps) => {
  const { t } = useTranslation();
  const { data } = useGetAllArticlesQuery({ pageSize: 3, isPublic: true });

  const className = useMemo(
    () => {
      const { models } = data || { models: [] };
      const isSingleItem = models.length === 1;

      return isSingleItem
        ? 'home-blog-content-articles-single'
        : 'home-blog-content-articles-grid';
    },
    [data]
  );

  if (data?.models.length === 0) {
    return null;
  }

  return (
    <section className="flex flex-column justify-center align-center home-blog">
      <article className="flex flex-column justify-center align-center home-blog-content">
        <h2 className="text-center home-blog-content-title">
          {t('home.blogTitle')}
        </h2>
        <p className="text-center home-blog-content-description">
          {t('home.blogText')}
        </p>
        <div className={className}>
          {data?.models?.map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
            />
          ))}
        </div>
        <LinkButton
          type={LinkButtonType.secondary}
          color={buttonColor}
          className="home-blog-content-button"
          text={t('home.blogReadMore')}
          to={ROUTES.Blog.path}
        />
      </article>
    </section>
  );
};

export default HomeBlog;
