import { useTranslation } from 'react-i18next';
import { Button, ButtonState } from 'components/button/Button';
import { ReactComponent as AddToCartIcon } from 'assets/icons/add-to-cart.svg';
import { ReactComponent as FavouriteIcon } from 'assets/icons/favourite-icon-with-border.svg';
import concatClassNames from 'utils/classNames';
import { useEffect, useState } from 'react';
import { useAddItemToShoppingCartMutation } from 'store/api/cartApi';
import { useNotification } from 'components/notification/Notification';
import useFavouriteProducts, { FAVOURITE_PRODUCTS, FAVOURITE_PRODUCTS_CHANGED_EVENT } from 'hooks/useFavouriteProducts';
import { isUserLoggedIn } from 'utils/auth';
import CartSellerConflictPopup from 'components/product/CartSellerConflictPopup';
import useLocalStorageListener from 'hooks/useLocalStorageListener';
import Tooltip from 'components/tooltip/Tooltip';
import { dispatchStorageEvent, getStorageItem, setStorageItem } from 'utils/storage';

interface IProductActionsProps {
  productId: number
  sizeId: number
  quantity: number
}

export const CART_ITEMS = 'cartItems';

export const CART_ITEMS_CHANGED_EVENT = 'cartItemsChanged';

const ProductActions = ({ productId, sizeId, quantity }: IProductActionsProps) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const isLoggedIn = isUserLoggedIn();
  const [addItemToShoppingCart] = useAddItemToShoppingCartMutation();
  const favouritesLocalStorage = useLocalStorageListener(FAVOURITE_PRODUCTS, FAVOURITE_PRODUCTS_CHANGED_EVENT);
  const [isFavourite, updateFavouriteProducts] = useFavouriteProducts(productId) as [boolean, (isAdding: boolean) => Promise<void>];

  const [showCartSellerConflictPopup, setShowCartSellerConflictPopup] = useState(false);
  const [otherCustomerAddedProductToCart, setOtherCustomerAddedProductToCart] = useState(false);
  const [iconClassName, setIconClassName] = useState('');

  useEffect(() => {
    const includesFavourite = favouritesLocalStorage?.includes(productId);

    setIconClassName(includesFavourite ? 'favourite' : '');
  }, [favouritesLocalStorage, productId]);

  const addItemToCart = async () => {
    if (!isLoggedIn) {
      showNotification(t('productActions.loginToAddToFavourites'));
      return;
    }

    try {
      const response: any = await addItemToShoppingCart({
        productId,
        sizeId,
        quantity
      });

      if (response.error) {
        throw new Error(response.error.data);
      } else {
        const cartItems = Number(getStorageItem(CART_ITEMS) || 0);
        const cartItemsUpdated = cartItems + 1;

        setStorageItem(CART_ITEMS, cartItemsUpdated);
        dispatchStorageEvent(CART_ITEMS_CHANGED_EVENT, cartItemsUpdated);

        showNotification(t('productActions.addToCartSuccess'));
      }
    } catch (error: any) {
      const err = error.toString();
      const differentSellerErrMessage = 'Error: Cannot add items from different sellers.';
      const otherCustomerAddedProductToCartErrMessage = 'Error: Another customer already added this product.';

      if (err === differentSellerErrMessage) {
        setShowCartSellerConflictPopup(true);
        return;
      }

      if (err === otherCustomerAddedProductToCartErrMessage) {
        setOtherCustomerAddedProductToCart(true);
        return;
      }

      showNotification(err ?? t('productActions.addToCartError'));
    }
  };

  const onIconClick = async () => {
    await updateFavouriteProducts(!isFavourite);
    setIconClassName(!isFavourite ? 'favourite' : '');
  };

  return (
    <div className="flex align-center space-between product-actions">
      <Tooltip title={otherCustomerAddedProductToCart ? t('productActions.clientCartConflict') : ''}
               arrow>
        <div className="full-width">
          <Button
            onClick={otherCustomerAddedProductToCart
              ? () => {
                }
              : addItemToCart}
            text={t('productActions.addToCart')}
            icon={<AddToCartIcon/>}
            state={otherCustomerAddedProductToCart ? ButtonState.disabled : ButtonState.enabled}
            className="add-to-cart-button"
          />
        </div>
      </Tooltip>
      <FavouriteIcon
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onClick={onIconClick}
        className={concatClassNames('favourite-icon', iconClassName)}
      />
      {showCartSellerConflictPopup && (
        <CartSellerConflictPopup
          productId={productId}
          open={showCartSellerConflictPopup}
          onClose={() => {
            setShowCartSellerConflictPopup(false);
          }}
        />)
      }
    </div>
  );
};

export default ProductActions;
