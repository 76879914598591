import { getToken } from 'utils/token';

const createHeaders = () => {
  const headers = new Headers();
  const token = getToken();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }
  return headers;
};

export default createHeaders;
