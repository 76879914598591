import React from 'react';
import Filter from 'components/filter-menu/Filter';

interface IGenderFilterProps {
  genders: number[]
  selectedGenders: number[]
  setSelectedGenders: any
}

const GenderFilter = ({ genders, selectedGenders, setSelectedGenders }: IGenderFilterProps) => (
  <Filter
    items={genders}
    selectedItems={selectedGenders}
    setSelectedItems={setSelectedGenders}
    className="generic-filters genders"
  />
);

export default GenderFilter;
