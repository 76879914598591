import { Helmet, HelmetProvider } from 'react-helmet-async';
import { type IOptionalClassName } from 'types/common/Props';
import { type IMetaTag, type IPage } from '../../types/IPage';
import concatClassNames from '../../utils/classNames';
import './PageSeoSection.scss';
import { useLocalization } from 'hooks/useLocalization';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { getLocalizedValue } from 'utils/localization';
import { usePageViews } from 'hooks/usePageViews';
import { useI18n } from 'hooks/usei18n';

export interface IPopup extends IOptionalClassName {
  pageSeo?: IPage
}

const PageSeoSection = ({ pageSeo, className }: IPopup) => {
  const {
    language,
    getLanguageUrl
  } = useI18n();

  const {
    heading1BulgarianContent,
    heading1EnglishContent
  } = pageSeo || {};

  const heading1Content = useLocalization(heading1BulgarianContent, heading1EnglishContent);

  const hasHeading1 = useMemo(
    () => !isNil(heading1Content) && !isEmpty(heading1Content),
    [heading1Content]
  );

  const renderHeading1 = () => {
    if (!hasHeading1) {
      return null;
    }

    return (
      <h1 className="text-center heading1">
        {heading1Content}
      </h1>
    );
  };

  const renderSection = () => {
    if (!hasHeading1) {
      return null;
    }

    return (
      <section className={concatClassNames(className, 'flex flex-column justify-center align-center page-seo-section')}>
        <div className="flex flex-column justify-center align-center container">
          {renderHeading1()}
        </div>
      </section>
    );
  };

  const getContent = ({ content, contentEn }: IMetaTag) =>
    getLocalizedValue(language, content, contentEn);

  const renderMetaTags = useCallback(
    () => {
      const { metaTags } = pageSeo || { metaTags: [] };

      if (isNil(metaTags) || isEmpty(metaTags)) {
        return null;
      }
      return metaTags
        .map((tag, index) => (
          <meta key={index} name={tag.name} content={getContent(tag)} />
        ));
    },
    [pageSeo]
  );

  usePageViews();

  const canonicalUrl = useMemo(
    () => {
      const { canonicalUrl } = pageSeo ?? { canonicalUrl: '' };
      return getLanguageUrl(canonicalUrl ?? '');
    },
    [pageSeo]
  );

  const title = useMemo(
    () => getLocalizedValue(language, pageSeo?.titleBulgarianContent, pageSeo?.titleEnglishContent),
    [language, pageSeo]
  );

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {title}
          </title>
          <link rel="canonical" href={canonicalUrl} />
          <meta name="robots" content={pageSeo?.isIndexed ? 'index' : 'noindex'} />
          {renderMetaTags()}
        </Helmet>
      </HelmetProvider>
      {renderSection()}
    </>
  );
};

export default PageSeoSection;
