import { useNavigate } from 'react-router-dom';
import PageNotFoundImg from 'assets/images/page-not-found/page-not-found.png';
import Button from 'components/button/Button';
import { ROUTES } from 'routes/routes';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import './PageNotFound.scss';

interface IPageNotFound extends IOptionalClassName {
}

const PageNotFound = ({ className }: IPageNotFound) => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate(ROUTES.Home.path);
  };

  return (
    <section className={concatClassNames(className, 'page-not-found')}>
      <div className="flex flex-column page-not-found-content">
        <img src={PageNotFoundImg} alt="Page not found" className="page-not-found-content-img"/>
        <p className="text-center page-not-found-content-text">
          Упс... Страницата, която търсите не съществува.
        </p>
        <Button
          onClick={navigateToHome}
          className="page-not-found-content-button"
          text="Обратно към началната страница"
        />
      </div>
    </section>
  );
};

export default PageNotFound;
