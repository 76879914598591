import { useEffect, useState } from 'react';
import { getStorageItem } from 'utils/storage';

const useLocalStorageListener = (key: string, event: string) => {
  const [value, setValue] = useState(getStorageItem(key));

  useEffect(() => {
    const handleStorageChange = (e: any) => {
      if (e.type === event) {
        setValue(e.detail);
      }
    };

    window.addEventListener(event, handleStorageChange);

    return () => {
      window.removeEventListener(event, handleStorageChange);
    };
  }, [key, event]);

  return value;
};

export default useLocalStorageListener;
