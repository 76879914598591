import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import type { ICityApiResponse, IComplexApiResponse, IOfficeApiResponse, IStreetApiResponse } from 'types/IDelivery';

const DELIVERY_URL = 'delivery';

export const deliveryApi = createApi({
  reducerPath: 'delivery',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getCities: builder.query<ICityApiResponse, { name?: string, language: string }>({
      query: ({ name, language }) => {
        let queryString = `${DELIVERY_URL}/cities?language=${language}`;

        if (name) {
          queryString += `&name=${name}`;
        }

        return queryString;
      }
    }),
    getOffices: builder.query<IOfficeApiResponse, {
      siteId?: number
      name?: string
      language: string
    }>({
      query: ({ siteId, name, language }) => {
        let queryString = `${DELIVERY_URL}/offices?language=${language}`;

        if (siteId) {
          queryString += `&siteId=${siteId}`;
        }

        if (name) {
          queryString += `&name=${name}`;
        }

        return queryString;
      }
    }),
    getComplexes: builder.query<IComplexApiResponse, {
      siteId: number
      name?: string
      language: string
    }>({
      query: ({ siteId, name, language }) => {
        let queryString = `${DELIVERY_URL}/complexes?siteId=${siteId}&language=${language} `;

        if (name) {
          queryString += `&name=${name}`;
        }

        return queryString;
      }
    }),
    getStreets: builder.query<IStreetApiResponse, {
      siteId: number
      name?: string
      language: string
    }>({
      query: ({ siteId, name, language }) => {
        let queryString = `${DELIVERY_URL}/streets?siteId=${siteId}&language=${language}`;

        if (name) {
          queryString += `&name=${name}`;
        }

        return queryString;
      }
    }),
    calculateDelivery: builder.query<number, {
      sellerId?: number
      totalWeight: number
      deliveryTypeValue: number
    }>({
      query: ({ sellerId, totalWeight, deliveryTypeValue }) => ({
        url: `${DELIVERY_URL}/price?sellerId=${sellerId}&totalWeight=${totalWeight}&deliveryTypeValue=${deliveryTypeValue}`
      })
    })
  })
});

export const {
  useGetCitiesQuery,
  useGetOfficesQuery,
  useGetComplexesQuery,
  useGetStreetsQuery,
  useCalculateDeliveryQuery
} = deliveryApi;
