import { useTranslation } from 'react-i18next';
import FilterMenu from 'components/filter-menu/FilterMenu';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

interface IDesktopProductsFilterProps {
  categoryFilters: number[]
  sizeFilters: number[]
  setSizeFilters: any
  genderFilters: number[]
  setGenderFilters: any
  specialFilters: any
  selectedSpecialFilters: any
  setSelectedSpecialFilters: any
}

const DesktopProductsFilter = ({
  categoryFilters,
  sizeFilters,
  setSizeFilters,
  genderFilters,
  setGenderFilters,
  specialFilters,
  selectedSpecialFilters,
  setSelectedSpecialFilters
}: IDesktopProductsFilterProps) => {
  const { t } = useTranslation();

  return (
    <div className="desktop-filter-wrapper">
      <div className="filter-text flex align-center">
        <FilterIcon/>
        <span>{t('allProducts.filters')}</span>
      </div>
      <FilterMenu
        categoryFilters={categoryFilters}
        sizeFilters={sizeFilters}
        setSizeFilters={setSizeFilters}
        genderFilters={genderFilters}
        setGenderFilters={setGenderFilters}
        specialFilters={specialFilters}
        selectedSpecialFilters={selectedSpecialFilters}
        setSelectedSpecialFilters={setSelectedSpecialFilters}
      />
    </div>
  );
};

export default DesktopProductsFilter;
