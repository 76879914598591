const checkForMobileFilter = () => {
  const filterElement = document.querySelector('.mobile-products-filter');
  const headerElement = document.querySelector('header');
  const footerElement = document.querySelector('footer');

  if (filterElement && headerElement && footerElement) {
    headerElement.style.display = 'none';
    footerElement.style.display = 'none';
  } else {
    if (headerElement) {
      headerElement.style.display = '';
    }

    if (footerElement) {
      footerElement.style.display = '';
    }
  }
};

export {
  checkForMobileFilter
};
