import { useCallback, useEffect, useMemo, useState } from 'react';
import LinkCard from 'components/cards/LinkCard';
import { getCurrencySymbol } from 'utils/currency';
import { type IProductResponse } from 'types/IProduct';
import { ReactComponent as FavouriteIcon } from 'assets/icons/favourite-icon.svg';
import concatClassNames from 'utils/classNames';
import { Language } from 'i18n';
import useFavouriteProducts from 'hooks/useFavouriteProducts';
import { ReactComponent as BabyOnTheWay } from 'assets/icons/badges/baby-on-the-way.svg';
import { ReactComponent as BabyOnTheWayBg } from 'assets/icons/badges/baby-on-the-way-bg.svg';
import { ReactComponent as BadgeDiscount } from 'assets/icons/badges/badge-discount.svg';
import { ReactComponent as BadgeDiscountBg } from 'assets/icons/badges/badge-discount-bg.svg';
import { ReactComponent as BadgeGoodAsNew } from 'assets/icons/badges/badge-good-as-new.svg';
import { ReactComponent as BadgeGoodAsNewBg } from 'assets/icons/badges/badge-good-as-new-bg.svg';
import { ReactComponent as BadgeHotDeal } from 'assets/icons/badges/badge-hot-deal.svg';
import { ReactComponent as BadgeHotDealBg } from 'assets/icons/badges/badge-hot-deal-bg.svg';
import { useI18n } from 'hooks/usei18n';
import { getLocalizedValue } from 'utils/localization';
import { buildProductUrl } from 'utils/product-urls';

import './ProductCard.scss';

interface IProductCardProps {
  product: IProductResponse
}

type BadgeIconType = Record<number, JSX.Element | null>;

const ProductCard = ({ product }: IProductCardProps) => {
  const { language } = useI18n();
  const [iconClassName, setIconClassName] = useState('');
  const [isFavourite, updateFavouriteProducts] = useFavouriteProducts(product.id) as [boolean, (isAdding: boolean) => Promise<void>];

  const onIconClick = useCallback(async () => {
    try {
      await updateFavouriteProducts(!isFavourite);
      setIconClassName(!isFavourite ? 'favourite' : '');
    } catch (e) {
      console.error(e);
    }
  }, [isFavourite, updateFavouriteProducts]);

  useEffect(() => {
    setIconClassName(isFavourite ? 'favourite' : '');
  }, [isFavourite]);

  const badgeIcons: BadgeIconType = {
    1: null,
    2: language === Language.BG ? <BabyOnTheWayBg /> : <BabyOnTheWay />,
    3: language === Language.BG ? <BadgeHotDealBg /> : <BadgeHotDeal />,
    4: language === Language.BG ? <BadgeDiscountBg /> : <BadgeDiscount />,
    5: language === Language.BG ? <BadgeGoodAsNewBg /> : <BadgeGoodAsNew />
  };

  const badge = product.badge ? badgeIcons[product.badge.value] : null;

  const productLink = useMemo(
    () => buildProductUrl(product),
    [product]
  );

  const productName = useMemo(
    () => {
      const {
        name: {
          english,
          bulgarian
        }
      } = product;
      return getLocalizedValue(language, bulgarian, english);
    },
    [product]
  );

  return (
    <LinkCard
      imgSrc={product.mainImageSource}
      title={productName}
      icon={FavouriteIcon}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onIconClick={onIconClick}
      link={productLink}
      isCloudinaryImage
      className={concatClassNames('product-card', iconClassName)}
    >
      {badge && <div className="product-card-badge">{badge}</div>}
      <p
        className="product-card-price">{product.price.amount.toFixed(2)} {getCurrencySymbol(product.price.currency)}</p>
    </LinkCard>
  );
};

export default ProductCard;
