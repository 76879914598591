import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type SerializedError } from '@reduxjs/toolkit';
import {
  SortBy,
  useDeleteBannerMutation,
  useGetAllBannersQuery,
  useToggleBannerVisibilityMutation
} from 'store/api/bannerApi';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import { DataGrid, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl as MuiFormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';
import CloudinaryImage, { ThumbnailSize } from 'components/common/cloudinary-img/CloudinaryImage';
import './AllBannersTab.scss';

const AllBannersTab = () => {
  const { t } = useTranslation();

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.Name, name: t('common.sort.name') },
    { value: SortBy.IsVisible, name: t('common.sort.isVisible') }
  ];

  const [queryParams, setQueryParams] = useState<{
    page: number
    order: Order
    sortBy?: SortBy
  }>({
    page: DEFAULT_PAGE,
    order: orderByDropdownOptions[0].value
  });

  const [editRowArgs, setEditRowArgs] = useState<any>(null);
  const { data, error, isLoading, refetch } = useGetAllBannersQuery(queryParams);
  const [toggleBannerVisibility] = useToggleBannerVisibilityMutation();
  const [deleteBanner] = useDeleteBannerMutation();
  const noButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    refetch()
      .then(() => {
      })
      .catch(() => {
      });
  }, [editRowArgs]);

  const dataColumns: GridColDef[] = [
    {
      field: 'id',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Id',
      width: 150
    },
    {
      field: 'name',
      align: 'center',
      headerAlign: 'center',
      headerName: t('administration.banners.name'),
      width: 250
    },
    {
      field: 'imageSource',
      align: 'center',
      headerAlign: 'center',
      headerName: t('administration.banners.allBanners.imageSource'),
      width: 500,
      renderCell: (params: GridRenderCellParams) => {
        return <CloudinaryImage imagePublicId={params.row.imageSource} thumbnailSize={ThumbnailSize.Small}/>;
      }
    },
    {
      field: 'isVisible',
      align: 'center',
      headerAlign: 'center',
      headerName: t('administration.banners.isVisible'),
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          setEditRowArgs({ newVal: !params.value, oldVal: params.value, id: params.row.id, isDelete: false });
        };
        return <Checkbox checked={params.value} onClick={onClick}/>;
      }
    },
    {
      field: 'deleteRow',
      align: 'center',
      headerAlign: 'center',
      headerName: t('administration.banners.allBanners.deleteRow'),
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          setEditRowArgs({ id: params.row.id, isDelete: true });
        };

        return (
          <Button
            onClick={onClick}
            sx={{ backgroundColor: '#e18f91', color: 'black' }}
          >
            {t('administration.common.delete')}
          </Button>);
      }
    }
  ];

  const handleNo = () => {
    setEditRowArgs(null);
  };

  const handleYes = async (id: number, isDelete: boolean) => {
    if (isDelete) {
      await deleteBanner(id);
    } else {
      await toggleBannerVisibility(id);
    }
    setEditRowArgs(null);
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setQueryParams(paramsObj);
    if (sortByOption.value === SortBy.Id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setQueryParams(prevState => ({ ...prevState, sortBy: null }));
    } else {
      setQueryParams(prevState => ({ ...prevState, sortBy: sortByOption.value }));
    }
  };

  const renderConfirmDialog = () => {
    if (!editRowArgs) {
      return null;
    }

    const { id, newVal, oldVal, isDelete } = editRowArgs;
    const mutation = computeMutation(newVal, oldVal, id, isDelete);

    return (
      <Dialog
        maxWidth='xs'
        open={!!editRowArgs}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will ${mutation}.`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>No</Button>
          <Button onClick={() => {
            handleYes(id, isDelete)
              .then(() => {
              })
              .catch(() => {
              });
          }}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const computeMutation = (newVal: boolean, oldVal: boolean, id: number, isDelete: boolean) => {
    if (isDelete) {
      return `Delete record with id: ${id}`;
    }
    if (newVal !== oldVal) {
      return `Change Is Visible from '${oldVal || ''}' to '${newVal || 'false'}'`;
    }
    return null;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {error
        ? <div>{(error as SerializedError).message}</div>
        : <>
          {renderConfirmDialog()}
          <div className='banners-sort-fields'>
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.sortBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams?.sortBy || sortByDropdownOptions[0].value}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onSortByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {sortByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.orderBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams.order}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onOrderByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {orderByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
          </div>
          <DataGrid
            rows={data ?? []}
            rowHeight={120}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
              }
            }}
            pageSizeOptions={[DEFAULT_PAGE_SIZE]}
          />
        </>
      }
    </>
  );
};

export default AllBannersTab;
