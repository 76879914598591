import { useTranslation } from 'react-i18next';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import SectionCard from 'components/cards/section-card/SectionCard';
import DeliveryOptions from 'components/delivery-options/DeliveryOptions';
import { DeliveryFields, DeliveryOptionsFields } from 'pages/cart/Cart';
import { HandoverType } from 'types/ICustomer';

interface ICartDeliveryOptions {
  values: any
  errors: any
  handleChange: (name: string, value: any) => void
  handleCitySelectionChange: (selectedCity: any) => void
  handleComplexChange: (selectedComplex: any) => void
  handleStreetChange: (selectedStreet: any) => void
  handleOfficeChange: (selectedOffice: any) => void
  selectedCity: any
  selectedComplex: any
  selectedStreet: any
  selectedOffice: any
}

const CartDeliveryOptions = ({
  values,
  errors,
  handleChange,
  handleCitySelectionChange,
  handleComplexChange,
  handleStreetChange,
  handleOfficeChange,
  selectedCity,
  selectedComplex,
  selectedStreet,
  selectedOffice
}: ICartDeliveryOptions) => {
  const { t } = useTranslation();

  return (
    <SectionCard title={t('shoppingCart.deliveryOptions.title')}>
      <article
        className="flex flex-column cart-delivery-options-form"
      >
        <FormControl
          name={DeliveryOptionsFields.FirstName}
          value={values.firstName}
          labelText={t('common.inputs.firstName')}
          error={errors.firstName}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(DeliveryOptionsFields.FirstName, value);
          }}
        />
        <FormControl
          name={DeliveryOptionsFields.MiddleName}
          value={values.middleName}
          labelText={t('common.inputs.middleName')}
          error={errors.middleName}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(DeliveryOptionsFields.MiddleName, value);
          }}
        />
        <FormControl
          name={DeliveryOptionsFields.LastName}
          value={values.lastName}
          labelText={t('common.inputs.lastName')}
          error={errors.lastName}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(DeliveryOptionsFields.LastName, value);
          }}
        />
        <FormControl
          name={DeliveryOptionsFields.PhoneNumber}
          value={values.phoneNumber}
          labelText={t('common.inputs.phoneNumber')}
          error={errors.phoneNumber}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(DeliveryOptionsFields.PhoneNumber, value);
          }}
        />
        <DeliveryOptions
          value={values.deliveryAddressOrOffice}
          onChange={(selectedValue: any) => {
            handleChange(DeliveryFields.DeliveryAddressOrOffice, selectedValue);
          }}
          errors={errors}
          selectedCity={selectedCity}
          selectedOffice={selectedOffice}
          selectedComplex={selectedComplex}
          selectedStreet={selectedStreet}
          values={values}
          handleChange={handleChange}
          handleCitySelectionChange={handleCitySelectionChange}
          handleComplexChange={handleComplexChange}
          handleStreetChange={handleStreetChange}
          handleOfficeChange={handleOfficeChange}
          showDiscountText={true}
          deliveryAddressOrOfficeTypes={[
            { value: HandoverType.Address, label: t('shoppingCart.deliveryOptions.addressDelivery') },
            { value: HandoverType.Office, label: t('shoppingCart.deliveryOptions.deliveryToSpeedyOffice') }
          ]}
        />
      </article>
    </SectionCard>
  );
};

export default CartDeliveryOptions;
