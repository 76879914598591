import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllPagesInfoQuery, useEditPageInfoMutation } from '../../../store/api/pagesApi';
import { DataGrid, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { Button } from '../../button/Button';
import { type IPage } from '../../../types/IPage';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';
import FormControl, { FormControlType } from '../../forms/FormControl';
import Tooltip from '../../tooltip/Tooltip';
import TextEditor from '../../editor/TextEditor';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PlusIcon } from 'assets/icons/photo-plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { cloneDeep } from 'lodash';
import Form from '../../forms/Form';
import useForm from '../../../hooks/useForm';
import { validateEditPageFields } from '../../../utils/validators/admin/pageValidator';
import './AdminPageTab.scss';

interface IPageInfoModalProps {
  page: IPage
  isOpen: boolean
  closeModal: () => void
  refetch: () => Promise<any>
}

enum PageEditFields {
  CanonicalUrl = 'canonicalUrl',
  TitleEnglishContent = 'titleEnglishContent',
  TitleBulgarianContent = 'titleBulgarianContent',
  Heading1EnglishContent = 'heading1EnglishContent',
  Heading1BulgarianContent = 'heading1BulgarianContent',
  Heading2EnglishContent = 'heading2EnglishContent',
  Heading2BulgarianContent = 'heading2BulgarianContent',
  DescriptionEnglishContent = 'descriptionEnglishContent',
  DescriptionBulgarianContent = 'descriptionBulgarianContent',
  IsIndexed = 'isIndexed',
  MetaTags = 'metaTags',
}

const PageEditModal = (props: IPageInfoModalProps) => {
  const { t } = useTranslation();
  const { page, isOpen, closeModal, refetch } = props;

  const [editPageMutation, { isLoading, isError }] = useEditPageInfoMutation();

  const initialValues = {
    [PageEditFields.IsIndexed]: page?.isIndexed ?? false,
    [PageEditFields.CanonicalUrl]: page?.canonicalUrl ?? '',
    [PageEditFields.TitleEnglishContent]: page?.titleEnglishContent ?? '',
    [PageEditFields.TitleBulgarianContent]: page?.titleBulgarianContent ?? '',
    [PageEditFields.Heading1EnglishContent]: page?.heading1EnglishContent ?? '',
    [PageEditFields.Heading1BulgarianContent]: page?.heading1BulgarianContent ?? '',
    [PageEditFields.Heading2EnglishContent]: page?.heading2EnglishContent ?? '',
    [PageEditFields.Heading2BulgarianContent]: page?.heading2BulgarianContent ?? '',
    [PageEditFields.DescriptionEnglishContent]: page?.descriptionEnglishContent ?? '',
    [PageEditFields.DescriptionBulgarianContent]: page?.descriptionBulgarianContent ?? '',
    [PageEditFields.MetaTags]: page?.metaTags ?? [{ name: '', content: '' }]
  };

  const onSubmit = async () => {
    const editedPage: IPage = {
      id: page.id,
      url: page.url,
      canonicalUrl: values[PageEditFields.CanonicalUrl] ? values[PageEditFields.CanonicalUrl] : undefined,
      isIndexed: values[PageEditFields.IsIndexed],
      titleEnglishContent: values[PageEditFields.TitleEnglishContent] ? values[PageEditFields.TitleEnglishContent] : undefined,
      titleBulgarianContent: values[PageEditFields.TitleBulgarianContent] ? values[PageEditFields.TitleBulgarianContent] : undefined,
      heading1EnglishContent: values[PageEditFields.Heading1EnglishContent] ? values[PageEditFields.Heading1EnglishContent] : undefined,
      heading1BulgarianContent: values[PageEditFields.Heading1BulgarianContent] ? values[PageEditFields.Heading1BulgarianContent] : undefined,
      heading2EnglishContent: values[PageEditFields.Heading2EnglishContent] ? values[PageEditFields.Heading2EnglishContent] : undefined,
      heading2BulgarianContent: values[PageEditFields.Heading2BulgarianContent] ? values[PageEditFields.Heading2BulgarianContent] : undefined,
      descriptionEnglishContent: values[PageEditFields.DescriptionEnglishContent] ? values[PageEditFields.DescriptionEnglishContent] : undefined,
      descriptionBulgarianContent: values[PageEditFields.DescriptionBulgarianContent] ? values[PageEditFields.DescriptionBulgarianContent] : undefined,
      metaTags: values[PageEditFields.MetaTags]
    };

    await editPageMutation({ pageId: page.id, page: editedPage });
    await refetch();
    closeModal();
  };

  const onClose = () => {
    closeModal();
  };

  const { values, setValues, handleChange, handleSubmit, errors } = useForm(
    initialValues,
    onSubmit,
    validateEditPageFields
  );

  useEffect(() => {
    if (page) {
      setValues({
        [PageEditFields.IsIndexed]: page?.isIndexed ?? false,
        [PageEditFields.CanonicalUrl]: page?.canonicalUrl ?? '',
        [PageEditFields.TitleEnglishContent]: page?.titleEnglishContent ?? '',
        [PageEditFields.TitleBulgarianContent]: page?.titleBulgarianContent ?? '',
        [PageEditFields.Heading1EnglishContent]: page?.heading1EnglishContent ?? '',
        [PageEditFields.Heading1BulgarianContent]: page?.heading1BulgarianContent ?? '',
        [PageEditFields.Heading2EnglishContent]: page?.heading2EnglishContent ?? '',
        [PageEditFields.Heading2BulgarianContent]: page?.heading2BulgarianContent ?? '',
        [PageEditFields.DescriptionEnglishContent]: page?.descriptionEnglishContent ?? '',
        [PageEditFields.DescriptionBulgarianContent]: page?.descriptionBulgarianContent ?? '',
        [PageEditFields.MetaTags]: page?.metaTags ?? [{ name: '', content: '' }]
      });
    }
  }, [page, setValues]);

  const handleAddMetaTag = () => {
    const newMetaTags = [...values.metaTags, { name: '', content: '', contentEn: '' }];
    handleChange(PageEditFields.MetaTags, newMetaTags);
  };

  const handleRemoveMetaTag = (index: number) => {
    const newMetaTags = values.metaTags.filter((_, i) => i !== index);
    handleChange(PageEditFields.MetaTags, newMetaTags);
  };

  const handleMetaTagChange = (index: number, field: string, value: string) => {
    const newMetaTags = cloneDeep(values.metaTags);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    newMetaTags[index][field] = value;
    handleChange(PageEditFields.MetaTags, newMetaTags);
  };

  const renderEditFields = () => {
    return (
      <div className="fields-container">
        <FormControl
          labelText="Canonical Url"
          name={PageEditFields.CanonicalUrl}
          value={values.canonicalUrl}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.canonicalUrl}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.CanonicalUrl, value);
          }}
        />
        <FormControl
          labelText="Is Indexed"
          name={PageEditFields.IsIndexed}
          checked={values.isIndexed}
          shouldUpdateValue
          type={FormControlType.checkbox}
          containerClassName="indexed-checkbox"
          onChange={(value) => {
            handleChange(PageEditFields.IsIndexed, value);
          }}
        />
        <FormControl
          labelText="Title EN"
          name={PageEditFields.TitleEnglishContent}
          value={values.titleEnglishContent}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.titleEnglishContent}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.TitleEnglishContent, value);
          }}
        />
        <FormControl
          labelText="Title BG"
          name={PageEditFields.TitleBulgarianContent}
          value={values.titleBulgarianContent}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.titleBulgarianContent}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.TitleBulgarianContent, value);
          }}
        />
        <FormControl
          labelText="Heading1 EN"
          name={PageEditFields.Heading1EnglishContent}
          value={values.heading1EnglishContent}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.heading1EnglishContent}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.Heading1EnglishContent, value);
          }}
        />
        <FormControl
          labelText="Heading1 BG"
          name={PageEditFields.Heading1BulgarianContent}
          value={values.heading1BulgarianContent}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.heading1BulgarianContent}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.Heading1BulgarianContent, value);
          }}
        />
        <FormControl
          labelText="Heading2 EN"
          name={PageEditFields.Heading2EnglishContent}
          value={values.heading2EnglishContent}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.heading2EnglishContent}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.Heading2EnglishContent, value);
          }}
        />
        <FormControl
          labelText="Heading2 BG"
          name={PageEditFields.Heading2BulgarianContent}
          value={values.heading2BulgarianContent}
          shouldUpdateValue
          type={FormControlType.text}
          error={errors.heading2BulgarianContent}
          className="edit-page-field"
          onChange={(value) => {
            handleChange(PageEditFields.Heading2BulgarianContent, value);
          }}
        />
        <TextEditor
          label="Description EN"
          name={PageEditFields.DescriptionEnglishContent}
          value={values.descriptionEnglishContent}
          error={errors.descriptionEnglishContent}
          onChange={(value) => {
            handleChange(PageEditFields.DescriptionEnglishContent, value);
          }}
        />
        <TextEditor
          label="Description BG"
          name={PageEditFields.DescriptionBulgarianContent}
          value={values.descriptionBulgarianContent}
          error={errors.descriptionBulgarianContent}
          onChange={(value) => {
            handleChange(PageEditFields.DescriptionBulgarianContent, value);
          }}
        />
        {values.metaTags.map((metaTag, index) => (
          <div key={index} className="meta-tag-group">
            <FormControl
              name={`metaTags[${index}].name`}
              value={metaTag.name}
              labelText={`${t('administration.blog.create.metaTagName')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaName}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'name', value);
              }}
            />
            <FormControl
              name={`metaTags[${index}].content`}
              value={metaTag.content}
              labelText={`${t('administration.blog.create.metaTagContent')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaContent}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'content', value);
              }}
            />
            <FormControl
              name={`metaTags[${index}].contentEn`}
              value={metaTag.contentEn}
              labelText={`${t('administration.blog.create.metaTagContentEn')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaContentEn}
              type={FormControlType.text}
              onChange={(value) => {
                handleMetaTagChange(index, 'contentEn', value);
              }}
            />
            {index !== 0 && (
              <IconButton className="add-btn" onClick={() => {
                handleRemoveMetaTag(index);
              }}>
                <MinusIcon/>
              </IconButton>
            )}
          </div>
        ))}
        <Tooltip title="Add Meta Tag">
          <IconButton onClick={handleAddMetaTag} className="remove-btn">
            <PlusIcon/>
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <Form
        onSubmit={handleSubmit}
        className="flex flex-column admin-page-edit-form"
        submitText={t('editArticle.edit')}
      >
        <DialogTitle>Editing</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
          {isError && <div className="error-wrapper">Error editing page</div>}
        <DialogContent>
          {isLoading ? <div>Loading...</div> : renderEditFields()}
        </DialogContent>
      </Form>
    </Dialog>
  );
};

const AdminPagesTab = () => {
  const { data, isLoading, error, refetch } = useGetAllPagesInfoQuery();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<IPage | null>(null);

  const dataColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 110,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'canonicalUrl',
      headerName: 'Canonical Url',
      width: 240,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'url',
      headerName: 'Url',
      width: 240,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'isIndexed',
      headerName: 'Is Indexed',
      width: 110,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'editRow',
      headerName: 'Edit',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button className="adminButton" onClick={() => {
            setIsEditModalOpen(true);
            setSelectedPage(params.row);
          }}>
            Edit
          </Button>
        );
      }
    }
  ];

  if (error) {
    return (<div>Error loading pages information</div>);
  }
  if (isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <div>
      <DataGrid
        rows={data?.models ?? []}
        columns={dataColumns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
          }
        }}
        pageSizeOptions={[DEFAULT_PAGE_SIZE]}
        rowCount={data?.totalCount ?? 0}
        disableColumnFilter
        disableRowSelectionOnClick
        paginationMode='server'
        onPaginationModelChange={(e) => {
        }}
      />
      <PageEditModal
        isOpen={isEditModalOpen}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        page={selectedPage!}
        refetch={refetch}
        closeModal={() => {
          setIsEditModalOpen(false);
        }}
      />
    </div>
  );
};

export default AdminPagesTab;
