import { decodeToken, getToken, removeToken } from 'utils/token';
import { setStorageItem } from './storage';
import { FAVOURITE_PRODUCTS } from 'hooks/useFavouriteProducts';
import { CART_ITEMS } from 'components/product/ProductActions';
import { COOKIE_CONSENT_KEY } from '../components/cookie-policy/CookiePolicy';

export enum ADMIN_USER_ROLES {
  GENERAL_ADMINISTRATOR = 'General Administrator',
  REGULAR_ADMINISTRATOR = 'Regular Administrator',
  MARKETING_ADMINISTRATOR = 'Marketing Administrator',
}

export const isUserLoggedIn = () => {
  const token = getToken();
  return !!token;
};

export const logout = () => {
  removeToken();

  setStorageItem(FAVOURITE_PRODUCTS, []);
  setStorageItem(CART_ITEMS, 0);
  setStorageItem(COOKIE_CONSENT_KEY, null);
};

export const isUserAdmin = (adminRoles: ADMIN_USER_ROLES | ADMIN_USER_ROLES[]) => {
  const token = getToken();

  if (!token) {
    return false;
  }

  let userRole: string[];
  try {
    const decoded = decodeToken(token);
    userRole = decoded.role;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }

  const rolesArray = Array.isArray(adminRoles) ? adminRoles : [adminRoles];

  return rolesArray.some(r => userRole?.includes(r));
};

export const isUserGeneralAdministrator = () => isUserAdmin(ADMIN_USER_ROLES.GENERAL_ADMINISTRATOR);
export const isUserRegularAdministrator = () => isUserAdmin(ADMIN_USER_ROLES.REGULAR_ADMINISTRATOR);
export const isUserMarketingAdministrator = () => isUserAdmin(ADMIN_USER_ROLES.MARKETING_ADMINISTRATOR);

export const hasAdminAccess = () => isUserAdmin([
  ADMIN_USER_ROLES.REGULAR_ADMINISTRATOR,
  ADMIN_USER_ROLES.MARKETING_ADMINISTRATOR,
  ADMIN_USER_ROLES.GENERAL_ADMINISTRATOR
]);
