import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Rating } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import { useGetProductQuery } from 'store/api/productApi';
import Label from 'components/label/Label';
import PageNotFound from 'pages/page-not-found/PageNotFound';
import SizeDropdown from 'components/common/dropdowns/SizeDropdown';
import NumberControl from 'components/number-control/NumberControl';
import { getCurrencySymbol } from 'utils/currency';
import ProductActions from 'components/product/ProductActions';
import useWindowWidth from 'hooks/useWindowWidth';
import { useGetReviewsQuery } from 'store/api/reviewApi';
import { type IReview } from 'types/IReview';
import ReviewCard from 'components/cards/review-card/ReviewCard';
import { Button, ButtonType } from 'components/button/Button';
import ReviewPopup from 'components/review/review-popup/ReviewPopup';
import { ReactComponent as RankingIcon } from 'assets/icons/rank.svg';
import { ReactComponent as RankingFilledIcon } from 'assets/icons/rank-filled.svg';
import { type IProductCategory, ProductLabel } from 'types/IProduct';
import Breadcrumb from 'components/breadcrumb/Breadcrumb';
import { ROUTES } from 'routes/routes';
import { localizeSize } from 'pages/all-products/filter-utils';
import { dispatchStorageEvent, getStorageItem, setStorageItem } from 'utils/storage';
import { UPDATED_PRODUCTS, UPDATED_PRODUCTS_CHANGED_EVENT } from 'hooks/useSignalR';
import CloudinaryImage from 'components/common/cloudinary-img/CloudinaryImage';
import './Product.scss';
import { Language } from '../../i18n';
import { useLocalization } from 'hooks/useLocalization';
import PageSeoSection from 'components/page-seo-section/PageSeoSection';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import { isNil, last } from 'lodash';
import { buildProductUrl } from 'utils/product-urls';
import { type IMetaTag } from 'types/IPage';
import { useI18n } from 'hooks/usei18n';

interface IProduct extends IClassName {
}

const Product = ({ className }: IProduct) => {
  const { t, i18n } = useTranslation();
  const { getLanguageUrl } = useI18n();
  const { idAndName } = useParams();
  const { isMobile } = useWindowWidth();

  const id = useMemo(
    () => last(idAndName?.split('-')),
    [idAndName]
  );

  const { data: product, isLoading } = useGetProductQuery(Number(id));
  const currentLanguage = i18n.language;

  const { data: reviews } = useGetReviewsQuery(
    product?.seller.id ? { sellerId: product.seller.id, pageSize: 1 } : skipToken
  );

  const [mainImage, setMainImage] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [initialAvailableQuantity, setInitialAvailableQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState('');
  const [selectedSize, setSelectedSize] = useState({
    sizeId: 0,
    sizeName: ''
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [showMoreReviewsPopup, setShowMoreReviewsPopup] = useState(false);
  const updatedProducts: number[] = getStorageItem(UPDATED_PRODUCTS) || [];

  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Product.path });

  useEffect(() => {
    if (updatedProducts.includes(product?.id)) {
      const filteredProducts = updatedProducts.filter(id => id !== product?.id);
      setStorageItem(UPDATED_PRODUCTS, filteredProducts);
      dispatchStorageEvent(UPDATED_PRODUCTS_CHANGED_EVENT, filteredProducts);
    }
  }, [product?.id, updatedProducts]);

  useEffect(() => {
    if (product) {
      setMainImage(product.images[0]);
    }
  }, [product]);

  useEffect(() => {
    if (product) {
      setTotalPrice(product.price.amount * quantity);
    }
  }, [product, quantity]);

  useEffect(() => {
    if (product) {
      const firstSize = product.inventoryItems?.[0];

      if (firstSize) {
        setSelectedSize({
          sizeId: firstSize.sizeId,
          sizeName: Number(firstSize.sizeName) ? firstSize.sizeName : t(localizeSize(firstSize.sizeName))
        });

        setInitialAvailableQuantity(firstSize.quantity);
      }
    }
  }, [product]);

  const onQuantityIncrement = () => {
    const availableQuantity = product.inventoryItems.find((item: any) => item.sizeId === +selectedSize.sizeId)?.quantity || 0;

    if (quantity < availableQuantity) {
      setQuantity(quantity + 1);
      setQuantityError('');
    } else {
      setQuantityError(t('product.quantityError'));
    }
  };

  const onQuantityDecrement = () => {
    if (quantity === 1) return;

    setQuantity(quantity - 1);
    setQuantityError('');
  };

  const handleSizeChange = (sizeId: number, sizeName: string) => {
    const availableQuantity = product.inventoryItems.find((item: any) => item.sizeId === +sizeId)?.quantity || 0;

    setSelectedSize({ sizeId, sizeName });
    setQuantity(1);
    setQuantityError('');
    setInitialAvailableQuantity(availableQuantity);
  };

  const productLink = useMemo(
    () => isNil(product)
      ? null
      : buildProductUrl(product),
    [product]
  );

  const productPageSeoDescriptionMetaTag = useMemo(
    () => {
      if (isNil(product?.description)) {
        return null;
      }

      const {
        description: {
          bulgarian: bulgarianDescription,
          english: englishDescription
        }
      } = product;

      return {
        name: 'description',
        content: bulgarianDescription.substring(0, 140),
        contentEn: englishDescription.substring(0, 140)
      } satisfies IMetaTag;
    },
    [product]
  );

  const metaTags = useMemo(
    () => {
      const { metaTags } = pageSeoData || { metaTags: [] };
      return [
        ...metaTags,
        productPageSeoDescriptionMetaTag
      ];
    },
    [pageSeoData, productPageSeoDescriptionMetaTag]
  );

  const productPageSeoTitles = useMemo(
    () => {
      const {
        titleBulgarianContent: originalTitleBulgarianContent,
        titleEnglishContent: originalTitleEnglishContent
      } = pageSeoData || {};

      if (isNil(product?.name)) {
        return {
          titleBulgarianContent: originalTitleBulgarianContent,
          titleEnglishContent: originalTitleEnglishContent
        };
      }

      const {
        name: {
          bulgarian: bulgarianProductName,
          english: englishProductName
        }
      } = product;

      const titleBulgarianContent = `${bulgarianProductName} - 2Kiddo`;
      const titleEnglishContent = `${englishProductName} - 2Kiddo`;

      return { titleBulgarianContent, titleEnglishContent };
    },
    [pageSeoData, product]
  );

  const productPageSeoData: any = useMemo(
    () => {
      return {
        ...pageSeoData,
        ...productPageSeoTitles,
        canonicalUrl: productLink,
        metaTags
      };
    },
    [pageSeoData, productLink, productPageSeoTitles]
  );

  const productsByUserUrl = useMemo(
    () => {
      const { seller } = product || { seller: {} };
      const { userId } = seller || { userId: '' };
      const url = ROUTES.AllProductsByUserId.path.replace(':userId', userId);
      return getLanguageUrl(url);
    },
    [product]
  );

  if (isLoading) {
    return null;
  }

  if (!product) {
    return <PageNotFound />;
  }

  const sizes = product.inventoryItems.map((item: any) => ({
    id: item.sizeId,
    name: Number(item.sizeName) ? item.sizeName : t(localizeSize(item.sizeName))
  }));

  const noSizes = sizes[0].id === null;

  const productLabel = product && (product.label.value === ProductLabel.New
    ? t('common.labels.new')
    : t('common.labels.used'));

  const renderProductActions = () => (
    <div className={isMobile ? 'product-actions-wrapper' : ''}>
      <ProductActions
        productId={product.id}
        sizeId={selectedSize.sizeId}
        quantity={quantity}
      />
    </div>
  );

  const renderCategories = ({ nameBg, nameEn, id, canonicalUrl }: IProductCategory) => {
    const categoryName = useLocalization(nameBg, nameEn);
    const link = ROUTES.AllProductsByCategory.path.replace(':category1', canonicalUrl || '');
    return (
      <Link key={id} to={link}>
        {categoryName}
      </Link>
    );
  };

  return (
    <>
      <PageSeoSection pageSeo={productPageSeoData} />
      <section className={concatClassNames(className, 'product')}>
        {isMobile && renderProductActions()}
        <div className="flex flex-column product-column">
          <Breadcrumb
            itemFunc={renderCategories}
            items={product.categories}
            className="product-breadcrumb"
          />
          <section className="flex flex-column justify-center align-center product-image">
            <CloudinaryImage
              imagePublicId={mainImage}
              className="product-image-main"
            />
            <article className="product-image-grid">
              {product.images.filter((i: string) => i !== mainImage).map((image: string, index: number) => (
                <div key={index} onClick={() => {
                  setMainImage(image);
                }}>
                  <CloudinaryImage
                    imagePublicId={image}
                    className="product-image-grid-image"
                  />
                </div>
              ))}
            </article>
          </section>
        </div>
        <section className="flex flex-column product-content">
          <h1 className="product-content-title">
            {currentLanguage === Language.BG ? product.name.bulgarian : product.name.english}
          </h1>
          <Label text={productLabel} className="product-content-label" />
          <article className="product-content-card details">
            <div className="description">
              {currentLanguage === Language.BG ? product.description.bulgarian : product.description.english}
            </div>
            <div className="flex align-center space-between size-and-quantity">
              {!noSizes && (
                <SizeDropdown
                  sizes={sizes}
                  selectedSize={selectedSize}
                  handleSizeChange={handleSizeChange}
                  className="size-dropdown"
                  error={quantityError}
                  disabled={sizes.length === 1}
                />
              )}
              {initialAvailableQuantity > 1 && (
                <NumberControl
                  text={t('product.quantity')}
                  number={quantity}
                  onIncrement={onQuantityIncrement}
                  onDecrement={onQuantityDecrement}
                  disabled={noSizes ? false : !selectedSize.sizeName}
                />
              )}
            </div>
            <span className="price">
              {totalPrice.toFixed(2)} {getCurrencySymbol(product.price.currency)}
            </span>
            {!isMobile && renderProductActions()}
          </article>
          <article className="product-content-card seller">
            <div className="flex space-between align-center">
              <div className="flex align-center" style={{ gap: 8 }}>
                <div className="flex">
                  <div className="flex flex-column">
                    <span className="seller-text">
                      {t('product.user')}
                    </span>
                    <span className="seller-name">
                      <Link to={productsByUserUrl}>
                        {product.seller.displayName}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex align-center" style={{ gap: 4 }}>
                {typeof reviews?.averageRating === 'number' && reviews?.averageRating > 0 && (
                  <>
                    <Rating
                      defaultValue={reviews.averageRating}
                      emptyIcon={<RankingIcon />}
                      icon={<RankingFilledIcon />}
                      precision={0.5}
                      className="seller-rating-stars"
                      readOnly
                    />
                    <span className="rating">
                      ({reviews.averageRating.toFixed(1)})
                    </span>
                  </>
                )}
              </div>
            </div>
          </article>
          {reviews?.totalCount > 0 && (
            <article className="product-content-card reviews">
              <h4 className="reviews-title">{t('product.reviews')} ({reviews.totalCount})</h4>
              {reviews.models.map((review: IReview) => (
                <ReviewCard
                  key={review.id}
                  review={review}
                />
              ))}
              <div className="reviews-footer">
                <Button
                  className="show-more-reviews-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowMoreReviewsPopup(true);
                  }}
                  type={ButtonType.plain}
                >
                  {t('product.showMoreReviews')}
                </Button>
              </div>
            </article>
          )}
        </section>
        {showMoreReviewsPopup && (
          <ReviewPopup
            seller={product?.seller}
            open={showMoreReviewsPopup}
            onClose={() => {
              setShowMoreReviewsPopup(false);
            }} />
        )}
      </section>
    </>
  );
};

export default Product;
