import './ErrorFormControl.scss';

interface IErrorFormControl {
  error: string
}

const ErrorFormControl = ({ error }: IErrorFormControl) => {
  if (!error) return null;

  return (
    <div className="error-form-control">{error}</div>
  );
};

export default ErrorFormControl;
