import React, { type ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import { type RouteType } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import { useI18n } from 'hooks/usei18n';

interface INavigationLinkProps extends IClassName {
  route: RouteType | any
  children?: ReactNode
  onMouseEnter?: any
  onMouseLeave?: any
  onClick?: any
  disableNavigation?: boolean
}

const NavigationLink = ({
  route,
  className,
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
  disableNavigation = false
}: INavigationLinkProps) => {
  const { t } = useTranslation();
  const { getLanguageUrl } = useI18n();
  const getClassName = (base: string, isActive: boolean) => concatClassNames(base, isActive ? 'active' : '');

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disableNavigation) {
      e.preventDefault();
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <NavLink
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      to={getLanguageUrl(route.path)}
      key={route.name}
      onClick={handleClick}
      className={({ isActive }) => (
        getClassName(className, isActive)
      )}
    >
      {children ?? t(`header.${route.name}`)}
    </NavLink>
  );
};

export default NavigationLink;
