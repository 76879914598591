import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserProductsQuery } from 'store/api/productApi';
import ProfileProductCard, { CardType } from 'components/cards/profile-product-card/ProfileProductCard';
import Pagination from 'components/pagination/Pagination';
import { DEFAULT_PAGE } from 'utils/constants';

const DEFAULT_PAGE_SIZE = 6;

const ProfileActiveProducts = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const { data: activeProducts } = useGetUserProductsQuery({
    page: currentPage,
    pageSize: DEFAULT_PAGE_SIZE,
    onlyInSale: true
  });

  if (activeProducts?.models.length === 0) {
    return <div>{t('profile.products.noActiveProducts')}</div>;
  }

  return (
    <section className="profile-active-products">
      <article className="products-wrapper">
        {activeProducts?.models.map(product => (
          <ProfileProductCard
            key={product.id}
            product={product}
            cardType={CardType.Active}
          />
        ))}
      </article>
      <Pagination
        count={activeProducts?.totalPages ?? 1}
        page={currentPage}
        onChange={setCurrentPage}
      />
    </section>
  );
};

export default ProfileActiveProducts;
