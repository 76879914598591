import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import Popup, { type IPopup } from 'components/popup/Popup';
import ReviewCard from 'components/cards/review-card/ReviewCard';
import { useGetReviewsQuery } from 'store/api/reviewApi';
import { type IReview } from 'types/IReview';
import { Button, ButtonState, ButtonType } from 'components/button/Button';
import { type ISeller } from 'types/IProduct';
import './ReviewPopup.scss';

interface IReviewPopup extends IPopup {
  seller: ISeller
}

const ReviewPopup = ({
  open,
  onClose,
  seller
}: IReviewPopup) => {
  const [page, setPage] = useState(1);
  const [allReviews, setAllReviews] = useState<IReview[]>([]);
  const [hasMoreReviews, setHasMoreReviews] = useState(true);
  const pageSize = 5;

  const { data: newReviews, isFetching } = useGetReviewsQuery({
    sellerId: seller?.id || skipToken,
    pageSize,
    page
  });

  useEffect(() => {
    if (newReviews?.models) {
      setAllReviews(prevReviews => [...prevReviews, ...newReviews.models]);
      setHasMoreReviews(newReviews.models.length === pageSize);
    }
  }, [newReviews]);

  if (!open) {
    return null;
  }

  const handleLoadMore = () => {
    if (!isFetching && hasMoreReviews) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={`Отзиви (${newReviews?.totalCount})`}
      className="review-popup"
    >
      {allReviews.map((review: any) => (
        <div key={review.id} className="review-card-wrapper">
          <ReviewCard
            review={review}
          />
          <span className="card-separator"/>
        </div>
      ))}
      {allReviews.length > pageSize && (
        <Button
          onClick={handleLoadMore}
          state={!hasMoreReviews || isFetching ? ButtonState.disabled : ButtonState.enabled}
          type={ButtonType.secondary}
          className="load-more-button"
          text="Зареди още"
        />
      )}
    </Popup>
  );
};

export default ReviewPopup;
