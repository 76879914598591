import React, { useEffect, useState } from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/tabs/TabPanel';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import './Tabs.scss';

interface TabData {
  label: string
  name?: string
  content: React.ReactNode
  disabled?: boolean
}

interface TabsComponentProps extends IOptionalClassName {
  tabsData: TabData[]
  ariaLabel: string
  activeTab?: string
}

const Tabs: React.FC<TabsComponentProps> = ({ tabsData, ariaLabel, className, activeTab }) => {
  const currentTab = activeTab ?? tabsData[0].label;
  const [value, setValue] = useState<string>(currentTab);

  useEffect(() => {
    if (activeTab) {
      setValue(currentTab);
    }
  }, [activeTab, currentTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <section className={concatClassNames('tabs', className)}>
      <article>
        <MuiTabs value={value} onChange={handleChange} aria-label={ariaLabel}>
          {tabsData.map(tab => (
            <Tab
              key={tab.label}
              label={tab.name ?? tab.label}
              value={tab.label}
              disabled={tab.disabled ?? false}
            />
          ))}
        </MuiTabs>
      </article>
      {tabsData.map(tab => (
        <TabPanel key={tab.label} value={value} index={tab.label}>
          {tab.content}
        </TabPanel>
      ))}
    </section>
  );
};

export default Tabs;
