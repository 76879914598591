import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import { SortBy, useGetAllCustomersQuery } from 'store/api/customerApi';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import type { SerializedError } from '@reduxjs/toolkit';
import { FormControl as MuiFormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import debounce from 'lodash/debounce';
import './AdminUsersTab.scss';

enum AdminUserFilterFields {
  Id = 'Id',
  Email = 'Email',
  Username = 'Username',
  BankAccountNumber = 'Bank Account Number',
  PhoneNumber = 'Phone Number'
}

interface IUserFilterField {
  id?: string
  email?: string
  username?: string
  bankAccountNumber?: string
  phoneNumber?: string

  [key: string]: string | undefined
}

const AllUsersTab = () => {
  const { t } = useTranslation();

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.FirstName, name: t('common.inputs.firstName') },
    { value: SortBy.MiddleName, name: t('common.inputs.middleName') },
    { value: SortBy.LastName, name: t('common.inputs.lastName') },
    { value: SortBy.Email, name: t('common.inputs.email') },
    { value: SortBy.UserName, name: t('common.inputs.username') }
  ];

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const [queryParams, setQueryParams] = useState({
    page: DEFAULT_PAGE,
    sortBy: sortByDropdownOptions[0].value,
    order: orderByDropdownOptions[0].value
  });
  const [filterFieldsValues, setFilterFieldsValues] = useState<IUserFilterField>({});
  const { data, error, isLoading } = useGetAllCustomersQuery({ ...queryParams });

  const handleFilterFieldChange = (field: string, value: string) => {
    const searchParams = { [field.toLowerCase().replace(' ', '')]: value };
    setFilterFieldsValues(searchParams);
    setQueryParams({ ...queryParams, ...searchParams });
  };

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setQueryParams(paramsObj);
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  const debounceSetQueryParams = debounce(handleFilterFieldChange, 1000);

  const dataColumns: GridColDef[] = [
    {
      field: 'names',
      headerName: t('administration.users.names'),
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.fullName.firstName || ''} ${params.row.fullName.middleName || ''} ${params.row.fullName.lastName || ''}`
    },
    { field: 'email', headerName: t('common.inputs.email'), width: 200, align: 'center', headerAlign: 'center' },
    { field: 'username', headerName: t('common.inputs.username'), width: 200, align: 'center', headerAlign: 'center' },
    {
      field: 'phoneNumber',
      headerName: t('common.inputs.phoneNumber'),
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => params.row.phoneNumber.number
    },
    // {
    //   field: 'bic',
    //   headerName: 'BIC',
    //   width: 130,
    //   align: 'center',
    //   headerAlign: 'center',
    //   valueGetter: (params: GridValueGetterParams) => params.row.bankDetails.swift
    // },
    {
      field: 'bankNumber',
      headerName: t('administration.users.bankAccountNumber'),
      width: 220,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => params.row.bankDetails.iban
    }
  ];

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (<div>
    {error
      ? <div>{(error as SerializedError).message}</div>
      : <>
        <div className='users-sort-fields'>
          <MuiFormControl variant="outlined" className="sort-container">
            <InputLabel id="sort-by-label">
              {t('common.sort.sortBy')}
            </InputLabel>
            <Select
              sx={{ width: '180px' }}
              value={queryParams.sortBy}
              label='Delivery Type'
              onChange={(e: any) => {
                onSortByDropdownChange(e);
              }}
              className='dropdown-select'
            >
              {sortByDropdownOptions.map((option, idx) => (
                <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                  <ListItemText primary={option.name}/>
                </MenuItem>
              ))}
            </Select>
          </MuiFormControl>
          <MuiFormControl variant="outlined" className="sort-container">
            <InputLabel id="sort-by-label">
              {t('common.sort.orderBy')}
            </InputLabel>
            <Select
              sx={{ width: '180px' }}
              value={queryParams.order}
              label='Delivery Type'
              onChange={(e: any) => {
                onOrderByDropdownChange(e);
              }}
              className='dropdown-select'
            >
              {orderByDropdownOptions.map((option, idx) => (
                <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                  <ListItemText primary={option.name}/>
                </MenuItem>
              ))}
            </Select>
          </MuiFormControl>
        </div>
        <div className='users-search-field'>
          <FormControl
            name={AdminUserFilterFields.Id}
            value={filterFieldsValues[AdminUserFilterFields.Id.toLowerCase()]}
            labelText={AdminUserFilterFields.Id}
            error={' '}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              debounceSetQueryParams(AdminUserFilterFields.Id, value);
            }}
          />
          <FormControl
            name={AdminUserFilterFields.Email}
            value={filterFieldsValues[AdminUserFilterFields.Email.toLowerCase()]}
            labelText={t('common.inputs.email')}
            error={' '}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              debounceSetQueryParams(AdminUserFilterFields.Email, value);
            }}
          />
          <FormControl
            name={AdminUserFilterFields.Username}
            value={filterFieldsValues[AdminUserFilterFields.Username.toLowerCase()]}
            labelText={t('common.inputs.username')}
            error={' '}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              debounceSetQueryParams(AdminUserFilterFields.Username, value);
            }}
          />
          <FormControl
            name={AdminUserFilterFields.BankAccountNumber}
            value={filterFieldsValues[AdminUserFilterFields.BankAccountNumber.toLowerCase()]}
            labelText={t('administration.users.bankAccountNumber')}
            error={' '}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              debounceSetQueryParams(AdminUserFilterFields.BankAccountNumber, value);
            }}
          />
          <FormControl
            name={AdminUserFilterFields.PhoneNumber}
            value={filterFieldsValues[AdminUserFilterFields.PhoneNumber.toLowerCase()]}
            labelText={t('common.inputs.phoneNumber')}
            error={' '}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              debounceSetQueryParams(AdminUserFilterFields.PhoneNumber, value);
            }}
          />
        </div>
        <DataGrid
          rows={data?.models ?? []}
          columns={dataColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
            }
          }}
          pageSizeOptions={[DEFAULT_PAGE_SIZE]}
          checkboxSelection
          rowCount={data?.totalCount ?? 0}
          paginationMode='server'
          onPaginationModelChange={(e) => {
            setQueryParams({ page: e.page + 1, sortBy: queryParams.sortBy, order: queryParams.order });
          }}
        />
      </>
    }
  </div>);
};

export default AllUsersTab;
