import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminBlog from 'components/administration/blog/AdminBlog';
import useArticleActions, { ArticleAction } from 'hooks/useArticleActions';
import Popup from 'components/popup/Popup';
import AdminBlogEdit from 'components/administration/blog/AdminBlogEdit';

const AdminBlogManager: React.FC = () => {
  const { t } = useTranslation();
  const { status, handleAction } = useArticleActions();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState<number>(-1);
  const [refetchArticles, setRefetchArticles] = useState(false);

  const onDelete = useCallback((id: number) => {
    handleAction(ArticleAction.Delete, id, 'Article successfully deleted!')
      .then(() => {
        setRefetchArticles(prev => !prev);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  }, [handleAction]);

  const onChangeVisibility = useCallback((id: number, isPublic: boolean) => {
    const action = isPublic ? ArticleAction.Hide : ArticleAction.Publish;
    const successMessage = `Article successfully ${action === ArticleAction.Hide ? 'hidden' : 'published'}!`;
    handleAction(action, id, successMessage)
      .then(() => {
        setRefetchArticles(prev => !prev);
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  }, [handleAction]);

  const onEdit = useCallback((articleId: number) => {
    setCurrentArticleId(articleId);
    setPopupVisible(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
    setRefetchArticles(prev => !prev);
  }, []);

  return (
    <div className="admin-blog-article-manager">
      {status.isLoading && <p>Loading...</p>}
      {status.isSuccess && <p>{status.message}</p>}
      {status.isError && <p>Error: {status.message}</p>}
      <AdminBlog
        className="admin-blog-list"
        onDelete={onDelete}
        onChangeVisibility={onChangeVisibility}
        onEdit={onEdit}
        refetchArticles={refetchArticles}
      />
      {isPopupVisible && currentArticleId && (
        <Popup
          open={isPopupVisible}
          onClose={handleClosePopup}
          title={t('editArticle.header')}
          className="admin-blog-edit-popup"
        >
          <AdminBlogEdit
            articleId={currentArticleId}
            onClosePopup={handleClosePopup}
          />
        </Popup>
      )}
    </div>
  );
};

export default React.memo(AdminBlogManager);
