import React, { type ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import concatClassNames from 'utils/classNames';
import { type IOptionalChildren, type IOptionalClassName } from 'types/common/Props';

import styles from './Button.module.scss';
import { Link } from 'react-router-dom';

enum ButtonState {
  enabled = 1,
  disabled = 2,
}

enum ButtonType {
  primary = 1,
  secondary = 2,
  plain = 3,
  image = 4,
  submit = 5,
}

enum ButtonColors {
  primary = 1,
  secondary = 2,
  tertiary = 3,
  custom = 4,
}

enum LinkButtonType {
  primary = 1,
  secondary = 2,
  plain = 3,
  image = 4,
}

enum ButtonSize {
  small = 1,
  medium = 2,
  large = 3,
  none = 4,
}

interface IButtonBaseProps<TButtonType> extends IOptionalClassName, IOptionalChildren {
  id?: string
  size?: ButtonSize
  text?: string | null
  type?: TButtonType
  state?: ButtonState
  imgSrc?: string
  altText?: string
  color?: ButtonColors
  icon?: ReactNode
}

interface IButtonProps extends IButtonBaseProps<ButtonType> {
  ref?: React.LegacyRef<HTMLButtonElement>
  onClick: React.MouseEventHandler<HTMLButtonElement>
  fullWidth?: boolean
  internalClassName?: string
}

interface ILinkButtonProps extends IButtonBaseProps<LinkButtonType> {
  to: string
  isExternal?: boolean
  disabled?: boolean
}

const classNameToButtonType = {
  [ButtonType.primary]: styles.primary,
  [ButtonType.submit]: styles.primary,
  [ButtonType.secondary]: styles.secondary,
  [ButtonType.plain]: styles.plain,
  [ButtonType.image]: styles.image
};

const classNameToLinkButtonType = {
  [LinkButtonType.primary]: styles.primary,
  [LinkButtonType.secondary]: styles.secondary,
  [LinkButtonType.plain]: styles.plain,
  [LinkButtonType.image]: styles.image
};

const sizeToClassName = {
  [ButtonSize.small]: styles.small,
  [ButtonSize.medium]: styles.medium,
  [ButtonSize.large]: styles.large,
  [ButtonSize.none]: styles.none
};

const colorToClassName = {
  [ButtonColors.primary]: styles.primaryColor,
  [ButtonColors.secondary]: styles.secondaryColor,
  [ButtonColors.tertiary]: styles.tertiaryColor,
  [ButtonColors.custom]: styles.customColor
};

const validateOnlyChildrenOrText = (text: string | null, children: ReactNode | null) => {
  if (!isNil(text) && !isNil(children)) {
    throw new Error('Buttons must have only `text` or `children`');
  }
};

const Button = ({
  onClick,
  text = null,
  children = null,
  className = '',
  type = ButtonType.primary,
  size = ButtonSize.medium,
  state = ButtonState.enabled,
  color = ButtonColors.primary,
  fullWidth = false,
  internalClassName = '',
  imgSrc = '',
  altText = '',
  icon = null,
  ref = null
}: IButtonProps) => {
  validateOnlyChildrenOrText(text, children);

  const { [type]: typeClassName } = classNameToButtonType;

  const { [size]: sizeClassName } = sizeToClassName;

  const { [color]: colorClassName } = colorToClassName;

  const stateClassName = state === ButtonState.disabled
    ? styles.disabled
    : '';

  const wideClassName = fullWidth
    ? styles.fullWidth
    : '';

  const buttonClassName =
    isEmpty(internalClassName)
      ? concatClassNames(
        styles.btn,
        typeClassName,
        sizeClassName,
        stateClassName,
        wideClassName,
        colorClassName,
        className
      )
      : internalClassName;

  const content = type === ButtonType.image
    ? <img src={imgSrc} alt={altText}/>
    : (
      <div className="flex align-center justify-center" style={{ gap: 16 }}>
        {icon !== null && <span className="flex">{icon}</span>}
        {children ?? text}
      </div>
      );

  return (
    <button
      ref={ref}
      type={type === ButtonType.submit
        ? 'submit'
        : 'button'}
      onClick={onClick}
      disabled={state === ButtonState.disabled}
      className={buttonClassName}
    >
      {content}
    </button>
  );
};

const LinkButton = ({
  to,
  text = null,
  children = null,
  className = '',
  type = LinkButtonType.primary,
  size = ButtonSize.medium,
  state = ButtonState.enabled,
  color = ButtonColors.primary,
  isExternal = false,
  imgSrc = '',
  altText = '',
  icon = null,
  disabled = false
}: ILinkButtonProps) => {
  validateOnlyChildrenOrText(text, children);
  const { [type]: typeClassName } = classNameToLinkButtonType;

  const { [size]: sizeClassName } = sizeToClassName;

  const { [color]: colorClassName } = colorToClassName;

  const stateClassName = disabled
    ? styles.disabled
    : '';

  const buttonClassName = concatClassNames(
    styles.btn,
    typeClassName,
    sizeClassName,
    stateClassName,
    colorClassName,
    className
  );

  const content = type === LinkButtonType.image
    ? <img src={imgSrc} alt={altText}/>
    : (
      <>
        {icon !== null && <span className={styles.icon}>{icon}</span>}
        {children ?? text}
      </>
      );

  const target = isExternal
    ? '_blank'
    : '';

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  return (
    <Link
      to={to}
      className={buttonClassName}
      target={target}
      onClick={handleClick}>
      {content}
    </Link>
  );
};

export default Button;

export {
  Button,
  LinkButton,
  ButtonType,
  LinkButtonType,
  ButtonSize,
  ButtonState,
  ButtonColors
};
