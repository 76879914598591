import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import Form from 'components/forms/Form';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import HorizontalTextSeparator from 'components/common/horizontal-text-separator/HorizontalTextSeparator';
import { Button, ButtonState, ButtonType, LinkButton, LinkButtonType } from 'components/button/Button';
import { ROUTES } from 'routes/routes';
import { useLoginMutation } from 'store/api/userApi';
import ForgottenPasswordPopup from 'components/forgotten-password/ForgottenPasswordPopup';
import GoogleLoginButton from 'components/common/social-login-button/GoogleLoginButton';
import useForm from 'hooks/useForm';
import { loginValidator } from 'utils/validators/authValidator';
import { storeToken } from 'utils/token';
import './Login.scss';
import { useI18n } from 'hooks/usei18n';
import { useNavigate } from 'react-router-dom';

export enum FieldName {
  Email = 'email',
  Password = 'password',
  RememberMe = 'rememberMe'
}

interface ILogin extends IClassName {
}

const initialValues = {
  [FieldName.Email]: '',
  [FieldName.Password]: ''
};

const Login = ({ className }: ILogin) => {
  const { t } = useTranslation();
  const { getLanguageUrl } = useI18n();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [isForgottenPasswordDialogOpen, setForgottenPasswordDialogOpen] = useState(false);

  const onSubmit = async (values: any) => {
    const response: any = await login(values);

    if (response.error) {
      throw new Error(t('login.invalidCredentials'));
    }

    const token = response.data.token;

    storeToken(token, values.rememberMe);
    navigate(getLanguageUrl(ROUTES.Home.path));
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    initialValues,
    onSubmit,
    loginValidator);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t('login.title')}</title>
        </Helmet>
      </HelmetProvider>
      <div className={concatClassNames(className, 'login')}>
        <section className="flex flex-column justify-center align-center login-content">
          <h1 className="login-content-title">{t('login.title')}</h1>
          <article className="flex flex-column login-content-login-links">
            <GoogleLoginButton className="register-login-redirect-link" />
            {/* <FacebookLoginButton className="register-login-redirect-link" /> */}
          </article>
          <HorizontalTextSeparator className="login-content-separator" word={t('common.or')} />
          <article className="flex flex-column justify-center align-center login-content-form">
            <Form
              submitText={t('login.title')}
              submitButtonState={isLoading ? ButtonState.disabled : ButtonState.enabled}
              className="flex flex-column login-form"
              submitButtonClassName="login-form-submit-button"
              onSubmit={handleSubmit}
            >
              <FormControl
                name={FieldName.Email}
                value={values.email}
                labelClassName="login-form-control-label"
                labelText={t('login.email')}
                error={errors.email}
                type={FormControlType.email}
                onChange={(value) => {
                  handleChange(FieldName.Email, value);
                }}
              />
              <FormControl
                name={FieldName.Password}
                value={values.password}
                labelClassName="login-form-control-label"
                labelText={t('login.password')}
                type={FormControlType.password}
                error={errors.password}
                onChange={(value) => {
                  handleChange(FieldName.Password, value);
                }} />
              <FormControl
                id={FieldName.RememberMe}
                name={FieldName.RememberMe}
                className="login-form-checkbox"
                labelClassName="login-form-checkbox-label"
                labelText={t('login.rememberMe')}
                type={FormControlType.checkbox}
                onChange={(value) => {
                  handleChange(FieldName.RememberMe, value);
                }} />
            </Form>
            <Button
              className="login-form-forgot-password-link text-underline"
              onClick={(e) => {
                e.preventDefault();
                setForgottenPasswordDialogOpen(true);
              }}
              type={ButtonType.plain}
            >
              {t('login.forgotPassword')}
            </Button>
          </article>
        </section>
        <section className="flex flex-column justify-center align-center login-no-account">
          <h3 className="login-no-account-title">{t('login.dontHaveAccount')}</h3>
          <LinkButton
            type={LinkButtonType.secondary}
            to={ROUTES.Register.path}
            className="login-no-account-link"
          >
            {t('login.createAccount')}
          </LinkButton>
        </section>
        {isForgottenPasswordDialogOpen && (
          <ForgottenPasswordPopup
            open={isForgottenPasswordDialogOpen}
            onClose={() => {
              setForgottenPasswordDialogOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default Login;
