import i18n from 'i18next';
import {
  createValidator,
  type FieldValidators,
  matchesRegex,
  maxLength,
  minLength,
  validateFields
} from 'utils/validators/validators';
import { type FormErrors } from 'hooks/useForm';
import { validateEmail, validatePassword, validateUsername } from 'utils/validators/authValidator';

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 120;
const MIN_PHONE_LENGTH = 10;
const MAX_PHONE_LENGTH = 13;
const PHONE_REGEX = /^(\+359|0)[2-9][0-9]{7,8}$/;
const NOT_ALLOWED_PHONE_REGEX = /^(\+359|0)8{9}$/;

const validateName = createValidator(
  minLength(MIN_NAME_LENGTH),
  maxLength(MAX_NAME_LENGTH)
);

const matchesNotAllowedPhoneNumbers = (regex: RegExp, message: string) => (value: string) =>
  regex.test(value) ? i18n.t(`validations.${message}`) : null;

const validatePhone = createValidator(
  minLength(MIN_PHONE_LENGTH),
  maxLength(MAX_PHONE_LENGTH),
  matchesRegex(PHONE_REGEX, 'phoneValidation'),
  matchesNotAllowedPhoneNumbers(NOT_ALLOWED_PHONE_REGEX, 'phoneValidation')
);

const fieldValidators = {
  email: validateEmail,
  username: validateUsername,
  firstName: validateName,
  middleName: validateName,
  lastName: validateName,
  phoneNumber: validatePhone
};

const validateCustomerFields = (values: any): FormErrors => validateFields(values, fieldValidators);

const validateConfirmPassword = (password: string, allValues?: {
  newPassword?: string
  confirmNewPassword?: string
}): string | null => {
  const newPassword = allValues ? allValues.newPassword : undefined;
  const confirmNewPassword = allValues ? allValues.confirmNewPassword : password;

  return newPassword === confirmNewPassword ? null : i18n.t('validations.passwordsDoNotMatch');
};

const customerPasswordValidators: FieldValidators = {
  currentPassword: validatePassword,
  newPassword: validatePassword,
  confirmNewPassword: validateConfirmPassword
};

const validateCustomerPasswordFields = (values: any) => validateFields(values, customerPasswordValidators);

export {
  validateCustomerFields,
  validateCustomerPasswordFields
};
