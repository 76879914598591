import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type SerializedError } from '@reduxjs/toolkit';
import { SortBy, useAuditInvoicesExcelMutation, useGetAllInvoicesQuery } from 'store/api/paymentApi';
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import { Button } from 'components/button/Button';
import { FormControl as MuiFormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import './AdminInvoices.scss';

interface IInvoicesExportParams {
  from?: string
  to?: string
  type?: number
  page?: number

  [key: string]: string | number | undefined
}

const Invoices = () => {
  const { t } = useTranslation();

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.Date, name: t('administration.payments.invoices.date') },
    { value: SortBy.OrderId, name: t('administration.payments.invoices.orderId') },
    { value: SortBy.paidAdId, name: t('administration.payments.invoices.paidAdId') },
    { value: SortBy.InvoiceType, name: t('administration.payments.invoices.invoiceType') }
  ];

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const invoiceTypeOptions = [
    { value: 1, name: t('administration.payments.invoices.company') },
    { value: 2, name: t('administration.payments.invoices.personal') }
  ];

  const [localError, setLocalError] = useState<string>('');
  const [queryParams, setQueryParams] = useState<IInvoicesExportParams>({
    page: DEFAULT_PAGE,
    order: orderByDropdownOptions[0].value,
    sortBy: sortByDropdownOptions[0].value
  });

  const { data, error, isLoading } = useGetAllInvoicesQuery(queryParams);
  const [exportInvoices] = useAuditInvoicesExcelMutation();

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setQueryParams(paramsObj);
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  const onInvoiceTypeDropdownChange = (e: any) => {
    const paramsObj: IInvoicesExportParams = { ...queryParams };
    const { target } = e;

    const selectedOption = invoiceTypeOptions.find(opt => opt.value === target.value);
    if (!selectedOption) return;

    const { value } = selectedOption;
    paramsObj.type = value;

    setQueryParams(paramsObj);
  };

  const onExportButtonClick = () => {
    if (!queryParams.from || !queryParams.to || !queryParams.type) {
      setLocalError('Both from, to date and type must be provided in order to Export Payments Audit data!');
      return;
    }
    // this mindless handling is required, else linter goes crazy
    exportInvoices({ ...queryParams })
      .then(() => {
      })
      .catch(() => {
      });

    setLocalError('');
    setQueryParams({});
  };

  const onDateChange = (e: any, field: string) => {
    const formattedDate = dayjs(e).format(DEFAULT_DATE_FORMAT);
    const paramsObj: IInvoicesExportParams = { ...queryParams };

    paramsObj[field] = `${formattedDate}Z`;
    setQueryParams(paramsObj);
  };

  const dataColumns: GridColDef[] = [
    {
      field: 'number',
      headerName: t('administration.payments.invoices.number'),
      width: 100,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'name',
      headerName: t('administration.payments.invoices.name'),
      align: 'center',
      headerAlign: 'center',
      width: 180
    },
    {
      field: 'unifiedIdentificationCode',
      headerName: 'Unified Id Code',
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'accountablePerson',
      headerName: t('administration.payments.invoices.accountablePerson'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'vatNumber',
      headerName: t('administration.payments.invoices.vatNumber'),
      align: 'center',
      headerAlign: 'center',
      width: 120
    },
    {
      field: 'country',
      headerName: t('administration.payments.invoices.country'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'city',
      headerName: t('administration.payments.invoices.city'),
      align: 'center',
      headerAlign: 'center',
      width: 180
    },
    {
      field: 'address',
      headerName: t('administration.payments.invoices.address'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'createdOn',
      headerName: t('administration.payments.invoices.date'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => new Date(params.row.createdOn).toLocaleDateString()
    },
    {
      field: 'orderId',
      headerName: t('administration.payments.invoices.orderId'),
      align: 'center',
      headerAlign: 'center',
      width: 80
    },
    {
      field: 'paidAdId',
      headerName: t('administration.payments.invoices.paidAdId'),
      align: 'center',
      headerAlign: 'center',
      width: 80
    },
    {
      field: 'type',
      headerName: t('administration.payments.invoices.invoiceType'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const { name } = params.value;
        return `${t(`administration.payments.invoices.${name.toLowerCase()}`)}`;
      }
    }
  ];

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (<div>
    {error
      ? <div style={{ color: 'red' }}>
        {(error as SerializedError).message}
      </div>
      : <>
        <div>
          <div style={{ color: 'red' }}>{localError}</div>
          <div className="invoices-sort-fields">
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.sortBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams.sortBy}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onSortByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {sortByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.orderBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams.order}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onOrderByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {orderByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
          </div>
          <div className="invoices-filter">
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('administration.payments.invoices.invoiceType')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams.type ?? ''}
                label={t('administration.payments.invoices.invoiceType')}
                onChange={(e: any) => {
                  onInvoiceTypeDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {invoiceTypeOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('administration.common.fromDate')}
                value={queryParams.from ? dayjs(queryParams.from) : null}
                sx={{ width: '180px' }}
                onChange={(e: any) => {
                  onDateChange(e, 'from');
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t('administration.common.toDate')}
                value={queryParams.to ? dayjs(queryParams.to) : null}
                sx={{ width: '180px' }}
                onChange={(e: any) => {
                  onDateChange(e, 'to');
                }}
              />
            </LocalizationProvider>
            <Button
              className='invoices-export-button'
              onClick={() => {
                setQueryParams({ page: DEFAULT_PAGE });
              }}
            >
              {t('administration.common.clearAll')}
            </Button>
            <Button
              className='invoices-export-button'
              onClick={onExportButtonClick}
            >
              {t('administration.common.export')}
            </Button>
          </div>
        </div>
        <DataGrid
          rows={data?.models ?? []}
          columns={dataColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
            }
          }}
          getRowId={(row) => row.number}
          pageSizeOptions={[DEFAULT_PAGE_SIZE]}
          checkboxSelection
          rowCount={data?.totalCount ?? 0}
          paginationMode='server'
          onPaginationModelChange={(e) => {
            setQueryParams({ page: e.page + 1, order: queryParams.order, sortBy: queryParams.sortBy });
          }}
        />
      </>
    }
  </div>);
};

export default Invoices;
