interface DateFormatOptions {
  year: 'numeric' | '2-digit'
  month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
  day: 'numeric' | '2-digit'
}

enum LocaleDateFormats {
  en = 'en-US',
  bg = 'bg-BG'
}

const formatDateString = (dateString: string, locale: LocaleDateFormats, options?: DateFormatOptions) => {
  const date = new Date(dateString);

  const defaultOptions: DateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattingOptions = options ?? defaultOptions;

  return date.toLocaleDateString(locale, formattingOptions);
};

export {
  formatDateString,
  LocaleDateFormats
};
