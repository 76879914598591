import React from 'react';
import Dropdown from 'components/dropdown/Dropdown';
import { type ICategory } from '../../../types/ICategory';
import { LOCAL_STORAGE_LOCALIZATION_KEY } from '../../../i18n';

interface ICategoryDropdownProps {
  categories: ICategory[]
  selectedCategory: any
  setSelectedCategory: any
  label: string
  error?: string
}

const CategoryDropdown = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  label,
  error
}: ICategoryDropdownProps) => {
  const selectedLanguage = localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_KEY) ?? 'bg';
  const localizedCategories = categories.map((category: ICategory) => ({
    ...category,
    name: selectedLanguage === 'en' ? category.nameEn : category.nameBg
  }));

  return (
    <Dropdown
      label={label}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      options={localizedCategories}
      value={selectedCategory ?? ''}
      onChange={setSelectedCategory}
      error={error}
      multiple={false}
    />
  );
};

export default CategoryDropdown;
