import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { type IPage, type IPageResponseType } from '../../types/IPage';

const PAGES_URL = 'pages';

export const pagesApi = createApi({
  reducerPath: 'pages',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllPagesInfo: builder.query<IPageResponseType, void>({
      query: () => ({
        url: `${PAGES_URL}`
      })
    }),
    editPageInfo: builder.mutation<void, { pageId: number, page: IPage }>({
      query: ({ pageId, page }) => ({
        url: `${PAGES_URL}/${pageId}`,
        method: 'PUT',
        body: {
          ...page
        }
      })
    }),
    getPageDetails: builder.query<IPage, { url: string }>({
      query: ({ url }) => ({
        url: `${PAGES_URL}/details?url=${url}`,
        method: 'GET'
      })
    }),
    createNewPage: builder.mutation<void, { page: IPage }>({
      query: ({ page }) => ({
        url: `${PAGES_URL}`,
        method: 'POST',
        body: {
          ...page
        }
      })
    }),
    deletePage: builder.mutation<void, { pageId: number }>({
      query: ({ pageId }) => ({
        url: `${PAGES_URL}/${pageId}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetAllPagesInfoQuery,
  useGetPageDetailsQuery,
  useEditPageInfoMutation,
  useCreateNewPageMutation,
  useDeletePageMutation
} = pagesApi;
