import { useI18n } from 'hooks/usei18n';
import { type ComponentType, type FC, useCallback, useEffect } from 'react';

const withLanguageUrlPrefix = <Props extends object>(
  WrappedComponent: ComponentType<Props>
): FC<Props> => {
  const HoC = (props: any) => {
    const { changeLanguage, language } = useI18n();

    const handleLanguageOnPageLoad = useCallback(
      async () => {
        if (location.pathname.startsWith('/en')) {
          if (language !== 'en') {
            await changeLanguage('en');
          }
        } else if (language !== 'bg') {
          await changeLanguage('bg');
        }
      },
      [changeLanguage, location]
    );

    useEffect(
      () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleLanguageOnPageLoad();
      },
      [handleLanguageOnPageLoad]
    );

    return <WrappedComponent { ...props } />;
  };

  return HoC;
};

export default withLanguageUrlPrefix;
