import { useI18n } from 'hooks/usei18n';
import { isNil } from 'lodash';
import { type ComponentType, type FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, ROUTES } from 'routes/routes';
import { ADMIN_USER_ROLES, isUserAdmin, isUserLoggedIn } from 'utils/auth';

const withAuth = <Props extends object>(
  WrappedComponent: ComponentType<Props>,
  auth: Auth
): FC<Props> => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { getLanguageUrl } = useI18n();
    const { GENERAL_ADMINISTRATOR, REGULAR_ADMINISTRATOR, MARKETING_ADMINISTRATOR } = ADMIN_USER_ROLES;
    const adminRoles = [
      GENERAL_ADMINISTRATOR,
      REGULAR_ADMINISTRATOR,
      MARKETING_ADMINISTRATOR
    ];
    const isLoggedIn = isUserLoggedIn();
    const isAdmin = isUserAdmin(adminRoles);
    const navigate = useNavigate();

    useEffect(() => {
      const path = auth === Auth.Protected && !isLoggedIn
        ? ROUTES.Login.path
        : auth === Auth.Anonymous && isLoggedIn
          ? ROUTES.Home.path
          : auth === Auth.Admin && !isAdmin
            ? ROUTES.Home.path
            : null;

      if (!isNil(path)) {
        navigate(getLanguageUrl(path));
      }
    }, [auth, isLoggedIn, isAdmin, navigate]);

    if (
      (auth === Auth.Protected && isLoggedIn) ||
      (auth === Auth.Anonymous && !isLoggedIn) ||
      (auth === Auth.Admin && isAdmin) ||
      auth === Auth.None
    ) {
      return <WrappedComponent {...props} />;
    }

    return null;
  };
};

export default withAuth;
