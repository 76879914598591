import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetStreetsQuery } from 'store/api/deliveryApi';
import debounce from 'utils/debounce';
import { DEFAULT_DEBOUNCE_TIME } from 'utils/constants';

interface IStreetDropdownProps {
  siteId: number
  selectedStreet: any
  handleStreetChange: any
  disabled?: boolean
  error?: string
}

const StreetDropdown = ({ siteId, selectedStreet, handleStreetChange, disabled, error }: IStreetDropdownProps) => {
  const { t, i18n } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const { data: streets, isLoading, isError } = useGetStreetsQuery({
    siteId,
    name: inputValue,
    language: i18n.language
  }, { skip: !siteId });

  useEffect(() => {
    if (selectedStreet?.name) {
      setInputValue(selectedStreet.name);
    }
  }, [selectedStreet]);

  const debouncedInputChange = useCallback(debounce((newInputValue: string) => {
    setInputValue(newInputValue);
  }, DEFAULT_DEBOUNCE_TIME), []);

  const handleInputChange = (event: any, newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  if (isLoading) return <div>{t('common.loading')}</div>;
  if (isError) return <div>{t('common.errorLoadingStreets')}</div>;

  const streetOptions = streets?.map((street) => ({
    ...street,
    label: `${street.type} ${street.name}`
  })) ?? [];

  const handleChange = (event: any, newValue: any) => {
    handleStreetChange(newValue);
  };

  return (
    <Autocomplete
      className="dropdown"
      disabled={disabled}
      options={streetOptions}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
          <li {...props} key={option.id}>{option.label}</li>
      )}
      value={streetOptions.find(street => street.name === selectedStreet?.name) ?? null}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
          <TextField {...params as any} label={t('common.chooseStreet')} error={!!error} helperText={error}/>
      )}
      noOptionsText={inputValue.length <= 0 ? t('common.noStreetsFound') : ''}
    />
  );
};

export default StreetDropdown;
