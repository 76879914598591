import { createValidator, matchesRegex, maxLength, minLength, validateFields } from 'utils/validators/validators';
import { type FormErrors } from 'hooks/useForm';

const MIN_BANK_IBAN_LENGTH = 5;
const MAX_BANK_IBAN_LENGTH = 34;
// const MIN_BANK_SWIFT_LENGTH = 8;
// const MAX_BANK_SWIFT_LENGTH = 11;
const IBAN_REGULAR_EXPRESSION = /^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/;
// const BANK_SWIFT_REGULAR_EXPRESSION = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

const validateIban = createValidator(
  minLength(MIN_BANK_IBAN_LENGTH),
  maxLength(MAX_BANK_IBAN_LENGTH),
  matchesRegex(IBAN_REGULAR_EXPRESSION, 'ibanValidation')
);

// const validateSwift = createValidator(
//   minLength(MIN_BANK_SWIFT_LENGTH),
//   maxLength(MAX_BANK_SWIFT_LENGTH),
//   matchesRegex(BANK_SWIFT_REGULAR_EXPRESSION, 'swiftValidation')
// );

const fieldValidators = {
  iban: validateIban
  // swift: validateSwift
};

const validateBankDetailFields = (values: any): FormErrors => validateFields(values, fieldValidators);

export {
  validateBankDetailFields
};
