import { Rating } from '@mui/material';
import { type IReview } from 'types/IReview';
import { ReactComponent as RankingIcon } from 'assets/icons/rank.svg';
import { ReactComponent as RankingFilledIcon } from 'assets/icons/rank-filled.svg';
import './ReviewCard.scss';

interface IReviewCardProps {
  review: IReview
}

const ReviewCard = ({ review }: IReviewCardProps) => (
  <article className="flex flex-column review-card">
    <div className="flex align-center" style={{ gap: 8 }}>
      {/*
      <img src={Girl} className="review-card-avatar" alt="avatar"/>
*/}
      <div className="flex flex-column" style={{ gap: 5 }}>
        <span className="review-card-reviewer-username">{review.reviewerDisplayName}</span>
        <div className="flex review-card-rating" style={{ gap: 8 }}>
          <div className="flex align-center" style={{ gap: 4 }}>
            {typeof review?.rating === 'number' && (
              <>
                <Rating
                  defaultValue={review.rating}
                  emptyIcon={<RankingIcon/>}
                  icon={<RankingFilledIcon/>}
                  precision={0.5}
                  className="review-card-rating-stars"
                  readOnly
                />
                <span className="rating">({review.rating.toFixed(1)})</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    <span className="review-card-comment">{review.comment}</span>
  </article>
);

export default ReviewCard;
