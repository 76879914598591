import { useTranslation } from 'react-i18next';
import Slider from 'components/slider/Slider';
import ProductCard from 'components/cards/product-card/ProductCard';
import useWindowWidth from 'hooks/useWindowWidth';
import { type IProductResponse } from 'types/IProduct';
import './ProductsOnFocusSlider.scss';

interface IProductsOnFocusSlider {
  products?: IProductResponse[]
  isLoading: boolean
  error?: any
}

const ProductsOnFocusSlider = ({ products, isLoading, error }: IProductsOnFocusSlider) => {
  const { t } = useTranslation();
  const { isSmallMobile, isMobile, isTablet, isLaptop, isDesktop, isLargeDesktop } = useWindowWidth();

  if (isLoading) {
    return <p>{t('common.loading')}</p>;
  }

  if (error) {
    return <p>{t('allProducts.errorFetchingProducts')}</p>;
  }

  const calculateSlidesToShow = () => {
    if (isLargeDesktop) {
      return 6;
    } else if (isDesktop) {
      return 5;
    } else if (isLaptop) {
      return 4;
    } else if (isTablet) {
      return 3;
    } else if (isSmallMobile) {
      return 2;
    } else if (isMobile) {
      return 2;
    } else {
      return 2;
    }
  };

  const slidesToShow = calculateSlidesToShow();

  const items = products?.map((product) => (
    <ProductCard
      key={product.id}
      product={product}
    />
  )) ?? [];

  return (
    <Slider
      className="products-on-focus-slider"
      slides={items}
      pagination={true}
      spaceBetween={isMobile ? 10 : 14}
      slidesToShow={slidesToShow}
      minSlidesToShow={slidesToShow}
    />
  );
};

export default ProductsOnFocusSlider;
