import React, { type FormEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import { generateGuid } from 'utils/text';
import { Button, ButtonState, ButtonType } from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';

interface IFormProps {
  onSubmit: any
  submitText?: string
  id?: string
  submitButtonClassName?: string
  submitButtonType?: ButtonType
  disableButton?: boolean
  children: React.ReactNode
  className?: string
  submitButtonState?: ButtonState
  shouldShowDisabledTooltip?: boolean
  encType?: string
}

const Form = ({
  onSubmit,
  children,
  submitText = 'Submit',
  id = generateGuid(),
  className = '',
  submitButtonClassName = '',
  submitButtonType = ButtonType.submit,
  submitButtonState = ButtonState.enabled,
  disableButton = false,
  shouldShowDisabledTooltip = false,
  encType
}: IFormProps) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (ev: FormEvent) => {
      ev.preventDefault();

      if (submitButtonState === ButtonState.disabled) {
        return false;
      }

      await onSubmit();

      return false;
    },
    [onSubmit]
  );

  const btnId = useMemo(
    () => `btn-submit-${id}`,
    [id]
  );

  const internalClassName = concatClassNames(className);
  const internalSubmitButtonClassName = concatClassNames('btnSubmitInForm', submitButtonClassName);

  const btn = (
    <Button
      id={btnId}
      onClick={(ev) => {
        ev.preventDefault();
        handleSubmit(ev).catch(error => {
          console.error('Error handling click:', error);
        });
      }}
      text={submitText}
      type={submitButtonType}
      className={internalSubmitButtonClassName}
      state={submitButtonState}
    />
  );

  const renderButton = useCallback(
    () => (
      !disableButton
        ? (
            !shouldShowDisabledTooltip
              ? btn
              : (
              <Tooltip
                title={t('common.disabledFormButtonText')}
                arrow>
                <div className="full-width">
                  {btn}
                </div>
              </Tooltip>
                )
          )
        : null
    ),
    [btnId, handleSubmit, disableButton, internalSubmitButtonClassName, submitText, submitButtonState]
  );

  return (
    <form
      id={id}
      encType={encType}
      onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit(ev).catch(error => {
          console.error('Error handling submit:', error);
        });
      }} className={internalClassName}
    >
      {children}
      {renderButton()}
    </form>
  );
};

export default Form;
