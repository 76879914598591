import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { type ICartItem, type IShoppingCartResponse } from 'types/ICart';

const CART_URL = 'shoppingCarts';

export const cartApi = createApi({
  reducerPath: 'cart',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    addItemToShoppingCart: builder.mutation({
      query: (item: ICartItem) => ({
        url: `${CART_URL}/addItem`,
        method: 'POST',
        body: item
      })
    }),
    getShoppingCart: builder.query<IShoppingCartResponse, void>({
      query: () => ({
        url: `${CART_URL}/mine`
      })
    }),
    removeItemFromShoppingCart: builder.mutation({
      query: (id: number) => ({
        url: `${CART_URL}/removeItem/${id}`,
        method: 'DELETE'
      })
    }),
    editItemQuantity: builder.mutation({
      query: ({ productId, sizeId, quantity }: { productId: number, sizeId?: number, quantity: number }) => ({
        url: `${CART_URL}/editQuantity`,
        method: 'PUT',
        body: {
          productId,
          sizeId,
          quantity
        }
      })
    }),
    getShoppingCartSummary: builder.query<{ cartItemsCount: number, favouriteProductIds: number[] }, void>({
      query: () => ({
        url: `${CART_URL}/summary`
      })
    })
  })
});

export const {
  useAddItemToShoppingCartMutation,
  useGetShoppingCartQuery,
  useRemoveItemFromShoppingCartMutation,
  useEditItemQuantityMutation,
  useGetShoppingCartSummaryQuery
} = cartApi;
