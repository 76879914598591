import i18n from 'i18next';
import { createValidator, maxLength, minLength } from 'utils/validators/validators';

interface IArticleFormValues {
  canonicalUrl: string
  titleEn: string
  titleBg: string
  heading1En: string
  heading1Bg: string
  heading2En: string
  heading2Bg: string
  descriptionEn: string
  descriptionBg: string
  file: File | null
}

type ArticleFormErrors = Partial<{ [K in keyof IArticleFormValues]: string }>;

interface IPublisherFormValues {
  name: string
}

type PublisherFormErrors = Partial<{ [K in keyof IPublisherFormValues]: string }>;

const MAX_ARTICLE_CANONICAL_URL_LENGTH = 2048;
const MIN_ARTICLE_TITLE_LENGTH = 2;
const MAX_ARTICLE_TITLE_LENGTH = 120;
const MIN_ARTICLE_HEADING_LENGTH = 2;
const MAX_ARTICLE_HEADING_LENGTH = 120;
const MIN_ARTICLE_DESCRIPTION_LENGTH = 2;

const MIN_META_NAME_LENGTH = 2;
const MAX_META_NAME_LENGTH = 60;
const MIN_META_CONTENT_LENGTH = 2;
const MAX_META_CONTENT_LENGTH = 300;

const MIN_PUBLISHER_NAME_LENGTH = 2;
const MAX_PUBLISHER_NAME_LENGTH = 240;

const validateArticleCanonicalUrl = createValidator(
  maxLength(MAX_ARTICLE_CANONICAL_URL_LENGTH)
);

const validateArticleTitle = createValidator(
  minLength(MIN_ARTICLE_TITLE_LENGTH),
  maxLength(MAX_ARTICLE_TITLE_LENGTH)
);

const validateArticleHeading = createValidator(
  minLength(MIN_ARTICLE_HEADING_LENGTH),
  maxLength(MAX_ARTICLE_HEADING_LENGTH)
);

const validateArticleDescription = createValidator(
  minLength(MIN_ARTICLE_DESCRIPTION_LENGTH)
);

const validateMetaTagName = createValidator(
  minLength(MIN_META_NAME_LENGTH),
  maxLength(MAX_META_NAME_LENGTH)
);

const validateMetaTagContent = createValidator(
  minLength(MIN_META_CONTENT_LENGTH),
  maxLength(MAX_META_CONTENT_LENGTH)
);

const validatePublisherName = createValidator(
  minLength(MIN_PUBLISHER_NAME_LENGTH),
  maxLength(MAX_PUBLISHER_NAME_LENGTH)
);

const MAX_FILE_SIZE = 10 * 1024 * 1024;
const SUPPORTED_FILE_TYPES = ['image/jpeg', 'image/png'];

const validateFileSize = (file: File) => {
  return file.size <= MAX_FILE_SIZE ? null : i18n.t('validations.blog.tooLargeError', { size: MAX_FILE_SIZE / 1024 / 1024 });
};

const validateFileType = (file: File) => {
  return SUPPORTED_FILE_TYPES.includes(file.type) ? null : i18n.t('validations.blog.unsupportedFileError', { types: SUPPORTED_FILE_TYPES.join(', ') });
};

const validateFile = createValidator(
  validateFileSize,
  validateFileType
);

const validateArticleFields = (values: Partial<any>): ArticleFormErrors => {
  const errors: any = {};

  const fieldValidators: { [K in keyof IArticleFormValues]?: () => string | null } = {
    canonicalUrl: () => values.canonicalUrl ? validateArticleCanonicalUrl(values.canonicalUrl) : null,
    titleEn: () => values.titleEn ? validateArticleTitle(values.titleEn) : null,
    titleBg: () => values.titleBg ? validateArticleTitle(values.titleBg) : null,
    heading1En: () => values.heading1En ? validateArticleHeading(values.heading1En) : null,
    heading1Bg: () => values.heading1Bg ? validateArticleHeading(values.heading1Bg) : null,
    heading2En: () => values.heading2En ? validateArticleHeading(values.heading2En) : null,
    heading2Bg: () => values.heading2Bg ? validateArticleHeading(values.heading2Bg) : null,
    descriptionEn: () => values.descriptionEn ? validateArticleDescription(values.descriptionEn) : null,
    descriptionBg: () => values.descriptionBg ? validateArticleDescription(values.descriptionBg) : null,
    file: () => values.file ? validateFile(values.file) : i18n.t('validations.blog.fileIsRequired')
  };

  if (values.metaName && values.metaName.length > 0) {
    const metaNameErrors = values.metaName.map((name: string, index: number) =>
      name
        ? validateMetaTagName(name)
        : (values.metaContent[index]
            ? i18n.t('validations.blog.metaNameIsRequired', { index: index + 1 })
            : null)
    );

    const hasMetaNameErrors = metaNameErrors.some((error: any) => error !== null);
    if (hasMetaNameErrors) {
      errors.metaName = metaNameErrors;
    }
  }

  if (values.metaContent && values.metaContent.length > 0) {
    const metaContentErrors = values.metaContent.map((content: string, index: number) =>
      content
        ? validateMetaTagContent(content)
        : (values.metaName[index]
            ? i18n.t('validations.blog.metaContentIsRequired', { index: index + 1 })
            : null)
    );

    const hasMetaContentErrors = metaContentErrors.some((error: any) => error !== null);
    if (hasMetaContentErrors) {
      errors.metaContent = metaContentErrors;
    }
  }

  for (const field in fieldValidators) {
    if (Object.prototype.hasOwnProperty.call(fieldValidators, field)) {
      const validator = fieldValidators[field as keyof IArticleFormValues];
      if (validator) {
        const error = validator();
        if (error) {
          errors[field as keyof IArticleFormValues] = error;
        }
      }
    }
  }

  return errors;
};

const validateEditArticleFields = (values: Partial<any>): ArticleFormErrors => {
  const errors: any = {};

  const fieldValidators: { [K in keyof IArticleFormValues]?: () => string | null } = {
    canonicalUrl: () => values.canonicalUrl ? validateArticleCanonicalUrl(values.canonicalUrl) : null,
    titleEn: () => values.titleEn ? validateArticleTitle(values.titleEn) : null,
    titleBg: () => values.titleBg ? validateArticleTitle(values.titleBg) : null,
    heading1En: () => values.heading1En ? validateArticleHeading(values.heading1En) : null,
    heading1Bg: () => values.heading1Bg ? validateArticleHeading(values.heading1Bg) : null,
    heading2En: () => values.heading2En ? validateArticleHeading(values.heading2En) : null,
    heading2Bg: () => values.heading2Bg ? validateArticleHeading(values.heading2Bg) : null,
    descriptionEn: () => values.descriptionEn ? validateArticleDescription(values.descriptionEn) : null,
    descriptionBg: () => values.descriptionBg ? validateArticleDescription(values.descriptionBg) : null
  };

  if (values.metaTags && values.metaTags.length > 0) {
    const metaNameErrors = values.metaTags.map((metaTag: any, index: number) =>
      metaTag.name
        ? validateMetaTagName(metaTag.name)
        : (values.metaTags.find((_: any, i: number) => i === index)?.name
            ? i18n.t('validations.blog.metaNameIsRequired', { index: index + 1 })
            : null)
    );

    const hasMetaNameErrors = metaNameErrors.some((error: any) => error !== null);
    if (hasMetaNameErrors) {
      errors.metaName = metaNameErrors;
    }

    const metaContentErrors = values.metaTags.map((metaTag: any, index: number) =>
      metaTag.content
        ? validateMetaTagContent(metaTag.content)
        : (values.metaTags.find((_: any, i: number) => i === index)?.content
            ? i18n.t('validations.blog.metaContentIsRequired', { index: index + 1 })
            : null)
    );

    const hasMetaContentErrors = metaContentErrors.some((error: any) => error !== null);
    if (hasMetaContentErrors) {
      errors.metaContent = metaContentErrors;
    }
  }

  for (const field in fieldValidators) {
    if (Object.prototype.hasOwnProperty.call(fieldValidators, field)) {
      const validator = fieldValidators[field as keyof IArticleFormValues];
      if (validator) {
        const error = validator();
        if (error) {
          errors[field as keyof IArticleFormValues] = error;
        }
      }
    }
  }

  return errors;
};

const validatePublisherFields = (values: Partial<IPublisherFormValues>): PublisherFormErrors => {
  const errors: PublisherFormErrors = {};

  const fieldValidators: { [K in keyof IPublisherFormValues]?: () => string | null } = {
    name: () => values.name ? validatePublisherName(values.name) : i18n.t('validations.blog.nameIsRequired')
  };

  for (const field in fieldValidators) {
    if (Object.prototype.hasOwnProperty.call(fieldValidators, field)) {
      const validator = fieldValidators[field as keyof IPublisherFormValues];
      if (validator) {
        const error = validator();
        if (error) {
          errors[field as keyof IPublisherFormValues] = error;
        }
      }
    }
  }

  return errors;
};

export {
  validateArticleCanonicalUrl,
  validateArticleTitle,
  validateArticleHeading,
  validateArticleDescription,
  validateMetaTagName,
  validateMetaTagContent,
  validateArticleFields,
  validatePublisherFields,
  validateEditArticleFields
};
