import { useTranslation } from 'react-i18next';
import Popup, { type IPopup } from 'components/popup/Popup';
import Button, { ButtonType } from 'components/button/Button';
import './StyledPopup.scss';

export interface IStyledPopup extends IPopup {
  onAccept?: () => void
}

const StyledPopup = ({ open, onClose, title, text, children, onAccept }: IStyledPopup) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      onClose={onClose}
      shouldShowCloseIcon={false}
      className="styled-popup"
    >
      <div className="flex flex-column justify-center align-center styled-popup-content">
        <h2 className="styled-popup-content-title">
          {title}
        </h2>
        {text && (
          <span className="styled-popup-content-text">
            {text}
          </span>
        )}
        {children && children}
        <div className="flex justify-center align-center styled-popup-content-ctas">
          <Button
            className="styled-popup-content-btn"
            onClick={onClose}
            type={ButtonType.secondary}
            text={t('common.cancel')}
          />
          <Button
            className="styled-popup-content-btn"
            onClick={onAccept ?? onClose}
            type={ButtonType.primary}
            text={t('common.confirm')}
          />
        </div>
      </div>
    </Popup>
  );
};

export default StyledPopup;
