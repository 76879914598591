import React, { type ChangeEvent, type FC, useState } from 'react';
import Radio, { type RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-unchecked.svg';
import { type IOptionalClassName } from 'types/common/Props';
import './RadioButtons.scss';

interface Option {
  value: any
  label: React.ReactNode
}

interface RadioButtonsProps extends IOptionalClassName {
  options: Option[]
  label?: string
  onValueChange?: (value: string | number) => void
  value?: any
  children?: React.ReactNode
}

const RadioButtons: FC<RadioButtonsProps> = ({ options, label, onValueChange, value, children }) => {
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const CustomRadio: FC<RadioProps> = (props) => (
    <Radio
      icon={<CheckboxUnchecked/>}
      checkedIcon={<CheckboxChecked/>}
      {...props}
    />
  );

  const isReactElement = (label: React.ReactNode): label is React.ReactElement => {
    return React.isValidElement(label);
  };

  const renderLabel = (option: Option) => {
    const isActive = selectedValue === option.value;
    const labelClassName = isActive ? 'active-label' : 'non-active-label';

    if (isReactElement(option.label)) {
      return React.cloneElement(option.label, { className: labelClassName });
    } else {
      return <span className={labelClassName}>{option.label}</span>;
    }
  };

  return (
    <FormControl component="fieldset" className="radio-buttons">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        aria-label={label ?? 'options'}
        name="options"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <React.Fragment key={option.value}>
            <FormControlLabel
              value={option.value}
              control={<CustomRadio/>}
              label={renderLabel(option)}
            />
            {+selectedValue === +option.value && children}
          </React.Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;
