import React from 'react';
import CreateNewProductButton from 'components/common/create-new-product-button/CreateNewProductButton';
import NavigationLink from 'components/header/NavigationLink';
import { PROFILE_ROUTES } from 'routes/routes';
import './ProfileSidebar.scss';

const ProfileSidebar = () => (
  <div className="profile-sidebar">
    <CreateNewProductButton className="profile-sidebar-button"/>
    <nav className="flex flex-column profile-sidebar-navigation">
      {PROFILE_ROUTES?.map((route) => (
        <NavigationLink
          key={route.name}
          route={route}
          className="profile-sidebar-navigation-link"
        />
      ))}
    </nav>
  </div>
);

export default ProfileSidebar;
