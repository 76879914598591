import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortBy, useAuditExcelMutation, useGetAllPaymentsQuery } from 'store/api/paymentApi';
import { Button } from 'components/button/Button';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import type { SerializedError } from '@reduxjs/toolkit';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import { FormControl as MuiFormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';

interface IPaymentsExportAuditParams {
  year?: number
  month?: number

  [key: string]: number | undefined
}

enum PaymentsAuditFields {
  Month = 'Month',
  Year = 'Year'
}

const Purchases = () => {
  const { t } = useTranslation();

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.Username, name: t('common.sort.username') },
    { value: SortBy.PaidAd, name: t('common.sort.paidAd') },
    { value: SortBy.Price, name: t('common.sort.price') },
    { value: SortBy.Date, name: t('common.sort.date') }
  ];

  const [localError, setLocalError] = useState<string>('');
  const [exportParams, setExportParams] = useState<IPaymentsExportAuditParams>({});
  const [queryParams, setQueryParams] = useState<{
    page: number
    order: Order
    sortBy?: SortBy
  }>({
    page: DEFAULT_PAGE,
    order: orderByDropdownOptions[0].value
  });
  const [auditExcelExport] = useAuditExcelMutation();
  const {
    data,
    error,
    isLoading,
    refetch
  } = useGetAllPaymentsQuery(queryParams);

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setQueryParams(paramsObj);
    if (sortByOption.value === SortBy.Id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setQueryParams(prevState => ({ ...prevState, sortBy: null }));
    } else {
      setQueryParams(prevState => ({ ...prevState, sortBy: sortByOption.value }));
    }
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  const onExportButtonClick = () => {
    if (!exportParams.month || !exportParams.year) {
      setLocalError('Both month and year must be provided in order to Export Payments Audit data!');
      return;
    }
    // this mindless handling is required, else linter goes crazy
    auditExcelExport({ ...exportParams })
      .then(() => {
      })
      .catch(() => {
      });

    setLocalError('');
    setExportParams({});
  };

  const onExportParamsChange = (field: string, value: number) => {
    setExportParams({ ...exportParams, [field]: value });
  };

  useEffect(() => {
    void refetch();
  }, [queryParams]);

  const dataColumns: GridColDef[] = [
    {
      field: 'username',
      headerName: t('administration.payments.purchases.username'),
      width: 200,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'paidAdDescription',
      headerName: t('administration.payments.purchases.advertisementPackageType'),
      align: 'center',
      headerAlign: 'center',
      width: 220
    },
    {
      field: 'amountPerThePackage',
      headerName: t('administration.payments.purchases.amountPerPackage'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        const vatAmount = (params.row.priceWithVat.amount - params.row.priceWithoutVat.amount).toFixed(2);
        return `${params.row.priceWithVat.amount.toFixed(2)} (${vatAmount} VAT) ${params.row.priceWithVat.currency.name}`;
      }
    },
    {
      field: 'paymentDate',
      headerName: t('administration.payments.purchases.dateOfPurchase'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.paymentDate).toLocaleDateString();
      }
    },
    {
      field: 'validUntil',
      headerName: t('administration.payments.purchases.validUntil'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.validUntil).toLocaleDateString();
      }
    }
  ];

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (<div>
    {error
      ? <div style={{ color: 'red' }}>
        {(error as SerializedError).message}
      </div>
      : <>
        <div>
          <div style={{ color: 'red' }}>{localError}</div>
          <div className="invoices-sort-fields">
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.sortBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams?.sortBy || sortByDropdownOptions[0].value}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onSortByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {sortByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.orderBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams.order}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onOrderByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {orderByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
          </div>
          <div className="invoices-filter">
            <FormControl
              name={PaymentsAuditFields.Month}
              value={exportParams[PaymentsAuditFields.Month.toLowerCase()] as any}
              labelText={t('administration.payments.purchases.month')}
              error={' '}
              shouldUpdateValue
              type={FormControlType.text}
              onChange={(value) => {
                onExportParamsChange(PaymentsAuditFields.Month.toLowerCase(), value);
              }}
            />
            <FormControl
              name={PaymentsAuditFields.Year}
              value={exportParams[PaymentsAuditFields.Year.toLowerCase()] as any}
              labelText={t('administration.payments.purchases.year')}
              error={' '}
              shouldUpdateValue
              type={FormControlType.text}
              onChange={(value) => {
                onExportParamsChange(PaymentsAuditFields.Year.toLowerCase(), value);
              }}
            />
            <Button onClick={onExportButtonClick}>
              {t('administration.common.export')}
            </Button>
          </div>
        </div>
        <DataGrid
          rows={data?.models ?? []}
          columns={dataColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
            }
          }}
          pageSizeOptions={[DEFAULT_PAGE_SIZE]}
          checkboxSelection
          rowCount={data?.totalCount ?? 0}
          paginationMode='server'
          onPaginationModelChange={(e) => {
            setQueryParams({ page: e.page + 1, sortBy: queryParams.sortBy, order: queryParams.order });
          }}
        />
      </>
    }
  </div>);
};

export default Purchases;
