import concatClassNames from 'utils/classNames';
import './NotificationCount.scss';

interface INotificationCount {
  count: any
  color: NotificationCountColor
}

export enum NotificationCountColor {
  red = 'red',
  green = 'green'
}

const NotificationCount = ({ count, color }: INotificationCount) => {
  const colors = {
    [NotificationCountColor.red]: 'red',
    [NotificationCountColor.green]: 'green'
  };

  if (!count) {
    return null;
  }

  return (
    count && (
      <div className={concatClassNames('notification-count', colors[color])}>
        {count}
      </div>)
  );
};

export default NotificationCount;
