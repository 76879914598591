import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortBy, useDeleteProductMutation, useGetProductsForSaleQuery } from 'store/api/productApi';
import type { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import type { SerializedError } from '@reduxjs/toolkit';
import type { IProductInventoryItems } from 'types/IProduct';
import { FormControl as MuiFormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { Button } from '../../button/Button';
import { useNotification } from '../../notification/Notification';
import './ItemsForSaleTab.scss';

const ItemsForSaleTab = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.Name, name: t('common.sort.name') },
    { value: SortBy.Price, name: t('common.sort.price') },
    { value: SortBy.Label, name: t('common.sort.label') },
    { value: SortBy.Seller, name: t('common.sort.seller') }
  ];

  const [queryParams, setQueryParams] = useState<{
    page: number
    order: Order
    sortBy?: SortBy
  }>({
    page: DEFAULT_PAGE,
    order: orderByDropdownOptions[0].value
  });

  const [deleteProduct] = useDeleteProductMutation();

  const {
    data,
    error,
    isLoading,
    refetch
  } = useGetProductsForSaleQuery(queryParams);

  useEffect(() => {
    void refetch();
  }, [queryParams]);

  const onDelete = async (id: number) => {
    try {
      const response: any = await deleteProduct({ id });

      if (response.data === null) {
        await refetch();
        showNotification(t('administration.products.deletedSuccessfully'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dataColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 150
    },
    { field: 'sellerUsername', headerName: t('administration.products.sellerUsername'), width: 180 },
    {
      field: 'name',
      headerName: t('administration.products.itemName'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.name.bulgarian
    },
    {
      field: 'description',
      headerName: t('administration.products.description'),
      width: 250,
      valueGetter: (params: GridValueGetterParams) => params.row.description.bulgarian
    },
    {
      field: 'status',
      headerName: t('administration.products.status'),
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        const quantitySum = params.row.inventoryItems.reduce((acc: number, currentValue: IProductInventoryItems) => acc + currentValue.quantity, 0);

        return quantitySum === 0 ? t('administration.products.sold') : t('administration.products.inSale');
      }
    },
    {
      field: 'size',
      headerName: t('administration.products.size'),
      width: 400,
      valueGetter: (params: GridValueGetterParams) => {
        const inventoryItemsFormatted = params.row.inventoryItems.map((inventoryItem: IProductInventoryItems) => {
          if (!inventoryItem.sizeName) {
            return `${inventoryItem.quantity}pcs. \n`;
          }

          return `${inventoryItem.sizeName}: ${inventoryItem.quantity}pcs. \n`;
        });

        return inventoryItemsFormatted.join('');
      }
    },
    {
      field: 'deleteRow',
      align: 'center',
      headerAlign: 'center',
      headerName: t('administration.reviews.deleteRow'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        return <Button onClick={async () => {
          await onDelete(params.row.id);
        }}>
          { t('administration.common.delete') }
        </Button>;
      }
    }
  ];

  const onSortByDropdownChange = (e: any) => {
    const { target } = e;
    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    if (sortByOption.value === SortBy.Id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setQueryParams(prevState => ({ ...prevState, sortBy: null }));
    } else {
      setQueryParams(prevState => ({ ...prevState, sortBy: sortByOption.value }));
    }
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (<div>
    {error
      ? <div>{(error as SerializedError).message}</div>
      : <>
        <div
          style={{ marginBottom: '10px' }}>{t('administration.products.totalAmountFromSoldItems')}: {data?.totalAmountForSoldProducts} BGN
        </div>
        <div className='products-sort-fields'>
          <MuiFormControl variant="outlined" className="sort-container">
            <InputLabel id="sort-by-label">
              {t('common.sort.sortBy')}
            </InputLabel>
            <Select
                sx={{ width: '180px' }}
                value={queryParams?.sortBy || sortByDropdownOptions[0].value}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onSortByDropdownChange(e);
                }}
                className='dropdown-select'
            >
              {sortByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
              ))}
            </Select>
          </MuiFormControl>
          <MuiFormControl variant="outlined" className="sort-container">
            <InputLabel id="sort-by-label">
              {t('common.sort.orderBy')}
            </InputLabel>
            <Select
                sx={{ width: '180px' }}
                value={queryParams.order}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onOrderByDropdownChange(e);
                }}
                className='dropdown-select'
            >
              {orderByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
              ))}
            </Select>
          </MuiFormControl>
        </div>
        <DataGrid
          rows={data?.models ?? []}
          columns={dataColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
            }
          }}
          pageSizeOptions={[DEFAULT_PAGE_SIZE]}
          checkboxSelection
          rowCount={data?.totalCount ?? 0}
          paginationMode='server'
          onPaginationModelChange={(e) => {
            setQueryParams({ ...queryParams, page: e.page + 1 });
          }}
        />
      </>
    }
  </div>);
};

export default ItemsForSaleTab;
