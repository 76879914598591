import { Link } from 'react-router-dom';
import Card, { type ICardProps } from 'components/cards/Card';
import { useI18n } from 'hooks/usei18n';
import { useMemo } from 'react';

interface ILinkCardProps extends ICardProps {
  link: string
}

const LinkCard = ({
  link,
  title,
  imgSrc,
  icon,
  children,
  className,
  onIconClick,
  titleClassName,
  isCloudinaryImage
}: ILinkCardProps) => {
  const { getLanguageUrl } = useI18n();
  const languageLink = useMemo(
    () => getLanguageUrl(link),
    [link, getLanguageUrl]
  );

  return (
    <Link to={languageLink} className="link-card">
      <Card
        title={title}
        imgSrc={imgSrc}
        icon={icon}
        className={className}
        titleClassName={titleClassName}
        onIconClick={onIconClick}
        isCloudinaryImage={isCloudinaryImage}
      >
        {children}
      </Card>
    </Link>
  );
};

export default LinkCard;
