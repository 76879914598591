import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { type IArticle, type IArticleApiResponse } from 'types/IArticle';
import createHeaders from 'utils/createHeaders';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';

const ARTICLES_URL = 'articles';

export enum SortBy {
  Id = 'id',
  Title = 'title',
  Date = 'date'
}

export const blogApi = createApi({
  reducerPath: 'blog',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL
  }),
  endpoints: (builder) => ({
    getAllArticles: builder.query<IArticleApiResponse, {
      pageSize?: number
      page?: number
      title?: string
      order?: Order
      isPublic?: boolean
      sortBy?: SortBy
    }>({
      query: ({
        pageSize = DEFAULT_PAGE_SIZE,
        page = DEFAULT_PAGE,
        title,
        order = Order.DESC,
        isPublic,
        sortBy
      }) => {
        let queryString = `${ARTICLES_URL}?pageSize=${pageSize}&page=${page}&order=${order}`;

        if (title) {
          queryString += `&title=${encodeURIComponent(title)}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (isPublic) {
          queryString += `&isPublic=${isPublic}`;
        }

        return queryString;
      }
    }),
    getArticleById: builder.query<IArticle, {
      id: number
    }>({
      query: ({ id }) => `${ARTICLES_URL}/byId/${id}`
    }),
    getArticleByCanonicalUrl: builder.query<IArticle, {
      canonicalUrl: string
    }>({
      query: ({ canonicalUrl }) => `${ARTICLES_URL}/${canonicalUrl}`
    }),
    createArticle: builder.mutation<IArticle, any>({
      query: (newArticle) => {
        const headers = createHeaders();

        const formData = new FormData();

        formData.append('isIndexed', newArticle.isIndexed.toString());

        if (newArticle.canonicalUrl) {
          formData.append('canonicalUrl', newArticle.canonicalUrl);
        }
        if (newArticle.titleEn) {
          formData.append('titleEn', newArticle.titleEn);
        }
        if (newArticle.titleBg) {
          formData.append('titleBg', newArticle.titleBg);
        }
        if (newArticle.heading1En) {
          formData.append('heading1En', newArticle.heading1En);
        }
        if (newArticle.heading1Bg) {
          formData.append('heading1Bg', newArticle.heading1Bg);
        }
        if (newArticle.heading2En) {
          formData.append('heading2En', newArticle.heading2En);
        }
        if (newArticle.heading2Bg) {
          formData.append('heading2Bg', newArticle.heading2Bg);
        }
        if (newArticle.descriptionEn) {
          formData.append('descriptionEn', newArticle.descriptionEn);
        }
        if (newArticle.descriptionBg) {
          formData.append('descriptionBg', newArticle.descriptionBg);
        }

        formData.append('file', newArticle.file);

        if (newArticle.metaName && newArticle.metaContent && newArticle.metaContentEn) {
          for (let i = 0; i < newArticle.metaName.length; i++) {
            const metaTagName = newArticle.metaName[i];
            const metaTagContent = newArticle.metaContent[i];
            const metaTagContentEn = newArticle.metaContentEn[i];

            if (metaTagName && metaTagContent && metaTagContentEn) {
              formData.append(`metaTags[${i}][name]`, metaTagName);
              formData.append(`metaTags[${i}][content]`, metaTagContent);
              formData.append(`metaTags[${i}][contentEn]`, metaTagContentEn);
            }
          }
        }

        return {
          url: ARTICLES_URL,
          method: 'POST',
          body: formData,
          headers
        };
      }
    }),
    editArticle: builder.mutation<IArticle, {
      id: number
      editedArticle: {
        isIndexed: boolean
        canonicalUrl: string
        titleEn: string
        titleBg: string
        heading1En: string
        heading1Bg: string
        heading2En: string
        heading2Bg: string
        descriptionEn: string
        descriptionBg: string
        file: File | null
        metaTags: Array<{ name: string, content: string, contentEn: string }>
      }
    }>({
      query: ({ id, editedArticle }) => {
        const headers = createHeaders();

        const formData = new FormData();

        formData.append('isIndexed', editedArticle.isIndexed.toString());

        if (editedArticle.canonicalUrl) {
          formData.append('canonicalUrl', editedArticle.canonicalUrl);
        }
        if (editedArticle.titleEn) {
          formData.append('titleEn', editedArticle.titleEn);
        }
        if (editedArticle.titleBg) {
          formData.append('titleBg', editedArticle.titleBg);
        }
        if (editedArticle.heading1En) {
          formData.append('heading1En', editedArticle.heading1En);
        }
        if (editedArticle.heading1Bg) {
          formData.append('heading1Bg', editedArticle.heading1Bg);
        }
        if (editedArticle.heading2En) {
          formData.append('heading2En', editedArticle.heading2En);
        }
        if (editedArticle.heading2Bg) {
          formData.append('heading2Bg', editedArticle.heading2Bg);
        }
        if (editedArticle.descriptionEn) {
          formData.append('descriptionEn', editedArticle.descriptionEn);
        }
        if (editedArticle.descriptionBg) {
          formData.append('descriptionBg', editedArticle.descriptionBg);
        }

        if (editedArticle.file !== null) {
          formData.append('file', editedArticle.file);
        } else {
          formData.append('file', JSON.stringify([]));
        }

        if (editedArticle.metaTags) {
          for (let i = 0; i < editedArticle.metaTags.length; i++) {
            const metaTag = editedArticle.metaTags[i];

            if (metaTag.name && metaTag.content) {
              formData.append(`metaTags[${i}][name]`, metaTag.name);
              formData.append(`metaTags[${i}][content]`, metaTag.content);
              formData.append(`metaTags[${i}][contentEn]`, metaTag.contentEn);
            }
          }
        }

        return {
          url: `${ARTICLES_URL}/${id}`,
          method: 'PUT',
          body: formData,
          headers
        };
      }
    }),
    deleteArticle: builder.mutation<{
      success: boolean
    }, {
      id: number
    }>({
      query: ({ id }) => {
        const headers = createHeaders();

        return {
          url: `${ARTICLES_URL}/${id}`,
          method: 'DELETE',
          headers
        };
      }
    }),
    hideArticle: builder.mutation<{
      success: boolean
    }, {
      id: number
    }>({
      query: ({ id }) => {
        const headers = createHeaders();

        return {
          url: `${ARTICLES_URL}/${id}/hide`,
          method: 'PUT',
          headers,
          body: { id }
        };
      }
    }),
    publishArticle: builder.mutation<{
      success: boolean
    }, {
      id: number
    }>({
      query: ({ id }) => {
        const headers = createHeaders();

        return {
          url: `${ARTICLES_URL}/${id}/publish`,
          method: 'PUT',
          headers,
          body: { id }
        };
      }
    })
  })
});

export const {
  useGetAllArticlesQuery,
  useGetArticleByIdQuery,
  useGetArticleByCanonicalUrlQuery,
  useCreateArticleMutation,
  useDeleteArticleMutation,
  useHideArticleMutation,
  usePublishArticleMutation,
  useEditArticleMutation
} = blogApi;
