import { flatten, isEmpty, isNil, last } from 'lodash';
import { findCategoryByCanonicalUrl, findParentCategoryByIds, type ICategory } from 'types/ICategory';

enum Gender {
  boys = 'boys',
  girls = 'girls',
  twins = 'twins',
  unisex = 'unisex',
}

interface ProductsPathSegmentsInput {
  categories?: ICategory[]
  genders?: Gender[]
}

interface ProductsPathSegmentOutput {
  categories: ICategory[]
  genders: Gender[]
}

type nullString = string | null | undefined;

class ProductsService {
  categories: ICategory[];

  constructor (categories: ICategory[]) {
    this.categories = categories;
  }

  isGender (value: string): boolean {
    return Object.keys(Gender).includes(value);
  }

  getComponents (segment: string): string[] {
    return segment.split('--');
  }

  decostruct (...args: nullString[]): ProductsPathSegmentOutput {
    const parts = args
      .filter(x => !isNil(x) && !isEmpty(x))
      .map(x => this.getComponents(x as string));

    const genders = flatten(
      parts
        .filter(x => x.every(x => this.isGender(x)))
        .map(x => x.map(y => y as Gender))
    );

    const categories = flatten(
      [last(parts)]
        .map(x => x?.map(categoryName => findCategoryByCanonicalUrl(this.categories, categoryName)))
        .filter(x => x?.every(x => !isNil(x)))
        .map(x => x as ICategory[])
    );

    return {
      genders,
      categories
    };
  }

  getCategoriesParentsUrl (allCategories: ICategory[], categories: ICategory[] | undefined): string {
    if (isNil(categories) || isEmpty(categories)) {
      return '';
    }

    const parent = findParentCategoryByIds(allCategories, categories.map(x => Number(x.id)));

    const parts = [parent?.canonicalUrl];

    if (parent?.parentId) {
      parts.push(this.getCategoriesParentsUrl(allCategories, [parent]));
    }

    return parts
      .filter(x => !isNil(x) && !isEmpty(x))
      .reverse()
      .join('/');
  }

  getCategoriesUrl (categories: ICategory[] | undefined): string | null {
    if (isNil(categories) || isEmpty(categories)) {
      return null;
    }

    const parentsUrl = this.getCategoriesParentsUrl(this.categories, categories);

    const categoryCanonicalUrl = [...categories]
      .sort((a, b) => Number(a.id) - Number(b.id))
      .map(category => category.canonicalUrl).join('--');

    return [
      parentsUrl,
      categoryCanonicalUrl
    ]
      .filter(x => !isNil(x) && !isEmpty(x))
      .join('/');
  }

  constructUrl ({ categories, genders }: ProductsPathSegmentsInput): string {
    const genderParts = isNil(genders) || isEmpty(genders)
      ? null
      : [...genders]
          .sort((a, b) => a.localeCompare(b))
          .map(g => g.toString()).join('--');

    const categoryCanonicalUrl = this.getCategoriesUrl(categories);

    const parts = [
      genderParts,
      categoryCanonicalUrl
    ]
      .filter(x => !isNil(x));

    return parts.join('/');
  }
}

export default ProductsService;

export {
  Gender
};
