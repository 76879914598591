import React from 'react';
import { type IClassName } from 'types/common/Props';
import Profile from 'pages/profile/Profile';
import concatClassNames from 'utils/classNames';
import CreateNewProductForm from 'pages/create-new-product/CreateNewProductForm';
import './CreateNewProduct.scss';
import { useGetPageDetailsQuery } from '../../store/api/pagesApi';
import { ROUTES } from '../../routes/routes';
import PageSeoSection from '../../components/page-seo-section/PageSeoSection';

interface ICreateNewProduct extends IClassName {
}

const CreateNewProduct = ({ className }: ICreateNewProduct) => {
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.CreateNewProduct.path });

  return (
    <Profile className={concatClassNames(className, 'create-new-product')} containerClassName='create-new-product-container'>
      <PageSeoSection pageSeo={pageSeoData} />
      <CreateNewProductForm/>
    </Profile>
  );
};

export default CreateNewProduct;
