import { useCallback, useState } from 'react';

export type UseToggleReturnType = [boolean, () => void];

const useToggle = (initialState: boolean = false): UseToggleReturnType => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return [isOpen, toggle];
};

export default useToggle;
