import { useTranslation } from 'react-i18next';
import { ReactComponent as BabyOnTheWay } from 'assets/icons/badges/baby-on-the-way.svg';
import { ReactComponent as BabyOnTheWayBg } from 'assets/icons/badges/baby-on-the-way-bg.svg';
import { ReactComponent as BadgeDiscount } from 'assets/icons/badges/badge-discount.svg';
import { ReactComponent as BadgeDiscountBg } from 'assets/icons/badges/badge-discount-bg.svg';
import { ReactComponent as BadgeGoodAsNew } from 'assets/icons/badges/badge-good-as-new.svg';
import { ReactComponent as BadgeGoodAsNewBg } from 'assets/icons/badges/badge-good-as-new-bg.svg';
import { ReactComponent as BadgeHotDeal } from 'assets/icons/badges/badge-hot-deal.svg';
import { ReactComponent as BadgeHotDealBg } from 'assets/icons/badges/badge-hot-deal-bg.svg';
import concatClassNames from 'utils/classNames';
import { ProductBadge } from 'types/IProduct';
import { Language } from 'i18n';
import './Badges.scss';

interface IBadges {
  selectedBadge?: any
  onClick?: (badge: any) => void
}

const Badges = ({ selectedBadge, onClick }: IBadges) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const badges = [
    {
      id: ProductBadge.BabyOnTheWay,
      icon: currentLanguage === Language.BG ? <BabyOnTheWayBg/> : <BabyOnTheWay/>
    },
    {
      id: ProductBadge.HotDeal,
      icon: currentLanguage === Language.BG ? <BadgeHotDealBg/> : <BadgeHotDeal/>
    },
    {
      id: ProductBadge.GoodAsNew,
      icon: currentLanguage === Language.BG ? <BadgeGoodAsNewBg/> : <BadgeGoodAsNew/>
    },
    {
      id: ProductBadge.Discount,
      icon: currentLanguage === Language.BG ? <BadgeDiscountBg/> : <BadgeDiscount/>
    }
  ];

  return (
    <article className="flex badges">
      {badges.map((badge) => (
        <div
          className={concatClassNames('flex justify-center align-center badge', selectedBadge?.id === badge.id ? 'selected' : '')}
          key={badge.id}
          onClick={() => {
            if (onClick) {
              onClick(badge);
            }
          }}
        >
          {badge.icon}
        </div>
      ))}
    </article>
  );
};

export default Badges;
