import React from 'react';
import { useTranslation } from 'react-i18next';
import Filter from 'components/filter-menu/Filter';

interface ISizesFilterProps {
  sizes: any[]
  selectedSizes: number[]
  setSelectedSizes: any
}

const SizesFilter = ({ sizes, selectedSizes, setSelectedSizes }: ISizesFilterProps) => {
  const { t } = useTranslation();

  return (
    <Filter
      items={sizes}
      selectedItems={selectedSizes}
      setSelectedItems={setSelectedSizes}
      title={t('sizes.title')}
      className="generic-filters"
    />
  );
};

export default SizesFilter;
