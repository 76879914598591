import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import type {
  ICustomerApiResponse,
  ICustomerBankDetails,
  ICustomerDetailsApiResponse,
  ICustomerPersonalDataApiRequest,
  ICustomerPersonalDataApiResponse,
  ICustomerUpdateSellingOptionsApiRequest
} from 'types/ICustomer';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';

const CUSTOMERS_URL = 'customers';

export enum SortBy {
  Id = 'id',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  Email = 'email',
  UserName = 'username'
}

export const customerApi = createApi({
  reducerPath: 'customer',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    updateCustomerPersonalData: builder.mutation<ICustomerPersonalDataApiResponse, ICustomerPersonalDataApiRequest>({
      query: (personalData) => ({
        url: `${CUSTOMERS_URL}/personalData`,
        method: 'PUT',
        body: personalData
      })
    }),
    getAllCustomers: builder.query<ICustomerApiResponse, {
      page?: number
      pageSize?: number
      buyer?: string
      sortBy?: SortBy
      order?: Order
      email?: string
      id?: number
      username?: string
      phonenumber?: string
      bankaccountdetails?: string
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        buyer,
        sortBy,
        order = Order.DESC,
        email,
        id,
        username,
        phonenumber,
        bankaccountdetails
      }) => {
        let queryString = `${CUSTOMERS_URL}?pageSize=${pageSize}&page=${page}&order=${order}`;

        if (email) {
          queryString += `&email=${email}`;
        }

        if (id) {
          queryString += `&id=${id}`;
        }

        if (username) {
          queryString += `&username=${username}`;
        }

        if (phonenumber) {
          queryString += `&phonenumber=${encodeURIComponent(phonenumber)}`;
        }

        if (bankaccountdetails) {
          queryString += `&bankaccountdetails=${bankaccountdetails}`;
        }

        if (buyer) {
          queryString += `&buyer=${buyer}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        return queryString;
      }
    }),
    getCustomerDetails: builder.query<ICustomerDetailsApiResponse, void>({
      query: () => `${CUSTOMERS_URL}/details`
    }),
    updateCustomerBankDetails: builder.mutation<ICustomerBankDetails, ICustomerBankDetails>({
      query: (bankDetails) => ({
        url: `${CUSTOMERS_URL}/bankDetails`,
        method: 'PUT',
        body: bankDetails
      })
    }),
    updateCustomerSellingOptions: builder.mutation<ICustomerUpdateSellingOptionsApiRequest, ICustomerUpdateSellingOptionsApiRequest>({
      query: (sellingOptions) => ({
        url: `${CUSTOMERS_URL}/sellingOptions`,
        method: 'PUT',
        body: sellingOptions
      })
    })
  })
});

export const {
  useGetAllCustomersQuery,
  useUpdateCustomerPersonalDataMutation,
  useGetCustomerDetailsQuery,
  useUpdateCustomerBankDetailsMutation,
  useUpdateCustomerSellingOptionsMutation
} = customerApi;
