import { useTranslation } from 'react-i18next';
import { Button, ButtonType } from 'components/button/Button';
import { logout } from 'utils/auth';
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import { useI18n } from 'hooks/usei18n';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/routes';
import { useCallback } from 'react';

interface ILogoutButtonProps extends IOptionalClassName {
}

const LogoutButton = ({ className }: ILogoutButtonProps) => {
  const { t } = useTranslation();
  const { getLanguageUrl } = useI18n();
  const navigate = useNavigate();

  const handleLogoutClick = useCallback(
    () => {
      logout();

      navigate(getLanguageUrl(ROUTES.Home.path));
    },
    [navigate, getLanguageUrl]
  );

  return (
    <Button
      className={concatClassNames('flex justify-center align-center logout-btn', className)}
      type={ButtonType.plain}
      onClick={handleLogoutClick}
    >
      <ExitIcon />
      <span>{t('header.logout')}</span>
    </Button>
  );
};

export default LogoutButton;
