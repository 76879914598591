import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonState } from 'components/button/Button';
import Popup, { type IPopup } from 'components/popup/Popup';
import PaidAdCard from 'components/cards/paid-ad-card/PaidAdCard';
import { ReactComponent as Carousel } from 'assets/icons/carousel-icon.svg';
import { ReactComponent as Badge } from 'assets/icons/badge.svg';
import { ReactComponent as AllBadges } from 'assets/icons/all-badges.svg';
import { ReactComponent as BadgeAndHomePage } from 'assets/icons/badge-and-home-page.svg';
import Tooltip from 'components/tooltip/Tooltip';
import { useGetPaidAdsQuery, usePayForAdMutation } from 'store/api/paidAdsApi';
import { type IPaidAd } from 'types/IPaidAd';
import Badges from 'components/common/badges/Badges';
import './PaidAdsPopup.scss';

interface IPaidAdsPopup extends IPopup {
  preselectedPaidAdId?: number
}

const APPLY_BADGE_FOR_ONE_PRODUCT_ID = 2;
const ACCESS_TO_ALL_BADGES_FOR_SEVEN_DAYS_ID = 3;

const PaidAdsPopup = ({ open, onClose, preselectedPaidAdId }: IPaidAdsPopup) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { data: paidAds } = useGetPaidAdsQuery({});
  const [payForAd] = usePayForAdMutation();

  const [selectedPaidAd, setSelectedPaidAd] = useState<IPaidAd | null>(null);

  const localizedPaidAds = paidAds?.map(ad => ({
    ...ad,
    description: t(`paidAds.${ad.id}`)
  }));

  const text = (
    <span>
    {t('paidAds.paragraph1')}
      {t('paidAds.read')}
      <a href="#" target="_blank" rel="noopener noreferrer" className="subscription-article">
      {t('paidAds.blogArticle')}
    </a>
      {t('paidAds.paragraph2')}
  </span>
  );

  const handlePaidAdClick = (paidAd: IPaidAd) => {
    setSelectedPaidAd(paidAd);

    setTimeout(() => {
      const payButton = document.querySelector('.paid-ads-popup-cta');
      if (payButton) {
        payButton.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  useEffect(() => {
    if (preselectedPaidAdId) {
      const preselectedPaidAd = localizedPaidAds?.find((paidAd) => paidAd.id === preselectedPaidAdId);

      if (preselectedPaidAd) {
        setSelectedPaidAd(preselectedPaidAd);
      }
    }
  }, [preselectedPaidAdId, paidAds]);

  const icons = {
    1: <Carousel/>,
    2: <Badge/>,
    3: <AllBadges/>,
    4: <BadgeAndHomePage/>
  };

  const mappedPaidAds = localizedPaidAds?.map((ad) => ({
    ...ad,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    icon: icons[ad.id]
  }));

  const handlePayment = async () => {
    try {
      if (!selectedPaidAd) {
        return;
      }

      const response: any = await payForAd({
        id: selectedPaidAd.id,
        locale: currentLanguage
      });

      if (response.data) {
        window.location.href = response.data;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const renderCta = () => {
    const noSelectedSubscriptionText = t('paidAds.noSelectedSubscriptionText');
    const shouldDisableButton = selectedPaidAd === null;
    const button = (
      <Button
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onClick={handlePayment}
        text={t('common.pay')}
        className="paid-ads-popup-cta"
        state={shouldDisableButton ? ButtonState.disabled : ButtonState.enabled}
      />
    );

    if (!shouldDisableButton) {
      return button;
    } else {
      return (
        <Tooltip title={noSelectedSubscriptionText} arrow>
          <div className="full-width text-center">
            {button}
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <Popup
      title={t('paidAds.title')}
      text={text}
      className="paid-ads-popup"
      open={open}
      onClose={onClose}>
      <div className="flex flex-column justify-center align-center">
        <div className="paid-ads-popup-content">
          {mappedPaidAds?.map(paidAd => (
            <PaidAdCard
              key={paidAd.id}
              className={selectedPaidAd?.id === paidAd.id ? 'selected' : ''}
              onClick={() => {
                handlePaidAdClick(paidAd);
              }}
              {...paidAd}
            />))}
        </div>
        {(selectedPaidAd?.id === APPLY_BADGE_FOR_ONE_PRODUCT_ID || selectedPaidAd?.id === ACCESS_TO_ALL_BADGES_FOR_SEVEN_DAYS_ID) && (
          <div className="paid-ads-popup-promo-badges">
            <h3 className="text-center paid-ads-popup-promo-badges-title">
              {t('paidAds.ourPromoBadges')}
            </h3>
            <Badges/>
          </div>
        )}
        {renderCta()}
      </div>
    </Popup>
  );
};

export default PaidAdsPopup;
