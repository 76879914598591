import { Cloudinary } from '@cloudinary/url-gen';

export const SUPPORTED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'webp', 'bmp', 'heif', 'heic'];

export const ALLOWED_IMAGE_SIZE = 1024 * 1024 * 10;

const Cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
  }
});

export default Cld;
