import React, { createContext, useContext, useMemo, useState } from 'react';
import { Snackbar } from '@mui/material';
import './Notification.scss';

const NotificationContext = createContext({
  showNotification: (msg: string) => {
  }
});

const useNotification = () => useContext(NotificationContext);

interface INotificationProviderProps {
  children: React.ReactNode
}

const NotificationProvider = ({ children }: INotificationProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const showNotification = useMemo(
    () => (msg: string) => {
      setMessage(msg);
      setOpen(true);
    },
    [setMessage, setOpen]);

  const closeNotification = () => {
    setOpen(false);
  };

  const value = useMemo(
    () => ({ showNotification }),
    [showNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Snackbar
        className="notification"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        message={message}
        autoHideDuration={3000}
        onClose={closeNotification}
      />
    </NotificationContext.Provider>
  );
};

export {
  NotificationProvider,
  useNotification
};
