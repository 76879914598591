import { useCallback, useMemo, useState } from 'react';
import {
  useDeleteArticleMutation,
  useEditArticleMutation,
  useHideArticleMutation,
  usePublishArticleMutation
} from 'store/api/blogApi';

export enum ArticleAction {
  Publish = 'publish',
  Hide = 'hide',
  Delete = 'delete',
  Edit = 'edit'
}

interface Status {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  message: string
}

interface ArticleActionsHook {
  status: Status
  handleAction: (action: ArticleAction, id: number, successMessage: string) => Promise<void>
}

const useArticleActions = (): ArticleActionsHook => {
  const [publishArticle] = usePublishArticleMutation();
  const [hideArticle] = useHideArticleMutation();
  const [deleteArticle] = useDeleteArticleMutation();
  const [editArticle] = useEditArticleMutation();
  const [status, setStatus] = useState<Status>({ isLoading: false, isError: false, isSuccess: false, message: '' });

  const actionHandlers = useMemo(() => ({
    [ArticleAction.Publish]: publishArticle,
    [ArticleAction.Hide]: hideArticle,
    [ArticleAction.Delete]: deleteArticle,
    [ArticleAction.Edit]: editArticle
  }), [publishArticle, hideArticle, deleteArticle]);

  const handleAction = useCallback(async (action: ArticleAction, id: number, successMessage: string, articleData?: any) => {
    setStatus({ isLoading: true, isError: false, isSuccess: false, message: '' });
    try {
      if (action === ArticleAction.Edit) {
        if (!articleData) throw new Error('Article data is required for editing');
        await actionHandlers[action]({ id, editedArticle: articleData }).unwrap();
      } else {
        await actionHandlers[action]({ id }).unwrap();
      }
      setStatus({ isLoading: false, isError: false, isSuccess: true, message: successMessage });
    } catch (error) {
      console.error(error);
      setStatus({ isLoading: false, isError: true, isSuccess: false, message: 'An error occurred. Please try again.' });
    }
  }, [actionHandlers]);

  return { status, handleAction };
};

export default useArticleActions;
