import { type ReactElement } from 'react';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material';
import { type IOptionalClassName } from 'types/common/Props';

export enum TooltipPlacement {
  BottomEnd = 'bottom-end',
  BottomStart = 'bottom-start',
  Bottom = 'bottom',
  LeftEnd = 'left-end',
  LeftStart = 'left-start',
  Left = 'left',
  RightEnd = 'right-end',
  RightStart = 'right-start',
  Right = 'right',
  TopEnd = 'top-end',
  TopStart = 'top-start',
  Top = 'top'
}

interface ITooltipProps extends IOptionalClassName {
  title: string
  children: ReactElement
  placement?: TooltipPlacement
  arrow?: boolean
}

const Tooltip = styled(({ className, ...props }: ITooltipProps) => (
  <MuiTooltip {...props} enterTouchDelay={0} classes={{ popper: className }}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E18F91',
    color: 'white',
    padding: '5px 10px',
    textAlign: 'center',
    fontSize: 12
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#E18F91'
  }
}));
export default Tooltip;
