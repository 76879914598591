import React, { type ReactNode } from 'react';
import Box from '@mui/material/Box';

interface ITabPanelProps {
  children: ReactNode
  index: any
  value: any
}

const TabPanel = ({ value, index, children }: ITabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
