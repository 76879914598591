import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type SerializedError } from '@reduxjs/toolkit';
import { SortBy, useDeleteReviewMutation, useGetReviewsQuery, useReportReviewsMutation } from 'store/api/reviewApi';
import { DataGrid, type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from 'components/button/Button';
import { useNotification } from 'components/notification/Notification';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import { FormControl as MuiFormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import './AdminReviewsTab.scss';

interface IReviewSearchParams {
  sellerUsername?: string
  from?: string
  to?: string
  pageSize?: number
  page?: number
}

enum ReviewFilterFields {
  SellerUsername = 'sellerUsername',
  FromDate = 'from',
  ToDate = 'to'
}

const AdminReviewsTab = () => {
  const { t } = useTranslation();

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.Rating, name: t('common.sort.rating') },
    { value: SortBy.Comment, name: t('common.sort.comment') }
  ];

  const [queryParams, setQueryParams] = useState<{
    page: number
    order: Order
    sortBy?: SortBy
  }>({
    page: DEFAULT_PAGE,
    order: orderByDropdownOptions[0].value
  });
  const { showNotification } = useNotification();
  const [reviewsSearchParam, setReviewsSearchParam] = useState<IReviewSearchParams>(queryParams);
  const { data, error, isLoading, refetch } = useGetReviewsQuery(reviewsSearchParam);
  const [reportReviews] = useReportReviewsMutation();
  const [deleteReview] = useDeleteReviewMutation();

  useEffect(() => {
    setReviewsSearchParam(prevState => ({ ...prevState, ...queryParams }));

    void refetch();
  }, [queryParams]);

  const handleFilterChange = (value: any, field: string) => {
    let formattedValue = value;
    if (field === ReviewFilterFields.FromDate || field === ReviewFilterFields.ToDate) {
      formattedValue = `${dayjs(value).format(DEFAULT_DATE_FORMAT)}Z`;
    }
    const reviewSearchParam = { [field]: formattedValue };
    setReviewsSearchParam({ ...reviewsSearchParam, ...reviewSearchParam });
  };

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setQueryParams(paramsObj);
    if (sortByOption.value === SortBy.Id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setQueryParams(prevState => ({ ...prevState, sortBy: null }));
    } else {
      setQueryParams(prevState => ({ ...prevState, sortBy: sortByOption.value }));
    }
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  const debounceFilterChange = debounce(handleFilterChange, 1000);

  const onDelete = async (id: number) => {
    try {
      const response: any = await deleteReview({ id });

      if (response.data === null) {
        await refetch();
        showNotification(t('administration.reviews.deletedSuccessfully'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dataColumns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 110, align: 'center', headerAlign: 'center' },
    {
      field: 'comment',
      headerName: t('administration.reviews.comment'),
      width: 180,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'rating',
      headerName: t('administration.reviews.rating'),
      width: 120,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'reviewerDisplayName',
      headerName: t('administration.reviews.reviewerUsername'),
      width: 180,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'sellerUsername',
      headerName: t('administration.reviews.sellerUsername'),
      width: 180,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'deleteRow',
      align: 'center',
      headerAlign: 'center',
      headerName: t('administration.reviews.deleteRow'),
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        return <Button onClick={async () => {
          await onDelete(params.row.id);
        }}>
          {t('administration.common.delete')}
        </Button>;
      }
    }
  ];

  if (error) {
    return (<div>{(error as SerializedError).message}</div>);
  }

  return (<div>
    {isLoading
      ? <div>{t('common.loading')}</div>
      : <>
          <div className="reviews-sort-fields">
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.sortBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams?.sortBy || sortByDropdownOptions[0].value}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onSortByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {sortByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
            <MuiFormControl variant="outlined" className="sort-container">
              <InputLabel id="sort-by-label">
                {t('common.sort.orderBy')}
              </InputLabel>
              <Select
                sx={{ width: '180px' }}
                value={queryParams.order}
                label={t('administration.payments.invoices.deliveryType')}
                onChange={(e: any) => {
                  onOrderByDropdownChange(e);
                }}
                className='dropdown-select'
              >
                {orderByDropdownOptions.map((option, idx) => (
                  <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                    <ListItemText primary={option.name}/>
                  </MenuItem>
                ))}
              </Select>
            </MuiFormControl>
          </div>
          <div className="reviews-filter">
            <FormControl
              name={ReviewFilterFields.SellerUsername}
              value={reviewsSearchParam[ReviewFilterFields.SellerUsername] as any}
              labelText={t('administration.reviews.sellerUsername')}
              error={' '}
              shouldUpdateValue
              type={FormControlType.text}
              onChange={(value) => {
                debounceFilterChange(value, ReviewFilterFields.SellerUsername);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '180px' }}
                label={t('administration.common.fromDate')}
                onChange={(e: any) => {
                  debounceFilterChange(e, ReviewFilterFields.FromDate);
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '180px' }}
                label={t('administration.common.toDate')}
                onChange={(e: any) => {
                  debounceFilterChange(e, ReviewFilterFields.ToDate);
                }}
              />
            </LocalizationProvider>
            <Button className='orders-export-button' onClick={() => {
              // required lines here, else linter goes crazy
              reportReviews({ ...reviewsSearchParam })
                .then(() => {
                })
                .catch(() => {
                });
            }}>
              {t('administration.common.export')}
            </Button>
          </div>
          <DataGrid
            rows={data?.models ?? []}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
              }
            }}
            pageSizeOptions={[DEFAULT_PAGE_SIZE]}
            rowCount={data?.totalCount ?? 0}
            disableColumnFilter
            disableRowSelectionOnClick
            paginationMode='server'
            onPaginationModelChange={(e) => {
              setReviewsSearchParam({ ...reviewsSearchParam, page: e.page + 1 });
            }}
          />
        </>
    }
  </div>);
};

export default AdminReviewsTab;
