import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import { ButtonType } from 'components/button/Button';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import SectionCard from 'components/cards/section-card/SectionCard';
import { useChangeUsernameMutation } from 'store/api/userApi';
import { useUpdateCustomerPersonalDataMutation } from 'store/api/customerApi';
import useForm from 'hooks/useForm';
import { validateCustomerFields } from 'utils/validators/customerValidator';
import { useCustomerDetails } from 'pages/profile/Profile';
import RadioButtons from 'components/radio-buttons/RadioButtons';

enum DisplayNamePreference {
  Username = 'username',
  FullName = 'fullName'
}

enum PersonalDataFields {
  Email = 'email',
  Username = 'username',
  FirstName = 'firstName',
  MiddleName = 'middleName',
  LastName = 'lastName',
  PhoneNumber = 'phoneNumber',
  DisplayName = 'displayName'
}

const initialPersonalDataValues = {
  [PersonalDataFields.Email]: '',
  [PersonalDataFields.Username]: '',
  [PersonalDataFields.FirstName]: '',
  [PersonalDataFields.MiddleName]: '',
  [PersonalDataFields.LastName]: '',
  [PersonalDataFields.PhoneNumber]: '',
  [PersonalDataFields.DisplayName]: ''
};

const ProfilePersonalData = () => {
  const { t } = useTranslation();
  const { customerDetails, refetch } = useCustomerDetails();
  const [updateCustomer] = useUpdateCustomerPersonalDataMutation();
  const [changeUsername] = useChangeUsernameMutation();

  const displayNameOptions = [
    { value: DisplayNamePreference.Username, label: t('profile.settings.showUsername') },
    { value: DisplayNamePreference.FullName, label: t('profile.settings.showFullName') }
  ];

  const onSubmit = async (values: any) => {
    if (customerDetails?.username.toLowerCase() !== values.username.toLowerCase()) {
      const response: any = await changeUsername(values.username);

      if (response.error) {
        throw new Error(t('register.usernameAlreadyExists', { username: values.username }));
      }
    }

    const response: any = await updateCustomer({
      ...values,
      displayName: values.displayName === DisplayNamePreference.Username
        ? values.username
        : values.firstName + ' ' + values.lastName
    });

    if (response.error) {
      throw new Error(t('errors.anErrorOccurred'));
    }

    await refetch();
  };

  const {
    values: personalDataValues,
    setValues: setPersonalDataValues,
    handleChange: handlePersonalDataChange,
    handleSubmit: handlePersonalDataSubmit,
    errors: personalDataErrors
  } = useForm(
    initialPersonalDataValues,
    onSubmit,
    validateCustomerFields,
    t('profile.settings.personalDataChangeSuccess'),
    false);

  useEffect(() => {
    if (customerDetails) {
      setPersonalDataValues({
        [PersonalDataFields.Email]: customerDetails?.email ?? '',
        [PersonalDataFields.Username]: customerDetails?.username ?? '',
        [PersonalDataFields.FirstName]: customerDetails?.fullName?.firstName ?? '',
        [PersonalDataFields.MiddleName]: customerDetails?.fullName?.middleName ?? '',
        [PersonalDataFields.LastName]: customerDetails?.fullName?.lastName ?? '',
        [PersonalDataFields.PhoneNumber]: customerDetails?.phoneNumber.number ?? '',
        [PersonalDataFields.DisplayName]: customerDetails?.displayName === customerDetails?.username
          ? DisplayNamePreference.Username
          : DisplayNamePreference.FullName
      });
    }
  }, [customerDetails, setPersonalDataValues]);

  return (
    <SectionCard title={t('profile.settings.personalDataTitle')}>
      <Form
        onSubmit={handlePersonalDataSubmit}
        submitText={t('profile.settings.save')}
        submitButtonType={ButtonType.secondary}
        className="flex flex-column profile-section-form"
        submitButtonClassName="profile-section-form-submit-button"
      >
        <FormControl
          name={PersonalDataFields.Email}
          value={personalDataValues.email}
          shouldUpdateValue
          labelText={t('profile.settings.email')}
          type={FormControlType.text}
          readOnly
        />
        <FormControl
          name={PersonalDataFields.Username}
          value={personalDataValues.username}
          error={personalDataErrors.username}
          shouldUpdateValue
          labelText={t('profile.settings.username')}
          type={FormControlType.text}
          onChange={(value) => {
            handlePersonalDataChange(PersonalDataFields.Username, value.trim());
          }}
          {...(customerDetails?.username && { isEditable: true })}
        />
        <FormControl
          name={PersonalDataFields.FirstName}
          value={personalDataValues.firstName}
          labelText={t('profile.settings.firstName')}
          error={personalDataErrors.firstName}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handlePersonalDataChange(PersonalDataFields.FirstName, value.trim());
          }}
          {...(customerDetails?.fullName?.firstName && { isEditable: true })}
        />
        <FormControl
          name={PersonalDataFields.MiddleName}
          value={personalDataValues.middleName}
          labelText={t('profile.settings.middleName')}
          error={personalDataErrors.middleName}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handlePersonalDataChange(PersonalDataFields.MiddleName, value.trim());
          }}
          {...(customerDetails?.fullName?.middleName && { isEditable: true })}
        />
        <FormControl
          name={PersonalDataFields.LastName}
          value={personalDataValues.lastName}
          labelText={t('profile.settings.lastName')}
          error={personalDataErrors.lastName}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handlePersonalDataChange(PersonalDataFields.LastName, value.trim());
          }}
          {...(customerDetails?.fullName?.lastName && { isEditable: true })}
        />
        <FormControl
          name={PersonalDataFields.PhoneNumber}
          value={personalDataValues.phoneNumber}
          labelText={t('profile.settings.phoneNumber')}
          error={personalDataErrors.phoneNumber}
          shouldUpdateValue
          onChange={(value) => {
            handlePersonalDataChange(PersonalDataFields.PhoneNumber, value.trim());
          }}
          {...(customerDetails?.phoneNumber?.number && { isEditable: true })}
        />
        <RadioButtons
          options={displayNameOptions}
          value={personalDataValues[PersonalDataFields.DisplayName]}
          onValueChange={(newValue) => {
            handlePersonalDataChange(PersonalDataFields.DisplayName, newValue);
          }}
        />
      </Form>
    </SectionCard>
  );
};

export default ProfilePersonalData;
