import { type ReactNode } from 'react';
import { Button, ButtonColors, ButtonType } from 'components/button/Button';
import concatClassNames from 'utils/classNames';
import './SocialLoginButton.scss';

interface ISocialLoginButtonProps {
  icon: ReactNode
  label: string
  onClick?: any
  className?: string
}

const SocialLoginButton = ({ icon, label, onClick, className }: ISocialLoginButtonProps) => (
  <Button
    icon={icon}
    type={ButtonType.secondary}
    color={ButtonColors.secondary}
    onClick={onClick}
    className={concatClassNames('flex justify-center align-center social-login-button', className)}
  >
    {label}
  </Button>
);

export default SocialLoginButton;
