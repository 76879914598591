import { type FormEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type IFormControlOnChangeValueType } from 'components/forms/FormControl';
import { useNotification } from 'components/notification/Notification';

export type Value = string | number | boolean | Value[] | { [key: string]: Value } | any[] | null;
export type FormValues = Record<string, Value>;
export type FormErrors = Record<string, string> | any;

type OnSubmit<FormValues> = (values: FormValues) => Promise<void>;

const useForm = <T extends FormValues>(
  initialValues: T,
  onSubmit: OnSubmit<T>,
  validate?: (values: T) => FormErrors,
  notificationMessage?: string,
  shouldResetFormOnSubmit: boolean = true
) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<FormErrors>({});

  const localisedNotificationMessage = !notificationMessage
    ? t('notifications.formSubmittedSuccessfully')
    : notificationMessage;

  const handleChange = (name: string, value: IFormControlOnChangeValueType) => {
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);

    if (validate) {
      const validationErrors = validate(updatedValues);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name] || ''
      }));
    }
  };

  const resetForm = () => {
    setErrors({});
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (typeof validate !== 'function') {
      console.error('Validation function is not provided');
      return;
    }

    const validationErrors = validate(values);
    if (Object.keys(validationErrors).length > 0) {
      showNotification(t('notifications.pleaseFillInAllFields'));
      setErrors(validationErrors);
      return;
    }

    try {
      await onSubmit(values);
      showNotification(localisedNotificationMessage);

      if (shouldResetFormOnSubmit) {
        resetForm();
      }
    } catch (error: any) {
      handleSubmissionError(error);
    }
  };

  const handleSubmissionError = (error: any) => {
    if (error?.response?.data?.errors) {
      const backendErrors = error.response.data.errors;
      const formattedErrors = Object.keys(backendErrors).reduce((acc, key) => ({
        ...acc,
        [key]: backendErrors[key][0]
      }), {});

      setErrors(formattedErrors);
      showNotification(t('notifications.anErrorOccurredDuringSubmission'));
    } else {
      console.error('Submission error:', error);
      showNotification(error.toString());
    }
  };

  return {
    values,
    setValues: useCallback((newValues: T) => {
      setValues(newValues);
    }, []),
    handleChange,
    handleSubmit,
    errors
  };
};

export default useForm;
