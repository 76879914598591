const collectIds = (obj: any): number[] => {
  let ids: number[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If the object has an 'id' key, add it to the array.
      // eslint-disable-next-line no-prototype-builtins
      if (obj[key].hasOwnProperty('id')) {
        ids.push(obj[key].id);
      }
      // Recursively collect ids from the nested objects
      ids = ids.concat(collectIds(obj[key]));
    }
  }

  return ids.filter(id => !isNaN(id) && isFinite(id));
};

const findMainCategory = (categories: any, subcategoryId: number): number | null => {
  for (const category of categories) {
    if (category.id === subcategoryId) {
      return category.id; // Found the subcategory, return the main category ID
    }

    // If subcategories exist, recursively search through them
    if (category?.subcategories?.length > 0) {
      const result = findMainCategory(category.subcategories, subcategoryId);
      if (result !== null) {
        return category.id; // Return the main category ID when the subcategory is found in recursion
      }
    }
  }

  return null; // Subcategory ID not found in the categories list
};

const localizeCategoryName = (category: string) => `categories.${category.toLowerCase().split(' ').join('')}`;

const localizeSize = (size: string) => `sizes.${size?.toLowerCase().split(/[ :]/).join('')}`;

export {
  collectIds,
  findMainCategory,
  localizeCategoryName,
  localizeSize
};
