import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import Popup, { type IPopup } from 'components/popup/Popup';
import { useResetPasswordMutation } from 'store/api/userApi';
import { useNotification } from 'components/notification/Notification';
import useForm from 'hooks/useForm';
import { resetPasswordValidator } from 'utils/validators/authValidator';
import './ResetPasswordPopup.scss';

const FIELD_NAMES = {
  Password: 'password',
  ConfirmPassword: 'confirmPassword'
};

const initialValues = {
  [FIELD_NAMES.Password]: '',
  [FIELD_NAMES.ConfirmPassword]: ''
};

interface IResetPassword extends IPopup {
  email: string
  token: string
}

const ResetPasswordPopup = ({
  open,
  onClose,
  email,
  token,
  ...otherProps
}: IResetPassword) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [resetPassword] = useResetPasswordMutation();

  const onSubmit = async (values: any) => {
    try {
      const response: any = await resetPassword({
        email,
        newPassword: values.password,
        token
      });

      if (response.error) {
        showNotification(response.error.data);
      } else {
        showNotification(t('resetPassword.success'));
        onClose();
      }
    } catch (e) {
      showNotification(t('resetPassword.error'));
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors
  } = useForm(initialValues, onSubmit, resetPasswordValidator);

  if (!open) {
    return null;
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={t('resetPassword.title')}
      className="reset-password-popup"
    >
      <Form
        onSubmit={handleSubmit}
        submitText={t('resetPassword.send')}
        className="flex flex-column justify-center align-center reset-password-popup-form full-width"
        submitButtonClassName="reset-password-popup-form-submit-button"
      >
        <FormControl
          name={FIELD_NAMES.Password}
          value={values.password}
          error={errors.password}
          labelText={t('resetPassword.password')}
          type={FormControlType.password}
          onChange={(value) => {
            handleChange(FIELD_NAMES.Password, value);
          }}
          className="reset-password-popup-form-control"
          labelClassName="reset-password-popup-form-control-label"
        />
        <FormControl
          name={FIELD_NAMES.ConfirmPassword}
          value={values.confirmPassword}
          error={errors.confirmPassword}
          labelText={t('resetPassword.confirmPassword')}
          type={FormControlType.password}
          onChange={(value) => {
            handleChange(FIELD_NAMES.ConfirmPassword, value);
          }}
          className="reset-password-popup-form-control"
          labelClassName="reset-password-popup-form-control-label"
        />
      </Form>
    </Popup>
  );
};

export default ResetPasswordPopup;
