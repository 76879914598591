import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import { ButtonType } from 'components/button/Button';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import SectionCard from 'components/cards/section-card/SectionCard';
import { useChangePasswordMutation } from 'store/api/userApi';
import useForm from 'hooks/useForm';
import { validateCustomerPasswordFields } from 'utils/validators/customerValidator';
import { logout } from '../../utils/auth';

enum PasswordFields {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  ConfirmNewPassword = 'confirmNewPassword',
}

const initialPasswordValues = {
  [PasswordFields.CurrentPassword]: '',
  [PasswordFields.NewPassword]: '',
  [PasswordFields.ConfirmNewPassword]: ''
};

const ProfileChangePassword = () => {
  const { t } = useTranslation();
  const [changePassword] = useChangePasswordMutation();

  const onSubmit = async (values: any) => {
    const res: any = await changePassword(values);

    if (res.error) {
      const firstErrorKey = Object.keys(res.error.data.errors)[0];
      const firstErrorMessage = res.error.data.errors[firstErrorKey][0];

      throw new Error(firstErrorMessage);
    } else {
      logout();
    }
  };

  const {
    values: passwordValues,
    handleChange: handlePasswordChange,
    handleSubmit: handlePasswordSubmit,
    errors: passwordErrors
  } = useForm(
    initialPasswordValues,
    onSubmit,
    validateCustomerPasswordFields);

  return (
    <SectionCard title={t('profile.settings.changePasswordTitle')}>
      <Form
        onSubmit={handlePasswordSubmit}
        submitText={t('profile.settings.save')}
        className="flex flex-column profile-section-form"
        submitButtonType={ButtonType.secondary}
        submitButtonClassName="profile-section-form-submit-button"
      >
        <FormControl
          name={PasswordFields.CurrentPassword}
          value={passwordValues.currentPassword}
          labelText={t('profile.settings.oldPassword')}
          error={passwordErrors.currentPassword}
          type={FormControlType.password}
          onChange={(value) => {
            handlePasswordChange(PasswordFields.CurrentPassword, value.trim());
          }}
        />
        <FormControl
          name={PasswordFields.NewPassword}
          value={passwordValues.newPassword}
          labelText={t('profile.settings.newPassword')}
          error={passwordErrors.newPassword}
          type={FormControlType.password}
          onChange={(value) => {
            handlePasswordChange(PasswordFields.NewPassword, value.trim());
          }}
        />
        <FormControl
          name={PasswordFields.ConfirmNewPassword}
          value={passwordValues.confirmNewPassword}
          labelText={t('profile.settings.confirmNewPassword')}
          error={passwordErrors.confirmNewPassword}
          type={FormControlType.password}
          onChange={(value) => {
            handlePasswordChange(PasswordFields.ConfirmNewPassword, value.trim());
          }}
        />
      </Form>
    </SectionCard>
  );
};

export default ProfileChangePassword;
