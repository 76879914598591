import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import FormControl, { FormControlType, FormControlVariant } from 'components/forms/FormControl';
import Label, { LabelColor } from 'components/label/Label';
import PhotoUploader from 'components/photo-uploader/PhotoUploader';
import RadioButtons from 'components/radio-buttons/RadioButtons';
import GenderDropdown from 'components/common/dropdowns/GenderDropdown';
import useDropdown from 'hooks/useDropdown';
import CategoryDropdown from 'components/common/dropdowns/CategoryDropdown';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/photo-plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ProductLabel } from 'types/IProduct';
import useForm from 'hooks/useForm';
import { PRODUCT_IMAGES_COUNT } from 'utils/constants';
import { validateProductFields } from 'utils/validators/productValidator';
import { useCreateProductMutation } from 'store/api/productApi';
import IconButton from '@mui/material/IconButton';
import { useGetAllCategoriesQuery } from '../../store/api/categoiesApi';
import Tooltip from 'components/tooltip/Tooltip';
import { Button, ButtonState, ButtonType } from 'components/button/Button';
import { redirectTo, ROUTES } from 'routes/routes';
import SizesDropdown from 'components/common/dropdowns/SizesDropdown';
import { useCustomerDetails } from 'pages/profile/Profile';
import { useNotification } from 'components/notification/Notification';
import { useNavigate } from 'react-router-dom';
import Popup from '../../components/popup/Popup';
import './CreateNewProduct.scss';
import { urlize } from 'utils/text';

enum CreateNewProductFormFields {
  NameEnglish = 'nameEnglish',
  NameBulgarian = 'nameBulgarian',
  DescriptionEnglish = 'descriptionEnglish',
  DescriptionBulgarian = 'descriptionBulgarian',
  IsGift = 'isGift',
  Price = 'price',
  Weight = 'weight',
  Label = 'label',
  GendersIds = 'gendersIds',
  CategoryId = 'categoryId',
  SubCategoryId = 'subCategoryId',
  NestedSubCategoryId = 'nestedSubCategoryId',
  InventoryItems = 'inventoryItems',
  Quantity = 'quantity',
  SizeId = 'sizeId'
}

const initialInventoryItem = {
  [CreateNewProductFormFields.Quantity]: 0,
  [CreateNewProductFormFields.SizeId]: 0
};

const initialFormValues = {
  [CreateNewProductFormFields.NameEnglish]: '',
  [CreateNewProductFormFields.NameBulgarian]: '',
  [CreateNewProductFormFields.IsGift]: false,
  [CreateNewProductFormFields.Price]: 0,
  [CreateNewProductFormFields.Weight]: 0.1,
  [CreateNewProductFormFields.DescriptionEnglish]: '',
  [CreateNewProductFormFields.DescriptionBulgarian]: '',
  [CreateNewProductFormFields.Label]: ProductLabel.Used,
  [CreateNewProductFormFields.GendersIds]: [],
  [CreateNewProductFormFields.CategoryId]: '',
  [CreateNewProductFormFields.SubCategoryId]: '',
  [CreateNewProductFormFields.NestedSubCategoryId]: '',
  [CreateNewProductFormFields.InventoryItems]: [initialInventoryItem]
};

const CreateNewProductForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { customerDetails } = useCustomerDetails();
  const { data: categories } = useGetAllCategoriesQuery();
  const [createProduct, { isLoading: isSubmitting }] = useCreateProductMutation();
  const [inventoryItems, setInventoryItems] = useState(initialFormValues[CreateNewProductFormFields.InventoryItems]);

  const [images, setImages] = useState<File[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(null);
  const [nestedSubCategories, setNestedSubCategories] = useState<any>([]);
  const [selectedNestedSubCategory, setSelectedNestedSubCategory] = useState<number | null>(null);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);

  const navigateToBankDetails = () => {
    navigate(ROUTES.DeliveryAndPayment.path);
  };

  const handleTextAreaFocus = () => {
    setIsTextAreaFocused(true);
  };

  const handleTextAreaBlur = () => {
    setIsTextAreaFocused(false);
  };

  const handleNameFieldFocus = () => {
    setIsNameFieldFocused(true);
  };

  const handleNameFieldBlur = () => {
    setIsNameFieldFocused(false);
  };

  const {
    selectedValues: selectedGenders,
    setSelectedValues: setSelectedGenders,
    handleSelectionChange: handleGenderChange
  } = useDropdown([]);

  const {
    setSelectedValues: setSelectedSizes
  } = useDropdown([], true);

  const handleInventoryItemChange = (index: number, field: any, value: any) => {
    const newInventoryItems = [...inventoryItems];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    newInventoryItems[index][field] = value;
    setInventoryItems(newInventoryItems);

    handleChange(CreateNewProductFormFields.InventoryItems, newInventoryItems);
  };
  const addInventoryItem = () => {
    setInventoryItems([...inventoryItems, { ...initialInventoryItem }]);
  };

  const removeInventoryItem = (index: number) => {
    if (inventoryItems.length === 1) {
      return;
    }

    const newInventoryItems = [...inventoryItems];
    newInventoryItems.splice(index, 1);
    setInventoryItems(newInventoryItems);

    handleChange(CreateNewProductFormFields.InventoryItems, newInventoryItems);
  };

  const options = [
    { value: false, label: t('createNewProduct.sale') },
    { value: true, label: t('createNewProduct.gift') }
  ];

  const productOptions = [
    { value: ProductLabel.New, label: <Label text={t('createNewProduct.new')} /> },
    { value: ProductLabel.Used, label: <Label text={t('createNewProduct.used')} color={LabelColor.Secondary} /> }
  ];

  const handleFileUpload = (file: File) => {
    const newImages = [...images];
    newImages.push(file);
    setImages(newImages);
  };

  const onSubmit = async (values: any) => {
    if (!customerDetails?.hasPermissionToSell) {
      return;
    }

    if (images.length === 0) {
      throw new Error(t('validations.validateImages'));
    }

    const categories = [selectedCategory, selectedSubCategory, selectedNestedSubCategory].filter((category) => category !== null);

    const data = {
      ...values,
      gendersIds: selectedGenders,
      categoriesIds: categories,
      file: images,
      inventoryItems: inventoryItems.map((item) => ({
        quantity: item.quantity,
        sizeId: item.sizeId === 0 ? null : item.sizeId
      }))
    };

    const response: any = await createProduct(data);

    if (response.error) {
      throw new Error(t('errors.anErrorOccurred'));
    }

    const productName = urlize(values.nameEnglish);

    if (response.data) {
      redirectTo(`/products/${productName}-${response.data}`);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors
  } = useForm(initialFormValues, onSubmit, validateProductFields, t('createNewProduct.successfullyCreatedProduct'), false);

  useEffect(() => {
    if (selectedCategory) {
      setSubCategories(categories?.find((category: any) => category.id === +selectedCategory)?.subcategories ?? []);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      setNestedSubCategories(subCategories?.find((category: any) => category.id === +selectedSubCategory)?.subcategories ?? []);
    }
  }, [selectedSubCategory]);

  const hasPermissionToSell = useMemo(
    () => {
      const { hasPermissionToSell } = customerDetails || { hasPermissionToSell: true };
      return hasPermissionToSell;
    },
    [customerDetails]
  );

  const renderPopup = useCallback(
    () => {
      if (hasPermissionToSell) {
        return null;
      }

      return (
        <Popup
          open={!hasPermissionToSell}
          onClose={navigateToBankDetails}
        >
          <div className="flex flex-column">
            <span className="no-permissions-popup-description">
              {t('common.disabledFormButtonText')}
            </span>
          </div>
          <Button
            type={ButtonType.primary}
            text={t('header.deliveryAndBankDetails')}
            onClick={navigateToBankDetails}
          />
        </Popup>
      );
    },
    [hasPermissionToSell]
  );

  return (
    <>
      {renderPopup()}
      <div className="flex flex-column">
        <Form
          encType={'multipart/form-data'}
          onSubmit={handleSubmit}
          className="create-new-product-form"
          submitText={t('createNewProduct.publish')}
          shouldShowDisabledTooltip={!customerDetails?.hasPermissionToSell}
          submitButtonState={(!customerDetails?.hasPermissionToSell || isSubmitting) ? ButtonState.disabled : ButtonState.enabled}
          submitButtonClassName="full-width"
        >
          <div className="flex flex-column create-new-product-form-row details-row">
            <div className="create-new-product-form-section genders">
              <span className="create-new-product-form-section-title">
                {t('createNewProduct.gender')}
              </span>
              <GenderDropdown
                error={errors.gendersIds}
                selectedGenders={selectedGenders}
                setSelectedGenders={setSelectedGenders}
                handleGenderChange={(value) => {
                  setSelectedGenders(value);
                  handleChange(CreateNewProductFormFields.GendersIds, value);
                  handleGenderChange(value);
                }}
              />
            </div>
            <div className="create-new-product-form-section category">
              <span className="create-new-product-form-section-title">
                {t('createNewProduct.category')}
              </span>
              <CategoryDropdown
                label={t('createNewProduct.chooseCategory')}
                categories={categories ?? []}
                selectedCategory={selectedCategory}
                setSelectedCategory={(value: any) => {
                  setSelectedCategory(value);
                  handleChange(CreateNewProductFormFields.CategoryId, value);
                }}
                error={errors.categoryId?.toString()}
              />
              {
                selectedCategory && subCategories.length > 0 && (
                  <CategoryDropdown
                    label={t('createNewProduct.chooseSubcategory')}
                    categories={subCategories}
                    selectedCategory={selectedSubCategory}
                    setSelectedCategory={(value: any) => {
                      setSelectedSubCategory(value);
                      handleChange(CreateNewProductFormFields.SubCategoryId, value);
                    }}
                    error={errors.subCategoryId?.toString()}
                  />
                )
              }
              {
                selectedSubCategory && nestedSubCategories.length > 0 && (
                  <CategoryDropdown
                    label={t('createNewProduct.chooseSubcategory')}
                    categories={nestedSubCategories}
                    selectedCategory={selectedNestedSubCategory}
                    setSelectedCategory={(value: any) => {
                      setSelectedNestedSubCategory(value);
                      handleChange(CreateNewProductFormFields.NestedSubCategoryId, value);
                    }}
                    error={errors.nestedSubCategoryId?.toString()}
                  />
                )
              }
            </div>
            <div className="create-new-product-form-section form">
              <FormControl
                name={CreateNewProductFormFields.NameBulgarian}
                labelText={t('createNewProduct.name')}
                variant={FormControlVariant.secondary}
                value={values.nameBulgarian.toString()}
                error={errors.nameBulgarian}
                onChange={(value) => {
                  handleChange(CreateNewProductFormFields.NameBulgarian, value.trim());
                }}
                placeholder={!isNameFieldFocused ? t('createNewProduct.namePlaceholder') : ''}
                onFocus={handleNameFieldFocus}
                onBlur={handleNameFieldBlur}
              />
              <FormControl
                name={CreateNewProductFormFields.NameEnglish}
                labelText={`${t('createNewProduct.name')} EN`}
                variant={FormControlVariant.secondary}
                value={values.nameEnglish.toString()}
                error={errors.nameEnglish}
                onChange={(value) => {
                  handleChange(CreateNewProductFormFields.NameEnglish, value.trim());
                }}
                placeholder={!isNameFieldFocused ? t('createNewProduct.namePlaceholder') : ''}
                onFocus={handleNameFieldFocus}
                onBlur={handleNameFieldBlur}
              />
              <FormControl
                name={CreateNewProductFormFields.DescriptionBulgarian}
                type={FormControlType.textarea}
                value={values.descriptionBulgarian.toString()}
                error={errors.descriptionBulgarian}
                labelText={t('createNewProduct.description')}
                placeholder={!isTextAreaFocused ? t('createNewProduct.descriptionPlaceholder') : ''}
                variant={FormControlVariant.secondary}
                onChange={(value) => {
                  handleChange(CreateNewProductFormFields.DescriptionBulgarian, value);
                }}
                onFocus={handleTextAreaFocus}
                onBlur={handleTextAreaBlur}
              />
              <FormControl
                name={CreateNewProductFormFields.DescriptionEnglish}
                type={FormControlType.textarea}
                value={values.descriptionEnglish.toString()}
                error={errors.descriptionEnglish}
                labelText={t('createNewProduct.descriptionEn')}
                placeholder={!isTextAreaFocused ? t('createNewProduct.descriptionPlaceholder') : ''}
                variant={FormControlVariant.secondary}
                onChange={(value) => {
                  handleChange(CreateNewProductFormFields.DescriptionEnglish, value);
                }}
                onFocus={handleTextAreaFocus}
                onBlur={handleTextAreaBlur}
              />
              {
                inventoryItems.map((item, index) => (
                  <div className="create-new-product-form-section-row" key={index}>
                    <div className="flex" style={{ gap: 25 }}>
                      <Tooltip title={t('createNewProduct.addSize')}>
                        <IconButton onClick={addInventoryItem} className="create-new-product-form-section-row-btn">
                          <PlusIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('createNewProduct.removeSize')}>
                        <IconButton
                          onClick={() => {
                            removeInventoryItem(index);
                          }}
                          className="create-new-product-form-section-row-btn"
                        >
                          <MinusIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="flex full-width create-new-product-form-section-row-inputs">
                      <FormControl
                        name={CreateNewProductFormFields.Quantity}
                        onChange={(value) => {
                          handleInventoryItemChange(index, CreateNewProductFormFields.Quantity, value);
                        }}
                        value={item.quantity.toString()}
                        type={FormControlType.number}
                        error={errors.inventoryItems?.[index]?.quantity}
                        labelText={t('createNewProduct.quantity')}
                      />
                      <SizesDropdown
                        categoryId={selectedCategory}
                        selectedSize={item.sizeId}
                        setSelectedSize={setSelectedSizes}
                        handleSizeChange={(e: any) => {
                          setSelectedSizes(e);
                          handleInventoryItemChange(index, CreateNewProductFormFields.SizeId, e);
                        }}
                        disabled={selectedCategory === null}
                        error={errors.inventoryItems?.[index]?.sizeId}
                        className="create-new-product-form-section-row-sizes"
                        onClick={() => {
                          if (selectedCategory === null) {
                            showNotification(t('createNewProduct.pleaseSelectCategory'));
                          }
                        }} />
                      {index === 0 && (
                        <FormControl
                          containerClassName="weight"
                          name={CreateNewProductFormFields.Weight}
                          onChange={(value) => {
                            handleChange(CreateNewProductFormFields.Weight, +value);
                          }}
                          min={0.1}
                          max={10}
                          step={0.1}
                          value={values.weight.toString()}
                          type={FormControlType.number}
                          error={errors.weight}
                          labelText={t('createNewProduct.weight')}
                        />
                      )}
                    </div>
                  </div>
                ))
              }
              <div>
                <span className="create-new-product-form-section-title">
                  {t('createNewProduct.productCondition')}
                </span>
                <div className="flex product-status">
                  <RadioButtons
                    options={productOptions}
                    value={values[CreateNewProductFormFields.Label]}
                    onValueChange={(value) => {
                      handleChange(CreateNewProductFormFields.Label, value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="create-new-product-form-section upload-images">
              <span className="create-new-product-form-section-title">
                {t('createNewProduct.uploadImages')}
              </span>
              <div className="flex upload-images-row">
                {[...Array(PRODUCT_IMAGES_COUNT)].map((_, index) => (
                  <PhotoUploader
                    key={index}
                    id={`photo-${index + 1}`}
                    onUpload={(file) => {
                      handleFileUpload(file);
                    }}
                    icon={index === 0 ? <PlusIcon /> : <CameraIcon />}
                  />
                ))}
              </div>
            </div>
            <div className="create-new-product-form-section price">
              <span className="create-new-product-form-section-title">
                {t('createNewProduct.price')}
              </span>
              <div className="price-checkbox-options">
                <RadioButtons
                  options={options}
                  value={values[CreateNewProductFormFields.IsGift]}
                  onValueChange={(value) => {
                    handleChange(CreateNewProductFormFields.IsGift, value);
                  }}
                />
              </div>
              <FormControl
                name={CreateNewProductFormFields.Price}
                value={values.isGift ? '0' : values.price.toString()}
                error={errors.price}
                onChange={(value) => {
                  handleChange(CreateNewProductFormFields.Price, value);
                }}
                readOnly={values.isGift.toString() === 'true'}
                shouldUpdateValue
                variant={FormControlVariant.secondary}
                type={FormControlType.number}
                placeholder="лв."
                containerClassName="price-input"
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateNewProductForm;
