import { type MouseEvent, type ReactNode, useState } from 'react';
import concatClassNames from 'utils/classNames';
import './Card.scss';
import CloudinaryImage from '../common/cloudinary-img/CloudinaryImage';

export interface ICardProps {
  children: ReactNode
  title: string | ReactNode
  imgSrc?: string
  altText?: string
  icon?: any
  className?: string
  onClick?: () => void
  onIconClick?: () => void
  titleClassName?: string
  isCloudinaryImage?: boolean
}

const Card = ({
  icon: IconComponent,
  children,
  imgSrc,
  title,
  className,
  onClick,
  altText,
  onIconClick,
  titleClassName,
  isCloudinaryImage
}: ICardProps) => {
  const [isIconActive, setIsIconActive] = useState(false);

  const cardClasses = `flex flex-column card ${onClick ? 'clickable' : ''} ${className ?? ''}`;

  const handleIconClick = (e: MouseEvent) => {
    e.preventDefault();
    setIsIconActive(!isIconActive);
    onIconClick && onIconClick();
  };

  return (
    <article className={cardClasses} onClick={onClick}>
      {IconComponent && (
        <IconComponent
          className={concatClassNames('card-icon', isIconActive ? 'active' : '')}
          onClick={handleIconClick}
        />
      )}
      {isCloudinaryImage
        ? <CloudinaryImage imagePublicId={imgSrc ?? ''} className="card-image"/>
        : <img src={imgSrc} alt={altText} className="card-image"/>}
      <div className="flex flex-column card-content">
        <h4 className={concatClassNames('card-content-title', titleClassName)}>
          {title}
        </h4>
        {children}
      </div>
    </article>
  );
};

export default Card;
