import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils/constants';
import { type IUserRoleResponseApi } from 'types/IIdentityUser';
import createHeaders from 'utils/createHeaders';

const IDENTITY_URL = 'identity';

export enum AuthProvider {
  Google = 'Google',
  Facebook = 'Facebook'
}

export enum UsersOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AllUserRolesSortBy {
  Id = 'id',
  Email = 'email',
  userName = 'userName',
  isDeleted = 'isDeleted'
}

export const getExternalLoginUrl = (provider: AuthProvider): string => {
  return `${process.env.REACT_APP_BASE_API_URL}${IDENTITY_URL}/ExternalLogin?provider=${provider}&returnUrl=${window.location.origin}`;
};

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getIdentity: builder.query({
      query: () => IDENTITY_URL
    }),
    getUsersRoles: builder.query<IUserRoleResponseApi, {
      page?: number
      pageSize?: number
      order?: UsersOrder
      sortBy?: AllUserRolesSortBy
      username?: string
      email?: string
      id?: number
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        order,
        sortBy,
        username,
        email,
        id
      }) => {
        let queryString = `${IDENTITY_URL}/Search?pageSize=${pageSize}&page=${page}&order=${order}`;

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (username) {
          queryString += `&username=${username}`;
        }

        if (email) {
          queryString += `&email=${email}`;
        }

        if (id) {
          queryString += `&id=${id}`;
        }

        return queryString;
      }
    }),
    register: builder.mutation({
      query: (userData) => ({
        url: `${IDENTITY_URL}/register`,
        method: 'POST',
        body: userData
      })
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: `${IDENTITY_URL}/login`,
        method: 'POST',
        body: credentials
      })
    }),
    changeUsername: builder.mutation({
      query: (username) => ({
        url: `${IDENTITY_URL}/changeUsername`,
        method: 'PUT',
        body: { username }
      })
    }),
    changePassword: builder.mutation({
      query: (passwords) => ({
        url: `${IDENTITY_URL}/changePassword`,
        method: 'PUT',
        body: passwords
      })
    }),
    deleteUser: builder.mutation<{
      success: boolean
    }, string>({
      query: (id) => {
        const headers = createHeaders();

        return {
          url: `${IDENTITY_URL}/${id}`,
          method: 'DELETE',
          headers
        };
      }
    }),
    addUserToRole: builder.mutation<{
      success: boolean
    }, { id: string, role: string }>({
      query: ({ id, role }) => {
        return {
          url: `${IDENTITY_URL}/AddToRole`,
          method: 'PUT',
          body: { userId: id, role }
        };
      }
    }),
    removeUserFromRole: builder.mutation<{
      success: boolean
    }, { id: string, role: string }>({
      query: ({ id, role }) => {
        return {
          url: `${IDENTITY_URL}/RemoveFromRole`,
          method: 'PUT',
          body: { userId: id, role }
        };
      }
    }),
    requestResetPassword: builder.mutation({
      query: (email: string) => ({
        url: `${IDENTITY_URL}/RequestResetPassword`,
        method: 'POST',
        body: { email }
      })
    }),
    resetPassword: builder.mutation({
      query: (data: { email: string, token: string, newPassword: string }) => ({
        url: `${IDENTITY_URL}/ResetPassword`,
        method: 'POST',
        body: data
      })
    }),
    confirmEmail: builder.mutation({
      query: ({ email, token }: { email: string, token: string }) => ({
        url: `${IDENTITY_URL}/ConfirmEmail`,
        method: 'PUT',
        body: { email, token }
      })
    })
  })
});

export const {
  useGetIdentityQuery,
  useRegisterMutation,
  useLoginMutation,
  useChangeUsernameMutation,
  useChangePasswordMutation,
  useGetUsersRolesQuery,
  useDeleteUserMutation,
  useAddUserToRoleMutation,
  useRemoveUserFromRoleMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useConfirmEmailMutation
} = userApi;
