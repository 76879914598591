import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import { useCreateBannerMutation } from 'store/api/bannerApi';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import { AdminBannersTabs } from './AdminBannerTabs';
import './AdminBanners.scss';

enum BannerCreateFields {
  Name = 'name',
  File = 'file',
  IsVisible = 'isVisible'
}

interface ICreateBannerTab {
  setCurrentTab: (tab: AdminBannersTabs) => void
}

interface ICreateBannerRequest {
  name?: string
  file?: File
  isVisible?: boolean

  [key: string]: string | File | boolean | undefined
}

const CreateBannerTab = ({ setCurrentTab }: ICreateBannerTab) => {
  const { t } = useTranslation();

  const [createBanner] = useCreateBannerMutation();
  const [uploadError, setUploadError] = useState(false);
  const [formValues, setFormValues] = useState<ICreateBannerRequest>({ isVisible: false });

  const handleSubmit = async () => {
    try {
      await createBanner(formValues);
      setUploadError(false);
      setFormValues({});
      setCurrentTab(AdminBannersTabs.AllBanners);
    } catch (err) {
      setUploadError(true);
      console.log('Failed to add banner:', err);
    }
  };

  const handleChange = (field: string, value: any) => {
    setFormValues({ ...formValues, [field]: value });
  };

  return (<>
    {uploadError && <div className="upload-banner-error">{t('administration.banners.createBanner.error')}</div>}
    <Form
      encType={'multipart/form-data'}
      onSubmit={handleSubmit}
      className="flex flex-column"
      submitText={t('administration.common.create')}
    >
      <FormControl
        className="create-banner-field"
        name={BannerCreateFields.Name}
        value={formValues[BannerCreateFields.Name]}
        labelText={t('administration.banners.name')}
        shouldUpdateValue
        type={FormControlType.text}
        onChange={(value) => {
          handleChange(BannerCreateFields.Name, value);
        }}
      />
      <FormControl
        className="create-banner-field"
        name={BannerCreateFields.File}
        type={FormControlType.file}
        shouldUpdateValue
        value={formValues[BannerCreateFields.File] as any}
        labelText={t('administration.banners.createBanner.file')}
        onChange={(value) => {
          handleChange(BannerCreateFields.File, value);
        }}
      />
      <FormControl
        id={BannerCreateFields.IsVisible}
        className="create-banner-field"
        name={BannerCreateFields.IsVisible}
        type={FormControlType.checkbox}
        shouldUpdateValue
        containerClassName="flex align-center isVisible-checkbox"
        value={formValues[BannerCreateFields.IsVisible] as any}
        labelText={t('administration.banners.isVisible')}
        onChange={(value) => {
          handleChange(BannerCreateFields.IsVisible, value);
        }}
      />
    </Form>
  </>);
};

export default CreateBannerTab;
