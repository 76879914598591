import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetOfficesQuery } from 'store/api/deliveryApi';
import { useTranslation } from 'react-i18next';

interface IOfficeDropdownProps {
  siteId?: number
  selectedOffice: any
  handleOfficeChange: any
  disabled?: boolean
  error?: string
}

const OfficeDropdown = ({ siteId, selectedOffice, handleOfficeChange, disabled, error }: IOfficeDropdownProps) => {
  const { t, i18n } = useTranslation();
  const { data: offices } = useGetOfficesQuery({
    siteId,
    language: i18n.language
  }, { skip: !siteId });

  const officeOptions = offices?.map((office) => ({
    ...office,
    label: `${office.name} ${office.address.localAddressString} [${office.id}]`
  })) ?? [];

  const handleChange = (event: any, newValue: any) => {
    handleOfficeChange(newValue);
  };

  return (
    <Autocomplete
      className="dropdown"
      disabled={disabled}
      options={officeOptions}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
          <li {...props} key={option.id}>{option.label}</li>
      )}
      value={officeOptions.find(office => office.id === selectedOffice?.id) ?? null}
      onChange={handleChange}
      renderInput={(params: any) => (
          <TextField {...params} label={t('common.chooseOffice')} error={!!error} helperText={error}/>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={t('common.noOfficesFound')}
    />
  );
};

export default OfficeDropdown;
