import React, { type ChangeEvent, type FormEvent, useCallback, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import concatClassNames from 'utils/classNames';
import { generateGuid } from 'utils/text';
import ErrorFormControl from 'components/forms/ErrorFormControl';
import { ReactComponent as EditPen } from 'assets/icons/pen.svg';
import Tooltip from 'components/tooltip/Tooltip';
import { ALLOWED_IMAGE_SIZE, SUPPORTED_IMAGE_TYPES } from '../../cloudinary-config';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../notification/Notification';
import styles from './FormControl.module.scss';

enum FormControlType {
  'email' = 'email',
  'radio' = 'radio',
  'checkbox' = 'checkbox',
  'text' = 'text',
  'textarea' = 'textarea',
  'input' = 'input',
  'password' = 'password',
  'file' = 'file',
  'number' = 'number',
}

type IFormControlOnChangeValueType = any;

type TextAreaOrInputElement = HTMLTextAreaElement | HTMLInputElement;

export enum FormControlVariant {
  'primary' = 'primary',
  'secondary' = 'secondary'
}

interface IFormControlProps {
  name: string
  value?: string
  containerClassName?: string
  labelText?: React.ReactNode
  labelClassName?: any
  type?: FormControlType
  onChange?: ((value?: IFormControlOnChangeValueType) => void) | null
  onInput?: ((value?: string) => void) | null
  onClick?: (value: FormEvent<HTMLInputElement>) => void
  checked?: boolean
  id?: string
  shouldDisableLabel?: boolean
  className?: string
  error?: string
  shouldUpdateValue?: boolean
  variant?: FormControlVariant
  placeholder?: string
  isEditable?: boolean
  readOnly?: boolean
  onFocus?: any
  onBlur?: any
  max?: number
  min?: number
  step?: number
}

interface ILabelInternalProps {
  id: string
  forKey: string
  text: React.ReactNode
  fieldType: FormControlType
  internalContainerClassName?: string
  className?: string
  children?: React.ReactNode
  variant?: FormControlVariant
}

const LabelInternal = ({
  id,
  text,
  className,
  internalContainerClassName,
  forKey,
  children,
  fieldType,
  variant = FormControlVariant.primary
}: ILabelInternalProps) => {
  if ((text === '') && (className == null)) {
    return (
      <div>
        {children}
      </div>
    );
  }

  const containerClassname = concatClassNames(
    fieldType !== FormControlType.checkbox
      ? styles.formControlContainer
      : 'checkbox-container',
    internalContainerClassName
  );
  const componentClassName = concatClassNames(
    fieldType !== FormControlType.checkbox
      ? variant === FormControlVariant.secondary ? styles.formLabelSecondary : styles.formLabel
      : null,
    className
  );

  return (
    <div className={containerClassname}>
      {children}
      <label id={id} className={componentClassName} htmlFor={forKey}>
        {typeof text === 'string' ? <span>{text}</span> : text}
      </label>
    </div>
  );
};

const FormControl = ({
  name,
  value = '',
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  onChange = null,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  onInput = null,
  onClick,
  className = '',
  containerClassName = '',
  labelText = '',
  labelClassName = '',
  type = FormControlType.text,
  checked = false,
  id = generateGuid(),
  shouldDisableLabel = false,
  error = '',
  shouldUpdateValue = false,
  variant = FormControlVariant.primary,
  placeholder = '',
  isEditable = false,
  readOnly = false,
  onFocus,
  max,
  min,
  step,
  onBlur
}: IFormControlProps) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [formControlValue, setFormControlValue] = useState(value);
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleEditIconClick = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const componentClassName = concatClassNames(
    type !== FormControlType.checkbox
      ? (variant === FormControlVariant.secondary ? styles.formControlSecondary : styles.formControl)
      : null,
    className
  );

  useEffect(() => {
    if (shouldUpdateValue) {
      setFormControlValue(value);
    }
  }, [shouldUpdateValue, value]);

  const handleOnChange = useCallback(
    (ev: ChangeEvent<TextAreaOrInputElement>) => {
      if (type === FormControlType.checkbox) {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);

        if (!isNil(onChange)) {
          onChange(newCheckedState);
        }
        return;
      }

      const inputValue = ev.target.value;
      setFormControlValue(inputValue);

      if (!isNil(onChange)) {
        onChange(inputValue);
      }
    },
    [isChecked, onChange, type]
  );

  const handleOnInput = useCallback(
    (ev: FormEvent<TextAreaOrInputElement>) => {
      const element = ev.target as TextAreaOrInputElement;

      setFormControlValue(element.value);

      if (isNil(onInput)) {
        return;
      }

      onInput(element.value);
    },
    [onInput]
  );

  const generateFormControl = useCallback(
    () => {
      if (type === FormControlType.textarea) {
        return (
          <textarea
            className={concatClassNames(componentClassName, styles.formControlTextArea)}
            name={name}
            id={id}
            onChange={handleOnChange}
            onInput={handleOnInput}
            value={formControlValue}
            placeholder={variant === FormControlVariant.primary
              ? (typeof labelText === 'string' ? labelText : undefined)
              : placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        );
      }

      if (type === FormControlType.checkbox) {
        return (
          <input
            type={type}
            className={componentClassName}
            name={name}
            id={id}
            value={value}
            checked={isChecked}
            onClick={onClick}
            onChange={handleOnChange}
          />
        );
      }

      if (type === FormControlType.file) {
        return (
          <input
            type={type}
            accept={SUPPORTED_IMAGE_TYPES.map(type => `.${type}`).join(',')}
            className={concatClassNames(componentClassName, styles.fileInput)}
            name={name}
            id={id}
            onChange={(e) => {
              if (isNil(onChange)) {
                return;
              }

              if (e.target.files?.[0]) {
                const file = e.target.files[0];

                if (file.size > ALLOWED_IMAGE_SIZE) {
                  showNotification(t('validations.fileTooLarge'));
                  e.target.files = new DataTransfer().files;
                  return;
                }

                onChange(file);
              }
            }}
          />
        );
      }

      const placeholderValue = variant === FormControlVariant.primary
        ? (typeof labelText === 'string' ? labelText : undefined)
        : placeholder;

      const inputControl = (
        <input
          type={type}
          className={componentClassName}
          name={name}
          id={id}
          onChange={handleOnChange}
          onInput={handleOnInput}
          value={formControlValue}
          checked={checked}
          max={max}
          min={min}
          step={step}
          placeholder={placeholderValue}
          readOnly={isEditable ? !isEditMode : readOnly}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );

      if (isEditable) {
        return (
          <div className={styles.inputWithIcon}>
            {inputControl}
            <Tooltip title="Редактирай">
              <span className={styles.iconContainer} onClick={handleEditIconClick}><EditPen/></span>
            </Tooltip>
          </div>
        );
      }

      return inputControl;
    },
    [checked, componentClassName, formControlValue, handleOnChange, handleOnInput,
      id, isChecked, labelText, name, onChange, onClick, type, value, isEditable, isEditMode]
  );

  const generateFormControlWithLabel = useCallback(
    () => (
      <LabelInternal
        id={`${id}-label`}
        text={labelText}
        fieldType={type}
        internalContainerClassName={containerClassName}
        className={labelClassName}
        forKey={id}
        variant={variant}
      >
        {generateFormControl()}
      </LabelInternal>
    ),
    [containerClassName, generateFormControl, id, labelClassName, labelText, type, variant]
  );

  const renderFormControl = useCallback(
    () => shouldDisableLabel
      ? generateFormControl()
      : generateFormControlWithLabel(),
    [shouldDisableLabel, generateFormControlWithLabel, generateFormControl]
  );

  return (
    <div className="flex flex-column">
      {renderFormControl()}
      <ErrorFormControl error={error}/>
    </div>
  );
};

export default FormControl;

export type {
  IFormControlOnChangeValueType
};

export {
  FormControlType
};
