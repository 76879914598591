import { useEffect } from 'react';
import Form from 'components/forms/Form';
import { ButtonType } from 'components/button/Button';
import SectionCard from 'components/cards/section-card/SectionCard';
import { DeliveryPayer, HandoverType } from 'types/ICustomer';
import useForm from 'hooks/useForm';
import useDropdown from 'hooks/useDropdown';
import { useUpdateCustomerSellingOptionsMutation } from 'store/api/customerApi';
import { validateProfileOptions } from 'utils/validators/deliveryValidators';
import DeliveryOptions from 'components/delivery-options/DeliveryOptions';
import { useCustomerDetails } from 'pages/profile/Profile';
import { useTranslation } from 'react-i18next';

enum DeliveryFields {
  DeliveryOption = 'deliveryOption',
  DeliveryAddressOrOffice = 'deliveryAddressOrOffice',
}

const initialDeliveryFields = {
  [DeliveryFields.DeliveryOption]: DeliveryPayer.Buyer,
  [DeliveryFields.DeliveryAddressOrOffice]: HandoverType.Office
};

export enum AddressDeliveryFields {
  City = 'city',
  Office = 'office',
  PostCode = 'postCode',
  Complex = 'complex',
  Street = 'street',
  BlockNo = 'blockNo',
  EntranceNo = 'entranceNo',
  FloorNo = 'floorNo',
  ApartmentNo = 'apartmentNo',
  AddressNote = 'addressNote'
}

const initialAddressDeliveryFields = {
  [AddressDeliveryFields.City]: '',
  [AddressDeliveryFields.Office]: '',
  [AddressDeliveryFields.PostCode]: '',
  [AddressDeliveryFields.Complex]: '',
  [AddressDeliveryFields.Street]: '',
  [AddressDeliveryFields.BlockNo]: '',
  [AddressDeliveryFields.EntranceNo]: '',
  [AddressDeliveryFields.FloorNo]: '',
  [AddressDeliveryFields.ApartmentNo]: '',
  [AddressDeliveryFields.AddressNote]: ''
};

const ProfileDeliveryOptions = () => {
  const { t } = useTranslation();
  const { customerDetails, refetch } = useCustomerDetails();
  const [updateSellingOptions] = useUpdateCustomerSellingOptionsMutation();

  const initialFormState = {
    ...initialDeliveryFields,
    ...initialAddressDeliveryFields
  };

  const deliveryAddressOrOfficeTypes = [
    { value: HandoverType.Address, label: t('profile.deliveryAndBankDetails.addressDelivery') },
    { value: HandoverType.Office, label: t('profile.deliveryAndBankDetails.officeDelivery') }
  ];

  const onSubmit = async (values: any) => {
    const commonDeliveryTypeData = {
      deliveryPayer: +values.deliveryOption,
      handoverType: +values.deliveryAddressOrOffice
    };

    const officeDeliveryTypeData = {
      ...commonDeliveryTypeData,
      handoverAddress: {
        ...selectedOffice.address,
        siteId: selectedCity.id,
        siteName: selectedCity.name,
        siteType: selectedCity.type,
        postCode: values.postCode,
        streetType: null,
        streetName: null,
        streetNo: null,
        complexType: null,
        complexName: null,
        blockNo: null,
        entranceNo: null,
        floorNo: null,
        apartmentNo: null,
        addressNote: null,
        dropOffOfficeId: selectedOffice.id
      }
    };

    const deliveryAddressTypeData = {
      ...commonDeliveryTypeData,
      handoverAddress: {
        ...selectedOffice.address,
        siteId: selectedCity.id,
        siteName: selectedCity.name,
        siteType: selectedCity.type,
        postCode: values.postCode,
        streetType: values.street?.type ?? customerDetails?.sellingOptionsHandoverAddress?.streetType ?? null,
        streetName: values.street?.name ?? customerDetails?.sellingOptionsHandoverAddress?.streetName ?? null,
        streetNo: selectedStreet?.streetNo ?? customerDetails?.sellingOptionsHandoverAddress?.streetNo ?? null,
        complexType: values.complex?.type ?? customerDetails?.sellingOptionsHandoverAddress?.complexType,
        complexName: values.complex?.name ?? customerDetails?.sellingOptionsHandoverAddress?.complexName,
        blockNo: values.blockNo,
        entranceNo: values.entranceNo,
        floorNo: values.floorNo,
        apartmentNo: values.apartmentNo,
        addressNote: values.addressNote,
        dropOffOfficeId: null
      }
    };

    const deliveryTypeData = +values.deliveryAddressOrOffice === HandoverType.Office
      ? officeDeliveryTypeData
      : deliveryAddressTypeData;

    const res: any = await updateSellingOptions(deliveryTypeData);
    await refetch();
    if (res.error) {
      throw new Error(t('errors.pleaseFillInTheData'));
    }
  };

  const { values, handleChange, setValues, handleSubmit, errors } = useForm(
    initialFormState,
    onSubmit,
    validateProfileOptions,
    t('profile.deliveryAndBankDetails.deliveryChangeSuccess')
  );

  // const deliveryOptionTypes = [
  //   { value: DeliveryPayer.Buyer, label: t('profile.deliveryAndBankDetails.clientDelivery') },
  //   { value: DeliveryPayer.Seller, label: t('profile.deliveryAndBankDetails.sellerDelivery') }
  // ];

  const {
    selectedValues: selectedCity,
    setSelectedValues: setSelectedCity
  } = useDropdown([], true);

  const {
    selectedValues: selectedComplex,
    setSelectedValues: setSelectedComplex,
    handleSelectionChange: handleComplexChange
  } = useDropdown([], true);

  const {
    selectedValues: selectedStreet,
    setSelectedValues: setSelectedStreet,
    handleSelectionChange: handleStreetChange
  } = useDropdown([], true);

  const {
    selectedValues: selectedOffice,
    setSelectedValues: setSelectedOffice,
    handleSelectionChange: handleOfficeChange
  } = useDropdown([], true);

  const handleCitySelectionChange = (selectedCityValue: any) => {
    setSelectedOffice({
      id: selectedCityValue?.id,
      name: customerDetails?.sellingOptionsHandoverAddress?.streetName ?? '',
      address: customerDetails?.sellingOptionsHandoverAddress ?? {}
    });

    setSelectedCity(selectedCityValue);
  };

  useEffect(() => {
    if (customerDetails?.sellingOptionsHandoverAddress) {
      setValues({
        deliveryOption: customerDetails.sellingOptionsDeliveryPayer.value,
        deliveryAddressOrOffice: customerDetails.sellingOptionsHandoverType.value,
        city: customerDetails.sellingOptionsHandoverAddress.siteName,
        office: customerDetails.sellingOptionsHandoverAddress.streetName,
        postCode: customerDetails.sellingOptionsHandoverAddress.postCode,
        complex: customerDetails.sellingOptionsHandoverAddress.complexName,
        street: customerDetails.sellingOptionsHandoverAddress.streetName,
        blockNo: customerDetails.sellingOptionsHandoverAddress.blockNo,
        entranceNo: customerDetails.sellingOptionsHandoverAddress.entranceNo,
        floorNo: customerDetails.sellingOptionsHandoverAddress.floorNo,
        apartmentNo: customerDetails.sellingOptionsHandoverAddress.apartmentNo,
        addressNote: customerDetails.sellingOptionsHandoverAddress.addressNote
      });
    }

    if (customerDetails?.sellingOptionsHandoverAddress) {
      setSelectedCity({
        id: customerDetails.sellingOptionsHandoverAddress.siteId,
        name: customerDetails.sellingOptionsHandoverAddress.siteName,
        type: customerDetails.sellingOptionsHandoverAddress.siteType
      });
    }

    if (customerDetails?.sellingOptionsHandoverAddress) {
      setSelectedComplex({
        name: customerDetails.sellingOptionsHandoverAddress.complexName,
        type: customerDetails.sellingOptionsHandoverAddress.complexType
      });

      setSelectedStreet({
        name: customerDetails.sellingOptionsHandoverAddress?.streetName,
        type: customerDetails.sellingOptionsHandoverAddress?.streetType,
        streetNo: customerDetails.sellingOptionsHandoverAddress?.streetNo
      });

      setSelectedOffice({
        id: customerDetails.sellingOptionsHandoverAddress.officeId
      });
    }
  }, [customerDetails, setValues]);

  return (
    <SectionCard title={t('profile.deliveryAndBankDetails.deliveryTitle')} className="profile-delivery-options">
      <Form
        onSubmit={handleSubmit}
        submitText={t('common.save')}
        className="flex flex-column profile-section-form"
        submitButtonType={ButtonType.secondary}
        submitButtonClassName="profile-section-form-submit-button"
      >
        {/* <RadioButtons */}
        {/*  options={deliveryOptionTypes} */}
        {/*  onValueChange={(selectedValue: any) => { */}
        {/*    handleChange(DeliveryFields.DeliveryOption, selectedValue); */}
        {/*  }} */}
        {/*  value={values.deliveryOption} */}
        {/* /> */}
        <h4 className="profile-section-form-title">{t('profile.deliveryAndBankDetails.deliveryText')}</h4>
        <DeliveryOptions
          value={values.deliveryAddressOrOffice}
          onChange={(selectedValue: any) => {
            handleChange(DeliveryFields.DeliveryAddressOrOffice, selectedValue);
          }}
          errors={errors}
          selectedCity={selectedCity}
          selectedOffice={selectedOffice}
          selectedComplex={selectedComplex}
          selectedStreet={selectedStreet}
          values={values}
          handleChange={handleChange}
          handleCitySelectionChange={handleCitySelectionChange}
          handleComplexChange={handleComplexChange}
          handleStreetChange={handleStreetChange}
          handleOfficeChange={handleOfficeChange}
          deliveryAddressOrOfficeTypes={deliveryAddressOrOfficeTypes}
          showDiscountText={false}
        />
      </Form>
    </SectionCard>
  );
};

export default ProfileDeliveryOptions;
