import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { type ICategory } from '../../types/ICategory';

const CATEGORIES_URL = '/categories';

export const categoriesApi = createApi({
  reducerPath: 'categories',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllCategories: builder.query<ICategory[], void>({
      query: () => ({
        url: `${CATEGORIES_URL}`
      })
    }),
    editCategory: builder.mutation({
      query: ({ categoryId, category }: { categoryId: number, category: ICategory }) => ({
        url: `${CATEGORIES_URL}/${categoryId}`,
        method: 'PUT',
        body: {
          ...category
        }
      })
    }),
    addCategory: builder.mutation({
      query: (category: ICategory) => ({
        method: 'POST',
        url: `${CATEGORIES_URL}`,
        body: category
      })
    }),
    deleteCategory: builder.mutation<any, {
      id: number
    }>({
      query: ({ id }) => ({
        url: `${CATEGORIES_URL}/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetAllCategoriesQuery,
  useEditCategoryMutation,
  useAddCategoryMutation,
  useDeleteCategoryMutation
} = categoriesApi;
