import React from 'react';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import Profile from 'pages/profile/Profile';
import ProfilePersonalData from 'pages/profile-settings/ProfilePersonalData';
import ProfileChangePassword from 'pages/profile-settings/ProfileChangePassword';
import { useGetPageDetailsQuery } from '../../store/api/pagesApi';
import { ROUTES } from '../../routes/routes';

interface IProfileSettings extends IOptionalClassName {
}

const ProfileSettings = ({ className }: IProfileSettings) => {
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.ProfileSettings.path });

  return (
    <>
      <Profile pageSeo={pageSeoData} className={concatClassNames(className, 'profile-settings')}>
        <ProfilePersonalData/>
        <ProfileChangePassword/>
      </Profile>
    </>
  );
};

export default ProfileSettings;
