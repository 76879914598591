import { type ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IScrollToTopProps {
  children: ReactNode
}

const ScrollToTop = ({ children }: IScrollToTopProps) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
