import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import Popup, { type IPopup } from 'components/popup/Popup';
import './ForgottenPasswordPopup.scss';
import { useRequestResetPasswordMutation } from 'store/api/userApi';
import { useNotification } from 'components/notification/Notification';
import useForm from 'hooks/useForm';
import { resetPasswordValidator } from 'utils/validators/authValidator';

const FIELD_NAMES = {
  email: 'email'
};

const initialValues = {
  [FIELD_NAMES.email]: ''
};

interface IForgottenPassword extends IPopup {
}

const ForgottenPasswordPopup = ({
  open,
  onClose,
  ...otherProps
}: IForgottenPassword) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [requestResetPassword] = useRequestResetPasswordMutation();

  const onSubmit = async (values: any) => {
    console.log(values);
    try {
      const response: any = await requestResetPassword(values.email);

      if (response.error) {
        showNotification(response.error.data);
      } else {
        showNotification(t('forgottenPassword.success'));
        onClose();
      }
    } catch (e) {
      showNotification(t('forgottenPassword.error'));
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors
  } = useForm(initialValues, onSubmit, resetPasswordValidator);

  if (!open) {
    return null;
  }

  console.log(values);

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={t('forgottenPassword.title')}
      text={t('forgottenPassword.text')}
      className="forgotten-password-popup"
    >
      <Form
        onSubmit={handleSubmit}
        submitText={t('forgottenPassword.send')}
        className="flex flex-column justify-center align-center forgotten-password-popup-form full-width"
        submitButtonClassName="forgotten-password-popup-form-submit-button"
      >
        <FormControl
          name={FIELD_NAMES.email}
          value={values.email}
          error={errors.email}
          labelText={t('forgottenPassword.email')}
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(FIELD_NAMES.email, value);
          }}
          className="forgotten-password-popup-form-control"
          labelClassName="forgotten-password-popup-form-control-label"
        />
      </Form>
    </Popup>
  );
};

export default ForgottenPasswordPopup;
