import i18n from 'i18next';
import {
  createValidator,
  type FieldValidators,
  maxLength,
  maxNumber,
  minLength,
  minNumber,
  validateFields
} from 'utils/validators/validators';

const MIN_RATING_VALUE: number = 1;
const MAX_RATING_VALUE: number = 5;
const MIN_COMMENT_LENGTH: number = 5;
const MAX_COMMENT_LENGTH: number = 2400;

const validateRating = createValidator(
  minNumber(MIN_RATING_VALUE),
  maxNumber(MAX_RATING_VALUE)
);

const validateComment = createValidator(
  minLength(MIN_COMMENT_LENGTH),
  maxLength(MAX_COMMENT_LENGTH)
);

const reviewFields: FieldValidators = {
  comment: validateComment
};

const isInteger = (value: number) => {
  if (!Number.isInteger(+value)) {
    return i18n.t('validations.ratingMustBeWholeNumber');
  }
};

const validateReview = (values: any) => {
  const errors: any = validateFields(values, {
    ...reviewFields,
    rating: () => {
      const ratingErrors = validateRating(values.rating);
      if (ratingErrors) {
        return ratingErrors;
      }

      return isInteger(values.rating) ?? null;
    }
  });

  return errors;
};

export default validateReview;
