import { useTranslation } from 'react-i18next';
import Tabs from 'components/tabs/Tabs';
import Invoices from 'components/administration/payments/Invoices';
import Purchases from 'components/administration/payments/Purchases';

enum AdminPaymentsTabs {
  Invoices = 'Invoices',
  Purchases = 'Purchases'
}

const AdminPaymentsTab = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: AdminPaymentsTabs.Purchases,
      content: <Purchases/>,
      name: t('administration.payments.title')
    },
    {
      label: AdminPaymentsTabs.Invoices,
      content: <Invoices/>,
      name: t('administration.payments.invoices.title')
    }
  ];

  return <Tabs className="user-administration-tabs" tabsData={tabs} ariaLabel="payment tabs"/>;
};

export default AdminPaymentsTab;
