import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import './HomeCategoryCard.scss';
import { useI18n } from 'hooks/usei18n';

interface IHomeCategoryCard extends IOptionalClassName {
  href: string
  imgSrc: string
  hoverImgSrc: string
  text: string
  hoverBgImage: string
}

const HomeCategoryCard = ({ href, imgSrc, hoverImgSrc, text, className, hoverBgImage }: IHomeCategoryCard) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { getLanguageUrl } = useI18n();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const redirectTo = () => {
    navigate(getLanguageUrl(href));
  };

  const backgroundImageStyle = isHovered && hoverBgImage ? { backgroundImage: `url(${hoverBgImage})` } : {};

  return (
    <article
      className={concatClassNames('flex flex-column justify-center align-center home-category-card', className)}
      style={backgroundImageStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={redirectTo}
    >
      <img src={isHovered ? hoverImgSrc : imgSrc} alt={text} className="home-category-card-image" />
      <h3 className="text-center home-category-card-text">{text}</h3>
    </article>
  );
};

export default HomeCategoryCard;
