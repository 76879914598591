import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AllUserRolesSortBy,
  useAddUserToRoleMutation,
  useDeleteUserMutation,
  useGetUsersRolesQuery,
  useRemoveUserFromRoleMutation,
  UsersOrder
} from 'store/api/userApi';
import { type SerializedError } from '@reduxjs/toolkit';
import { DataGrid, type GridColDef, type GridRenderCellParams, type GridValueGetterParams } from '@mui/x-data-grid';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, USER_ROLES } from 'utils/constants';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { type UserRole } from 'types/IIdentityUser';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl as MuiFormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';
import { isUserGeneralAdministrator } from 'utils/auth';
import FormControl, { FormControlType } from '../../forms/FormControl';
import debounce from 'lodash/debounce';
import Dialog from '@mui/material/Dialog';
import './AdminUsersTab.scss';

enum AdminUserRolesFilterFields {
  Id = 'Id',
  Email = 'Email',
  Username = 'Username'
}

enum UserRoleEditActions {
  AddRole = 'Add Role',
  RemoveRole = 'Remove Role',
  DeleteUser = 'Delete'
}

interface IEditUserRoleArgs {
  id: string
  username: string
  action: UserRoleEditActions
  userRoles: UserRole[]
}

interface IUserRolesFilterField {
  id?: string
  username?: string
  email?: string

  [key: string]: string | undefined
}

const UserRolesTab = () => {
  const { t } = useTranslation();

  const sortByDropdownOptions = [
    { value: AllUserRolesSortBy.Id, name: 'Id' },
    { value: AllUserRolesSortBy.userName, name: t('common.inputs.username') },
    { value: AllUserRolesSortBy.Email, name: t('common.inputs.email') },
    { value: AllUserRolesSortBy.isDeleted, name: t('administration.users.isUserDeleted') }
  ];

  const orderByDropdownOptions = [
    { value: UsersOrder.DESC, name: t('common.sort.descending') },
    { value: UsersOrder.ASC, name: t('common.sort.ascending') }
  ];

  const [addRemoveRoleName, setAddRemoveRoleName] = useState<string>('');
  const [queryError, setQueryError] = useState<string>('');
  const [editRowArgs, setEditRowArgs] = useState<IEditUserRoleArgs | null>(null);
  const [queryParams, setQueryParams] = useState({
    page: DEFAULT_PAGE,
    sortBy: sortByDropdownOptions[0].value,
    order: orderByDropdownOptions[0].value
  });
  const [filterFieldsValues, setFilterFieldsValues] = useState<IUserRolesFilterField>({});
  const { data, error, isLoading, refetch } = useGetUsersRolesQuery(queryParams);
  const [deleteUser] = useDeleteUserMutation();
  const [addUserToRole] = useAddUserToRoleMutation();
  const [removeUserFromRole] = useRemoveUserFromRoleMutation();

  const handleFilterFieldChange = (field: string, value: string) => {
    const searchParams = { [field.toLowerCase().replace(' ', '')]: value };
    setFilterFieldsValues(searchParams);
    setQueryParams({ page: queryParams.page, sortBy: queryParams.sortBy, order: queryParams.order, ...searchParams });
  };

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setQueryParams(paramsObj);
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...queryParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setQueryParams(paramsObj);
  };

  const debounceSetQueryParams = debounce(handleFilterFieldChange, 1000);

  const onUserCrudButtonClick = (params: GridRenderCellParams, action: UserRoleEditActions) => {
    if (!isUserGeneralAdministrator()) {
      alert('No access for this operation!');
      return;
    }

    const { userName, id, roles } = params.row;

    setEditRowArgs({
      action,
      username: userName,
      id,
      userRoles: roles
    });
  };

  const handleEntered = () => {
  };

  const handleYes = async (id: string, action: UserRoleEditActions, role: string) => {
    setQueryError('');
    try {
      let res;
      if (action === UserRoleEditActions.DeleteUser) {
        res = await deleteUser(id) as any;
      } else if (action === UserRoleEditActions.AddRole) {
        res = await addUserToRole({ id, role });
      } else if (action === UserRoleEditActions.RemoveRole) {
        res = await removeUserFromRole({ id, role });
      }
      if (res.error?.data) {
        setQueryError(res.error?.data);
      } else {
        await refetch();
      }
      console.log(' res => ', res);
    } catch (err) {
      if (err) {
        setQueryError('An error occurred please try again later!');
      }
      console.log('err => ', err);
    }

    setEditRowArgs(null);
  };

  const handleNo = () => {
    setEditRowArgs(null);
  };

  const renderDialog = () => {
    if (!editRowArgs) {
      return null;
    }

    const { id, username, action, userRoles } = editRowArgs;

    let rolesToShow = userRoles.map((role) => role.name);
    if (action === UserRoleEditActions.AddRole) {
      const allUserRoles: string[] = [
        USER_ROLES.MarketingAdministratorRoleName,
        USER_ROLES.RegularAdministratorRoleName,
        USER_ROLES.GeneralAdministratorRoleName
      ];

      const availableRoles = allUserRoles.filter((role: string) => !rolesToShow.includes(role));
      rolesToShow = [...availableRoles];
    }

    return (
      <Dialog
        maxWidth='xs'
        TransitionProps={{ onEntered: handleEntered }}
        open={!!editRowArgs}
      >
        <DialogTitle>{t('administration.users.areYouSure')}</DialogTitle>
        <DialogContent dividers>
          <>
            <div style={{ marginBottom: '10px' }}>{action} for user {username}</div>
            {action !== UserRoleEditActions.DeleteUser && (
              <>
                {rolesToShow.length === 0
                  ? <>No Roles Available.</>
                  : <MuiFormControl variant="outlined" className="role-change">
                    <InputLabel id="role-change-label">{action}</InputLabel>
                    <Select
                      sx={{ width: '300px' }}
                      labelId="role-add-remove"
                      value={addRemoveRoleName}
                      onChange={(event) => {
                        setAddRemoveRoleName(event.target.value);
                      }}
                      label={action}
                    >
                      {rolesToShow.map((role: string, idx: number) => (
                        <MenuItem key={`riv-${idx}`} value={role}>{role}</MenuItem>
                      ))}
                    </Select>
                  </MuiFormControl>
                }
              </>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button
            disabled={action !== UserRoleEditActions.DeleteUser && !addRemoveRoleName}
            onClick={() => {
              // this handling is required, because otherwise linter goes crazy
              handleYes(id, action, addRemoveRoleName)
                .then(() => {
                })
                .catch(() => {
                });
            }}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const dataColumns: GridColDef[] = [
    { field: 'id', headerName: 'Id', align: 'center', headerAlign: 'center', width: 300 },
    { field: 'email', headerName: t('common.inputs.email'), align: 'center', headerAlign: 'center', width: 250 },
    { field: 'userName', headerName: t('common.inputs.username'), align: 'center', headerAlign: 'center', width: 260 },
    {
      field: 'isDeleted',
      headerName: t('administration.users.isUserDeleted'),
      align: 'center',
      headerAlign: 'center',
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        const isDeleted = params.value;
        if (!isDeleted) {
          return <CancelIcon/>;
        }
        return <CheckCircleIcon/>;
      }
    },
    {
      field: 'roles',
      headerName: t('administration.users.roles'),
      align: 'center',
      headerAlign: 'center',
      width: 280,
      valueGetter: (params: GridValueGetterParams) => {
        const roleNames = params.value.map((role: UserRole) => role.name);
        return roleNames.join(', ');
      }
    },
    {
      field: 'addRole',
      headerName: t('administration.users.addRole'),
      align: 'center',
      headerAlign: 'center',
      width: 260,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            disabled={!isUserGeneralAdministrator()}
            onClick={() => {
              onUserCrudButtonClick(params, UserRoleEditActions.AddRole);
            }}
            sx={{ backgroundColor: '#e18f91', color: 'black' }}
          >
            {t('administration.users.addRole')}
          </Button>
        );
      }
    },
    {
      field: 'removeRole',
      headerName: 'Remove User From Role',
      align: 'center',
      headerAlign: 'center',
      width: 260,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            disabled={!isUserGeneralAdministrator()}
            onClick={() => {
              onUserCrudButtonClick(params, UserRoleEditActions.RemoveRole);
            }}
            sx={{ backgroundColor: '#e18f91', color: 'black' }}
          >
            REMOVE ROLE
          </Button>
        );
      }
    },
    {
      field: 'deleteRow',
      headerName: t('administration.users.deleteUser'),
      align: 'center',
      headerAlign: 'center',
      width: 220,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            disabled={!isUserGeneralAdministrator()}
            onClick={() => {
              onUserCrudButtonClick(params, UserRoleEditActions.DeleteUser);
            }}
            sx={{ backgroundColor: '#e18f91', color: 'black' }}
          >
            {t('administration.users.delete')}
          </Button>
        );
      }
    }
  ];

  if (isLoading) {
    return (<div>{t('common.loading')}</div>);
  }

  return (
    <div>
      {error
        ? <div>{(error as SerializedError).message}</div>
        : <>
          {renderDialog()}
          <div>
            <div className='users-sort-fields'>
              <MuiFormControl variant="outlined" className="sort-container">
                <InputLabel id="sort-by-label">
                  {t('common.sort.sortBy')}
                </InputLabel>
                <Select
                  sx={{ width: '180px' }}
                  value={queryParams.sortBy}
                  label='Delivery Type'
                  onChange={(e: any) => {
                    onSortByDropdownChange(e);
                  }}
                  className='dropdown-select'
                >
                  {sortByDropdownOptions.map((option, idx) => (
                    <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                      <ListItemText primary={option.name}/>
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
              <MuiFormControl variant="outlined" className="sort-container">
                <InputLabel id="sort-by-label">
                  {t('common.sort.orderBy')}
                </InputLabel>
                <Select
                  sx={{ width: '180px' }}
                  value={queryParams.order}
                  label='Delivery Type'
                  onChange={(e: any) => {
                    onOrderByDropdownChange(e);
                  }}
                  className='dropdown-select'
                >
                  {orderByDropdownOptions.map((option, idx) => (
                    <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                      <ListItemText primary={option.name}/>
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
            <div className='users-search-field'>
              <FormControl
                className='user-filter-field'
                name={AdminUserRolesFilterFields.Id}
                value={filterFieldsValues[AdminUserRolesFilterFields.Id.toLowerCase()]}
                labelText={AdminUserRolesFilterFields.Id}
                error={' '}
                shouldUpdateValue
                type={FormControlType.text}
                onChange={(value) => {
                  debounceSetQueryParams(AdminUserRolesFilterFields.Id, value);
                }}
              />
              <FormControl
                className='user-role-filter-field'
                name={AdminUserRolesFilterFields.Email}
                value={filterFieldsValues[AdminUserRolesFilterFields.Email.toLowerCase()]}
                labelText={t('common.inputs.email')}
                error={' '}
                shouldUpdateValue
                type={FormControlType.text}
                onChange={(value) => {
                  debounceSetQueryParams(AdminUserRolesFilterFields.Email, value);
                }}
              />
              <FormControl
                className='user-role-filter-field'
                name={AdminUserRolesFilterFields.Username}
                value={filterFieldsValues[AdminUserRolesFilterFields.Username.toLowerCase()]}
                labelText={t('common.inputs.username')}
                error={' '}
                shouldUpdateValue
                type={FormControlType.text}
                onChange={(value) => {
                  debounceSetQueryParams(AdminUserRolesFilterFields.Username, value);
                }}
              />
            </div>
          </div>
          {queryError && <div style={{ color: 'red' }}>{queryError}</div>}
          <DataGrid
            rows={data?.models ?? []}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
              }
            }}
            pageSizeOptions={[DEFAULT_PAGE_SIZE]}
            rowCount={data?.totalCount ?? 0}
            paginationMode='server'
            onPaginationModelChange={(e) => {
              setQueryParams({ page: e.page + 1, sortBy: queryParams.sortBy, order: queryParams.order });
            }}
          />
        </>
      }
    </div>
  );
};

export default UserRolesTab;
