import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import i18n from '../i18n';
import { useLocation, useNavigate } from 'react-router-dom';

interface II18nContext {
  changeLanguage: (lang: string) => Promise<void>
  getLanguageUrl: (url: string) => string
  language: string
  availableLanguages: string[]
}

const defaultValue = {
  language: i18n.language,
  availableLanguages: i18n.languages
};

const I18nContext = createContext<II18nContext>(defaultValue as II18nContext);

const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChanged = useCallback(
    (lang: string) => {
      setLanguage(lang);
      const currentPath = location.pathname;

      const newPath = lang === 'en' && !currentPath.startsWith('/en')
        ? '/en' + currentPath
        : lang === 'bg' && currentPath.startsWith('/en')
          ? currentPath.replace('/en', '')
          : currentPath;

      if (newPath !== currentPath) {
        navigate(newPath);
      }
    },
    [language, navigate, i18n, location]);

  const availableLanguages = useMemo(
    () => [...i18n.languages],
    []
  );

  const changeLanguage = useCallback(
    async (lang: string) => {
      await i18n.changeLanguage(lang);
      setLanguage(lang);
    },
    [setLanguage]);

  const getLanguageUrl = useCallback(
    (url: string) => language === 'en'
      ? `/en${url}`
      : url,
    [language]
  );

  const value = useMemo(
    () => ({
      changeLanguage,
      language,
      availableLanguages,
      getLanguageUrl
    }),
    [changeLanguage, language, availableLanguages]
  );

  i18n.on('languageChanged', handleLanguageChanged);

  return (
    <I18nContext.Provider value={value}>
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;

export const useI18n = () => useContext(I18nContext);
