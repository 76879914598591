import { useTranslation } from 'react-i18next';
import { ButtonColors, LinkButton, LinkButtonType } from 'components/button/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ROUTES } from 'routes/routes';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import { useI18n } from 'hooks/usei18n';
import { useMemo } from 'react';

interface ICreateNewProductButton extends IOptionalClassName {
}

const CreateNewProductButton = ({ className }: ICreateNewProductButton) => {
  const { t } = useTranslation();
  const { getLanguageUrl } = useI18n();

  const path = useMemo(
    () => getLanguageUrl(ROUTES.CreateNewProduct.path),
    [getLanguageUrl]
  );

  return (
    <LinkButton
      type={LinkButtonType.primary}
      color={ButtonColors.primary}
      className={concatClassNames('flex justify-center align-center create-new-product-button', className)}
      to={path}
    >
      <PlusIcon />
      <span>{t('header.createNewProduct')}</span>
    </LinkButton>
  );
};

export default CreateNewProductButton;
