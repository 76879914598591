import { useTranslation } from 'react-i18next';
import RadioButtons from 'components/radio-buttons/RadioButtons';
import { HandoverType } from 'types/ICustomer';
import CityDropdown from 'components/common/dropdowns/CityDropdown';
import OfficeDropdown from 'components/common/dropdowns/OfficeDropdown';
import ComplexDropdown from 'components/common/dropdowns/ComplexDropdown';
import StreetDropdown from 'components/common/dropdowns/StreetDropdown';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import { AddressDeliveryFields } from 'pages/profile-delivery-and-payment/ProfileDeliveryOptions';
import { type ICity, type IComplex, type IOffice, type IStreet } from 'types/IDelivery';
import './DeliveryOptions.scss';

interface IDeliveryOptionsProps {
  value: number
  onChange: any
  errors: any
  selectedCity: any
  selectedOffice: any
  selectedComplex: any
  selectedStreet: any
  values: any
  handleChange: (field: AddressDeliveryFields, value: any) => void
  handleCitySelectionChange: any
  handleComplexChange: (value: any) => void
  handleStreetChange: (value: any) => void
  handleOfficeChange: (value: any) => void
  deliveryAddressOrOfficeTypes: Array<{ value: number, label: string }>
  showDiscountText: boolean
}

const DeliveryOptions = ({
  value,
  onChange,
  errors,
  selectedCity,
  selectedOffice,
  selectedComplex,
  selectedStreet,
  values,
  handleChange,
  handleCitySelectionChange,
  handleComplexChange,
  handleStreetChange,
  handleOfficeChange,
  deliveryAddressOrOfficeTypes,
  showDiscountText
}: IDeliveryOptionsProps) => {
  const { t } = useTranslation();
  const handleCityChange = (value: ICity) => {
    handleCitySelectionChange(value);
    handleChange(AddressDeliveryFields.City, value);

    if (value) {
      handleChange(AddressDeliveryFields.PostCode, value.postCode);
    } else {
      handleChange(AddressDeliveryFields.PostCode, '');
    }
  };

  return (
    <RadioButtons
      options={deliveryAddressOrOfficeTypes}
      onValueChange={onChange}
      value={value}
    >
      {+value === HandoverType.Office && (
          <article className="flex flex-column delivery-options office-delivery">
            <CityDropdown
                selectedCity={selectedCity}
                handleCityChange={(value: ICity) => {
                  handleCityChange(value);
                }}
                error={errors.city}
            />
            <OfficeDropdown
                disabled={!selectedCity?.id}
                selectedOffice={selectedOffice}
                handleOfficeChange={(value: IOffice) => {
                  handleOfficeChange(value);
                  handleChange(AddressDeliveryFields.Office, value);
                }}
                siteId={+selectedCity?.id ?? undefined}
                error={errors.office}
            />
          </article>
      )}
      {+value === HandoverType.Address && (
        <article className="flex flex-column delivery-options address-delivery">
          {showDiscountText && (
              <strong className="address-delivery-discount-text">
                {t('shoppingCart.deliveryOptions.addressDeliveryDiscount')}
              </strong>
          )}
          <div className="flex flex-column small-row-gap">
            <CityDropdown
              selectedCity={selectedCity}
              handleCityChange={(value: ICity) => {
                handleCityChange(value);
              }}
              error={errors.city}
            />
            <FormControl
              name={AddressDeliveryFields.PostCode}
              value={values.postCode}
              error={errors.postCode}
              shouldUpdateValue
              labelText={t('common.postCode')}
              type={FormControlType.text}
              readOnly
            />
          </div>
          <ComplexDropdown
            siteId={selectedCity?.id}
            selectedComplex={selectedComplex}
            handleComplexChange={(value: IComplex) => {
              handleComplexChange(value);
              handleChange(AddressDeliveryFields.Complex, value);
            }}
            error={errors.complex}
            disabled={!selectedCity?.id}
          />
          <StreetDropdown
            siteId={selectedCity?.id}
            selectedStreet={selectedStreet}
            handleStreetChange={(value: IStreet) => {
              handleStreetChange(value);
              handleChange(AddressDeliveryFields.Street, value);
            }}
            error={errors.street}
            disabled={!selectedCity?.id}
          />
          <div className="flex row-gap">
            <FormControl
              name={AddressDeliveryFields.BlockNo}
              value={values.blockNo}
              labelText={t('common.block')}
              shouldUpdateValue
              error={errors.blockNo}
              type={FormControlType.text}
              onChange={(value) => {
                handleChange(AddressDeliveryFields.BlockNo, value);
              }}
            />
            <FormControl
              name={AddressDeliveryFields.EntranceNo}
              value={values.entranceNo}
              labelText={t('common.entrance')}
              error={errors.entranceNo}
              shouldUpdateValue
              type={FormControlType.text}
              onChange={(value) => {
                handleChange(AddressDeliveryFields.EntranceNo, value);
              }}
            />
            <FormControl
              name={AddressDeliveryFields.FloorNo}
              value={values.floorNo}
              labelText={t('common.floor')}
              shouldUpdateValue
              type={FormControlType.text}
              error={errors.floorNo}
              onChange={(value) => {
                handleChange(AddressDeliveryFields.FloorNo, value);
              }}
            />
            <FormControl
              name={AddressDeliveryFields.ApartmentNo}
              value={values.apartmentNo}
              labelText={t('common.apartment')}
              shouldUpdateValue
              type={FormControlType.text}
              error={errors.apartmentNo}
              onChange={(value) => {
                handleChange(AddressDeliveryFields.ApartmentNo, value);
              }}
            />
          </div>
          <FormControl
            name={AddressDeliveryFields.AddressNote}
            value={values.addressNote}
            labelText={t('common.additionalNotes')}
            shouldUpdateValue
            type={FormControlType.text}
            error={errors.addressNote}
            onChange={(value) => {
              handleChange(AddressDeliveryFields.AddressNote, value);
            }}
            className="address-note"
          />
        </article>
      )}
    </RadioButtons>
  );
};

export default DeliveryOptions;
