enum StorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage'
}

const setStorageItem = (key: string, value: any, storageType = StorageType.LocalStorage) => {
  try {
    const valueToStore = JSON.stringify(value);
    window[storageType].setItem(key, valueToStore);
  } catch (error: any) {
    console.error(`Error setting ${storageType} item: ${error}`);
  }
};

const getStorageItem = (key: string, storageType = StorageType.LocalStorage) => {
  try {
    const item = window[storageType].getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error: any) {
    console.error(`Error getting ${storageType} item: ${error}`);
    return null;
  }
};

const removeStorageItem = (key: string, storageType = StorageType.LocalStorage) => {
  try {
    window[storageType].removeItem(key);
  } catch (error: any) {
    console.error(`Error removing ${storageType} item: ${error}`);
  }
};

const dispatchStorageEvent = (event: string, updatedItems: any) => {
  window.dispatchEvent(new CustomEvent(event, { detail: updatedItems }));
};

export {
  setStorageItem,
  getStorageItem,
  removeStorageItem,
  dispatchStorageEvent,
  StorageType
};
