import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './NumberControl.scss';

interface INumberControlProps {
  text: string
  number: number
  onIncrement: () => void
  onDecrement: () => void
  disabled?: boolean
}

const NumberControl = ({ text, number, onIncrement, onDecrement, disabled }: INumberControlProps) => (
  <div className="flex align-center number-control">
    <span className="number-control-text">{text}</span>
    <IconButton onClick={onDecrement} aria-label="decrement" disabled={disabled}>
      <RemoveIcon/>
    </IconButton>
    <span className="number-control-number">{number}</span>
    <IconButton onClick={onIncrement} aria-label="increment" disabled={disabled}>
      <AddIcon/>
    </IconButton>
  </div>
);

export default NumberControl;
