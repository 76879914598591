import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType } from 'components/button/Button';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import { type IPrice } from 'types/IProduct';
import { getCurrencySymbol } from 'utils/currency';
import './PaidAdCard.scss';

interface IPaidAdCardProps extends IOptionalClassName {
  id: number
  description: string
  icon: ReactNode
  daysAvailable: number
  price: IPrice
  onClick: () => void
}

const PaidAdCard = ({ id, description, icon, daysAvailable, price, onClick, className }: IPaidAdCardProps) => {
  const { t } = useTranslation();

  const transformText = (text: string): string[] => {
    const words = text.split(' ');

    return [
      words.slice(0, 2).join(' '),
      words[2],
      words.slice(3).join(' ')
    ];
  };

  return (
    <article className={concatClassNames('flex flex-column align-center paid-ad-card', className)}>
      {icon}
      <div className="text-center paid-ad-card-title">
        {id === 2
          ? (
              transformText(description).map((word, index) => <span key={index}>{word}</span>)
            )
          : (
            <span>{description}</span>
            )}
      </div>
      <div className="flex flex-column paid-ad-card-duration text-center">
        <span className="paid-ad-card-duration-text">{t('paidAds.duration')}</span>
        <span className="paid-ad-card-duration-days">{daysAvailable} {t('paidAds.days')}</span>
      </div>
      <span className="paid-ad-card-price">
        {price.amount} {getCurrencySymbol(price.currency)}
      </span>
      <Button
        type={ButtonType.primary}
        onClick={onClick}
        text={t('common.choose')}
        className="paid-ad-card-button"
      />
    </article>
  );
};

export default PaidAdCard;
