import { createValidator, matchesRegex, maxLength, minLength } from 'utils/validators/validators';

const MinEmailLength = 3;
const MaxEmailLength = 256;
const MinUsernameLength = 3;
const MaxUsernameLength = 256;
const MinPasswordLength = 8;
const MaxPasswordLength = 32;
const PasswordRegularExpression = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/; // This regex checks for at least 1 uppercase, 1 lowercase, 1 number and minimum 8 characters in total
const EmailRegularExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const UsernameRegularExpression = /^[a-zA-Z0-9\-._@+]+$/;

const emailValidator = createValidator(
  minLength(MinEmailLength),
  maxLength(MaxEmailLength),
  matchesRegex(EmailRegularExpression, 'emailValidation')
);

const usernameValidator = createValidator(
  minLength(MinUsernameLength),
  maxLength(MaxUsernameLength),
  matchesRegex(UsernameRegularExpression, 'usernameValidation')
);

const passwordValidator = createValidator(
  minLength(MinPasswordLength),
  maxLength(MaxPasswordLength),
  matchesRegex(PasswordRegularExpression, 'passwordValidation')
);

export {
  emailValidator,
  usernameValidator,
  passwordValidator
};
