import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  type IHttpConnectionOptions
} from '@microsoft/signalr';
import { getToken } from 'utils/token';
import { useNotification } from 'components/notification/Notification';
import { CART_ITEMS, CART_ITEMS_CHANGED_EVENT } from 'components/product/ProductActions';
import { dispatchStorageEvent, getStorageItem, setStorageItem } from 'utils/storage';
import { uniq } from 'lodash';

const RECEIVE_PRODUCT_UPDATED_NOTIFICATION = 'ReceiveProductUpdatedNotification';
const RECEIVE_PRODUCT_DELETED_NOTIFICATION = 'ReceiveProductDeletedNotification';

export const UPDATED_PRODUCTS = 'updatedProducts';
export const UPDATED_PRODUCTS_CHANGED_EVENT = 'updatedProductsChanged';

const useSignalR = (hubUrl: string) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  useEffect(() => {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => getToken(),
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    };

    const connection = new HubConnectionBuilder()
      .withUrl(hubUrl, options)
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => {
        console.log('Connection started');
      })
      .catch((error) => {
        console.error('Error while starting connection: ' + error);
      });

    connection.on(RECEIVE_PRODUCT_UPDATED_NOTIFICATION, (message: { id: number }) => {
      const updatedProducts: number[] = getStorageItem(UPDATED_PRODUCTS) || [];
      const combinedProducts = [...updatedProducts, message.id];
      const uniqueProducts = uniq(combinedProducts);
      setStorageItem(UPDATED_PRODUCTS, uniqueProducts);
      dispatchStorageEvent(UPDATED_PRODUCTS_CHANGED_EVENT, uniqueProducts);
    });

    connection.on(RECEIVE_PRODUCT_DELETED_NOTIFICATION, (productName: string) => {
      const cartItems = Number(getStorageItem(CART_ITEMS)) - 1;

      setStorageItem(CART_ITEMS, cartItems);
      dispatchStorageEvent(CART_ITEMS_CHANGED_EVENT, cartItems);

      showNotification(t('notifications.productDeleted', { productName }));
    });

    return () => {
      if (connection.state === HubConnectionState.Connected) {
        void connection.stop();
      }
    };
  }, [hubUrl]);
};

export default useSignalR;
