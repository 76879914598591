import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtons from 'components/radio-buttons/RadioButtons';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import useForm from 'hooks/useForm';
import { validateInvoiceFields } from 'utils/validators/invoiceValidators';

export enum InvoiceOptions {
  Company = 1,
  Person = 2
}

enum InvoiceCompanyFields {
  UnifiedIdentificationCode = 'unifiedIdentificationCode',
  CompanyName = 'companyName',
  Address = 'address',
  City = 'city',
  Country = 'country',
  VatNumber = 'vatNumber',
  AccountablePerson = 'accountablePerson'
}

enum InvoicePersonFields {
  FullName = 'fullName',
  UnifiedIdentificationCode = 'unifiedIdentificationCode',
  Country = 'country',
  City = 'city',
  Address = 'address'
}

const initialInvoiceCompanyValues = {
  [InvoiceCompanyFields.UnifiedIdentificationCode]: '',
  [InvoiceCompanyFields.CompanyName]: '',
  [InvoiceCompanyFields.Address]: '',
  [InvoiceCompanyFields.City]: '',
  [InvoiceCompanyFields.Country]: '',
  [InvoiceCompanyFields.VatNumber]: '',
  [InvoiceCompanyFields.AccountablePerson]: ''
};

const initialInvoicePersonValues = {
  [InvoicePersonFields.FullName]: '',
  [InvoicePersonFields.UnifiedIdentificationCode]: '',
  [InvoicePersonFields.Country]: '',
  [InvoicePersonFields.City]: '',
  [InvoicePersonFields.Address]: ''
};

const CartInvoice = ({ onInvoiceDataChange }: any) => {
  const { t } = useTranslation();

  const InvoiceCompanyFieldLabels: Record<InvoiceCompanyFields, string> = {
    [InvoiceCompanyFields.UnifiedIdentificationCode]: t('shoppingCart.paymentOptions.eik'),
    [InvoiceCompanyFields.CompanyName]: t('shoppingCart.paymentOptions.companyName'),
    [InvoiceCompanyFields.AccountablePerson]: t('shoppingCart.paymentOptions.accountablePerson'),
    [InvoiceCompanyFields.VatNumber]: t('shoppingCart.paymentOptions.vatNumber'),
    [InvoiceCompanyFields.Country]: t('shoppingCart.paymentOptions.country'),
    [InvoiceCompanyFields.City]: t('shoppingCart.paymentOptions.city'),
    [InvoiceCompanyFields.Address]: t('shoppingCart.paymentOptions.registrationAddress')
  };

  const InvoicePersonFieldLabels: Record<InvoicePersonFields, string> = {
    [InvoicePersonFields.FullName]: t('shoppingCart.paymentOptions.fullName'),
    [InvoicePersonFields.UnifiedIdentificationCode]: t('shoppingCart.paymentOptions.unifiedIdentificationCode'),
    [InvoicePersonFields.Country]: t('shoppingCart.paymentOptions.country'),
    [InvoicePersonFields.City]: t('shoppingCart.paymentOptions.city'),
    [InvoicePersonFields.Address]: t('shoppingCart.paymentOptions.address')
  };
  const [invoiceType, setInvoiceType] = useState(InvoiceOptions.Company);

  const initialValues = useMemo(() => (
    invoiceType === InvoiceOptions.Company ? initialInvoiceCompanyValues : initialInvoicePersonValues
  ), [invoiceType]);

  const onSubmit = async () => {
  };

  const { values, handleChange, errors } = useForm(initialValues, onSubmit, validateInvoiceFields);

  useEffect(() => {
    onInvoiceDataChange((prevState: any) => ({ ...prevState, type: invoiceType }));
  }, [invoiceType]);

  const handleInvoiceTypeChange = useCallback((newValue: any) => {
    setInvoiceType(Number(newValue));
  }, []);

  useEffect(() => {
    onInvoiceDataChange((prevState: any) => ({ ...prevState, errors }));
  }, [errors]);

  const renderFormControls = (fields: Record<string, string>) => {
    return Object.entries(fields).map(([fieldName, fieldLabel]) => (
      <FormControl
        key={fieldName}
        name={fieldName}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-expect-error
        value={values[fieldName]}
        labelText={fieldLabel}
        shouldUpdateValue
        type={FormControlType.text}
        error={errors[fieldName]}
        onChange={(value) => {
          onInvoiceDataChange((prevState: any) => ({ ...prevState, [fieldName]: value }));
          handleChange(fieldName, value);
        }}
      />
    ));
  };

  return (
    <div className="cart-invoice">
      <RadioButtons
        options={[
          { value: InvoiceOptions.Company, label: t('shoppingCart.paymentOptions.company') },
          { value: InvoiceOptions.Person, label: t('shoppingCart.paymentOptions.individual') }
        ]}
        onValueChange={handleInvoiceTypeChange}
        value={invoiceType}
      />
      {invoiceType === InvoiceOptions.Company && (
        <article className="cart-invoice-fields">
          {renderFormControls(InvoiceCompanyFieldLabels)}
        </article>
      )}
      {invoiceType === InvoiceOptions.Person && (
        <article className="cart-invoice-fields">
          {renderFormControls(InvoicePersonFieldLabels)}
        </article>
      )}
    </div>
  );
};

export default CartInvoice;
