import { useTranslation } from 'react-i18next';
import Popup, { type IPopup } from 'components/popup/Popup';
import { Button, ButtonType } from 'components/button/Button';
import { ReactComponent as HeartIcon } from 'assets/icons/white-heart.svg';
import useFavouriteProducts from 'hooks/useFavouriteProducts';
import './CartSellerConflictPopup.scss';

interface ICartSellerConflictPopup extends IPopup {
  productId: number
}

const CartSellerConflictPopup = ({ productId, open, onClose }: ICartSellerConflictPopup) => {
  const { t } = useTranslation();
  const [isFavourite, updateFavouriteProducts] = useFavouriteProducts(productId) as [boolean, (isAdding: boolean) => Promise<void>];

  const text = isFavourite
    ? t('productActions.removeFromFavorites')
    : t('productActions.addToFavourites');

  const onAddToFavourites = async () => {
    try {
      await updateFavouriteProducts(!isFavourite);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      className="cart-seller-conflict-popup"
      shouldShowCloseIcon={false}
    >
      <div className="flex flex-column" style={{ gap: 8 }}>
        <h2 className="text-center cart-seller-conflict-popup-title">
          {t('productActions.cartSellerConflictText1')}
        </h2>
        <span className="text-center cart-seller-conflict-popup-description">
          {t('productActions.cartSellerConflictText2')}
        </span>
      </div>
      <div className="flex justify-center align-center cart-seller-conflict-popup-actions">
        <Button
          onClick={onClose}
          className="cart-seller-conflict-popup-actions-close"
          type={ButtonType.secondary}
          text={t('common.close')}
        />
        <Button
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onClick={onAddToFavourites}
          className="cart-seller-conflict-popup-actions-add-to-favorites"
          type={ButtonType.primary}
          text={text}
          icon={<HeartIcon/>}
        />
      </div>
    </Popup>
  );
};

export default CartSellerConflictPopup;
