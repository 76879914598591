import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import concatClassNames from 'utils/classNames';
import { ButtonState, LinkButton, LinkButtonType } from 'components/button/Button';
import { ROUTES } from 'routes/routes';
import Form from 'components/forms/Form';
import { type IClassName } from 'types/common/Props';
import { useRegisterMutation } from 'store/api/userApi';
import HorizontalTextSeparator from 'components/common/horizontal-text-separator/HorizontalTextSeparator';
import GoogleLoginButton from 'components/common/social-login-button/GoogleLoginButton';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import ConfirmEmailPopup from 'components/confirm-email/ConfirmEmailPopup';
import useForm from 'hooks/useForm';
import { registerValidator } from 'utils/validators/authValidator';
import './Register.scss';

export enum FieldName {
  Email = 'email',
  Username = 'username',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  AcceptTerms = 'acceptTerms',
}

interface IRegister extends IClassName {
}

const initialValues = {
  [FieldName.Username]: '',
  [FieldName.Email]: '',
  [FieldName.Password]: '',
  [FieldName.ConfirmPassword]: '',
  [FieldName.AcceptTerms]: false
};

const Register = ({ className }: IRegister) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [register, { isLoading }] = useRegisterMutation();
  const [openPopup, setOpenPopup] = useState(false);

  const onSubmit = async (values: any) => {
    const { username, email, password, confirmPassword, acceptTerms } = values;

    if (!acceptTerms) {
      return;
    }

    const response: any = await register({
      username,
      email,
      password,
      confirmPassword
    });

    if (response.error) {
      if (response.error.data.some((e: string) => e.includes('Email'))) {
        throw new Error(t('register.emailAlreadyExists', { email }));
      }

      if (response.error.data.some((e: string) => e.includes('Username'))) {
        throw new Error(t('register.usernameAlreadyExists', { username }));
      }

      throw new Error(t('errors.anErrorOccurred'));
    }

    setOpenPopup(true);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors
  } = useForm(initialValues, onSubmit, registerValidator, t('register.success'));

  const handleClosePopup = () => {
    setOpenPopup(false);
    navigate(ROUTES.Home.path);
  };

  const disableSubmitButton = (values: any): boolean => {
    if (!values) {
      return false;
    }

    const { username, email, password, confirmPassword, acceptTerms } = values;

    return !username || !email || !password || !confirmPassword || !acceptTerms || isLoading;
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{t('register.title')}</title>
        </Helmet>
      </HelmetProvider>
      <div className={concatClassNames(className, 'register')}>
        <section className="flex flex-column justify-center align-center register-login-redirect">
          <p className="text-center register-login-redirect-text">
            {t('register.alreadyHaveAccount')}
          </p>
          <LinkButton
              type={LinkButtonType.secondary}
              to={ROUTES.Login.path}
              className="register-login-redirect-link"
          >
            {t('register.loginToAccount')}
          </LinkButton>
        </section>
        <section className="flex flex-column justify-center align-center register-content">
          <article className="flex flex-column register-content-login-links">
            <GoogleLoginButton className="register-login-redirect-link"/>
            {/* <FacebookLoginButton className="register-login-redirect-link"/> */}
          </article>
          <HorizontalTextSeparator word={t('common.or')}/>
          <article className="flex flex-column justify-center align-center register-content-form">
            <h1 className="text-center register-content-form-title">
              {t('register.title')}
            </h1>
            <Form
                onSubmit={handleSubmit}
                submitButtonState={disableSubmitButton(values) ? ButtonState.disabled : ButtonState.enabled}
                submitText={t('register.createAccount')}
                className="flex flex-column register-form"
            >
              <FormControl
                  name={FieldName.Email}
                  value={values.email}
                  labelClassName="register-form-control-label"
                  labelText={t('register.email')}
                  type={FormControlType.email}
                  error={errors.email}
                  onChange={(value) => {
                    handleChange(FieldName.Email, value);
                  }}
              />
              <FormControl
                  name={FieldName.Username}
                  value={values.username}
                  labelClassName="register-form-control-label"
                  labelText={t('register.username')}
                  type={FormControlType.text}
                  error={errors.username}
                  onChange={(value) => {
                    handleChange(FieldName.Username, value);
                  }}/>
              <FormControl
                  name={FieldName.Password}
                  value={values.password}
                  labelClassName="register-form-control-label"
                  labelText={t('register.password')}
                  type={FormControlType.password}
                  error={errors.password}
                  onChange={(value) => {
                    handleChange(FieldName.Password, value);
                  }}
              />
              <FormControl
                  name={FieldName.ConfirmPassword}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  labelClassName="register-form-control-label"
                  labelText={t('register.confirmPassword')}
                  type={FormControlType.password}
                  onChange={(value) => {
                    handleChange(FieldName.ConfirmPassword, value);
                  }}
              />
              <FormControl
                  id={FieldName.AcceptTerms}
                  name={FieldName.AcceptTerms}
                  className="register-form-checkbox"
                  error={errors.acceptTerms}
                  labelClassName="register-form-checkbox-label"
                  labelText={
                    <span>
                {t('register.acceptTermsAndConditions')}
                      <a href={ROUTES.PrivatePolicy.path} className="text-underline link">
                  {t('register.privacyPolicy')}
                </a>
              </span>
                  } type={FormControlType.checkbox}
                  onChange={(value) => {
                    handleChange(FieldName.AcceptTerms, value);
                  }}/>
            </Form>
          </article>
        </section>
        {openPopup && (
            <ConfirmEmailPopup
                open={openPopup}
                onClose={handleClosePopup}
            />
        )}
      </div>
    </>
  );
};

export default Register;
