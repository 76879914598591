import i18n from 'i18next';
import { emailValidator, passwordValidator, usernameValidator } from 'utils/validators/formValidators';
import { type FormErrors } from 'hooks/useForm';

interface IFormValues {
  email: string
  password: string
  username?: string
  confirmPassword?: string
  acceptTerms?: boolean
}

type FieldValidator = () => string | null;

type FieldValidators = {
  [key in keyof IFormValues]?: FieldValidator;
};

const validateEmail = (email: string): string | null => emailValidator(email) ?? null;

const validateUsername = (username?: string): string | null => username ? usernameValidator(username) : null;

const validatePassword = (password: string): string | null => passwordValidator(password) ?? null;

const validateConfirmPassword = (password: string, confirmPassword?: string): string | null => password === confirmPassword ? null : i18n.t('validations.passwordsDoNotMatch');

const validateAcceptTerms = (acceptTerms?: boolean): string | null => acceptTerms ? null : i18n.t('validations.acceptTermsAndConditions');

const validateFields = (values: Partial<IFormValues>): FormErrors => {
  const errors: FormErrors = {};

  const fieldValidators: FieldValidators = {
    email: () => values.email ? validateEmail(values.email) : null,
    username: () => values.username ? validateUsername(values.username) : null,
    password: () => values.password ? validatePassword(values.password) : null,
    confirmPassword: () => values.password && values.confirmPassword ? validateConfirmPassword(values.password, values.confirmPassword) : null,
    acceptTerms: () => values.acceptTerms !== undefined ? validateAcceptTerms(values.acceptTerms) : null
  };

  for (const field in fieldValidators) {
    if (Object.prototype.hasOwnProperty.call(fieldValidators, field) && values[field as keyof IFormValues] !== undefined) {
      const validator = fieldValidators[field as keyof typeof fieldValidators];
      if (validator) {
        const error = validator();
        if (error) errors[field] = error;
      }
    }
  }

  return errors;
};

const registerValidator = (values: IFormValues): FormErrors => validateFields(values);

const loginValidator = (values: Pick<IFormValues, 'email' | 'password'>): FormErrors => {
  return {};
};

const resetPasswordValidator = (values: any): FormErrors => validateFields(values);

export {
  registerValidator,
  loginValidator,
  validateEmail,
  validateUsername,
  validatePassword,
  validateConfirmPassword,
  resetPasswordValidator
};
