import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: any[]
  }
}

const usePageViews = () => {
  const location = useLocation();

  useEffect(
    () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        pageType: location.pathname + location.search,
        pageTitle: document.title
      });
    },
    [location]
  );
};

export { usePageViews };
