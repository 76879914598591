import Cld from 'cloudinary-config';
import { AdvancedImage } from '@cloudinary/react';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { type IOptionalClassName } from 'types/common/Props';

export enum ThumbnailSize {
  Small = 'small',
  Large = 'large',
  None = 'none'
}

interface ICloudinaryImage extends IOptionalClassName {
  imagePublicId: string
  thumbnailSize?: ThumbnailSize
}

const CloudinaryImage = ({ imagePublicId, thumbnailSize = ThumbnailSize.None, className }: ICloudinaryImage) => {
  const cloudinaryImage = Cld.image(imagePublicId);

  switch (thumbnailSize) {
    case 'small':
      cloudinaryImage.resize(thumbnail().width(120).height(120));
      break;
    case 'large':
      cloudinaryImage.resize(thumbnail().width(200).height(200));
      break;
    default:
      break;
  }

  return <AdvancedImage cldImg={cloudinaryImage} className={className}/>;
};

export default CloudinaryImage;
