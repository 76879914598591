import { useTranslation } from 'react-i18next';
import PinkDotsBg from 'assets/images/home/pink-dots.png';
import BoysCategoryImage from 'assets/images/home/boys-category.png';
import GirlsCategoryImage from 'assets/images/home/girls-category.png';
import BoysCategoryImageHover from 'assets/images/home/boys-category-hover.png';
import GirlsCategoryImageHover from 'assets/images/home/girls-category-hover.png';
import GreenDotsBg from 'assets/images/home/green-dots.png';
import HomeCategoryCard from 'components/home/HomeCategoryCard';
import { ROUTES } from 'routes/routes';
import './HomeCategories.scss';

const HomeCategories = () => {
  const { t } = useTranslation();

  return (
    <section className="flex justify-center top-section-padding home-categories">
      <HomeCategoryCard
        href={ROUTES.AllProducts.path + '/boys'}
        imgSrc={BoysCategoryImage}
        text={t('home.shopForBoys')}
        className="home-categories-card boys"
        hoverImgSrc={BoysCategoryImageHover}
        hoverBgImage={GreenDotsBg}
      />
      <HomeCategoryCard
        href={ROUTES.AllProducts.path + '/girls'}
        imgSrc={GirlsCategoryImage}
        text={t('home.shopForGirls')}
        className="home-categories-card girls"
        hoverImgSrc={GirlsCategoryImageHover}
        hoverBgImage={PinkDotsBg}
      />
    </section>
  );
};

export default HomeCategories;
