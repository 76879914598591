import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ErrorFormControl from 'components/forms/ErrorFormControl';
import './TextEditor.scss';

interface IGenericEditorProps {
  value: string
  name: string
  onChange: (value: string) => void
  label?: string
  error?: string
}

const API_KEY = '67jyshxkbe9pjc0t34lwlouph6fcv827qoiphootenuymgy6';

const TextEditor = ({ value, name, onChange, label, error }: IGenericEditorProps) => {
  return (
    <div className="text-editor">
      {label && <label htmlFor={name} className="text-editor-label">{label}</label>}
      <Editor
        apiKey={API_KEY}
        value={value}
        textareaName={name}
        init={{
          branding: false,
          height: 400,
          menubar: true,
          plugins:
            'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
          toolbar:
            'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
          image_advtab: true,
          setup: (editor) => {
            editor.on('GetContent', function (e) {
              const div = document.createElement('div');
              div.innerHTML = e.content;
              const imgs = div.getElementsByTagName('img');

              for (let i = 0; i < imgs.length; i++) {
                const img = imgs[i];
                const width = img.getAttribute('width');
                const height = img.getAttribute('height');

                if (width || height) {
                  let style = img.getAttribute('style') || '';

                  if (width) {
                    style += 'width:' + width + 'px;';
                    img.removeAttribute('width');
                  }

                  if (height) {
                    style += 'height:' + height + 'px;';
                    img.removeAttribute('height');
                  }

                  img.setAttribute('style', style);
                }
              }

              e.content = div.innerHTML;
            });
          }
        }}
        onEditorChange={onChange}
      />
      {error && <ErrorFormControl error={error}/>}
    </div>
  );
};

export default TextEditor;
