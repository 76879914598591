import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddFavouriteProductMutation, useRemoveFavouriteProductMutation } from 'store/api/productApi';
import { redirectTo, ROUTES } from 'routes/routes';
import { dispatchStorageEvent, getStorageItem, setStorageItem } from 'utils/storage';
import { isUserLoggedIn } from 'utils/auth';
import { useNotification } from 'components/notification/Notification';

export const FAVOURITE_PRODUCTS = 'favouriteProducts';
export const FAVOURITE_PRODUCTS_CHANGED_EVENT = 'favouriteProductsChanged';

export const REMOVE_FROM_FAVOURITES = 'removeFromFavourites';

const useFavouriteProducts = (productId: number) => {
  const isLoggedIn = isUserLoggedIn();

  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const [isFavourite, setIsFavourite] = useState(false);
  const [addFavourite] = useAddFavouriteProductMutation();
  const [removeFavourite] = useRemoveFavouriteProductMutation();

  useEffect(() => {
    const favouriteProducts = getStorageItem(FAVOURITE_PRODUCTS) || [];
    setIsFavourite(favouriteProducts.includes(productId));
  }, [productId]);

  const updateFavouriteProducts = useCallback(async (add: boolean) => {
    if (!isLoggedIn) {
      redirectTo(ROUTES.Login.path);
      return;
    }

    try {
      const favouriteProducts = getStorageItem(FAVOURITE_PRODUCTS) || [];
      if (add && !favouriteProducts.includes(productId)) {
        await addFavourite(productId);
        setFavourites([...favouriteProducts, productId]);
        showNotification(t('productActions.addToFavouritesSuccess'));
      } else {
        await removeFavourite(productId);
        setFavourites(favouriteProducts.filter((id: number) => id !== productId));
        dispatchStorageEvent(REMOVE_FROM_FAVOURITES, productId);
        showNotification(t('productActions.removeFromFavouritesSuccess'));
      }
    } catch (error) {
      console.error('Error updating favourite products:', error);
    }
  }, [productId]);

  const setFavourites = useCallback((newFavourites: number[]) => {
    setStorageItem(FAVOURITE_PRODUCTS, newFavourites);
    dispatchStorageEvent(FAVOURITE_PRODUCTS_CHANGED_EVENT, newFavourites);
    setIsFavourite(newFavourites.includes(productId));
  }, [productId]);

  return [isFavourite, updateFavouriteProducts];
};

export default useFavouriteProducts;
