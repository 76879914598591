import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type IOptionalClassName } from 'types/common/Props';
import { useCustomerDetails } from 'pages/profile/Profile';
import Tabs from 'components/tabs/Tabs';
import ProfileOrders from 'pages/profile-payments/ProfileOrders';
import ProfileProfit from 'pages/profile-payments/ProfileProfit';
import useWindowWidth from 'hooks/useWindowWidth';
import Dropdown, { type OptionType } from 'components/dropdown/Dropdown';
import ProfileSubscriptions from 'pages/profile-payments/ProfileSubscriptions';
import './ProfilePayments.scss';

interface IProfilePayments extends IOptionalClassName {
}

enum ProfilePaymentsTab {
  Orders = 'Поръчки',
  Profit = 'Приходи',
  Subscriptions = 'Абонаменти'
}

const ProfilePaymentsTabs = ({ className }: IProfilePayments) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();
  const { customerDetails } = useCustomerDetails();
  const [selectedTab, setSelectedTab] = useState(ProfilePaymentsTab.Orders);

  const tabs = [
    {
      label: ProfilePaymentsTab.Orders,
      content: <ProfileOrders/>,
      name: t('profile.payments.orders')
    },
    {
      label: ProfilePaymentsTab.Profit,
      content: <ProfileProfit/>,
      disabled: !customerDetails?.hasPermissionToSell,
      name: t('profile.payments.income')
    },
    {
      label: ProfilePaymentsTab.Subscriptions,
      content: <ProfileSubscriptions/>,
      disabled: !customerDetails?.hasPermissionToSell,
      name: t('profile.payments.subscriptions')
    }
  ];

  const dropdownOptions: OptionType[] = tabs.map(tab => ({
    id: tab.label,
    name: tab.label === ProfilePaymentsTab.Orders
      ? t('profile.payments.orders')
      : tab.label === ProfilePaymentsTab.Profit
        ? t('profile.payments.income')
        : t('profile.payments.subscriptions')
  }));

  const handleDropdownChange = (event: any) => {
    setSelectedTab(event);
  };

  const renderContent = (): React.ReactElement => {
    const activeTab = tabs.find(tab => tab.label === selectedTab);
    return activeTab && isMobile ? activeTab.content : <></>;
  };

  return (
    <div>
      {isMobile
        ? (
          <Dropdown
            label=""
            options={dropdownOptions}
            value={selectedTab}
            onChange={handleDropdownChange}
            multiple={false}
            className="profile-payments-dropdown"
          />
          )
        : (
          <Tabs
            className="profile-payments-tabs flex flex-column full-width"
            tabsData={tabs}
            ariaLabel="profile tabs"
          />
          )}
      {renderContent()}
    </div>
  );
};

export default ProfilePaymentsTabs;
