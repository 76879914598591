import { useTranslation } from 'react-i18next';
import { type IPopup } from 'components/popup/Popup';
import StyledPopup from 'components/common/styled-popup/StyledPopup';

interface IBadgeActivationPopup extends IPopup {
  onAccept: () => void
}

const AddToCarouselConfirmationPopup = ({ open, onClose, onAccept }: IBadgeActivationPopup) => {
  const { t } = useTranslation();

  return (
    <StyledPopup
      open={open}
      onClose={onClose}
      shouldShowCloseIcon={false}
      onAccept={onAccept}
      className="add-to-carousel-confirmation-popup"
      title={t('productActions.areYouSureYouWantToAddProductToHomePage')}
    />
  );
};

export default AddToCarouselConfirmationPopup;
