import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import { isUserGeneralAdministrator, isUserMarketingAdministrator, isUserRegularAdministrator } from 'utils/auth';
import { type IClassName } from 'types/common/Props';
import AdminBlogTab from 'components/administration/blog/AdminBlogTab';
import AdminUsersTab from 'components/administration/users/AdminUsersTab';
import AdminProductsTab from 'components/administration/products/AdminProductsTab';
import AdminOrdersTab from 'components/administration/orders/AdminOrdersTab';
import AdminPaymentsTab from 'components/administration/payments/AdminPaymentsTab';
import AdminBannerTabs from 'components/administration/banners/AdminBannerTabs';
import AdminReviewsTab from 'components/administration/reviews/AdminReviewsTab';
import AdminPagesTab from '../../components/administration/pages/AdminPagesTab';
import AdminCategoriesTab from '../../components/administration/categories/AdminCategoriesTab';

import Tabs from 'components/tabs/Tabs';
import './Administration.scss';

interface IAdministrationProps extends IClassName {
}

enum AdministrationTabs {
  Blog = 'Blog',
  Banners = 'Banners',
  Users = 'Users',
  Orders = 'Orders',
  Products = 'Products',
  Payments = 'Payments',
  Reviews = 'Reviews',
  Pages = 'Pages',
  Categories = 'Categories'
}

const Administration = ({ className }: IAdministrationProps) => {
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const tabsArray = [];

    if (isUserGeneralAdministrator() || isUserRegularAdministrator()) {
      tabsArray.push({
        label: AdministrationTabs.Users,
        content: <AdminUsersTab/>,
        name: t('administration.users.title')
      });
      tabsArray.push({
        label: AdministrationTabs.Orders,
        content: <AdminOrdersTab/>,
        name: t('administration.orders.title')
      });
      tabsArray.push({
        label: AdministrationTabs.Products,
        content: <AdminProductsTab/>,
        name: t('administration.products.title')
      });
      tabsArray.push({
        label: AdministrationTabs.Reviews,
        content: <AdminReviewsTab/>,
        name: t('administration.reviews.title')
      });
    }

    if (isUserGeneralAdministrator()) {
      tabsArray.push({
        label: AdministrationTabs.Payments,
        content: <AdminPaymentsTab/>,
        name: t('administration.payments.title')
      });
    }

    if (isUserGeneralAdministrator() || isUserRegularAdministrator() || isUserMarketingAdministrator()) {
      tabsArray.push({
        label: AdministrationTabs.Categories,
        content: <AdminCategoriesTab/>,
        name: t('administration.categories.title')
      });
      tabsArray.push({
        label: AdministrationTabs.Blog,
        content: <AdminBlogTab/>,
        name: t('administration.blog.title')
      });
      tabsArray.push({
        label: AdministrationTabs.Banners,
        content: <AdminBannerTabs/>,
        name: t('administration.banners.title')
      });
      tabsArray.push({
        label: AdministrationTabs.Pages,
        content: <AdminPagesTab/>,
        name: t('administration.pages.title')
      });
    }

    return tabsArray;
  }, []);

  return (
    <section className={concatClassNames(className, 'administration')}>
      <h1 className="text-center fs-31 administration-title">
        {t('administration.title')}
      </h1>
      <Tabs tabsData={tabs} ariaLabel="administration tabs"/>
    </section>
  );
};

export default Administration;
