import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/dropdown/Dropdown';
import { useGetProductsSizesQuery } from 'store/api/productApi';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import { localizeSize } from 'pages/all-products/filter-utils';

// interface Size {
//   id: number
//   name: string
// }

interface ISizesDropdownProps extends IOptionalClassName {
  categoryId: number | null
  selectedSize: number
  setSelectedSize: any
  handleSizeChange: (event: React.ChangeEvent<{
    value: unknown
  }>) => void
  disabled?: boolean
  error?: string
  onClick?: any
}

const SizesDropdown = ({
  categoryId,
  selectedSize,
  setSelectedSize,
  handleSizeChange,
  disabled,
  className,
  onClick,
  error
}: ISizesDropdownProps) => {
  const { t } = useTranslation();

  const { data: allSizes, isLoading, isError } = useGetProductsSizesQuery(categoryId);

  const localizedSizes = useMemo(
    () => allSizes?.map((size: any) => ({
      ...size,
      name: Number(size.name) ? size.name : t(localizeSize(size.name))
    })),
    [allSizes]
  );

  if (isLoading) return <div>{t('common.loading')}</div>;
  if (isError) return <div>{t('common.errorLoadingSizes')}</div>;

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Dropdown
      label={t('common.chooseSize')}
      options={localizedSizes}
      value={selectedSize ?? ''}
      onChange={handleSizeChange}
      disabled={disabled || allSizes.length === 0}
      multiple={false}
      error={error}
      className={concatClassNames(className, 'sizes-dropdown')}
      onClick={handleOnClick}
    />
  );
};

export default SizesDropdown;
