import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import { useGetAllArticlesQuery, useGetArticleByCanonicalUrlQuery } from 'store/api/blogApi';
import { ReactComponent as FacebookShareIcon } from 'assets/icons/fb-share.svg';
import { ReactComponent as InstagramShareIcon } from 'assets/icons/ig-share.svg';
import { SOCIAL_MEDIA } from 'components/footer/Footer';
import { formatDateString, LocaleDateFormats } from 'utils/date';
import ArticleCard from 'components/cards/article-card/ArticleCard';
import useWindowWidth from 'hooks/useWindowWidth';
import Slider from 'components/slider/Slider';
import CloudinaryImage from 'components/common/cloudinary-img/CloudinaryImage';
import DOMPurify from 'dompurify';
import './BlogPost.scss';
import { getLocalizedValue } from 'utils/localization';
import { useI18n } from 'hooks/usei18n';
import { isNil } from 'lodash';
import { extractTextFromHtml } from 'utils/text';

interface IBlogPostProps extends IClassName {
}

const BlogPost = ({ className }: IBlogPostProps) => {
  const { t } = useTranslation();
  const { language, getLanguageUrl } = useI18n();

  const {
    canonicalUrl: canonicalUrlParam
  } = useParams();

  const canonicalUrl = useMemo(
    () => canonicalUrlParam ?? '',
    [canonicalUrlParam]
  );

  const localizedCanonicalUrl = useMemo(
    () => getLanguageUrl(`/${canonicalUrl}`),
    [getLanguageUrl, canonicalUrl]
  );

  const localizedDateFormat = useMemo(
    () => getLocalizedValue(language, LocaleDateFormats.bg, LocaleDateFormats.en),
    [language]
  );

  const { isMobile } = useWindowWidth();
  const { data: article } = useGetArticleByCanonicalUrlQuery({ canonicalUrl });
  const { data: articles } = useGetAllArticlesQuery({ pageSize: 4, isPublic: true });

  const descriptionContent = useMemo(
    () => {
      const {
        descriptionBulgarianContent,
        descriptionEnglishContent
      } = article || {};
      return getLocalizedValue(language, descriptionBulgarianContent ?? '', descriptionEnglishContent ?? '');
    },
    [article]
  );

  const descriptionMetaTagValue = useMemo(
    () => extractTextFromHtml(descriptionContent).substring(0, 140),
    [descriptionContent]
  );

  const sanitizedHtmlContent = useMemo(
    () => DOMPurify.sanitize(
      descriptionContent ?? ''
    ),
    [descriptionContent]
  );

  const filteredArticles = useMemo(
    () => articles?.models.filter(a => a.canonicalUrl !== canonicalUrl).slice(0, 3),
    [articles, canonicalUrl]
  );

  const mappedArticles = useMemo(
    () => filteredArticles?.map((article) => <ArticleCard key={article.id} article={article}/>) ?? [],
    [filteredArticles]
  );

  const title = useMemo(
    () => {
      const {
        titleBulgarianContent,
        titleEnglishContent
      } = article || {};
      return getLocalizedValue(language, titleBulgarianContent ?? '', titleEnglishContent ?? '');
    },
    [article]
  );

  const heading1Content = useMemo(
    () => {
      const {
        heading1BulgarianContent,
        heading1EnglishContent
      } = article || {};

      return getLocalizedValue(language, heading1BulgarianContent ?? '', heading1EnglishContent ?? '');
    },
    [article]
  );

  const heading2Content = useMemo(
    () => {
      const {
        heading2BulgarianContent,
        heading2EnglishContent
      } = article || {};

      return getLocalizedValue(language, heading2BulgarianContent ?? '', heading2EnglishContent ?? '');
    },
    [article]
  );

  const publishedOn = useMemo(
    () => {
      const { publishedOn } = article || {};

      if (isNil(publishedOn)) {
        return null;
      }

      return formatDateString(publishedOn, localizedDateFormat);
    },
    [article, localizedDateFormat]
  );

  if (isNil(article)) {
    return null;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={localizedCanonicalUrl}/>
          <meta name="robots" content={article?.isIndexed ? 'index' : 'noindex'}/>
          {article?.metaTags.map((tag, index) => (
            <meta key={index} name={tag.name} content={getLocalizedValue(language, tag.content, tag.contentEn)}/>
          ))}
          <meta name="description" content={descriptionMetaTagValue} />
        </Helmet>
      </HelmetProvider>
      <section className={concatClassNames(className, 'blog-post')}>
        <div className="flex flex-column justify-center align-center blog-post-wrapper">
          <article className="flex flex-column blog-post-content">
            <CloudinaryImage
              imagePublicId={article.images[0]}
              className="blog-post-content-image"
            />
            <div className=" blog-post-content-info flex space-between">
              {article?.publisherName && (
                <span>{t('blog.author')}: {article.publisherName}</span>
              )}
              <span>{publishedOn}</span>
            </div>
            <h1 className="blog-post-content-heading1">
              {heading1Content}
            </h1>
            <h2 className="blog-post-content-heading2">
              {heading2Content}
            </h2>
            <div className="blog-post-content-text" dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}></div>
          </article>
          <article className="flex justify-end align-center blog-post-share">
          <span className="blog-post-share-text">
            {t('blog.share')}
          </span>
            <a href={SOCIAL_MEDIA.facebook} target="_blank" rel="noopener noreferrer">
              <FacebookShareIcon/>
            </a>
            <a href={SOCIAL_MEDIA.instagram} target="_blank" rel="noopener noreferrer">
              <InstagramShareIcon/>
            </a>
          </article>
          {articles && articles?.models.length > 1 && (
            <article className="blog-post-read-more">
              <h3 className="blog-post-read-more-title">
                {t('blog.readMore')}
              </h3>
              {!isMobile && (
                <div className="blog-post-read-more-articles">
                  {mappedArticles}
                </div>
              )}
            </article>
          )}
        </div>
      </section>
      {isMobile && (
        <div className="blog-post-read-more-articles-slider-wrapper">
          <Slider
            slides={mappedArticles}
            pagination={true}
            spaceBetween={10}
            slidesToShow={2}
            className="blog-post-read-more-articles-slider"
          />
        </div>
      )}
    </>
  );
};

export default BlogPost;
