import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import type {
  IGetProductsByUserRequestBody,
  IGetProductsRequestBody,
  IProductForSaleApiResponse,
  IProductRequestBody,
  IProductResponseModel,
  IProductsMineResponseModel
} from 'types/IProduct';
import createHeaders from 'utils/createHeaders';

const PRODUCTS_URL = 'products';

export enum SortBy {
  Id = 'id',
  Date = 'date',
  Name = 'name',
  Price = 'price',
  Label = 'label',
  Seller = 'seller',
  Badge = 'badge'
}

export const productApi = createApi({
  reducerPath: 'products',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getProducts: builder.query<IProductResponseModel, IGetProductsRequestBody>({
      query: ({
        labels,
        sizes,
        genders,
        categories,
        onlyGifts,
        onlyInSale,
        onlyWithBadge,
        onlyForHomePage,
        sortBy = SortBy.Badge,
        order = Order.DESC,
        pageSize = DEFAULT_PAGE_SIZE,
        page = DEFAULT_PAGE,
        favourites
      }) => {
        let queryString = favourites
          ? `${PRODUCTS_URL}/favourites?pageSize=${pageSize}&page=${page}`
          : `${PRODUCTS_URL}?pageSize=${pageSize}&page=${page}`;

        if (labels) labels.forEach(label => queryString += `&labels=${label}`);
        if (sizes) sizes.forEach(size => queryString += `&sizes=${size}`);
        if (genders) genders.forEach(gender => queryString += `&genders=${gender}`);
        if (categories) categories.forEach(category => queryString += `&categories=${category}`);
        if (onlyGifts) queryString += '&onlyGifts=true';
        if (onlyInSale) queryString += '&onlyInSale=true';
        if (onlyWithBadge) queryString += '&onlyWithBadge=true';
        if (onlyForHomePage) queryString += '&onlyForHomePage=true';
        if (sortBy) queryString += `&sortBy=${sortBy}`;
        if (order) queryString += `&order=${order}`;

        return queryString;
      }
    }),
    getProductsByUser: builder.query<IProductResponseModel, IGetProductsByUserRequestBody>({
      query: ({
        labels,
        sizes,
        genders,
        categories,
        onlyGifts,
        onlyInSale,
        onlyWithBadge,
        onlyForHomePage,
        sortBy = SortBy.Badge,
        order = Order.DESC,
        pageSize = DEFAULT_PAGE_SIZE,
        page = DEFAULT_PAGE,
        userId
      }) => {
        let queryString = `${PRODUCTS_URL}/byuserid?pageSize=${pageSize}&page=${page}&userId=${userId}`;

        if (labels) labels.forEach(label => queryString += `&labels=${label}`);
        if (sizes) sizes.forEach(size => queryString += `&sizes=${size}`);
        if (genders) genders.forEach(gender => queryString += `&genders=${gender}`);
        if (categories) categories.forEach(category => queryString += `&categories=${category}`);
        if (onlyGifts) queryString += '&onlyGifts=true';
        if (onlyInSale) queryString += '&onlyInSale=true';
        if (onlyWithBadge) queryString += '&onlyWithBadge=true';
        if (onlyForHomePage) queryString += '&onlyForHomePage=true';
        if (sortBy) queryString += `&sortBy=${sortBy}`;
        if (order) queryString += `&order=${order}`;

        return queryString;
      }
    }),
    createProduct: builder.mutation({
      query: (product: IProductRequestBody) => {
        const headers = createHeaders();

        const formData = new FormData();
        formData.append('id', product.id as any);
        formData.append('nameEnglish', product.nameEnglish);
        formData.append('nameBulgarian', product.nameBulgarian);
        formData.append('isGift', product.isGift.toString());
        formData.append('price', product.price.toString());
        formData.append('weight', product.weight.toString());
        formData.append('label', product.label.toString());
        formData.append('descriptionEnglish', product.descriptionEnglish);
        formData.append('descriptionBulgarian', product.descriptionBulgarian);

        product.gendersIds.forEach((genderId, index) => {
          formData.append(`gendersIds[${index}]`, genderId as any);
        });

        product.categoriesIds.forEach((categoryId, index) => {
          formData.append(`categoriesIds[${index}]`, categoryId as any);
        });

        product.file.forEach((file, index) => {
          formData.append(`file[${index}]`, file);
        });

        product.inventoryItems.forEach((item, index) => {
          formData.append(`inventoryItems[${index}][quantity]`, item.quantity as any);
          formData.append(`inventoryItems[${index}][sizeId]`, item.sizeId as any);
        });

        return {
          url: PRODUCTS_URL,
          method: 'POST',
          body: formData,
          headers
        };
      }
    }),
    editProduct: builder.mutation({
      query: (product: IProductRequestBody) => {
        const headers = createHeaders();

        const formData = new FormData();
        formData.append('id', product.id.toString());
        formData.append('nameEnglish', product.nameEnglish);
        formData.append('nameBulgarian', product.nameBulgarian);
        formData.append('isGift', product.isGift.toString());
        formData.append('price', product.price.toString());
        formData.append('weight', product.weight.toString());
        formData.append('label', product.label.toString());
        formData.append('descriptionEnglish', product.descriptionEnglish);
        formData.append('descriptionBulgarian', product.descriptionBulgarian);

        product.gendersIds.forEach((genderId, index) => {
          formData.append(`gendersIds[${index}]`, genderId as any);
        });

        product.categoriesIds.forEach((categoryId, index) => {
          formData.append(`categoriesIds[${index}]`, categoryId as any);
        });

        if (product.filesToDelete) {
          product.filesToDelete.forEach((imgSrc, index) => {
            formData.append(`filesToDelete[${index}]`, imgSrc);
          });
        }

        if (product.file.every(item => typeof item === 'string')) {
          formData.append('file[0]', JSON.stringify([]));
        } else {
          product.file.forEach((file, index) => {
            formData.append(`file[${index}]`, file);
          });
        }

        product.inventoryItems.forEach((item, index) => {
          formData.append(`inventoryItems[${index}][quantity]`, item.quantity as any);
          formData.append(`inventoryItems[${index}][sizeId]`, item.sizeId as any);
        });

        return {
          url: `${PRODUCTS_URL}/${product.id}`,
          method: 'PUT',
          body: formData,
          headers
        };
      }
    }),
    getProductsCategories: builder.query({
      query: () => `${PRODUCTS_URL}/categories`
    }),
    getProductsSizes: builder.query({
      query: (categoryId) => `${PRODUCTS_URL}/sizes?categoryId=${categoryId}`
    }),
    getProductsGenders: builder.query({
      query: () => `${PRODUCTS_URL}/genders`
    }),
    getProductsForSale: builder.query<IProductForSaleApiResponse, {
      page?: number
      pageSize?: number
      seller?: string
      name?: string
      order?: Order
      sortBy?: SortBy
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        seller,
        name,
        sortBy,
        order
      }) => {
        let queryString = `${PRODUCTS_URL}/forSale?pageSize=${pageSize}&page=${page}`;

        if (seller) {
          queryString += `&seller=${seller}`;
        }

        if (name) {
          queryString += `&name=${name}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (order) {
          queryString += `&order=${order}`;
        }

        return queryString;
      }
    }),
    getFavouriteProducts: builder.query<IProductResponseModel, {
      page?: number
      pageSize?: number
      sortBy?: SortBy
      order?: Order
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        sortBy = SortBy.Date,
        order = Order.DESC
      }) => `${PRODUCTS_URL}/favourites?pageSize=${pageSize}&page=${page}&sortBy=${sortBy}&order=${order}`
    }),
    addFavouriteProduct: builder.mutation({
      query: (id: number) => ({
        url: `${PRODUCTS_URL}/${id}/addFavourite`,
        method: 'POST'
      })
    }),
    removeFavouriteProduct: builder.mutation({
      query: (id: number) => ({
        url: `${PRODUCTS_URL}/${id}/removeFavourite`,
        method: 'DELETE'
      })
    }),
    getProduct: builder.query({
      query: (id: number) => `${PRODUCTS_URL}/${id}`
    }),
    showOnHomePage: builder.mutation({
      query: (id: number) => ({
        url: `${PRODUCTS_URL}/${id}/ShowOnHomePage`,
        method: 'PUT',
        body: {}
      })
    }),
    applyBadge: builder.mutation({
      query: ({ id, badge }: { id: number, badge: number }) => ({
        url: `${PRODUCTS_URL}/${id}/applyBadge`,
        method: 'PUT',
        body: { badge }
      })
    }),
    getUserProducts: builder.query<IProductsMineResponseModel, {
      badge?: number
      sizes?: number[]
      labels?: number[]
      genders?: number[]
      categories?: number[]
      onlyGifts?: boolean
      onlyInSale?: boolean
      onlyForHomePage?: boolean
      sortBy?: SortBy
      order?: Order
      pageSize?: number
      page?: number
    }>({
      query: ({
        badge,
        sizes,
        labels,
        genders,
        categories,
        onlyGifts,
        onlyInSale,
        onlyForHomePage,
        sortBy,
        order,
        pageSize = DEFAULT_PAGE_SIZE,
        page = DEFAULT_PAGE
      }) => {
        let queryString = `${PRODUCTS_URL}/mine?pageSize=${pageSize}&page=${page}`;

        if (badge !== undefined) queryString += `&badge=${badge}`;
        if (sizes) sizes.forEach(size => queryString += `&sizes=${size}`);
        if (labels) labels.forEach(label => queryString += `&labels=${label}`);
        if (genders) genders.forEach(gender => queryString += `&genders=${gender}`);
        if (categories) categories.forEach(category => queryString += `&categories=${category}`);
        if (onlyGifts) queryString += '&onlyGifts=true';
        if (onlyInSale) queryString += '&onlyInSale=true';
        if (!onlyInSale) queryString += '&onlyInSale=false';
        if (onlyForHomePage) queryString += '&onlyForHomePage=true';
        if (sortBy) queryString += `&sortBy=${sortBy}`;
        if (order) queryString += `&order=${order}`;

        return queryString;
      }
    }),
    deleteProduct: builder.mutation<any, {
      id: number
    }>({
      query: ({ id }) => ({
        url: `${PRODUCTS_URL}/${id}`,
        method: 'DELETE'
      })
    }),
    archiveProduct: builder.mutation<any, {
      id: number
    }>({
      query: ({ id }) => ({
        url: `${PRODUCTS_URL}/${id}/archive`,
        method: 'DELETE'
      })
    })
  })
});

export const fetchSizesForCategory = async (categoryId: number) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}${PRODUCTS_URL}/sizes?categoryId=${categoryId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

export const {
  useGetProductsForSaleQuery,
  useCreateProductMutation,
  useGetProductsQuery,
  useGetProductsByUserQuery,
  useGetProductsCategoriesQuery,
  useGetProductsSizesQuery,
  useGetProductsGendersQuery,
  useAddFavouriteProductMutation,
  useGetFavouriteProductsQuery,
  useRemoveFavouriteProductMutation,
  useGetProductQuery,
  useGetUserProductsQuery,
  useEditProductMutation,
  useShowOnHomePageMutation,
  useApplyBadgeMutation,
  useDeleteProductMutation,
  useArchiveProductMutation
} = productApi;
