import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import { useGetAllArticlesQuery } from 'store/api/blogApi';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import Pagination from 'components/pagination/Pagination';
import ArticleCard from 'components/cards/article-card/ArticleCard';
import useWindowWidth from 'hooks/useWindowWidth';
import { ROUTES } from '../../routes/routes';
import PageSeoSection from '../../components/page-seo-section/PageSeoSection';
import './Blog.scss';

interface IBlogProps extends IClassName {
}

const PAGE_SIZE = {
  DESKTOP: 12,
  MOBILE: 5
};

const Blog = ({ className }: IBlogProps) => {
  const { isMobile } = useWindowWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = isMobile ? PAGE_SIZE.MOBILE : PAGE_SIZE.DESKTOP;

  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Blog.path });
  const { data: articles } = useGetAllArticlesQuery({ pageSize, page: currentPage, isPublic: true });

  return (
    <section className={concatClassNames(className, 'flex flex-column align-center blog')}>
      <PageSeoSection pageSeo={pageSeoData} />

      <article className="blog-content">
        <article className="blog-content-posts">
          {articles?.models.map(article => (
            <ArticleCard
              key={article.id}
              article={article}
            />
          ))}
        </article>
        <Pagination
          count={articles?.totalPages ?? 1}
          page={currentPage}
          onChange={setCurrentPage}
        />
      </article>
    </section>
  );
};

export default Blog;
