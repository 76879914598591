import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from 'locales/en/translation.json';
import translationBG from 'locales/bg/translation.json';

export enum Language {
  EN = 'en',
  BG = 'bg'
}

const resources = {
  en: {
    translation: translationEN
  },
  bg: {
    translation: translationBG
  }
};

export const LOCAL_STORAGE_LOCALIZATION_KEY = 'i18nextLng';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: Language.BG,
    lng: localStorage.getItem(LOCAL_STORAGE_LOCALIZATION_KEY) ?? Language.BG,
    debug: true,
    interpolation: {
      escapeValue: false
    }
  }).catch((e) => {
    console.error(e);
  });

i18n.on('languageChanged', (lang) => {
  document.documentElement.lang = lang;
});

export default i18n;
