import Popup, { type IPopup } from 'components/popup/Popup';
import { useAddReviewMutation } from 'store/api/reviewApi';
import useForm from 'hooks/useForm';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import Form from 'components/forms/Form';
import validateReview from 'utils/validators/reviewValidator';
import './WriteReviewPopup.scss';
import { useTranslation } from 'react-i18next';

interface IReviewPopup extends IPopup {
  sellerId: number
  sellerUsername: string
}

const MAX_RATING = 5;

enum FieldNames {
  Rating = 'rating',
  Comment = 'comment'
}

const initialValues = {
  [FieldNames.Rating]: MAX_RATING,
  [FieldNames.Comment]: ''
};

const WriteReviewPopup = ({
  open,
  onClose,
  sellerId,
  sellerUsername
}: IReviewPopup) => {
  const { t } = useTranslation();
  const [addReview] = useAddReviewMutation();
  const title = `${t('writeReview.title')} ${sellerUsername}`;

  const onSubmit = async (values: any) => {
    try {
      await addReview({
        sellerId,
        sellerUsername,
        rating: values.rating,
        comment: values.comment
      });
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors
  } = useForm(initialValues, onSubmit, validateReview);

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={title}
      className="write-review-popup"
    >
      <Form
        onSubmit={handleSubmit}
        submitText={t('common.submit')}
        className="flex flex-column justify-center align-center write-review-popup-form full-width"
      >
        <FormControl
          name={FieldNames.Rating}
          value={values.rating.toString()}
          error={errors.rating}
          labelText={t('writeReview.rating')}
          max={5}
          min={1}
          type={FormControlType.number}
          onChange={(value) => {
            handleChange(FieldNames.Rating, value);
          }}
        />
        <FormControl
          name={FieldNames.Comment}
          value={values.comment}
          error={errors.comment}
          labelText={t('writeReview.comment')}
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(FieldNames.Comment, value);
          }}
        />
      </Form>
    </Popup>
  );
};

export default WriteReviewPopup;
