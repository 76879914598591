import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { type IPaidAdsApiResponse } from 'types/IPaidAd';

const PAID_ADS_API = 'paidAds';

export const paidAdsApi = createApi({
  reducerPath: 'paidAds',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getPaidAds: builder.query<IPaidAdsApiResponse, unknown>({
      query: () => `${PAID_ADS_API}`
    }),
    payForAd: builder.mutation<string, {
      id: number
      locale: string
    }>({
      query: ({ id, locale }) => ({
        url: `${PAID_ADS_API}/${id}/checkout`,
        method: 'POST',
        responseHandler: 'text',
        body: {
          locale
        }
      })
    })
  })
});

export const {
  useGetPaidAdsQuery,
  usePayForAdMutation
} = paidAdsApi;
