import { useTranslation } from 'react-i18next';
import { type IPopup } from 'components/popup/Popup';
import StyledPopup from 'components/common/styled-popup/StyledPopup';

interface IDeleteConfirmationPopup extends IPopup {
  onAccept: () => void
}

const DeleteConfirmationPopup = ({ open, onClose, onAccept }: IDeleteConfirmationPopup) => {
  const { t } = useTranslation();

  return (
    <StyledPopup
      open={open}
      onClose={onClose}
      shouldShowCloseIcon={false}
      onAccept={onAccept}
      title={t('productActions.areYouSureYouWantToDeleteProduct')}
    />
  );
};

export default DeleteConfirmationPopup;
