import { useCallback, useMemo } from 'react';
import { ReactComponent as FilterToggleIcon } from 'assets/icons/filter-toggle.svg';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-red.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkbox-red-unchecked.svg';
import { Checkbox, FormControlLabel } from '@mui/material';
import concatClassNames from 'utils/classNames';
import './CategoryFilter.scss';
import { findParentCategoryByIds, type ICategory } from 'types/ICategory';
import { useI18n } from 'hooks/usei18n';
import { getLocalizedValue } from 'utils/localization';
import ProductsService from 'services/ProductsService';
import { useGetAllCategoriesQuery } from 'store/api/categoiesApi';
import { useNavigate } from 'react-router-dom';
import { preventDefault } from 'utils/htmlElementHandlers';
import { intersection, isEmpty, isNil } from 'lodash';
import { useLocalization } from 'hooks/useLocalization';

interface ICategoryFilterProps {
  category: any
  filters: number[]
  level: number
}

const CategoryFilter = ({
  category,
  filters,
  level
}: ICategoryFilterProps) => {
  const { language, getLanguageUrl } = useI18n();
  const { data: categories } = useGetAllCategoriesQuery();
  const subcategories = useMemo(
    () => {
      const { subcategories } = category as ICategory;
      if (isNil(subcategories) || isEmpty(subcategories)) {
        return [];
      }
      return subcategories;
    },
    [category]
  );

  const hasSubcategories = useMemo(
    () => !isEmpty(subcategories),
    [subcategories]
  );

  const isSelected = useMemo(
    () => {
      const { id } = category;
      return filters.includes(id);
    },
    [filters, category]
  );

  const buildCategoryUrl = useCallback(
    (category: ICategory) => {
      const categoryUrl = new ProductsService(categories as ICategory[])
        .constructUrl({ categories: [category] });
      const pageUrl = `/all-products/${categoryUrl}`;

      return getLanguageUrl(pageUrl);
    },
    [categories, category, getLanguageUrl]
  );

  const navigate = useNavigate();

  const handleCategoryClick = useCallback(
    () => {
      const { id } = category;
      const categoryForUrl = isSelected
        ? findParentCategoryByIds(categories as ICategory[], [Number(id)])
        : category;

      const url = buildCategoryUrl(categoryForUrl);

      navigate(url);
    },
    [buildCategoryUrl, isSelected, navigate, categories, category]
  );

  const categoryClass = useMemo(
    () => concatClassNames('flex', 'align-center', 'category-filter',
      isSelected
        ? 'selected-category'
        : null),
    [isSelected]
  );

  const { nameBg, nameEn } = useMemo(
    () => category,
    []
  );

  const localizedName = useLocalization(nameBg, nameEn);

  const renderLevelOne = useCallback(
    () => {
      if (level > 1) {
        return null;
      }

      return (
        <a
          href={buildCategoryUrl(category)}
          onClick={(ev: any) => preventDefault(ev, () => { handleCategoryClick(); })}
          className="flex align-center category-filter-icon">
          <FilterToggleIcon />
          <span
            className={concatClassNames('category-filter-name', isSelected ? 'selected-category-name' : '')}>
            {localizedName}
          </span>
        </a>
      );
    },
    [category, handleCategoryClick, level]
  );

  const renderOtherLevel = useCallback(
    () => {
      if (level < 2) {
        return null;
      }

      const {
        id,
        nameBg,
        nameEn
      } = category;

      const labelText = getLocalizedValue(language, nameBg, nameEn);

      return (
        <FormControlLabel
          className={concatClassNames('category-filter-checkbox', isSelected ? 'selected-checkbox' : '')}
          control={
            <Checkbox
              checked={isSelected}
              onClick={() => { handleCategoryClick(); }}
              checkedIcon={<CheckboxChecked />}
              icon={<CheckboxUnchecked />}
            />
          }
          label={labelText}
          key={id}
        />
      );
    },
    [language, category, isSelected, level, handleCategoryClick, filters]
  );

  const isChildSelected = useMemo(
    () => !isEmpty(intersection(filters, subcategories.map(c => c.id))),
    [filters, subcategories]
  );

  const renderSubcategories = useCallback(
    () => {
      if (!hasSubcategories || !(isSelected || isChildSelected)) {
        return null;
      }

      return (
        <div className="subcategories">
          {subcategories.map((subcat: any) => (
            <CategoryFilter
              key={subcat.id}
              category={subcat}
              filters={filters}
              level={level + 1}
            />
          ))}
        </div>
      );
    },
    [hasSubcategories, filters, isChildSelected, isSelected, category, subcategories, level]
  );

  return (
    <>
      <div
        className={categoryClass}>
        <div>
          {renderLevelOne()}
          {renderOtherLevel()}
        </div>
      </div>
      {renderSubcategories()}
    </>
  );
};

export default CategoryFilter;
