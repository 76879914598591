import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import { useEditPublisherMutation, useGetPublisherQuery } from 'store/api/publisherApi';
import Form from 'components/forms/Form';
import useForm from 'hooks/useForm';
import { validatePublisherFields } from 'utils/validators/admin/blogValidator';

export enum FieldName {
  Name = 'name'
}

const AdminBlogPublisher = () => {
  const { t } = useTranslation();
  const { data: publisher, isLoading } = useGetPublisherQuery({});
  const [editPublisher] = useEditPublisherMutation();

  const initialValues = {
    name: publisher?.name || ''
  };

  const onSubmit = async (values: any) => {
    try {
      await editPublisher(values).unwrap();
      console.log('Publisher updated successfully!');
    } catch (error) {
      console.error('Failed to update publisher:', error);
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm(initialValues, onSubmit, validatePublisherFields);

  useEffect(() => {
    if (publisher?.name) {
      handleChange(FieldName.Name, publisher.name);
    }
  }, [publisher]);

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (
    <div className="admin-blog-publisher">
      <Form
        onSubmit={handleSubmit}
        className="flex flex-column"
        submitText={t('administration.common.save')}
      >
        <FormControl
          name={FieldName.Name}
          labelText={t('administration.blog.publisherName.title')}
          value={values.name || publisher?.name}
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(FieldName.Name, value);
          }}
          error={errors.name}
        />
      </Form>
    </div>
  );
};

export default AdminBlogPublisher;
