import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import { type IInvoicesResponseApi, type IPaymentsResponseApi } from 'types/IPayment';

const PAYMENTS_URL = 'payments';
const INVOICES_URL = 'Invoices';

export enum SortBy {
  Id = 'id',
  Date = 'date',
  InvoiceType = 'type',
  OrderId = 'orderId',
  paidAdId = 'paidAdId',
  PaidAd = 'paidAd',
  Price = 'price',
  Username = 'username',
}

export const paymentApi = createApi({
  reducerPath: 'payment',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllPayments: builder.query<IPaymentsResponseApi, {
      month?: number
      year?: number
      pageSize?: number
      page?: number
      sortBy?: SortBy
      order?: Order
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        month,
        year,
        order = Order.DESC,
        sortBy
      }) => {
        let queryString = `${PAYMENTS_URL}?pageSize=${pageSize}&page=${page}&order=${order}`;

        if (month) {
          queryString += `&month=${month}`;
        }

        if (year) {
          queryString += `&year=${year}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        return queryString;
      }
    }),
    getUserPayments: builder.query<IPaymentsResponseApi, {
      pageSize?: number
      page?: number
      year?: number
      month?: number
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        year,
        month
      }) => {
        let queryString = `${PAYMENTS_URL}/mine?pageSize=${pageSize}&page=${page}`;

        if (year) {
          queryString += `&year=${year}`;
        }

        if (month) {
          queryString += `&month=${month}`;
        }

        return queryString;
      }
    }),
    getAllInvoices: builder.query<IInvoicesResponseApi, {
      order?: Order
      sortBy?: SortBy
      type?: number
      to?: string
      from?: string
      pageSize?: number
      page?: number
    }>({
      query: ({
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        from,
        to,
        type,
        order,
        sortBy
      }) => {
        let queryString = `${INVOICES_URL}?pageSize=${pageSize}&page=${page}&order=${order}&sortBy=${sortBy}`;

        if (from) {
          queryString += `&from=${from}`;
        }

        if (to) {
          queryString += `&to=${to}`;
        }

        if (type) {
          queryString += `&type=${type}`;
        }

        return queryString;
      }
    }),
    auditInvoicesExcel: builder.mutation<any, {
      type?: number
      from?: string
      to?: string
    }>({
      queryFn: async ({
        type,
        from,
        to
      }, api, extraOptions, baseQuery) => {
        const queryString = `${INVOICES_URL}/Export?type=${type}&from=${from}&to=${to}`;

        const result = await baseQuery({
          url: queryString,
          responseHandler: async response => await response.blob()
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL;
        const invoiceType = type === 1 ? 'company' : 'personal';
        hiddenElement.href = url.createObjectURL((result as any).data);
        hiddenElement.target = '_blank';
        hiddenElement.download = `invoices_audit_from_${from}_to_${to}_type_${invoiceType}.xlsx`;
        hiddenElement.click();

        return { data: null };
      }
    }),
    auditExcel: builder.mutation<any, {
      month?: number
      year?: number
    }>({
      queryFn: async ({
        month,
        year
      }, api, extraOptions, baseQuery) => {
        const queryString = `${PAYMENTS_URL}/ExportAudit?month=${month}&year=${year}`;

        const result = await baseQuery({
          url: queryString,
          responseHandler: async response => await response.blob()
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL;
        hiddenElement.href = url.createObjectURL((result as any).data);
        hiddenElement.target = '_blank';
        hiddenElement.download = `payments_audit_month_${month}_year_${year}.xml`;
        hiddenElement.click();

        return { data: null };
      }
    })
  })
});

export const {
  useGetAllPaymentsQuery,
  useAuditExcelMutation,
  useGetAllInvoicesQuery,
  useAuditInvoicesExcelMutation,
  useGetUserPaymentsQuery
} = paymentApi;
