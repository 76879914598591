import { useTranslation } from 'react-i18next';
import Tabs from 'components/tabs/Tabs';
import AllUsersTab from 'components/administration/users/AllUsersTab';
import UserRolesTab from 'components/administration/users/UserRolesTab';

enum AdminUsersTabs {
  AllUsers = 'All Users',
  UserRoles = 'User Roles'
}

const AdminUsersTab = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: AdminUsersTabs.AllUsers,
      content: <AllUsersTab/>,
      name: t('administration.users.allUsers')
    },
    {
      label: AdminUsersTabs.UserRoles,
      content: <UserRolesTab/>,
      name: t('administration.users.userRoles')
    }
  ];

  return <Tabs className="user-administration-tabs" tabsData={tabs} ariaLabel="users tabs"/>;
};

export default AdminUsersTab;
