import React, { useState } from 'react';
import {
  Button as MuiButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl as MuiFormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { SerializedError } from '@reduxjs/toolkit';
import { Button } from 'components/button/Button';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SortBy, useGetAllOrdersQuery, useReportExcelMutation } from 'store/api/orderApi';
import { DEFAULT_DATE_FORMAT, DEFAULT_PAGE_SIZE, Order } from 'utils/constants';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import './OrdersTab.scss';

interface OrdersSearchParams {
  sortBy?: SortBy
  order?: Order
  deliveryType?: number
  handoverType?: number
  from?: string
  to?: string
  [key: string]: string | number | undefined
}

interface DeliveryTypeOption {
  value: { deliveryType?: number, handoverType?: number }
  name: string
}

const AdminOrdersTab = () => {
  const { t } = useTranslation();

  const deliveryTypeOptions: DeliveryTypeOption[] = [
    { value: {}, name: t('administration.orders.all') },
    { value: { deliveryType: 1, handoverType: 1 }, name: t('administration.orders.officeToOffice') },
    { value: { deliveryType: 2, handoverType: 1 }, name: t('administration.orders.officeToAddress') },
    { value: { deliveryType: 2, handoverType: 2 }, name: t('administration.orders.addressToOffice') },
    { value: { deliveryType: 1, handoverType: 2 }, name: t('administration.orders.addressToAddress') }
  ];

  const sortByDropdownOptions = [
    { value: SortBy.Id, name: 'Id' },
    { value: SortBy.Date, name: t('common.inputs.date') }
  ];

  const orderByDropdownOptions = [
    { value: Order.DESC, name: t('common.sort.descending') },
    { value: Order.ASC, name: t('common.sort.ascending') }
  ];

  const [renderConfirmDialogue, setRenderConfirmDialogue] = useState<boolean>(false);
  const [ordersSearchParams, setOrdersSearchParams] = useState<OrdersSearchParams>({
    sortBy: sortByDropdownOptions[0].value,
    order: orderByDropdownOptions[0].value
  });
  const [selectedDeliveryTypeValue, setSelectedDeliveryTypeValue] = useState<DeliveryTypeOption>(deliveryTypeOptions[0]);

  const { data, error, isLoading } = useGetAllOrdersQuery(ordersSearchParams);
  const [reportExcel] = useReportExcelMutation();

  const onDateChange = (e: any, field: string) => {
    const formattedDate = dayjs(e).format(DEFAULT_DATE_FORMAT);
    const paramsObj: OrdersSearchParams = { ...ordersSearchParams };

    paramsObj[field] = `${formattedDate}Z`;
    setOrdersSearchParams(paramsObj);
  };

  const onDeliveryTypeDropdownChange = (e: any) => {
    const paramsObj: OrdersSearchParams = { ...ordersSearchParams };
    const { target } = e;

    const selectedOption = deliveryTypeOptions.find(opt => opt.value === target.value);
    if (!selectedOption) return;

    const { deliveryType, handoverType } = selectedOption.value;
    paramsObj.deliveryType = deliveryType;
    paramsObj.handoverType = handoverType;

    setOrdersSearchParams(paramsObj);
    setSelectedDeliveryTypeValue(selectedOption);
  };

  const onSortByDropdownChange = (e: any) => {
    const paramsObj = { ...ordersSearchParams };
    const { target } = e;

    const sortByOption = sortByDropdownOptions.find(opt => opt.value === target.value);
    if (!sortByOption) return;

    paramsObj.sortBy = sortByOption.value;

    setOrdersSearchParams(paramsObj);
  };

  const onOrderByDropdownChange = (e: any) => {
    const paramsObj = { ...ordersSearchParams };
    const { target } = e;

    const orderByOption = orderByDropdownOptions.find(opt => opt.value === target.value);
    if (!orderByOption) return;

    paramsObj.order = orderByOption.value;

    setOrdersSearchParams(paramsObj);
  };

  const handleEntered = () => {
  };

  const renderConfirmDialog = () => {
    if (!renderConfirmDialogue) {
      return null;
    }

    return (
      <Dialog
        maxWidth='xs'
        TransitionProps={{ onEntered: handleEntered }}
        open={renderConfirmDialogue}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          Pressing Yes will export the whole orders data available without filter, which could be high volume data and
          take some time.
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => {
            setRenderConfirmDialogue(false);
          }}>No</MuiButton>
          <MuiButton onClick={() => {
            // required lines here, else linter goes crazy
            reportExcel({ ...ordersSearchParams })
              .then(() => {
              })
              .catch(() => {
              });
            setRenderConfirmDialogue(false);
          }}>Yes</MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  const dataColumns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: t('administration.orders.orderNumber'),
      width: 110,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => params.row.id
    },
    {
      field: 'products',
      headerName: t('administration.orders.products'),
      width: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'createdOn',
      headerName: t('administration.orders.dateOfOrder'),
      width: 160,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => new Date(params.row.createdOn).toLocaleDateString()
    },
    {
      field: 'deliveryType',
      headerName: t('administration.orders.deliveryType'),
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'totalPrice',
      headerName: t('administration.orders.paidByCustomer'),
      width: 160,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => `${params.value.amount.toFixed(2)} (${params.value.currency.name})`
    },
    {
      field: 'priceCompanyEarned',
      headerName: t('administration.orders.companyEarned'),
      width: 160,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => `${params.value.amount.toFixed(2)} (${params.value.currency.name})`
    },
    {
      field: 'priceOwedToSeller',
      headerName: t('administration.orders.owedToSeller'),
      width: 160,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridValueGetterParams) => `${params.value.amount.toFixed(2)} (${params.value.currency.name})`
    },
    {
      field: 'sellerFullName',
      headerName: t('administration.orders.sellerFullName'),
      align: 'center',
      headerAlign: 'center',
      width: 160
    },
    {
      field: 'buyerFullName',
      headerName: t('administration.orders.buyerFullName'),
      align: 'center',
      headerAlign: 'center',
      width: 160
    },
    {
      field: 'shipmentId',
      headerName: t('administration.orders.speedyTrackingNumber'),
      align: 'center',
      headerAlign: 'center',
      width: 320
    },
    {
      field: 'deliveryCost',
      headerName: t('administration.orders.deliveryCost'),
      align: 'center',
      headerAlign: 'center',
      width: 180,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.shipmentPrice.amount.toFixed(2)} ${params.row.shipmentPrice.currency.name}`
    },
    {
      field: 'sellerBankDetails',
      headerName: t('administration.orders.sellerBankDetails'),
      align: 'center',
      headerAlign: 'center',
      width: 350,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.sellerIBAN}`
    },
    {
      field: 'status',
      headerName: t('administration.orders.status'),
      align: 'center',
      headerAlign: 'center',
      width: 350,
      valueGetter: (params: GridValueGetterParams) =>
        t(`operationCodes.${params.row.operationCode.toString()}`)
    }
  ];

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  return (<div>
      {error
        ? <div>{(error as SerializedError).message}</div>
        : <>
          {renderConfirmDialog()}
          <div
            className='orders-total-amount'>{t('administration.orders.totalAmountFromSoldItems')}: {data?.totalAmount ?? 0} BGN
          </div>
          <div>
            <div className='orders-sort-fields'>
              <MuiFormControl variant="outlined" className="sort-container">
                <InputLabel id="sort-by-label">
                  {t('common.sort.sortBy')}
                </InputLabel>
                <Select
                  sx={{ width: '180px' }}
                  value={ordersSearchParams.sortBy}
                  label={t('administration.orders.deliveryType')}
                  onChange={(e: any) => {
                    onSortByDropdownChange(e);
                  }}
                  className='dropdown-select'
                >
                  {sortByDropdownOptions.map((option, idx) => (
                    <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                      <ListItemText primary={option.name}/>
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
              <MuiFormControl variant="outlined" className="sort-container">
                <InputLabel id="sort-by-label">
                  {t('common.sort.orderBy')}
                </InputLabel>
                <Select
                  sx={{ width: '180px' }}
                  value={ordersSearchParams.order}
                  label={t('administration.orders.deliveryType')}
                  onChange={(e: any) => {
                    onOrderByDropdownChange(e);
                  }}
                  className='dropdown-select'
                >
                  {orderByDropdownOptions.map((option, idx) => (
                    <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                      <ListItemText primary={option.name}/>
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
            </div>
            <div className='orders-filter'>
              <MuiFormControl variant="outlined" className="sort-container">
                <InputLabel id="sort-by-label">
                  {t('administration.orders.deliveryType')}
                </InputLabel>
                <Select
                  sx={{ width: '180px' }}
                  value={selectedDeliveryTypeValue.value}
                  label={t('administration.orders.deliveryType')}
                  onChange={(e: any) => {
                    onDeliveryTypeDropdownChange(e);
                  }}
                  className='dropdown-select'
                >
                  {deliveryTypeOptions.map((option, idx) => (
                    <MenuItem key={`dto-${idx}`} value={option.value as any} className='dropdown-select-item'>
                      <ListItemText primary={option.name}/>
                    </MenuItem>
                  ))}
                </Select>
              </MuiFormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: '180px' }}
                  label={t('administration.common.fromDate')}
                  onChange={(e: any) => {
                    onDateChange(e, 'from');
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: '180px' }}
                  label={t('administration.common.toDate')}
                  onChange={(e: any) => {
                    onDateChange(e, 'to');
                  }}
                />
              </LocalizationProvider>
              <Button className='orders-export-button' onClick={() => {
                if (!ordersSearchParams.to &&
                  !ordersSearchParams.from &&
                  !ordersSearchParams.deliveryType &&
                  !ordersSearchParams.handoverType) {
                  setRenderConfirmDialogue(true);
                  return;
                }
                // required lines here, else linter goes crazy
                reportExcel({ ...ordersSearchParams })
                  .then(() => {
                  })
                  .catch(() => {
                  });
              }}>EXPORT</Button>
            </div>
          </div>
          <DataGrid
            rows={data?.models ?? []}
            columns={dataColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
              }
            }}
            pageSizeOptions={[DEFAULT_PAGE_SIZE]}
            checkboxSelection
            rowCount={data?.totalCount ?? 0}
            paginationMode='server'
            onPaginationModelChange={(e) => {
              setOrdersSearchParams({ ...ordersSearchParams, page: e.page + 1 });
            }}
          />
        </>
      }
    </div>
  );
};

export default AdminOrdersTab;
