/* eslint-disable @typescript-eslint/indent */
import i18n from 'i18next';
import { createValidator, maxLength, minLength } from 'utils/validators/validators';

interface IPageFormValues {
    canonicalUrl: string
    titleEnglishContent: string
    titleBulgarianContent: string
    heading1EnglishContent: string
    heading1BulgarianContent: string
    heading2EnglishContent: string
    heading2BulgarianContent: string
    descriptionEnglishContent: string
    descriptionBulgarianContent: string
}

type PageFormErrors = Partial<{ [K in keyof IPageFormValues]: string }>;

const MAX_PAGE_CANONICAL_URL_LENGTH = 2048;
const MIN_PAGE_TITLE_LENGTH = 2;
const MAX_PAGE_TITLE_LENGTH = 120;
const MIN_PAGE_HEADING_LENGTH = 2;
const MAX_PAGE_HEADING_LENGTH = 120;
const MIN_PAGE_DESCRIPTION_LENGTH = 2;

const MIN_META_NAME_LENGTH = 2;
const MAX_META_NAME_LENGTH = 60;
const MIN_META_CONTENT_LENGTH = 2;
const MAX_META_CONTENT_LENGTH = 300;

const validatePageCanonicalUrl = createValidator(
    maxLength(MAX_PAGE_CANONICAL_URL_LENGTH)
);

const validatePageTitle = createValidator(
    minLength(MIN_PAGE_TITLE_LENGTH),
    maxLength(MAX_PAGE_TITLE_LENGTH)
);

const validatePageHeading = createValidator(
    minLength(MIN_PAGE_HEADING_LENGTH),
    maxLength(MAX_PAGE_HEADING_LENGTH)
);

const validatePageDescription = createValidator(
    minLength(MIN_PAGE_DESCRIPTION_LENGTH)
);

const validateMetaTagName = createValidator(
    minLength(MIN_META_NAME_LENGTH),
    maxLength(MAX_META_NAME_LENGTH)
);

const validateMetaTagContent = createValidator(
    minLength(MIN_META_CONTENT_LENGTH),
    maxLength(MAX_META_CONTENT_LENGTH)
);

const validateEditPageFields = (values: Partial<any>): PageFormErrors => {
    const errors: any = {};

    const fieldValidators: { [K in keyof IPageFormValues]?: () => string | null } = {
        canonicalUrl: () => values.canonicalUrl ? validatePageCanonicalUrl(values.canonicalUrl) : null,
        titleEnglishContent: () => values.titleEnglishContent ? validatePageTitle(values.titleEnglishContent) : null,
        titleBulgarianContent: () => values.titleBulgarianContent ? validatePageTitle(values.titleBulgarianContent) : null,
        heading1EnglishContent: () => values.heading1EnglishContent ? validatePageHeading(values.heading1EnglishContent) : null,
        heading1BulgarianContent: () => values.heading1BulgarianContent ? validatePageHeading(values.heading1BulgarianContent) : null,
        heading2EnglishContent: () => values.heading2EnglishContent ? validatePageHeading(values.heading2EnglishContent) : null,
        heading2BulgarianContent: () => values.heading2BulgarianContent ? validatePageHeading(values.heading2BulgarianContent) : null,
        descriptionEnglishContent: () => values.descriptionEnglishContent ? validatePageDescription(values.descriptionEnglishContent) : null,
        descriptionBulgarianContent: () => values.descriptionBulgarianContent ? validatePageDescription(values.descriptionBulgarianContent) : null
    };

    if (values.metaTags && values.metaTags.length > 0) {
        const metaNameErrors = values.metaTags.map((metaTag: any, index: number) =>
            metaTag.name
                ? validateMetaTagName(metaTag.name)
                : (values.metaTags.find((_: any, i: number) => i === index)?.name
                    ? i18n.t('validations.blog.metaNameIsRequired', { index: index + 1 })
                    : null)
        );

        const hasMetaNameErrors = metaNameErrors.some((error: any) => error !== null);
        if (hasMetaNameErrors) {
            errors.metaName = metaNameErrors[0];
        }

        const metaContentErrors = values.metaTags.map((metaTag: any, index: number) =>
            metaTag.content
                ? validateMetaTagContent(metaTag.content)
                : (values.metaTags.find((_: any, i: number) => i === index)?.content
                    ? i18n.t('validations.blog.metaContentIsRequired', { index: index + 1 })
                    : null)
        );

        const hasMetaContentErrors = metaContentErrors.some((error: any) => error !== null);
        if (hasMetaContentErrors) {
            errors.metaContent = metaContentErrors[0];
        }
    }

    for (const field in fieldValidators) {
        if (Object.prototype.hasOwnProperty.call(fieldValidators, field)) {
            const validator = fieldValidators[field as keyof IPageFormValues];
            if (validator) {
                const error = validator();
                if (error) {
                    errors[field as keyof IPageFormValues] = error;
                }
            }
        }
    }

    return errors;
};

export {
    validatePageCanonicalUrl,
    validatePageTitle,
    validatePageHeading,
    validatePageDescription,
    validateMetaTagName,
    validateMetaTagContent,
    validateEditPageFields
};
