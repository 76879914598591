import React from 'react';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import Profile from 'pages/profile/Profile';
import ProfileDeliveryOptions from 'pages/profile-delivery-and-payment/ProfileDeliveryOptions';
import ProfileBankDetails from 'pages/profile-delivery-and-payment/ProfileBankDetails';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import { ROUTES } from '../../routes/routes';

interface IProfileDeliveryAndPayment extends IOptionalClassName {
}

const ProfileDeliveryAndPayment = ({ className }: IProfileDeliveryAndPayment) => {
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.DeliveryAndPayment.path });

  return (
    <Profile pageSeo={pageSeoData} className={concatClassNames(className, 'profile-delivery-and-payment')}>
      <ProfileDeliveryOptions/>
      <ProfileBankDetails/>
    </Profile>
  );
};

export default ProfileDeliveryAndPayment;
