import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useGetCitiesQuery } from 'store/api/deliveryApi';
import debounce from 'utils/debounce';
import { DEFAULT_DEBOUNCE_TIME } from 'utils/constants';

interface ICityDropdownProps {
  selectedCity: any
  handleCityChange: (city: any) => void
  error?: string
}

const CityDropdown = ({ selectedCity, handleCityChange, error }: ICityDropdownProps) => {
  const { t, i18n } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const { data: cities } = useGetCitiesQuery({ name: inputValue, language: i18n.language });

  useEffect(() => {
    if (selectedCity?.name) {
      setInputValue(selectedCity.name);
    }
  }, [selectedCity]);

  const debouncedInputChange = useCallback(debounce((newInputValue: string) => {
    setInputValue(newInputValue);
  }, DEFAULT_DEBOUNCE_TIME), []);

  const handleInputChange = (event: any, newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  const handleChange = (event: any, newValue: any) => {
    handleCityChange(newValue);
  };

  const cityOptions = cities?.map(city => ({
    ...city,
    label: city.name
  })) ?? [];

  return (
    <Autocomplete
      className="dropdown"
      options={cityOptions}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
          <li {...props} key={option.id}>{option.label}</li>
      )}
      value={cityOptions.find(city => city.id === selectedCity?.id) ?? null}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => (
          <TextField {...params as any} label={t('common.chooseCity')} error={!!error} helperText={error}/>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={inputValue.length > 0 ? t('common.noCitiesFound') : t('common.chooseCity')}
    />
  );
};

export default CityDropdown;
