import React from 'react';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import Profile from 'pages/profile/Profile';
import ProfilePaymentsTabs from 'pages/profile-payments/ProfilePaymentsTabs';
import { useGetPageDetailsQuery } from '../../store/api/pagesApi';
import { ROUTES } from '../../routes/routes';
import './ProfilePayments.scss';

interface IProfilePayments extends IOptionalClassName {
}

const ProfilePayments = ({ className }: IProfilePayments) => {
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Payments.path });

  return (
    <Profile pageSeo={pageSeoData} containerClassName="content-container" className={concatClassNames(className, 'profile-payments')}>
      <ProfilePaymentsTabs/>
    </Profile>
  );
};

export default ProfilePayments;
