import { createValidator, maxLength, minLength, validateFields } from 'utils/validators/validators';

const MIN_NAME_LENGTH: number = 2;
const MAX_NAME_LENGTH: number = 120;
const MIN_UNIFIED_IDENTIFICATION_CODE_LENGTH: number = 9;
const MAX_UNIFIED_IDENTIFICATION_CODE_LENGTH: number = 15;
const MIN_ACCOUNTABLE_PERSON_LENGTH: number = 2;
const MAX_ACCOUNTABLE_PERSON_LENGTH: number = 120;
const MIN_VAT_NUMBER_LENGTH: number = 10;
const MAX_VAT_NUMBER_LENGTH: number = 15;
const MIN_COUNTRY_LENGTH: number = 2;
const MAX_COUNTRY_LENGTH: number = 50;
const MIN_CITY_LENGTH: number = 2;
const MAX_CITY_LENGTH: number = 50;
const MIN_ADDRESS_LENGTH: number = 2;
const MAX_ADDRESS_LENGTH: number = 200;

const validateName = createValidator(
  minLength(MIN_NAME_LENGTH),
  maxLength(MAX_NAME_LENGTH)
);

const validateUnifiedIdentificationCode = createValidator(
  minLength(MIN_UNIFIED_IDENTIFICATION_CODE_LENGTH),
  maxLength(MAX_UNIFIED_IDENTIFICATION_CODE_LENGTH)
);

const validateAccountablePerson = createValidator(
  minLength(MIN_ACCOUNTABLE_PERSON_LENGTH),
  maxLength(MAX_ACCOUNTABLE_PERSON_LENGTH)
);

const validateVatNumber = createValidator(
  minLength(MIN_VAT_NUMBER_LENGTH),
  maxLength(MAX_VAT_NUMBER_LENGTH)
);

const validateCountry = createValidator(
  minLength(MIN_COUNTRY_LENGTH),
  maxLength(MAX_COUNTRY_LENGTH)
);

const validateCity = createValidator(
  minLength(MIN_CITY_LENGTH),
  maxLength(MAX_CITY_LENGTH)
);

const validateAddress = createValidator(
  minLength(MIN_ADDRESS_LENGTH),
  maxLength(MAX_ADDRESS_LENGTH)
);

const validateCompanyName = createValidator(
  minLength(MIN_NAME_LENGTH),
  maxLength(MAX_NAME_LENGTH)
);

const validateFullName = createValidator(
  minLength(MIN_NAME_LENGTH),
  maxLength(MAX_NAME_LENGTH)
);

const fieldValidators = {
  name: validateName,
  unifiedIdentificationCode: validateUnifiedIdentificationCode,
  accountablePerson: validateAccountablePerson,
  vatNumber: validateVatNumber,
  country: validateCountry,
  city: validateCity,
  address: validateAddress,
  companyName: validateCompanyName,
  fullName: validateFullName
};

const validateInvoiceFields = (values: any, context = {}) => validateFields(values, fieldValidators);

export {
  validateInvoiceFields
};
