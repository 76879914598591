import React from 'react';
import { PROFILE_ROUTES } from 'routes/routes';
import NavigationLink from 'components/header/NavigationLink';
import LogoutButton from 'components/common/logout-button/LogoutButton';
import CreateNewProductButton from 'components/common/create-new-product-button/CreateNewProductButton';
import './ProfileMenuMobile.scss';

const ProfileMenuMobile = () => (
  <article className="flex flex-column mobile-menu profile-menu-mobile">
    <div className="profile-menu-mobile-header">
      <CreateNewProductButton className="profile-menu-mobile-header-create-new-ad"/>
    </div>
    <nav className="flex flex-column justify-center align-center profile-menu-mobile-navigation">
      {PROFILE_ROUTES?.map((route) => (
        <NavigationLink
          key={route.name}
          route={route}
          className="header-nav-link profile-menu-mobile-navigation-link text-center"
        />
      ))}
    </nav>
    <div className="flex justify-center align-center  profile-menu-mobile-footer">
      <LogoutButton className="profile-menu-mobile-footer-logout"/>
    </div>
  </article>
);

export default ProfileMenuMobile;
