import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, type GridColDef, type GridValueGetterParams } from '@mui/x-data-grid';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils/constants';
import { useGetOrdersProfitQuery } from 'store/api/orderApi';
import Pagination from 'components/pagination/Pagination';
import { getCurrencySymbol } from 'utils/currency';
import useWindowWidth from 'hooks/useWindowWidth';
import { Language } from '../../i18n';
import './ProfileProfit.scss';

const ProfileProfit = () => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [queryParams, setQueryParams] = useState({ page: DEFAULT_PAGE });
  const { data: profit } = useGetOrdersProfitQuery(queryParams);
  const currentLanguage = i18n.language;

  const dataColumnsDesktop: GridColDef[] = [
    {
      field: 'productName',
      headerName: t('profile.payments.productName'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return currentLanguage === Language.BG ? params.row.productName.bulgarian : params.row.productName.english;
      }
    },
    {
      field: 'sizeName',
      flex: 1,
      headerName: t('profile.payments.size'),
      disableColumnMenu: true
    },
    {
      field: 'quantity',
      flex: 1,
      headerName: t('profile.payments.quantity'),
      disableColumnMenu: true
    },
    {
      field: 'unitPrice',
      headerName: t('profile.payments.income'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.unitPrice.amount.toFixed(2)} ${getCurrencySymbol(params.row.unitPrice.currency)}`;
      }
    },
    {
      field: 'createdOn',
      headerName: t('profile.payments.date'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row.createdOn).toLocaleDateString();
      }
    },
    {
      field: 'status',
      headerName: t('profile.payments.status'),
      disableColumnMenu: true,
      width: 250,
      valueGetter: (params: GridValueGetterParams) =>
        t(`operationCodes.${params.row.operationCode.toString()}`)
    }
  ];

  const dataColumnsMobile: GridColDef[] = [
    {
      field: 'productName',
      headerName: t('profile.payments.productName'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column">
          <span>{new Date(params.row.createdOn).toLocaleDateString()}</span>
          <span>{currentLanguage === Language.BG ? params.row.productName.bulgarian : params.row.productName.english}</span>
        </div>
      )
    },
    {
      field: 'sizeName',
      flex: 1,
      headerName: t('profile.payments.size'),
      disableColumnMenu: true
    },
    {
      field: 'quantity',
      flex: 1,
      headerName: t('profile.payments.quantity'),
      disableColumnMenu: true
    },
    {
      field: 'unitPrice',
      headerName: t('profile.payments.income'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.unitPrice.amount.toFixed(2)} ${getCurrencySymbol(params.row.unitPrice.currency)}`;
      }
    },
    {
      field: 'status',
      headerName: t('profile.payments.status'),
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        t(`operationCodes.${params.row.operationCode.toString()}`)
    }
  ];

  if (profit?.models.length === 0) {
    return <div>{t('profile.payments.noIncome')}</div>;
  }

  return (
    <div className="profile-profit">
      <DataGrid
        className="profile-data-grid profile-profit-table"
        rows={profit?.models ?? []}
        columns={isMobile ? dataColumnsMobile : dataColumnsDesktop}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
          }
        }}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[DEFAULT_PAGE_SIZE]}
        disableColumnFilter
        rowCount={profit?.totalCount ?? 0}
        paginationMode='server'
        onPaginationModelChange={(e) => {
          setQueryParams({ page: e.page + 1 });
        }}
      />
      <div className="flex align-center justify-end profile-profit-table-footer">
        <span className="profile-profit-table-footer-text">{t('profile.payments.totalIncome')}:</span>
        <span className="profile-profit-table-footer-price">{profit?.totalAmount.toFixed(2)} лв.</span>
      </div>
      <Pagination
        count={profit?.totalPages ?? 1}
        page={queryParams.page}
        onChange={(value) => {
          setQueryParams({ page: value });
        }}
      />
    </div>
  );
};

export default ProfileProfit;
