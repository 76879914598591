import { type FunctionComponent } from 'react';
import concatClassNames from 'utils/classNames';
import { type AuthProvider, getExternalLoginUrl } from 'store/api/userApi';
import SocialLoginButton from 'components/common/social-login-button/SocialLoginButton';
import { type IOptionalClassName } from 'types/common/Props';
import { useTranslation } from 'react-i18next';

const withSocialLogin = (type: AuthProvider, IconComponent: FunctionComponent, label: string) => {
  // eslint-disable-next-line react/display-name
  return ({ className }: IOptionalClassName) => {
    const { t } = useTranslation();
    const handleLoginClick = () => {
      window.location.href = getExternalLoginUrl(type);
    };

    return (
      <SocialLoginButton
        icon={<IconComponent/>}
        label={t(`common.${label}`)}
        className={concatClassNames('social-login-button', className)}
        onClick={handleLoginClick}
      />
    );
  };
};

export default withSocialLogin;
