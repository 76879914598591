import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import { type IOptionalClassName } from 'types/common/Props';
import Profile from 'pages/profile/Profile';
import Tabs from 'components/tabs/Tabs';
import useWindowWidth from 'hooks/useWindowWidth';
import Dropdown, { type OptionType } from 'components/dropdown/Dropdown';
import ProfileActiveProducts from 'pages/profile-products/ProfileActiveProducts';
import ProfileCompleteProducts from 'pages/profile-products/ProfileCompleteProducts';
import ProfileRequestedProducts from 'pages/profile-products/ProfileRequestedProducts';
import { useGetPageDetailsQuery } from '../../store/api/pagesApi';
import { ROUTES } from '../../routes/routes';
import './ProfileProducts.scss';

interface IProfilePayments extends IOptionalClassName {
}

enum ProfileProductsTabs {
  Active = 'Активни',
  Requested = 'Заявени',
  Complete = 'Завършени',
}

const ProfileProducts = ({ className }: IProfilePayments) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [selectedTab, setSelectedTab] = useState(ProfileProductsTabs.Active);

  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Products.path });

  const tabs = [
    {
      label: ProfileProductsTabs.Active,
      content: <ProfileActiveProducts/>,
      name: t('profile.products.active')
    },
    {
      label: ProfileProductsTabs.Requested,
      content: <ProfileRequestedProducts/>,
      name: t('profile.products.requested')
    },
    {
      label: ProfileProductsTabs.Complete,
      content: <ProfileCompleteProducts/>,
      name: t('profile.products.completed')
    }
  ];

  const dropdownOptions: OptionType[] = tabs.map(tab => ({
    id: tab.label,
    name: tab.label === ProfileProductsTabs.Active
      ? t('profile.products.active')
      : tab.label === ProfileProductsTabs.Requested
        ? t('profile.products.requested')
        : t('profile.products.completed')
  }));

  const handleDropdownChange = (event: any) => {
    setSelectedTab(event);
  };

  const renderContent = (): React.ReactElement => {
    const activeTab = tabs.find(tab => tab.label === selectedTab);
    return activeTab && isMobile ? activeTab.content : <></>;
  };

  return (
    <Profile pageSeo={pageSeoData} containerClassName="content-container" className={concatClassNames(className, 'profile-products')}>
      {isMobile
        ? (
          <Dropdown
              label=""
              options={dropdownOptions}
              value={selectedTab}
              onChange={handleDropdownChange}
              multiple={false}
              className="profile-products-dropdown"
          />
          )
        : (<Tabs
              className="profile-products-tabs flex flex-column full-width"
              tabsData={tabs}
              ariaLabel="profile tabs"
          />)}
      {renderContent()}
    </Profile>
  );
};

export default ProfileProducts;
