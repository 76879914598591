import { isNil, isEmpty, get } from 'lodash';

const findItemByValue = <T, V>(
  items: T[] = [],
  value: V | null = null,
  findItemFunc: (item: T, value: V) => boolean,
  subitemsPath: string
): T | null => {
  if (isNil(items) || isEmpty(items) || isNil(value)) {
    return null;
  }

  const stack: T[] = [...items];

  while (!isEmpty(stack)) {
    const item = stack.pop();
    if (isNil(item)) {
      continue;
    }

    if (findItemFunc(item, value)) {
      return item;
    }

    const subitems = get(item, subitemsPath) as T[];

    if (!isNil(subitems) && !isEmpty(subitems)) {
      stack.push(...subitems);
    }
  }

  return null;
};

const findItemsByValues = <T, V>(
  items: T[],
  values: V[],
  findItemFunc: (item: T, value: V) => boolean,
  subitemsPath: string
): T[] => {
  if (isNil(items) || isEmpty(items) || isNil(values) || isEmpty(values)) {
    return [];
  }

  const stack: T[] = [...items];
  const foundItems: T[] = [];

  while (!isEmpty(stack)) {
    const item = stack.pop();
    if (isNil(item)) {
      continue;
    }

    const items: T[] = values.map(v => findItemFunc(item, v)
      ? item
      : null)
      .filter(x => !isNil(x)) as T[];

    foundItems.push(...items);

    const subitems = get(item, subitemsPath) as T[];

    if (!isNil(subitems) && !isEmpty(subitems)) {
      stack.push(...subitems);
    }
  }

  return foundItems;
};

export {
  findItemByValue,
  findItemsByValues
};
