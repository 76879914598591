import { type IProductResponse } from 'types/IProduct';
import { urlize } from './text';

const buildProductUrl = (product: IProductResponse) => {
  const {
    id,
    name: {
      english: name
    }
  } = product;

  const urlizedName = urlize(name);

  return `/products/${urlizedName}-${id}`;
};

export {
  buildProductUrl
};
