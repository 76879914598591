import { useTranslation } from 'react-i18next';
import Dropdown from 'components/dropdown/Dropdown';
import { type IOptionalClassName } from 'types/common/Props';

interface ISizesDropdownProps extends IOptionalClassName {
  sizes: Array<{ id: number, name: string }>
  selectedSize: { sizeId: number, sizeName: string }
  handleSizeChange: any
  error?: string
  disabled?: boolean
}

const SizeDropdown = ({ sizes, selectedSize, handleSizeChange, className, error, disabled }: ISizesDropdownProps) => {
  const { t } = useTranslation();
  const handleChange = (sizeId: any) => {
    const sizeName = sizes.find((size) => +size.id === +sizeId)?.name ?? '';

    handleSizeChange(sizeId, sizeName);
  };

  return (
    <Dropdown
      label={sizes.length > 1 ? t('common.chooseSize') : t('productActions.size')}
      options={sizes}
      value={selectedSize.sizeName}
      onChange={handleChange}
      multiple={false}
      className={className}
      renderValue={(selected: number[]) => {
        return selected;
      }}
      error={error}
      disabled={disabled}
    />
  );
};

export default SizeDropdown;
