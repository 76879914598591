import { useTranslation } from 'react-i18next';
import HeaderMenu from 'components/header/HeaderMenu';
import { PROFILE_ROUTES } from 'routes/routes';
import LogoutButton from 'components/common/logout-button/LogoutButton';
import CreateNewProductButton from 'components/common/create-new-product-button/CreateNewProductButton';
import './ProfileMenu.scss';

const ProfileMenu = () => {
  const { t } = useTranslation();

  return (
    <HeaderMenu
      title={t('header.profile')}
      className="profile-menu"
      cta={<CreateNewProductButton className="profile-menu-create-new-ad"/>}
      navigationLinks={PROFILE_ROUTES}
      footer={<LogoutButton className="profile-menu-logout-btn"/>}
    />
  );
};
export default ProfileMenu;
