import React, { type ReactNode } from 'react';
import concatClassNames from 'utils/classNames';
import NavigationLink from 'components/header/NavigationLink';
import { type IOptionalClassName } from 'types/common/Props';
import { type RouteType } from 'routes/routes';
import './HeaderMenu.scss';

interface IHeaderMenuProps extends IOptionalClassName {
  title: string
  cta?: ReactNode
  navigationLinks?: RouteType[]
  footer?: ReactNode
}

const HeaderMenu = ({ title, cta, navigationLinks, footer, className }: IHeaderMenuProps) => (
  <article className={concatClassNames('header-menu', className)}>
    <div className="flex justify-center align-center header-menu-header">
      <span className="header-menu-header-title">{title}</span>
    </div>
    <div className="flex flex-column header-menu-content">
      {cta && cta}
      <nav className="flex flex-column header-menu-content-navigation">
        {navigationLinks?.map((route) => (
          <NavigationLink
            key={route.name}
            route={route}
            className="header-menu-content-navigation-link"
          />
        ))}
      </nav>
    </div>
    <div className="flex justify-center align-center header-menu-footer">
      {footer}
    </div>
  </article>
);

export default HeaderMenu;
