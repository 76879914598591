import { type ReactNode } from 'react';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import './SectionCard.scss';

interface ISectionCard extends IOptionalClassName {
  title?: string
  children: ReactNode
  text?: string
}

const SectionCard = ({ title, children, text, className }: ISectionCard) => (
  <article className={concatClassNames('flex flex-column section-card', className)}>
    {title && (
      <div className="flex flex-column section-card-header">
        <h3 className="section-card-header-title">{title}</h3>
        {text && <p className="section-card-header-text">{text}</p>}
      </div>
    )}
    {children}
  </article>
);

export default SectionCard;
