import { type ReactNode } from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import concatClassNames from 'utils/classNames';
import useToggle from 'hooks/useToggle';
import { isUserLoggedIn } from 'utils/auth';
import CookiePolicy from 'components/cookie-policy/CookiePolicy';
import './PageLayout.scss';

interface PageLayoutProps {
  children: ReactNode
}

const PageLayout = ({ children }: PageLayoutProps) => {
  const isLoggedIn = isUserLoggedIn();
  const [isNavigationMenuOpen, toggleNavigationMenu] = useToggle(false);
  const [isProfileMenuOpen, toggleProfileMenu] = useToggle(false);

  const isMenuOpen = isNavigationMenuOpen || (isProfileMenuOpen && isLoggedIn);
  return (
    <main className={concatClassNames('flex flex-column page-layout', isMenuOpen ? 'menu-opened' : '')}>
      <Header
        isNavigationMenuOpen={isNavigationMenuOpen}
        toggleNavigationMenu={toggleNavigationMenu}
        isProfileMenuOpen={isProfileMenuOpen}
        toggleProfileMenu={toggleProfileMenu}
      />
      {children}
      <CookiePolicy/>
      <Footer/>
    </main>
  );
};

export default PageLayout;
