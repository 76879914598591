import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';

const PUBLISHER_URL = 'publishers';

export const publisherApi = createApi({
  reducerPath: 'publisher',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getPublisher: builder.query({
      query: () => `${PUBLISHER_URL}`
    }),
    editPublisher: builder.mutation({
      query: (newPublisher) => ({
        url: `${PUBLISHER_URL}`,
        method: 'PUT',
        body: newPublisher
      })
    })
  })
});

export const {
  useGetPublisherQuery,
  useEditPublisherMutation
} = publisherApi;
