import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/forms/Form';
import { ButtonType } from 'components/button/Button';
import SectionCard from 'components/cards/section-card/SectionCard';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import useForm from 'hooks/useForm';
import { useUpdateCustomerBankDetailsMutation } from 'store/api/customerApi';
import { validateBankDetailFields } from 'utils/validators/bankDetailsValidator';
import { useCustomerDetails } from 'pages/profile/Profile';

enum BankDetailsFields {
  Iban = 'iban'
  // Swift = 'swift',
}

const initialBankDetailsValues = {
  [BankDetailsFields.Iban]: ''
  // [BankDetailsFields.Swift]: ''
};

const ProfileBankDetails = () => {
  const { t } = useTranslation();
  const { customerDetails, refetch } = useCustomerDetails();
  const [updateBankDetails] = useUpdateCustomerBankDetailsMutation();

  useEffect(() => {
    if (customerDetails) {
      setValues({
        iban: customerDetails.bankDetails.iban
        // swift: customerDetails.bankDetails.swift
      });
    }
  }, [customerDetails]);
  const onSubmit = async (values: any) => {
    const res: any = await updateBankDetails(values);
    await refetch();
    if (res.error) {
      throw new Error(res.error.data);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    errors
  } = useForm(
    initialBankDetailsValues,
    onSubmit,
    validateBankDetailFields,
    t('profile.deliveryAndBankDetails.bankDetailsChangeSuccess'),
    false);

  return (
    <SectionCard
      title={t('profile.deliveryAndBankDetails.bankDetailsTitle')}
      text={t('profile.deliveryAndBankDetails.bankDetailsText')}
    >
      <Form
        onSubmit={handleSubmit}
        submitText={t('common.save')}
        className="flex flex-column profile-section-form"
        submitButtonType={ButtonType.secondary}
        submitButtonClassName="profile-section-form-submit-button"
      >
        <FormControl
          name={BankDetailsFields.Iban}
          value={values.iban}
          labelText="IBAN"
          error={errors.iban}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(BankDetailsFields.Iban, value.trim());
          }}
          {...(customerDetails?.bankDetails?.iban)}
        />
      </Form>
    </SectionCard>
  );
};

export default ProfileBankDetails;
