import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'components/notification/Notification';
import { type IShoppingCartItem } from 'types/ICart';
import { getCurrencySymbol } from 'utils/currency';
import { ReactComponent as FavouriteIcon } from 'assets/icons/white-heart.svg';
import { ReactComponent as RemoveFromCartIcon } from 'assets/icons/remove-from-basket.svg';
import { useEditItemQuantityMutation, useRemoveItemFromShoppingCartMutation } from 'store/api/cartApi';
import concatClassNames from 'utils/classNames';
import NumberControl from 'components/number-control/NumberControl';
import { type IOptionalClassName } from 'types/common/Props';
import useFavouriteProducts from 'hooks/useFavouriteProducts';
import Tooltip, { TooltipPlacement } from 'components/tooltip/Tooltip';
import { dispatchStorageEvent, getStorageItem, setStorageItem } from 'utils/storage';
import { CART_ITEMS, CART_ITEMS_CHANGED_EVENT } from 'components/product/ProductActions';
import { localizeSize } from 'pages/all-products/filter-utils';
import './ShoppingCartCard.scss';
import CloudinaryImage from '../../common/cloudinary-img/CloudinaryImage';
import { useLocalization } from 'hooks/useLocalization';

interface IShoppingCartCardProps extends IOptionalClassName {
  cartItem: IShoppingCartItem
  handleItemChanged?: () => void
}

const ShoppingCartCard = ({ cartItem, handleItemChanged, className }: IShoppingCartCardProps) => {
  const { t } = useTranslation();

  const { showNotification } = useNotification();
  const [editItemQuantity] = useEditItemQuantityMutation();
  const [removeItemFromCart] = useRemoveItemFromShoppingCartMutation();
  const [isFavourite, updateFavouriteProducts] = useFavouriteProducts(cartItem.productId) as [boolean, (isAdding: boolean) => Promise<void>];
  const [iconClassName, setIconClassName] = useState('');
  const [totalPrice, setTotalPrice] = useState(cartItem.productPrice.amount);
  const [quantity, setQuantity] = useState(cartItem.quantity || 1);

  useEffect(() => {
    setTotalPrice(cartItem.productPrice.amount * quantity);
  }, [quantity, cartItem.productPrice.amount]);

  useEffect(() => {
    setIconClassName(isFavourite ? 'favourite' : '');
  }, [isFavourite]);

  const handleRemoveItemFromCart = async () => {
    try {
      const response: any = await removeItemFromCart(cartItem.id);

      if (response.error) {
        showNotification(response.error.data);
      } else {
        handleItemChanged?.();

        const cartItems = Number(getStorageItem(CART_ITEMS)) - 1;
        setStorageItem(CART_ITEMS, cartItems);
        dispatchStorageEvent(CART_ITEMS_CHANGED_EVENT, cartItems);

        showNotification(t('productActions.removeFromCartSuccess'));
      }
    } catch (e) {
      showNotification(t('productActions.removeFromCartError'));
    }
  };

  const toggleFavouriteStatus = async () => {
    if (isFavourite) {
      try {
        await updateFavouriteProducts(!isFavourite);
        setIconClassName('');
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await updateFavouriteProducts(!isFavourite);
        setIconClassName('favourite');
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleQuantityChange = async (newQuantity: number) => {
    if (newQuantity > cartItem.productInventoryQuantity) {
      showNotification(t('productActions.notEnoughQuantity'));
      return;
    }

    setQuantity(newQuantity);

    try {
      const response: any = await editItemQuantity({
        productId: cartItem.productId,
        sizeId: cartItem.sizeId,
        quantity: newQuantity
      });

      if (response.error) {
        showNotification(response.error.data);
      } else {
        handleItemChanged?.();
      }
    } catch (e) {
      showNotification(t('productActions.quantityChangeError'));
    }
  };

  const { productNameBulgarian, productNameEnglish } = cartItem;

  const productName = useLocalization(productNameBulgarian, productNameEnglish);

  return (
    <article className={concatClassNames('flex shopping-cart-card', className)}>
      <CloudinaryImage
        imagePublicId={cartItem.productImageSource}
        className="shopping-cart-card-image"
      />
      <div className="shopping-cart-card-content flex flex-column space-between full-width">
        <div className="flex space-between">
          <div className="shopping-cart-card-title">{productName}</div>
          <div className="shopping-cart-card-price">
            {totalPrice.toFixed(2)} {getCurrencySymbol(cartItem.productPrice.currency)}
          </div>
        </div>
        {cartItem.sizeName !== null && (
          <div className="flex" style={{ gap: 10, marginLeft: 10 }}>
            <span className="size-text">
              {t('productActions.size')}:
            </span>
            {cartItem.sizeName && (
              <span
                className="size-name">{Number(cartItem.sizeName) ? cartItem.sizeName : t(localizeSize(cartItem.sizeName))}</span>
            )}
          </div>
        )}
        <div className="shopping-cart-card-actions flex justify-end align-center">
          <Tooltip
            title={isFavourite
              ? t('productActions.removeFromFavorites')
              : t('productActions.addToFavourites')}
            arrow
            placement={TooltipPlacement.Bottom}
          >
            <FavouriteIcon
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onClick={toggleFavouriteStatus}
              className={concatClassNames('favourite-icon heart', iconClassName)}
            />
          </Tooltip>
          <Tooltip title={t('productActions.removeFromCart')} arrow placement={TooltipPlacement.Bottom}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <RemoveFromCartIcon onClick={handleRemoveItemFromCart} className="remove-icon"/>
          </Tooltip>
          {cartItem.productInventoryQuantity > 1 && (
            <NumberControl
              text={t('shoppingCart.quantity')}
              number={cartItem.quantity}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onIncrement={async () => {
                await handleQuantityChange(cartItem.quantity + 1);
              }}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDecrement={async () => {
                cartItem.quantity > 1 && await handleQuantityChange(cartItem.quantity - 1);
              }}
            />
          )}
        </div>
      </div>
    </article>
  );
};

export default ShoppingCartCard;
