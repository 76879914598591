import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import { hasAdminAccess, isUserLoggedIn } from 'utils/auth';
import { NAVBAR_ROUTES, ROUTES } from 'routes/routes';
import NavigationLink from 'components/header/NavigationLink';
import LogoutButton from 'components/common/logout-button/LogoutButton';
import './MobileMenu.scss';

interface IMobileMenuProps extends IOptionalClassName {
  toggleMenu: () => void
}

const MobileMenu = ({ className, toggleMenu }: IMobileMenuProps) => {
  const { t } = useTranslation();
  const isLoggedIn = isUserLoggedIn();
  const isAdmin = hasAdminAccess();

  return (
    <article className={concatClassNames('mobile-menu', className)}>
      <div className="flex flex-column justify-center align-center mobile-menu-header">
        {NAVBAR_ROUTES.map((route) => (
          <NavigationLink
            key={route.name}
            route={route}
            onClick={toggleMenu}
            className="header-nav-link mobile-menu-header-link"
          />
        ))}
        {isAdmin && (
          <NavigationLink
            route={ROUTES.Administration}
            className="header-nav-link mobile-menu-header-link"
            onClick={toggleMenu}
          />
        )}
      </div>
      <div className="flex justify-center align-center mobile-menu-footer">
        {isLoggedIn
          ? (<LogoutButton/>)
          : (
            <>
              <Link to={ROUTES.Login.path} onClick={toggleMenu} className="flex justify-center align-center mobile-menu-footer-link">
                {t('login.title')}
              </Link>
              <span className="mobile-menu-footer-word">{t('common.or')}</span>
              <Link to={ROUTES.Register.path} onClick={toggleMenu} className="flex justify-center align-center mobile-menu-footer-link">
                {t('login.createAccount')}
              </Link>
            </>
            )}
      </div>
    </article>
  );
};

export default MobileMenu;
