import React from 'react';
import Pagination, { type PaginationProps } from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowsLeft } from 'assets/icons/arrows-left.svg';
import { ReactComponent as ArrowsRight } from 'assets/icons/arrows-right.svg';
import concatClassNames from 'utils/classNames';
import './Pagination.scss';

interface IPaginationControlsProps extends Omit<PaginationProps, 'onChange'> {
  count: number
  page: number
  onChange: (value: number) => void | undefined
}

const PaginationControls = ({
  count,
  page,
  onChange,
  className = '',
  ...props
}: IPaginationControlsProps) => {
  const paginationClassNames = concatClassNames('flex justify-center pagination', className);

  return (
    <Pagination
      count={count}
      onChange={(ev, value) => onChange(value)}
      page={page}
      className={paginationClassNames}
      showFirstButton
      showLastButton
      siblingCount={0}
      renderItem={(item) => {
        // Check if the item is the first button
        if (item.type === 'first') {
          return <PaginationItem {...item} component={ArrowsLeft}/>;
        }
        // Check if the item is the last button
        if (item.type === 'last') {
          return <PaginationItem {...item} component={ArrowsRight}/>;
        }
        // For other types, render the default
        return (
          <PaginationItem
            slots={{ previous: ArrowLeft, next: ArrowRight }}
            {...item}
          />
        );
      }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    />
  );
};

export default PaginationControls;
