import { useState } from 'react';

type DropdownOptionType = any;

const useDropdown = (initialValues: DropdownOptionType = [], shouldSaveResponse: boolean = false) => {
  const [selectedValues, setSelectedValues] = useState<DropdownOptionType>(initialValues);

  const handleSelectionChange = (event: any) => {
    if (!shouldSaveResponse) {
      setSelectedValues(event.target.value);
    } else {
      setSelectedValues(event);
    }
  };

  return {
    selectedValues,
    setSelectedValues,
    handleSelectionChange
  };
};

export default useDropdown;
