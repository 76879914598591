import i18n from 'i18next';

type ValidatorFunction<T = any> = (value: T, ...args: any[]) => string | null;

export type FieldValidators = Record<string, ValidatorFunction>;

type FormErrors = Record<string, string>;

const minLength = (min: number): ValidatorFunction => (value: string) =>
  value?.length < min ? i18n.t('validations.minLength', { min }) : null;

const maxLength = (max: number): ValidatorFunction => (value: string) =>
  value?.length > max ? i18n.t('validations.maxLength', { max }) : null;

const matchesRegex = (regex: RegExp, message: string): ValidatorFunction => (value: string) =>
  regex.test(value) ? null : i18n.t(`validations.${message}`);

const isEqualTo = (otherValue: any, message: string): ValidatorFunction => (value: any) =>
  value === otherValue ? null : i18n.t(`validations.${message}`);

const minNumber = (min: number): ValidatorFunction<number> => (value: number) =>
  value < min ? i18n.t('validations.minNumber', { min }) : null;

const maxNumber = (max: number): ValidatorFunction<number> => (value: number) =>
  value > max ? i18n.t('validations.maxNumber', { max }) : null;

const createValidator = <T>(...rules: Array<ValidatorFunction<T>>): ValidatorFunction<T> => (value: T, ...args: any[]) => {
  for (const rule of rules) {
    const error = rule(value, ...args);
    if (error) {
      return error;
    }
  }
  return null;
};

const validateFields = (values: any, fieldValidators: FieldValidators, context = {}): FormErrors => {
  const errors: FormErrors = {};

  for (const field in fieldValidators) {
    if (Object.prototype.hasOwnProperty.call(fieldValidators, field) && values !== undefined) {
      const validator = fieldValidators[field];
      if (validator) {
        const value = values[field];
        let error;
        // eslint-disable-next-line prefer-const
        error = validator(value, context);

        if (error) errors[field] = error;
      }
    }
  }

  return errors;
};

export {
  minLength,
  maxLength,
  matchesRegex,
  isEqualTo,
  minNumber,
  maxNumber,
  createValidator,
  validateFields
};
