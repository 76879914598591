import { useTranslation } from 'react-i18next';
import Popup, { type IPopup } from 'components/popup/Popup';
import { Button, ButtonType } from 'components/button/Button';
import './CompletedOrderPopup.scss';

interface ICompletedOrderPopup extends IPopup {
}

const CompletedOrderPopup = ({ open, onClose }: ICompletedOrderPopup) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      onClose={onClose}
      className="completed-order-popup"
      shouldShowCloseIcon={false}
    >
      <div className="flex flex-column" style={{ gap: 8 }}>
        <h2 className="text-center completed-order-popup-title">
          {t('shoppingCart.thankYouTitle')}
        </h2>
        <span className="text-center completed-order-popup-description">
          {t('shoppingCart.thankYouText')}
        </span>
      </div>
      <Button
        onClick={onClose}
        type={ButtonType.primary}
        className="completed-order-popup-button"
        text={t('common.close')}
      />
    </Popup>
  );
};

export default CompletedOrderPopup;
