import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, type Order } from 'utils/constants';
import { getToken } from 'utils/token';

const REVIEW_URL = 'reviews';

export enum SortBy {
  Id = 'id',
  Rating = 'rating',
  Comment = 'comment'
}

export const reviewApi = createApi({
  reducerPath: 'reviews',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: ({
        sellerId,
        sellerUsername,
        from,
        to,
        page = DEFAULT_PAGE,
        pageSize = DEFAULT_PAGE_SIZE,
        sortBy,
        order
      }) => {
        let queryString = `${REVIEW_URL}?pageSize=${pageSize}&page=${page}`;

        if (sellerId) {
          queryString += `&sellerId=${sellerId}`;
        }

        if (sellerUsername) {
          queryString += `&sellerUsername=${sellerUsername}`;
        }

        if (from) {
          queryString += `&from=${from}`;
        }

        if (to) {
          queryString += `&to=${to}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (order) {
          queryString += `&order=${order}`;
        }

        return queryString;
      }
    }),
    reportReviews: builder.mutation<any, {
      sellerId?: number
      sellerUsername?: string
      from?: string
      to?: string
      sortBy?: SortBy
      order?: Order
    }>({
      queryFn: async ({
        sellerId,
        sellerUsername,
        from,
        to,
        sortBy,
        order
      }, api, extraOptions, baseQuery) => {
        let queryString = `${REVIEW_URL}/Export?`;

        if (sellerId) {
          queryString += `&sellerId=${sellerId}`;
        }

        if (sellerUsername) {
          queryString += `&sellerUsername=${sellerUsername}`;
        }

        if (from) {
          queryString += `&from=${from}`;
        }

        if (to) {
          queryString += `&to=${to}`;
        }

        if (sortBy) {
          queryString += `&sortBy=${sortBy}`;
        }

        if (order) {
          queryString += `&order=${order}`;
        }

        const result = await baseQuery({
          url: queryString,
          responseHandler: async response => await response.blob()
        });
        const hiddenElement = document.createElement('a');
        const url = window.URL;
        hiddenElement.href = url.createObjectURL((result as any).data);
        hiddenElement.target = '_blank';
        hiddenElement.download = `reviews_report${from ? `_from_${from.split('T')[0]}` : ''}${to ? `_to_${to.split('T')[0]}` : ''}.xlsx`;
        hiddenElement.click();

        return { data: null };
      }
    }),
    addReview: builder.mutation<any, {
      rating: number
      comment: string
      sellerId: number
      sellerUsername: string
    }>({
      query: ({ sellerId, rating, comment, sellerUsername }) => ({
        url: REVIEW_URL,
        method: 'POST',
        body: {
          sellerId,
          rating,
          comment,
          sellerUsername
        }
      })
    }),
    deleteReview: builder.mutation<any, {
      id: number
    }>({
      query: ({ id }) => ({
        url: `${REVIEW_URL}/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetReviewsQuery,
  useReportReviewsMutation,
  useAddReviewMutation,
  useDeleteReviewMutation
} = reviewApi;
