enum USER_ROLES {
  GeneralAdministratorRoleName = 'General Administrator',
  RegularAdministratorRoleName = 'Regular Administrator',
  MarketingAdministratorRoleName = 'Marketing Administrator'
}

const DEFAULT_PAGE = 1;

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const DEFAULT_DEBOUNCE_TIME = 500;

const PRODUCT_IMAGES_COUNT = 6;

export enum Order {
  ASC = 'asc',
  DESC = 'desc'
}

export {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DEBOUNCE_TIME,
  PRODUCT_IMAGES_COUNT,
  USER_ROLES
};
