import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateArticleMutation } from 'store/api/blogApi';
import Form from 'components/forms/Form';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import useForm from 'hooks/useForm';
import { validateArticleFields } from 'utils/validators/admin/blogValidator';
import TextEditor from 'components/editor/TextEditor';
import { BlogTabs } from './AdminBlogTab';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PlusIcon } from 'assets/icons/photo-plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';

enum BlogCreateFields {
  File = 'file',
  CanonicalUrl = 'canonicalUrl',
  TitleEn = 'titleEn',
  TitleBg = 'titleBg',
  Heading1En = 'heading1En',
  Heading1Bg = 'heading1Bg',
  Heading2En = 'heading2En',
  Heading2Bg = 'heading2Bg',
  DescriptionEn = 'descriptionEn',
  DescriptionBg = 'descriptionBg',
  IsIndexed = 'isIndexed',
  MetaName = 'metaName',
  MetaContent = 'metaContent',
  MetaContentEn = 'metaContentEn'
}

const initialValues = {
  [BlogCreateFields.File]: null,
  [BlogCreateFields.IsIndexed]: false,
  [BlogCreateFields.CanonicalUrl]: '',
  [BlogCreateFields.TitleEn]: '',
  [BlogCreateFields.TitleBg]: '',
  [BlogCreateFields.Heading1En]: '',
  [BlogCreateFields.Heading1Bg]: '',
  [BlogCreateFields.Heading2En]: '',
  [BlogCreateFields.Heading2Bg]: '',
  [BlogCreateFields.DescriptionEn]: '',
  [BlogCreateFields.DescriptionBg]: '',
  [BlogCreateFields.MetaName]: [''],
  [BlogCreateFields.MetaContent]: [''],
  [BlogCreateFields.MetaContentEn]: ['']
};

interface IAdminBlogCreate {
  setCurrentTab: (tab: BlogTabs) => void
}

const AdminBlogCreate = ({ setCurrentTab }: IAdminBlogCreate) => {
  const { t } = useTranslation();
  const [createArticle] = useCreateArticleMutation();

  const onSubmit = async (values: any) => {
    try {
      await createArticle(values);
      setCurrentTab(BlogTabs.ManageArticles);
    } catch (err) {
      console.error('Failed to add the article:', err);
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    initialValues,
    onSubmit,
    validateArticleFields);

  const handleAddField = (fieldName: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    values[fieldName] = [...values[fieldName], ''];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    handleChange(fieldName, values[fieldName]);
  };

  const handleRemoveField = (fieldName: string, index: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    values[fieldName] = values[fieldName].filter((_: any, i: number) => i !== index);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    handleChange(fieldName, values[fieldName]);
  };

  return (
    <div className="admin-blog-create">
      <Form
        encType={'multipart/form-data'}
        onSubmit={handleSubmit}
        className="flex flex-column"
        submitText={t('administration.common.create')}
      >
        <FormControl
          className="create-banner-field"
          name={BlogCreateFields.File}
          type={FormControlType.file}
          error={errors.file}
          shouldUpdateValue
          value={values.file ?? ''}
          labelText={t('administration.banners.createBanner.file')}
          onChange={(value) => {
            handleChange(BlogCreateFields.File, value);
          }}
        />
        <FormControl
          name={BlogCreateFields.IsIndexed}
          labelText="Is Indexed"
          checked={values.isIndexed}
          error={errors.isIndexed}
          shouldUpdateValue
          type={FormControlType.checkbox}
          containerClassName="flex align-center indexed-checkbox"
          onChange={(value) => {
            handleChange(BlogCreateFields.IsIndexed, value);
          }}
        />
        <FormControl
          name={BlogCreateFields.CanonicalUrl}
          labelText="Canonical URL"
          value={values.canonicalUrl}
          error={errors.canonicalUrl}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(BlogCreateFields.CanonicalUrl, value);
          }}
        />
        <FormControl
          name={BlogCreateFields.TitleEn}
          labelText="Title En"
          value={values.titleEn}
          error={errors.titleEn}
          shouldUpdateValue
          type={FormControlType.text}
          onChange={(value) => {
            handleChange(BlogCreateFields.TitleEn, value);
          }}
        />
        <FormControl
            name={BlogCreateFields.TitleBg}
            labelText="Title Bg"
            value={values.titleBg}
            error={errors.titleBg}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogCreateFields.TitleBg, value);
            }}
        />
        <FormControl
            name={BlogCreateFields.Heading1En}
            labelText="Heading 1 En"
            value={values.heading1En}
            error={errors.heading1En}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogCreateFields.Heading1En, value);
            }}
        />
        <FormControl
            name={BlogCreateFields.Heading1Bg}
            labelText="Heading 1 BG"
            value={values.heading1Bg}
            error={errors.heading1Bg}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogCreateFields.Heading1Bg, value);
            }}
        />
        <FormControl
            name={BlogCreateFields.Heading2En}
            labelText="Heading 2 En"
            value={values.heading2En}
            error={errors.heading2En}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogCreateFields.Heading2En, value);
            }}
        />
        <FormControl
            name={BlogCreateFields.Heading2Bg}
            labelText="Heading 2 BG"
            value={values.heading2Bg}
            error={errors.heading2Bg}
            shouldUpdateValue
            type={FormControlType.text}
            onChange={(value) => {
              handleChange(BlogCreateFields.Heading2Bg, value);
            }}
        />
        <TextEditor
          name={BlogCreateFields.DescriptionEn}
          label="Description En"
          value={values.descriptionEn}
          error={errors.descriptionEn}
          onChange={(newContent) => {
            handleChange(BlogCreateFields.DescriptionEn, newContent);
          }}
        />
        <TextEditor
            name={BlogCreateFields.DescriptionBg}
            label="Description Bg"
            value={values.descriptionBg}
            error={errors.descriptionBg}
            onChange={(newContent) => {
              handleChange(BlogCreateFields.DescriptionBg, newContent);
            }}
        />
        {values.metaName.map((_, index) => (
          <div key={index} className="meta-tag-group">
            <FormControl
              name={`${BlogCreateFields.MetaName}[${index}]`}
              value={values.metaName[index]}
              labelText={`${t('administration.blog.create.metaTagName')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaName}
              type={FormControlType.text}
              onChange={(value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                const metaNames = [...values.metaName];
                metaNames[index] = value;
                handleChange(BlogCreateFields.MetaName, metaNames);
              }}
            />
            <FormControl
              name={`${BlogCreateFields.MetaContent}[${index}]`}
              value={values.metaContent[index]}
              labelText={`${t('administration.blog.create.metaTagContent')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaContent}
              type={FormControlType.text}
              onChange={(value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                const metaContents = [...values.metaContent];
                metaContents[index] = value;
                handleChange(BlogCreateFields.MetaContent, metaContents);
              }}
            />
            <FormControl
              name={`${BlogCreateFields.MetaContentEn}[${index}]`}
              value={values.metaContentEn[index]}
              labelText={`${t('administration.blog.create.metaTagContentEn')} ${index + 1}`}
              shouldUpdateValue
              error={errors.metaContent}
              type={FormControlType.text}
              onChange={(value) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                const metaContentsEn = [...values.metaContentEn];
                metaContentsEn[index] = value;
                handleChange(BlogCreateFields.MetaContentEn, metaContentsEn);
              }}
            />
            {index !== 0 && (
              <IconButton onClick={() => {
                handleRemoveField(BlogCreateFields.MetaName, index);
              }} className="add-btn">
                <MinusIcon/>
              </IconButton>
            )}
          </div>
        ))}
        <IconButton onClick={() => {
          handleAddField(BlogCreateFields.MetaName);
        }} className="remove-btn">
          <PlusIcon/>
        </IconButton>
      </Form>
    </div>
  );
};

export default AdminBlogCreate;
