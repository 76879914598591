import { useTranslation } from 'react-i18next';
import ProductsOnFocusSlider from 'components/home/ProductsOnFocusSlider';
import { useGetProductsQuery } from 'store/api/productApi';
import './HomeProducts.scss';

const HomeProducts = () => {
  const { t } = useTranslation();
  const {
    data: homePageProducts,
    isLoading,
    error
  } = useGetProductsQuery({ onlyForHomePage: true });

  if (homePageProducts?.models.length === 0) {
    return null;
  }

  return (
    <section className="home-products">
      <article className="flex flex-column justify-center align-center section-padding">
        <div className="flex flex-column justify-center align-center home-products-content">
          <h2 className="text-center home-products-content-title">{t('home.productsOnFocusTitle')}</h2>
        </div>
      </article>
      <article className="home-products-slider">
        <ProductsOnFocusSlider
          products={homePageProducts?.models}
          isLoading={isLoading}
          error={error}
        />
      </article>
    </section>
  );
};

export default HomeProducts;
