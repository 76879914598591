import React from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import ErrorFormControl from 'components/forms/ErrorFormControl';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import './Dropdown.scss';

export interface OptionType {
  id: number | string
  name: string
}

interface IDropdownProps extends IOptionalClassName {
  label: string
  options: OptionType[]
  value: number | number[] | string | string[]
  onChange: any
  disabled?: boolean
  renderValue?: (selected: any) => React.ReactNode
  error?: string
  multiple?: boolean
  onClick?: any
}

const Dropdown = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
  renderValue,
  error,
  multiple = true,
  className,
  onClick
}: IDropdownProps) => {
  const isMultiple = multiple;
  const stringValues = isMultiple ? (Array.isArray(value) ? value.map(String) : []) : [value.toString()];

  const handleChange = (event: any) => {
    if (multiple) {
      onChange(event);
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <div className={concatClassNames('flex flex-column', className)}>
      <FormControl variant="standard" fullWidth className="dropdown">
        <InputLabel className="dropdown-label">{label}</InputLabel>
        <Select
          multiple={isMultiple}
          value={stringValues}
          label={label}
          onChange={handleChange}
          renderValue={renderValue}
          disabled={disabled}
          className={isMultiple ? 'dropdown-select-multiple' : 'dropdown-select'}
          onClick={onClick}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id.toString()} className="dropdown-select-item">
              {isMultiple && (
                <Checkbox
                  checked={stringValues.includes(option.id.toString())}
                />
              )}
              <ListItemText primary={option.name}/>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && <ErrorFormControl error={error}/>}
    </div>
  );
};

export default Dropdown;
