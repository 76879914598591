import React from 'react';
import { type IOptionalClassName } from 'types/common/Props';
import concatClassNames from 'utils/classNames';
import './Label.scss';

export enum LabelColor {
  Primary = 'primary',
  Secondary = 'secondary'
}

interface ILabel extends IOptionalClassName {
  text: string
  color?: LabelColor
}

const Label = ({ text, color = LabelColor.Primary, className }: ILabel) => (
  <div className={concatClassNames('label', color, className)}>{text}</div>
);

export default Label;
