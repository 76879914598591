import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'components/tabs/Tabs';
import AdminBlogCreate from 'components/administration/blog/AdminBlogCreate';
import AdminBlogArticleManager from 'components/administration/blog/AdminBlogManager';
import AdminBlogPublisher from 'components/administration/blog/AdminBlogPublisher';

export enum BlogTabs {
  Create = 'Create',
  ManageArticles = 'Manage articles',
  PublisherName = 'Publisher name',
}

const AdminBlogTab = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(BlogTabs.Create);

  const tabs = useMemo(() => [
    {
      label: BlogTabs.Create,
      content: <AdminBlogCreate setCurrentTab={setCurrentTab}/>,
      name: t('administration.blog.create.title')
    },
    {
      label: BlogTabs.ManageArticles,
      content: <AdminBlogArticleManager/>,
      name: t('administration.blog.manageArticles.title')
    },
    {
      label: BlogTabs.PublisherName,
      content: <AdminBlogPublisher/>,
      name: t('administration.blog.publisherName.title')
    }
  ], []);

  return (
    <Tabs
      className="admin-blog-tabs"
      tabsData={tabs}
      ariaLabel="blog tabs"
      activeTab={currentTab}
    />
  );
};

export default AdminBlogTab;
