import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from 'i18n';
import { DataGrid, type GridColDef, type GridRenderCellParams, type GridValueGetterParams } from '@mui/x-data-grid';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils/constants';
import { useGetOrdersProfitQuery } from 'store/api/orderApi';
import Pagination from 'components/pagination/Pagination';
import { getCurrencySymbol } from 'utils/currency';
import useWindowWidth from 'hooks/useWindowWidth';
import ReturnProductToActivePopup from 'components/return-product-to-active-popup/ReturnProductToActivePopup';
import './ProfileRequestedProducts.scss';

const ProfileRequestedProducts = () => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [queryParams, setQueryParams] = useState({ page: DEFAULT_PAGE });
  const { data: profit, refetch } = useGetOrdersProfitQuery(queryParams);
  const [returnProductToActivePopupOpen, setReturnProductToActivePopupOpen] = useState(false);
  const [currentOrderItem, setCurrentOrderItem] = useState<{ id: number, name: string }>({
    id: 0,
    name: ''
  });

  const currentLanguage = i18n.language;

  const handleReturnToActiveClick = (orderItemId: number, orderItemName: string) => {
    setCurrentOrderItem({
      id: orderItemId,
      name: orderItemName
    });
    setReturnProductToActivePopupOpen(true);
  };

  const dataColumnsDesktop: GridColDef[] = [
    {
      field: 'productName',
      headerName: t('profile.payments.productName'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return currentLanguage === Language.BG ? params.row.productName.bulgarian : params.row.productName.english;
      }
    },
    {
      field: 'sizeName',
      flex: 1,
      headerName: t('profile.payments.size'),
      disableColumnMenu: true
    },
    {
      field: 'quantity',
      flex: 1,
      headerName: t('profile.payments.quantity'),
      disableColumnMenu: true
    },
    {
      field: 'unitPrice',
      headerName: t('profile.payments.income'),
      flex: 1,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.unitPrice.amount.toFixed(2)} ${getCurrencySymbol(params.row.unitPrice.currency)}`;
      }
    },
    {
      field: 'createdOn',
      headerName: t('profile.payments.date'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column">
          {new Date(params.row.createdOn).toLocaleDateString()}
          <span
            className="return-to-active"
            onClick={() => {
              handleReturnToActiveClick(
                params.row.id,
                currentLanguage === Language.BG
                  ? params.row.productName.bulgarian
                  : params.row.productName.english
              );
            }}
          >
            {t('profile.payments.returnToActive')}
          </span>
        </div>
      )
    }
  ];

  const dataColumnsMobile: GridColDef[] = [
    {
      field: 'productName',
      headerName: t('profile.payments.productName'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <div className="flex flex-column">
          <span>{new Date(params.row.createdOn).toLocaleDateString()}</span>
          <span>{currentLanguage === Language.BG ? params.row.productName.bulgarian : params.row.productName.english}</span>
        </div>
      )
    },
    {
      field: 'sizeName',
      flex: 1,
      headerName: t('profile.payments.size'),
      disableColumnMenu: true
    },
    {
      field: 'quantity',
      width: 50,
      headerName: t('profile.payments.quantity'),
      disableColumnMenu: true
    },
    {
      field: 'unitPrice',
      headerName: t('profile.payments.income'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="flex flex-column">
          {params.row.unitPrice.amount.toFixed(2)} {getCurrencySymbol(params.row.unitPrice.currency)}
          <span
            className="return-to-active"
            onClick={() => {
              handleReturnToActiveClick(
                params.row.id,
                currentLanguage === Language.BG
                  ? params.row.productName.bulgarian
                  : params.row.productName.english
              );
            }}
          >
            {t('profile.payments.returnToActive')}
          </span>
        </div>
      )
    }
  ];

  if (profit?.models.length === 0) {
    return <div>{t('profile.payments.noIncome')}</div>;
  }

  return (
    <div className="profile-requested-products">
      <DataGrid
        className="profile-data-grid"
        rows={profit?.models ?? []}
        columns={isMobile ? dataColumnsMobile : dataColumnsDesktop}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }
          }
        }}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[DEFAULT_PAGE_SIZE]}
        disableColumnFilter
        rowCount={profit?.totalCount ?? 0}
        paginationMode='server'
        onPaginationModelChange={(e) => {
          setQueryParams({ page: e.page + 1 });
        }}
      />
      <div className="flex align-center justify-end profile-requested-products-table-footer">
        <span className="profile-requested-products-table-footer-text">{t('profile.payments.totalIncome')}:</span>
        <span className="profile-requested-products-table-footer-price">{profit?.totalAmount.toFixed(2)} лв.</span>
      </div>
      <Pagination
        count={profit?.totalPages ?? 1}
        page={queryParams.page}
        onChange={(value) => {
          setQueryParams({ page: value });
        }}
      />
      <ReturnProductToActivePopup
        orderItemId={currentOrderItem.id}
        orderItemName={currentOrderItem.name}
        refetch={refetch}
        open={returnProductToActivePopupOpen}
        onClose={() => {
          setReturnProductToActivePopupOpen(false);
        }}
      />
    </div>
  );
};

export default ProfileRequestedProducts;
