import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SectionCard from 'components/cards/section-card/SectionCard';
import RadioButtons from 'components/radio-buttons/RadioButtons';
import FormControl, { FormControlType } from 'components/forms/FormControl';
import CartInvoice from './CartInvoice';

const FieldName = {
  Invoice: 'invoice'
};

const CartPaymentOptions = ({ onInvoiceDataChange }: any) => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState(false);
  const paymentOptions = [
    { value: 1, label: t('shoppingCart.paymentOptions.cashOnDelivery') }
  ];

  const handleChange = (fieldName: string, value: any) => {
    if (fieldName === FieldName.Invoice) {
      setInvoice(value);
    }
  };

  return (
    <SectionCard title={t('shoppingCart.paymentOptions.title')}>
      <RadioButtons
        options={paymentOptions}
        value={paymentOptions[0].value}
      />
      <FormControl
        id={FieldName.Invoice}
        name={FieldName.Invoice}
        labelText={t('shoppingCart.paymentOptions.iWantAnInvoice')}
        type={FormControlType.checkbox}
        containerClassName="flex align-center invoice-checkbox"
        onChange={(value) => {
          handleChange(FieldName.Invoice, value);
        }}/>
      {invoice && (
        <CartInvoice onInvoiceDataChange={onInvoiceDataChange}/>
      )}
    </SectionCard>
  );
};

export default CartPaymentOptions;
