import Cookies from 'js-cookie';
import { getStorageItem, removeStorageItem, setStorageItem, StorageType } from 'utils/storage';
import { COOKIE_CONSENT_KEY, CookieConsentType } from 'components/cookie-policy/CookiePolicy';

export const USER_TOKEN = 'token';
export const TOKEN_EXPIRY = 'token_expiry';

const getUserAcceptedCookies = () => getStorageItem(COOKIE_CONSENT_KEY) === CookieConsentType.accepted;

const storeToken = (token: string, rememberMe: boolean = false) => {
  const userAcceptedCookies = getUserAcceptedCookies();

  const decodedToken = decodeToken(token);
  const expirationDays = getTokenExpirationDays(decodedToken.exp);
  const expiryInMilliseconds = expirationDays * 24 * 60 * 60 * 1000; // Convert days to milliseconds
  const expiryTime = new Date().getTime() + expiryInMilliseconds; // future time when the token will expire

  if (userAcceptedCookies) {
    const cookieOptions = rememberMe ? { expires: expirationDays } : {};
    return Cookies.set(USER_TOKEN, token, { sameSite: 'strict', ...cookieOptions });
  }

  if (rememberMe) {
    setStorageItem(USER_TOKEN, token);
    setStorageItem(TOKEN_EXPIRY, expiryTime.toString());
  } else {
    setStorageItem(USER_TOKEN, token, StorageType.LocalStorage);
  }
};

const getToken = () => {
  const userAcceptedCookies = getUserAcceptedCookies();

  if (userAcceptedCookies) {
    const token = Cookies.get(USER_TOKEN);
    // Check if token is expired
    if (token) {
      const decodedToken = decodeToken(token);
      const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
      if (decodedToken.exp < currentTimestamp) {
        removeToken(); // Remove token from cookie if it has expired
        return null;
      }
    }
    return token;
  } else {
    const now = new Date();
    const expiryTime = getStorageItem(TOKEN_EXPIRY);
    // Check if token is expired
    if (expiryTime && now.getTime() > parseInt(expiryTime)) {
      removeTokenFromStorage(); // Remove token from storage if it has expired
      return null;
    }
    return getStorageItem(USER_TOKEN) ?? getStorageItem(USER_TOKEN, StorageType.SessionStorage);
  }
};

const removeTokenFromStorage = () => {
  removeStorageItem(USER_TOKEN);
  removeStorageItem(TOKEN_EXPIRY);
  removeStorageItem(USER_TOKEN, StorageType.SessionStorage);
};

const removeToken = () => {
  const userAcceptedCookies = getUserAcceptedCookies();

  if (userAcceptedCookies) {
    Cookies.remove(USER_TOKEN);
  } else {
    removeTokenFromStorage();
  }
};

const migrateTokenToCookie = () => {
  const token = getStorageItem(USER_TOKEN) ?? getStorageItem(USER_TOKEN, StorageType.SessionStorage);

  if (token) {
    storeToken(token, true);
    removeTokenFromStorage();
  }
};

const decodeToken = (token: string) => {
  // Split the token into parts and get the payload part
  const base64Url = token.split('.')[1];

  // Replace necessary characters for decoding
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  // Convert the Base64 string to a JSON string
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload); // Parse the JSON string and return the parsed object
};

const getTokenExpirationDays = (expirationTimestamp: number) => {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
  const secondsUntilExpiration = expirationTimestamp - currentTimestamp; // Difference in seconds
  const daysUntilExpiration = secondsUntilExpiration / 24 / 60 / 60; // Convert to days

  return Math.round(daysUntilExpiration);
};

export {
  storeToken,
  getToken,
  removeToken,
  decodeToken,
  getTokenExpirationDays,
  migrateTokenToCookie
};
