import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl as MuiFormControl, InputLabel, MenuItem, Select } from '@mui/material';
import concatClassNames from 'utils/classNames';
import { type IClassName } from 'types/common/Props';
import { SortBy, useGetAllArticlesQuery } from 'store/api/blogApi';
import Pagination from 'components/pagination/Pagination';
import ArticleCard from 'components/cards/article-card/ArticleCard';
import useWindowWidth from 'hooks/useWindowWidth';
import FormControl, { FormControlType, type IFormControlOnChangeValueType } from 'components/forms/FormControl';
import debounce from 'utils/debounce';
import { Order } from 'utils/constants';
import './AdminBlog.scss';

interface IBlogProps extends IClassName {
  onDelete?: (id: number) => void
  onEdit?: (id: number) => void
  onChangeVisibility?: (id: number, isPublic: boolean) => void
  refetchArticles?: boolean
}

const PAGE_SIZE = {
  DESKTOP: 12,
  MOBILE: 5
};

const AdminBlog = ({ className, onDelete, onEdit, onChangeVisibility, refetchArticles }: IBlogProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTitle, setSearchTitle] = useState<IFormControlOnChangeValueType>('');
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Date);
  const [order, setOrder] = useState<Order>(Order.DESC);

  const pageSize = isMobile ? PAGE_SIZE.MOBILE : PAGE_SIZE.DESKTOP;

  const { data: articles, refetch } = useGetAllArticlesQuery({
    pageSize,
    page: currentPage,
    title: searchTitle?.toString(),
    sortBy,
    order
  });

  useEffect(() => {
    refetch().catch((error) => {
      console.error('An error occurred:', error);
    });
  }, [refetchArticles, refetch]);

  const debouncedSearch = debounce((query) => {
    setSearchTitle(query);
    setCurrentPage(1);
  }, 300);

  const handleSearchChange = (value: IFormControlOnChangeValueType) => {
    debouncedSearch(value);
  };

  useEffect(() => {
    return () => {
      if (debouncedSearch?.cancel) {
        debouncedSearch.cancel();
      }
    };
  }, [debouncedSearch]);

  return (
    <section className={concatClassNames(className, 'flex flex-column align-center blog admin-blog')}>
      <div className="flex justify-center align-center admin-blog-filter">
        <FormControl
          type={FormControlType.text}
          name="search"
          labelText={t('administration.blog.manageArticles.searchByTitle')}
          value={searchTitle?.toString()}
          onChange={handleSearchChange}
          containerClassName="search-container"
        />
        <MuiFormControl variant="outlined" className="sort-container">
          <InputLabel id="sort-by-label">
            {t('common.sort.sortBy')}
          </InputLabel>
          <Select
            labelId="sort-by-label"
            value={sortBy}
            onChange={(event) => {
              setSortBy(event.target.value as SortBy);
            }}
            label={t('common.sort.sortBy')}
          >
            <MenuItem value={SortBy.Title}>
              {t('administration.blog.manageArticles.sortByTitle')}
            </MenuItem>
            <MenuItem value={SortBy.Date}>
              {t('administration.blog.manageArticles.date')}
            </MenuItem>
          </Select>
        </MuiFormControl>
        <MuiFormControl variant="outlined" className="order-container">
          <InputLabel id="order-label">
            {t('common.sort.orderBy')}
          </InputLabel>
          <Select
            labelId="order-label"
            value={order}
            onChange={(event) => {
              setOrder(event.target.value as Order);
            }}
            label={t('common.sort.orderBy')}
          >
            <MenuItem value={Order.ASC}>
              {t('common.sort.ascending')}
            </MenuItem>
            <MenuItem value={Order.DESC}>
              {t('common.sort.descending')}
            </MenuItem>
          </Select>
        </MuiFormControl>

      </div>
      <article className="blog-content">
        <article className="blog-content-posts">
          {articles?.models.map(article => (
            <ArticleCard
              key={article.id}
              article={article}
              onDelete={onDelete}
              onChangeVisibility={onChangeVisibility}
              onEdit={onEdit}
            />
          ))}
        </article>
        <Pagination
          count={articles?.totalPages ?? 1}
          page={currentPage}
          onChange={setCurrentPage}
        />
      </article>
    </section>
  );
};

export default AdminBlog;
