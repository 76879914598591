import { useTranslation } from 'react-i18next';
import OurMissionImage from 'assets/images/home/our-mission.png';
import { LinkButton, LinkButtonType } from 'components/button/Button';
import OurMissionButton from 'assets/images/home/our-mission-button.png';
import OurMissionButtonEn from 'assets/images/home/our-mission-button-en.png';
import { ROUTES } from 'routes/routes';
import { Language } from 'i18n';
import './HomeOurMission.scss';
import { useGetPageDetailsQuery } from 'store/api/pagesApi';
import { useCallback, useMemo } from 'react';
import { getLocalizedValue } from 'utils/localization';
import { isEmpty, isNil } from 'lodash';
import { extractTextFromHtml } from 'utils/text';

const HomeOurMission = () => {
  const { data: pageSeoData } = useGetPageDetailsQuery({ url: ROUTES.Home.path });

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const ourMissionButtonImage = useMemo(
    () => language === Language.BG
      ? OurMissionButton
      : OurMissionButtonEn,
    [language]
  );

  const description = useMemo(
    () => {
      if (isNil(pageSeoData)) {
        return null;
      }

      const {
        descriptionBulgarianContent,
        descriptionEnglishContent
      } = pageSeoData;

      return getLocalizedValue(language, descriptionBulgarianContent, descriptionEnglishContent);
    },
    [pageSeoData]
  );

  const renderDescription = useCallback(
    () => {
      if (isNil(description)) {
        return null;
      }

      const descriptionParts = extractTextFromHtml(description);
      console.log(descriptionParts);
      return descriptionParts
        .split('\n')
        .map(paragraph => paragraph.trim())
        .filter(paragraph => !isNil(paragraph) && !isEmpty(paragraph))
        .map((paragraph, index) => (
          <p key={index} className="home-our-mission-content-text">
            {paragraph}
          </p>
        ));
    },
    [description]
  );

  return (
    <section className="justify-center align-center section-padding home-our-mission">
      <div className="flex justify-center align-center">
        <img alt="Нашата мисия" src={OurMissionImage} className="home-our-mission-image" />
      </div>
      <article className="flex flex-column home-our-mission-content">
        <h2 className="home-our-mission-content-title">{t('home.ourMissionTitle')}</h2>
        {/* <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} /> */}
        {renderDescription()}
        <LinkButton
          type={LinkButtonType.image}
          imgSrc={ourMissionButtonImage}
          className="home-our-mission-content-button"
          to={ROUTES.CreateNewProduct.path}
        />
      </article>
    </section>
  );
};

export default HomeOurMission;
