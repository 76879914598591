import { type IPagination } from 'types/IPagination';

interface IProductInventoryItem {
  quantity: number
  sizeId?: number
  sizeName?: string
}

interface IProductCategory {
  id: number
  nameEn?: string
  nameBg?: string
  canonicalUrl?: string
}

interface IProductRequestBody {
  id: number
  nameEnglish: string
  nameBulgarian: string
  isGift: boolean
  price: number
  weight: number
  label: number
  descriptionEnglish: string
  descriptionBulgarian: string
  gendersIds: number[]
  categoriesIds: number[]
  file: File[]
  inventoryItems: IProductInventoryItem[]
  filesToDelete?: string[]
}

interface IProductName {
  english: string
  bulgarian: string
}

interface IProductDescription {
  english: string
  bulgarian: string
}

interface ICurrency {
  value: number
  name: string
}

interface ILabel {
  value: number
  name: string
}

interface IBadge {
  value: number
  name: string
}

interface IPrice {
  amount: number
  currency: ICurrency
}

interface ISeller {
  id: number
  displayName: string
}

interface IProductResponse {
  id: number
  seller: ISeller
  name: IProductName
  price: IPrice
  label: ILabel
  badge: IBadge
  mainImageSource: string
  categories: IProductCategory[]
  inventoryItems: IProductInventoryItem[]
}

interface IProductMineResponse {
  id: number
  name: IProductName
  price: IPrice
  label: ILabel
  badge: IBadge
  mainImageSource: string
  showOnHomePage: boolean
  createdOn: string
}

interface IProductResponseModel extends IPagination {
  models: IProductResponse[]
}

interface IGetProductsRequestBody {
  labels?: number[]
  sizes?: number[]
  genders?: number[]
  categories?: number[]
  onlyGifts?: boolean
  onlyInSale?: boolean
  onlyWithBadge?: boolean
  onlyForHomePage?: boolean
  sortBy?: string
  order?: string
  pageSize?: number
  page?: number
  favourites?: boolean
}

interface IGetProductsByUserRequestBody extends IGetProductsRequestBody {
  userId: string
}

enum ProductLabel {
  New = 1,
  Used = 2,
}

enum ProductBadge {
  None = 1,
  BabyOnTheWay = 2,
  HotDeal = 3,
  Discount = 4,
  GoodAsNew = 5
}

enum GenderIds {
  Girls = 1,
  Boys = 2,
  Twins = 3,
  Unisex = 4,
}

interface Currency {
  name: string
  value: number
}

interface IProductInventoryItems {
  quantity: number
  sizeName: string
}

interface IProductLabel {
  value: number
  name: string
}

interface IProductPrice {
  amount: number
  currency: Currency
}

interface IProductForSale {
  id: number
  description: IProductDescription
  inventoryItems: IProductInventoryItems[]
  label: IProductLabel
  name: IProductName
  price: IProductPrice
  sellerUsername: string
}

interface IProductForSaleApiResponse extends IPagination {
  models: IProductForSale[]
  totalAmountForSoldProducts: number
  totalCount: number
}

interface IProductsMineResponseModel extends IPagination {
  models: IProductMineResponse[]
  totalCount: number
}

export type {
  IProductInventoryItem,
  IProductRequestBody,
  IGetProductsRequestBody,
  IGetProductsByUserRequestBody,
  IProductResponseModel,
  IProductInventoryItems,
  IProductForSale,
  IProductForSaleApiResponse,
  IProductResponse,
  IProductName,
  IProductDescription,
  IProductPrice,
  IProductLabel,
  IProductCategory,
  IProductsMineResponseModel,
  IProductMineResponse,
  ICurrency,
  ISeller,
  IPrice
};

export {
  ProductLabel,
  ProductBadge,
  GenderIds
};

export type {
  Currency
};
